import React from "react";
import { Table, Label, TextBox, Column } from "lib/components";
import PanelCustomerOrderDetail from "./PanelCustomerOrderDetail";
import { useData } from "lib/components/Context";
import { getDataFromContext } from "lib/util/ModelUtil";
import { formatCityStateZip } from "lib/util/CityUtil";

export default function TableCustomerOrders({showETAColumn=true, showStatusColumn, description, ...props}) {
	const closeSpacing = {marginTop:-4};
	const alignAdjust = {marginTop:0};
    let emptyCaption;
    if (description == null)
        emptyCaption = "No orders available for display";
    else
        emptyCaption = "No " + description + " orders available for display";
    return (
		<Table {...props} expandComponent={PanelCustomerOrderDetail} emptyCaption={emptyCaption}
			defaultSort={[{fieldName:"shipper.sched_arrive_early", dataType:"datetime", ascending: false}]} exportName="Order Listing" cellVerticalAlign="top">
			<Column caption="BOL number" fieldName="orders.blnum" minWidth={100}/>
    		<Column caption="Tracking numbers" sortFieldName="reference_number" minWidth={160}>
                <TextBox caption="Order number" fieldName="orders.id" color="primary" variant="label" style={alignAdjust} />
                <TextBox caption="{reference_number_descr}" fieldName="reference_number" variant="label" nullDisplayValue="N/A"/>
                <TextBox caption="Shipper PO number" fieldName="po_number" variant="label" nullDisplayValue="N/A" visible={isBRLTL}/>
            </Column>
			<Column caption="Status" sortFieldName="status_descr" visible={showStatusColumn} minWidth={140}>
				<StatusLabel />
			</Column>
			<Column caption="ETA" visible={showETAColumn} sortFieldName="eta_date_time" sortDataType="datetime" minWidth={180}>
				<TextBox caption="{eta_label}" fieldName="eta_date_time" displayType="date" format="long" variant="label" style={alignAdjust}/>
				<Label fieldName="eta_date_time" export={false} displayType="time" format="long" style={closeSpacing} />
			</Column>
			<Column caption="Shipper" sortFieldName="shipper.sched_arrive_early" sortDataType="datetime" minWidth={180}>
                <Label caption={shipperLocation} color="primary" fontBold displayLabel="Shipper" />
				<TextBox caption="Pickup" fieldName="shipper.sched_arrive_early" variant="label" displayLabel="Pickup date" displayType="date" format="long" style={closeSpacing}/>
				<Label fieldName="shipper.sched_arrive_early" export={false} displayType="time" format="long" style={closeSpacing} />
			</Column>
			<Column caption="Consignee" sortFieldName="consignee.sched_arrive_early" sortDataType="datetime" minWidth={180}>
                <Label caption={consigneeLocation} color="primary" displayLabel="Consignee" fontBold />
				<TextBox caption="Delivery" fieldName="consignee.sched_arrive_early" variant="label" displayLabel="Delivery date" displayType="date" format="long"  style={closeSpacing}/>
				<Label fieldName="consignee.sched_arrive_early" export={false} displayType="time" format="long" style={closeSpacing}/>
			</Column>
		</Table>
	);
}

function shipperLocation(context) {
	return formatLocation(context, "shipper");
}

function consigneeLocation(context) {
	return formatLocation(context, "consignee");
}

function formatLocation(context, prefix) {
	const data = getDataFromContext(context);
	return formatCityStateZip(data[prefix + ".city_name"], data[prefix + ".state"], data[prefix + ".zip_code"]);
}

function isBRLTL(context) {
	const data = getDataFromContext(context);
	return data && data["isBrLtl"];
}

function StatusLabel() {
	let status = useData()["orders.status"];
	let imageName;
	if (status === "D")
		imageName = "circle-blue-check";
	else if (status === "P")
		imageName = "circle-filled-blue";
	else
		imageName = "circle-empty-blue";
	return <Label fieldName="status_descr" image={imageName} />
}
