import { makeStyles } from "@material-ui/core/styles";
import { successColors, errorColors } from "core/config/theme";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    success: {
        color: successColors.main,
        fontSize: "medium",
        marginRight: 4
    },
    error: {
        color: errorColors.main,
        fontSize: "medium",
        marginRight: 4
    },
    grow: {
        flexGrow: 1
    },
    reply: {
        width: 26,
        height: 26,
        marginTop: 8,
        marginRight: -6,
        alignItems: "center"
    },
    row: {
        "& li": {
            marginLeft: theme.spacing(0),
            "&:first-of-type": {
                marginLeft: 0,
                marginRight: 1
            },
            "&:last-of-type": {
                marginRight: theme.spacing(7)
            }
        }
    },
    gutters: {
        paddingRight: theme.spacing(0),
        paddingLeft: theme.spacing(0)
    }
}));
