import React from "react";
import { Tooltip, CircularProgress} from "@material-ui/core";
import {isSpecified, getSizeSpecifier, getMarginSpecifier} from "./ComponentUtil";
import { makeStylesLight, classNames } from "lib/util";
import { useComponentContext } from "./Context";
import { getDataFromContext } from "lib/util/ModelUtil";

let styles;

function useStyles() {
  if (styles == null)
    styles = makeStylesLight("cmp", {
      component: { margin: "4px" },
      overflowAuto: {overflow: "auto"},
      overflowHide: {overflow: "hidden"},
      fontBold: {	fontWeight: "500" },
      font_primary: {color: "rgb(19, 124, 221)" },
      font_disabled: { color: "rgb(102, 102, 102)" },
      font_xsmall: {fontSize: "10px" },
      font_small: {	fontSize: "12px" },
      font_medium: { fontSize: "14px" },
      font_large: {	fontSize: "16px" },
      font_xlarge: { fontSize: "20px" },
      fillHeight: {height:"100%", overflow: "hidden"},
      backgroundColor: props => ({backgroundColor: props.backgroundColor}),
      progress: {position: "absolute", top: "0px", bottom: "0px", left:"0px", right: "0px",
        display:"flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgb(0, 0, 0, 0.2)", zIndex: "100"}
    });
  return styles;
}

export default function Component(props) {
	let classes = useStyles(props);
	let componentContext = useComponentContext();
    if (!isVisible(props, componentContext))
        return null;
	let renderProps = { id: props.name, className: getClassName(classes, props) };
	if (props.onClick)
		renderProps.onClick = (event) => props.onClick(event, componentContext, getDataFromContext(componentContext));
	// probably should use some fancy MUI makeStyles to build classes for these
    renderProps.style = {
		...props.style,
        ...getMarginStyle(props),
        ...getPaddingStyle(props),
        ...getSizeStyle(props),
        ...getAlignmentStyle(props),
        ...getBorderStyle(props),
        ...getCursorStyle(props),
        ...getColorStyle(props),
	};
	let progress;
	if (props.loading)
		progress = <div className={classes.progress}><CircularProgress size={24}/> </div>
	let result;
	if (props.tooltip)
        result = (
            <div {...renderProps}>
                <Tooltip title={props.tooltip}>{props.children}</Tooltip>
            </div>
		);
	else
        result = <div {...renderProps}>{props.children}{progress}</div>
    return result;
}

function isVisible(props, componentContext) {
	if (props.visible === false)
		return false;
	else if (typeof props.visible === "function")
		return props.visible(componentContext);
	else
		return true;
}

function getClassName(classes, props) {
	let result = [];
	if (isSpecified(props.className))
		result.push(props.className);
    if (props.fontSize != null)
        result.push(classes["font_"+ props.fontSize]);
    if (props.fontBold === true)
        result.push(classes.fontBold);
    if (props.color != null) {
		const colorClass = classes["font_" + props.color];
		if (colorClass)
			result.push(colorClass);
	}
	if (props.fillHeight)
    result.push(classes.fillHeight);
	if (props.overflow != null) {
		if (props.overflow === "hidden")
			result.push(classes.overflowHide);
		else if (props.overflow === "auto")
			result.push(classes.overflowAuto);
	}
	result.push(classes.component);
	return classNames(result);
}

function appendObject(o, key, value) {
	if (value != null) {
		if (o == null)
			o = {};
		o[key] = value;
	}
	return o;
}

function getMarginStyle(props) {
	let result;
	result = appendObject(result, "margin", getMarginSpecifier(props.margin));
	result = appendObject(result, "marginTop", getMarginSpecifier(props.marginTop));
	result = appendObject(result, "marginBottom", getMarginSpecifier(props.marginBottom));
	result = appendObject(result, "marginLeft", getMarginSpecifier(props.marginLeft));
	result = appendObject(result, "marginRight", getMarginSpecifier(props.marginRight));
	return result;
}

function getPaddingStyle(props) {
	let result;
	result = appendObject(result, "padding", getMarginSpecifier(props.padding));
	result = appendObject(result, "paddingTop", getMarginSpecifier(props.paddingTop));
	result = appendObject(result, "paddingBottom", getMarginSpecifier(props.paddingBottom));
	result = appendObject(result, "paddingLeft", getMarginSpecifier(props.paddingLeft));
	result = appendObject(result, "paddingRight", getMarginSpecifier(props.paddingRight));
	return result;
}

function getSizeStyle(props) {
    let style = {};
    if (props.widthFillWeight != null)
		style.flex = props.widthFillWeight;
	else if (props.fillRow)
		style.flex = 1;
    if (props.width != null) {
        if (props.widthFillWeight != null)
            style.minWidth = getSizeSpecifier(props.width);
        else
            style.width = getSizeSpecifier(props.width);
	}
    if (props.height != null)
		style.height = getSizeSpecifier(props.height);
	if (props.minHeight)
		style.minHeight = getSizeSpecifier(props.minHeight);
    return style;
}

function getAlignmentStyle(props) {
	if (props.align != null) {
		if (props.align === "right")
			return {justifyContent: "flex-end"};
		else if (props.align === "center")
			return {justifyContent: "center"};
	}
}

function getBorderStyle(props) {
	let style;
	style = addBorderStyle(style, "border", props.borderWidth, props.borderColor, props.borderType);
	style = addBorderStyle(style, "borderTop", props.borderTopWidth, props.borderTopColor, props.borderTopType);
	style = addBorderStyle(style, "borderBottom", props.borderBottomWidth, props.borderBottomColor, props.borderBottomType);
	style = addBorderStyle(style, "borderLeft", props.borderLeftWidth, props.borderLeftColor, props.borderLeftType);
	style = addBorderStyle(style, "borderRight", props.borderRightWidth, props.borderRightColor, props.borderRightType);
	if (props.borderRadius != null) { // no need to support border radius for each corner until we have a use for them
		if (style === undefined)
			style = {};
		style.borderRadius = props.borderRadius;
	}
	return style;
}

function addBorderStyle(style, property, width, color, type) {
	if (width === undefined)
		return style;
	if (style === undefined)
		style = {};
	if (color === undefined)
		color = "black";
	if (type === undefined)
		type = "solid";
	style[property] = width + "px " + type + " " + color;
	return style;
}

function getCursorStyle(props) {

}

function getColorStyle(props) {
  let result = {};
  if (props.color != null)
    result.color = props.color;
  if (props.backgroundColor != null)
    result.backgroundColor = props.backgroundColor;
  return result;
}
