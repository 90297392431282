import React, { useEffect } from "react";
import { CallinsTable } from "features/CallinsTable";
import { TableGroup } from "common";
import { ReticleIcon } from "common/SVG";
import { usePrevious } from "core/hooks";
import {
  DIALOG_CONTEXT_DISPATCH,
  DIALOG_CONTEXT_IMAGES,
  DIALOG_CONTEXT_TERMS_CONDITIONS,
  DIALOG_CONTEXT_CALLINS
} from "core/utils/constCapsule";
import { updateOrder } from "core/store/actions/OrdersActions";
import { connect } from "react-redux";
import { hasPermission, hasLicense } from "core/store/selectors/UserSelectors";
import { USER_PERMISSIONS } from "core/config/permissions";
import { CropOriginal } from "@material-ui/icons";
import { ImagesTable } from "features/ImagesTable";
import { USER_LICENSES } from "core/config/licenses";
import { ImagesDialog } from "features/ImagesDialog/ImagesDialog";
import { AddImagesButton } from "features/AddImagesButton";
import { AddCallinButton } from "features/AddCallinButton";
import { RateConfirmationButton } from "features/RateConfirmationButton";
import { DispatchButton } from "features/DispatchButton";
import { useDialogContext } from "core/hooks/useDialogContext";
import { useActiveRow } from "common/Table/hooks";
import { TermsAndConditionsDialog } from "../TermsAndConditionsDialog";
import { DispatchConfirmationDialog } from "../DispatchConfirmationDialog";
import { CallinsDialog } from "../CallinsDialog";

function DetailsDrawerPresentation({
  id = null,
  movementId = null,
  dispatchUpdateOrder,
  enteredCallins,
  hasImagingLicense = false,
  hasDispatchPerm = false,
  pickup,
  delivery,
  commodity
}) {
  const [dialogContext, toggleDialog] = useDialogContext(null);
  const previousDialog = usePrevious(dialogContext);
  const { inlineIndex, setInlineIndex } = useActiveRow(movementId);

  useEffect(() => {
    // If the Dispatch dialog was open and is no longer open, update the order.
    if (previousDialog === DIALOG_CONTEXT_DISPATCH && dialogContext !== DIALOG_CONTEXT_DISPATCH) {
      dispatchUpdateOrder(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dialogContext, previousDialog]);

  const tables = [];
  if (enteredCallins) {
    tables.push({
      Table: <CallinsTable orderId={id} />,
      label: "Callins",
      Icon: <ReticleIcon />
    });
  }

  if (hasImagingLicense) {
    tables.push({
      Table: <ImagesTable movementId={movementId} />,
      label: "Images",
      Icon: <CropOriginal />
    });
  }

  return (
    <>
      <TableGroup
        tables={tables}
        inline
        value={inlineIndex}
        onChange={setInlineIndex}
        renderActions={() => (
          <>
            {enteredCallins && <AddCallinButton onClick={toggleDialog(DIALOG_CONTEXT_CALLINS)} />}
            {hasImagingLicense && <AddImagesButton onClick={toggleDialog(DIALOG_CONTEXT_IMAGES)} />}
            <RateConfirmationButton onClick={toggleDialog(DIALOG_CONTEXT_TERMS_CONDITIONS)} />
            {hasDispatchPerm && <DispatchButton onClick={toggleDialog(DIALOG_CONTEXT_DISPATCH)} />}
          </>
        )}
      />
      <DispatchConfirmationDialog
        open={dialogContext === DIALOG_CONTEXT_DISPATCH}
        onClose={toggleDialog(DIALOG_CONTEXT_DISPATCH)}
        id={id}
        movementId={movementId}
      />
      <TermsAndConditionsDialog
        showDialog={dialogContext === DIALOG_CONTEXT_TERMS_CONDITIONS}
        onClose={toggleDialog(DIALOG_CONTEXT_TERMS_CONDITIONS)}
        movementId={movementId}
        id={id}
      />
      {enteredCallins && (
        <CallinsDialog
          id={id}
          movementId={movementId}
          open={dialogContext === DIALOG_CONTEXT_CALLINS}
          onClose={toggleDialog(DIALOG_CONTEXT_CALLINS)}
          pickup={pickup}
          delivery={delivery}
          commodity={commodity}
        />
      )}
      {hasImagingLicense && (
        <ImagesDialog
          orderId={id}
          movementId={movementId}
          open={dialogContext === DIALOG_CONTEXT_IMAGES}
          onClose={toggleDialog(DIALOG_CONTEXT_IMAGES)}
        />
      )}
    </>
  );
}

const mapDispatchToProps = {
  dispatchUpdateOrder: updateOrder
};

const mapStateToProps = (state) => ({
  enteredCallins: hasPermission(state, USER_PERMISSIONS.INTERNET_MODULE_CARRIER_ENTERED_CALLINS),
  hasImagingLicense: hasLicense(state, USER_LICENSES.LOADMASTER_IMAGING_RUN_IMAGING_PROGRAM),
  hasDispatchPerm: hasPermission(state, USER_PERMISSIONS.INTERNET_MODULE_CARRIER_DISPATCH)
});

export const DetailsDrawer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsDrawerPresentation);
