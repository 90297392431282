import { getCompanySettings, getLogger, getUserSettings } from "lib/util";

const log = getLogger("lib.pages.ContactUs");

export default function ContactUs() {
	if (getCompanySettings() == null || getCompanySettings().adminEmailAddress == null)
		log.error("Couldn't open email to admin because company settings weren't populated %o", getCompanySettings());
	else {
		log.debug("Contact us settings user %o company %o", getUserSettings(), getCompanySettings());
		let address = getUserSettings().adminEmailAddress;
		if (address == null)
			address = getCompanySettings().adminEmailAddress;
		const href = "mailto:" + encodeURIComponent(address);
		window.location.href = href;
		log.debug("Set href to %s   window location: %o", href, window.location);
	}	
}