import React from "react";
import { Container } from "@material-ui/core";
import { Spacer } from "common/Spacer";
import { Typography } from "common/Typography";
import { classNames } from "core/utils";
import { useStyles } from "./TableToolbar.styles";
import { TableFilter } from "./TableFilter";
import { TablePagination } from "./TablePagination";
import { TableOptions } from "./TableOptions";

export function TableToolbar({
  options,
  count,
  inline = false,
  pagination = false,
  disableFilter = false,
  paginationProps,
  filterProps,
  renderRight = () => null,
  renderLeft = () => null
}) {
  const classes = useStyles();

  return (
    <Container
      className={classNames(classes.root, {
        [classes.inline]: inline
      })}
      maxWidth={false}
    >
      {!disableFilter && <TableFilter {...filterProps} />}
      <Typography
        className={classes.count}
        variant="body2"
        value={`${count} ${count > 1 ? "Results" : "Result"}`}
      />
      {renderLeft()}
      <Spacer />
      {pagination && <TablePagination component="div" {...paginationProps} />}
      {renderRight()}
      {<TableOptions {...options} />}
    </Container>
  );
}
