import React from "react";
import { Switch, Box } from "@material-ui/core";
import classNames from "core/utils/classNames";
import { useStyles } from "./IconSwitch.styles";
import { Typography } from "./Typography";

export function IconSwitch({
  onChange,
  checked = false,
  startLabel = null,
  endLabel = null,
  rightIcon = null,
  leftIcon = null
}) {
  const classes = useStyles({
    checked,
    thumbSvg: checked ? rightIcon : leftIcon,
    trackSvg: checked ? leftIcon : rightIcon
  });

  return (
    <Box className={classes.root}>
      {startLabel && (
        <Typography
          fontSize="medium"
          className={classNames(classes.label, {
            [classes.activeLabel]: !checked
          })}
        >
          {startLabel}
        </Typography>
      )}
      <Switch
        color="primary"
        checked={checked}
        onChange={onChange}
        classes={{
          root: classes.switchRoot,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
      />
      {endLabel && (
        <Typography
          fontSize="medium"
          className={classNames(classes.label, {
            [classes.activeLabel]: checked
          })}
        >
          {endLabel}
        </Typography>
      )}
    </Box>
  );
}
