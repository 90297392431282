import { TableCell as MuiTableCell } from "@material-ui/core";
import classNames from "core/utils/classNames";
import React, { useState } from "react";
import { useMountEffect } from "core/hooks";
import { useTableProps } from "./hooks";
import { useStyles } from "./TableRowCell.styles";
import { ExpansionWrapper } from "./ExpansionWrapper";

function TableRowCellComponent({
  className,
  onClick,
  expandable,
  active,
  align = "left",
  verticalAlign = "top",
  colSpan = 1,
  fullWidth = false,
  children = [],
  disableSpacing = false,
  disableGutters = false
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { inline } = useTableProps();

  useMountEffect(() => {
    if (expandable && !open && active) setOpen(true);
  });

  return (
    <MuiTableCell
      className={classNames(className, classes.root, {
        [classes.verticalAlignMiddle]: verticalAlign === "middle",
        [classes.inlineCell]: inline,
        [classes.disableSpacing]: disableSpacing,
        [classes.disableGutters]: disableGutters
      })}
      align={align}
      onClick={onClick}
      colSpan={colSpan}
    >
      {expandable ? (
        <ExpansionWrapper open={open} fullWidth={fullWidth}>
          {children}
        </ExpansionWrapper>
      ) : (
        children
      )}
    </MuiTableCell>
  );
}

export const TableRowCell = React.memo(TableRowCellComponent);
