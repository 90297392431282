import React from "react";
import { Button } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { Typography } from "common";

export function AddImagesButton({ onClick }) {
  return (
    <Button onClick={onClick} startIcon={<AddCircle />} size="small" color="primary">
      <Typography color="primary" value="Add Images" />
    </Button>
  );
}
