import { mapObject } from "core/utils/mapper";
import produce from "immer";
import { convertMilliToDateTimeObject } from "core/utils/formatters";

const keyMap = {};
const valueFormatters = {
  postedDate: (milli) => convertMilliToDateTimeObject(milli, "MM/dd/yyyy")
};

export function mapOrderImagesResponse({ data = [] }) {
  if (!data) return [];
  const reducer = produce((images, draft) => {
    images.push({
      movementId: draft.movementId,
      images: draft.imageData.map(produce((image) => mapObject(image, keyMap, valueFormatters)))
    });
  });

  return data.reduce(reducer, []);
}
