import React, { useState } from "react";
import Panel from "./Panel";
import { useComponentContext } from "./Context";
import { Button } from ".";
import Label from "./Label";
import { getChildArray } from "./ComponentUtil";
import { Column, Table} from ".";

const MODE_AUTO = 0;
//const MODE_SEARCH = 1;
const MODE_LIST = 2;

export default function DataEntryPage(props) {
	let children = getChildArray(props.children);
	let [mode, setMode] = useState(MODE_AUTO);
	return (
		<Panel {...props}>
			<ClassicEntryToolbar mode={mode} setMode={setMode}/>
			{getChildrenForMode(children, mode)}
		</Panel>
	);
}

function getChildrenForMode(children, mode) {
	if (mode === MODE_LIST) {
		let columns = [];
		for (let i = 0; i < children.length; i++) {
			if (children[i].fieldName != null) {
				columns.push(<Column fieldName={children[i].fieldName} caption={children[i].caption} />);
			}
		}
		return (
			<Table fillRow>
				{columns}
			</Table>
		);
	}
	else
		return children;
}

export function ClassicEntryToolbar(props) {
	let context = useComponentContext();
	return (
		<Panel>
			<Panel fillRow noRowBreak  marginRight={100}>
				<Button caption="Search" noRowBreak/>
				<Button caption="Add" noRowBreak/>
				<Button caption="Update" noRowBreak/>
				<Button caption="Delete" noRowBreak/>
				<Button caption="List" onClick={() => props.setMode(MODE_LIST)}  noRowBreak/>
			</Panel>
			<Panel>
				<Button caption="Prev" image="record-nav-left-active" enabled={isPreviousEnabled(context)} onClick={() => previousRecord(context)} noRowBreak/>
				<Label caption={getRecordCaption(context)} noRowBreak />
				<Button caption="Next" image="record-nav-right-active" enabled={isNextEnabled(context)} onClick={() => nextRecord(context)} />
			</Panel>
		</Panel>
	);
}

function isPreviousEnabled(context) {
	if (context == null || context.data == null)
		return false;
	return context.data.listIndex > 0;
}

function isNextEnabled(context) {
	if (context == null || context.data == null)
		return false;
	return context.data.listIndex < context.data.list.length - 1;
}


function getRecordCaption(context) {
	if (context == null || context.data == null)
		return "No records present";
	else
		return (context.data.listIndex + 1) + " of " + context.data.list.length; 
}

function previousRecord(context) {
	context.data.setListIndex(--context.data.listIndex);
}

function nextRecord(context, setCurrentRecord) {
	context.data.setListIndex(++context.data.listIndex);
}