import React from "react";
import listIcon from "images/svg/list.svg";
import pendingLineIcon from "images/svg/clock-white.svg";
import {
  ORDERS_CATEGORY_AVAILABLE,
  ORDERS_TYPE_PENDING,
  ORDERS_TYPE_ALL
} from "core/utils/constCapsule";
import { IconSwitch } from "common";
import { getActiveOrdersType } from "core/store/selectors/OrdersSelectors";
import { setOrdersType } from "core/store/actions/OrdersActions";
import { connect } from "react-redux";
import { doesControlNotMatch } from "core/store/selectors/UserSelectors";
import { USER_CONTROLS, CONTROL_CHARS } from "core/config/controls";

export function OrdersTypeSwitchPresentation({ isEnabled, ordersType, dispatchSetOrdersType }) {
  const handleChange = ({ target: { checked } }) =>
    dispatchSetOrdersType(
      ORDERS_CATEGORY_AVAILABLE,
      checked ? ORDERS_TYPE_PENDING : ORDERS_TYPE_ALL
    );

  if (!isEnabled) return null;
  return (
    <IconSwitch
      checked={ordersType === ORDERS_TYPE_PENDING}
      startLabel="All Orders"
      endLabel="Pending"
      rightIcon={pendingLineIcon}
      leftIcon={listIcon}
      onChange={handleChange}
    />
  );
}

const mapStateToProps = (state) => ({
  ordersType: getActiveOrdersType(state),
  isEnabled: doesControlNotMatch(state, USER_CONTROLS.LOAD_BOARD_ACTIVE, CONTROL_CHARS.N)
});

const mapDispatchToProps = {
  dispatchSetOrdersType: setOrdersType
};

export const OrdersTypeSwitch = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersTypeSwitchPresentation);
