import React from "react";
import { Table, Column, showProgressSnackbar } from "lib/components";
import { downloadURL } from "lib/util/DownloadUtil";

export function TableImagingList(props) {
	return (
		<Table 
			name="images" 
			fieldName="images"			
			emptyCaption="No images available at this time"  
			alternateRowColor 
			showLinesBetweenRows={false} 
			headerVisible={false} 
			border="none" 
			fillRow
			{...props}
		>
			<Column 
				caption="Description" 
				fieldName="descr" 
				variant="link" 
				onClick={downloadImage} 
				minWidth={200}
			/> 
			<Column 
				caption="Scan date" 
				fieldName="scan_date" 
				minWidth={140}
			/> 
			<Column 
				caption="Image count" 
				fieldName="count" 
				minWidth={80}
				align="right"
			/> 
		</Table>
	);
}

function downloadImage(event, componentContext, data) {
	showProgressSnackbar("Image Download", "We are generating this image and it will download automatically."); 
	downloadURL(`ui/image/${data["image_id"]}`, `Order ${data["order_id"]} - ${data["descr"]}.tif`, 
		"Image downloaded.", "Error downloading image");
}