import React from "react";
import {
  ORDERS_TYPE_ALL,
  ORDERS_CATEGORY_ACCEPTED,
  ORDERS_TYPE_ACCEPTED
} from "core/utils/constCapsule";
import { Table, TableColumn, TableDrawer, TableDrawerColumn } from "common";
import {
  OrderNumberTableCell,
  ExchangeeDetailsTableCell,
  CommodityDetailsTableCell,
  AdditionalDetailsTableCell,
  CommentsDetailsTableCell,
  ContactDetailsTableCell
} from "common/TableCells";
import { connect } from "react-redux";
import { getActiveOrdersAndTypeByCategory } from "core/store/selectors/OrdersSelectors";
import { carrierFilterKeys } from "core/config/filterKeys";
import { IndicatorActions } from "common/TableCells/IndicatorActions";
import { ImagesIndicatorAction, CallinsIndicatorAction } from "features/IndicatorActions";
import { fetchOrders } from "core/store/actions/OrdersActions";
import { useMountEffect } from "lib/util";
import { DetailsDrawer } from "./DetailsDrawer";

function AcceptedOrdersTablePresentation({
  isFetching = true,
  orders,
  ordersType = ORDERS_TYPE_ALL,
  dispatchFetchOrders
}) {
  useMountEffect(() => {
    dispatchFetchOrders(ORDERS_CATEGORY_ACCEPTED, ORDERS_TYPE_ACCEPTED);
  });

  return (
    <Table
      loading={!orders && isFetching}
      data={orders}
      defaultSortKey="pickup.milli"
      stickyHeader
      emptyText="No Available Orders Available for Display"
      filterKeys={carrierFilterKeys}
    >
      <TableColumn
        label="Order Number"
        sortKey="id"
        minWidth={70}
        Component={<OrderNumberTableCell />}
      />
      <TableColumn
        label="Pickup"
        sortKey="pickup.milli"
        minWidth={200}
        Component={<ExchangeeDetailsTableCell dataKey="pickup" label="Pickup" />}
      />
      <TableColumn
        label="Delivery"
        sortKey="delivery.milli"
        minWidth={200}
        Component={<ExchangeeDetailsTableCell dataKey="delivery" label="Delivery" />}
      />
      <TableColumn
        label="Commodity"
        sortKey="commodity.name"
        minWidth={300}
        Component={<CommodityDetailsTableCell label="Equipment" />}
      />
      <TableColumn
        label="Additional Details"
        minWidth={150}
        Component={<AdditionalDetailsTableCell ordersType={ordersType} />}
      />
      <TableColumn
        label="Actions"
        minWidth={120}
        Component={
          <IndicatorActions>
            <CallinsIndicatorAction index={0} />
            <ImagesIndicatorAction index={1} />
          </IndicatorActions>
        }
      />
      <TableDrawer>
        <TableDrawerColumn />
        <TableDrawerColumn colSpan={2} Component={<ContactDetailsTableCell />} />
        <TableDrawerColumn colSpan={2} Component={<CommentsDetailsTableCell />} />
        <TableDrawerColumn colSpan={3} />
      </TableDrawer>
      <TableDrawer>
        <TableDrawerColumn fullWidth Component={<DetailsDrawer />} />
      </TableDrawer>
    </Table>
  );
}

const mapStateToProps = (state) => ({
  ...getActiveOrdersAndTypeByCategory(state, ORDERS_CATEGORY_ACCEPTED)
});

const mapDispatchToProps = {
  dispatchFetchOrders: fetchOrders
};

export const AcceptedOrdersTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptedOrdersTablePresentation);
