import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  cell: {
    backgroundColor: theme.palette.common.slate,
    padding: theme.spacing(2)
  },
  inlineCell: {
    backgroundColor: "transparent",
    border: "none"
  }
}));
