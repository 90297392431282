import React, { useState } from "react";
import { Dialog as MuiDialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import { Spacer } from "common";
import classNames from "core/utils/classNames";
import CloseIcon from "@material-ui/icons/Close";
import { getLogger, getUIContext, makeStyles } from "lib/util";

const log = getLogger("lib.components.Dialog");

const useStyles = makeStyles((theme) => ({
	root : {"& .MuiPaper-root" : {	maxWidth: "90%" } },
	content: {
		paddingBottom: theme.spacing(4)
	},
	header: (props) => ({
		display: "flex",
		whiteSpace: "nowrap",
		alignItems: "flex-end",
		flexDirection: "row",
		backgroundColor: props.titleBackgroundColor,
		paddingBottom: 12,
		"& .MuiTypography-root": {color:props.titleColor},
		"& .MuiSvgIcon-root": {fill:props.titleColor},
	}),
	title: {
		paddingTop: 0,
		paddingBottom: 4,
	},
}));

export function Dialog({
	className,
	title = null,
	children = [],
	actions = null,
	open = false,
	onClose,
	titleBackgroundColor = "#EEEEEE",
	titleColor,
	...restProps
}) {
	const classes = useStyles({titleColor, titleBackgroundColor});
	return (
		<MuiDialog {...restProps} className={classNames(classes.root, className)}
			open={open} onClose={onClose} aria-labelledby="dialog" >
			{getTitle(classes, title, onClose)}
			{children}
		</MuiDialog>
	);
}

export function DialogPlaceholder() {
  let [dialogContent, setDialogContent] = useState(null);
  getUIContext().setDialogContent = setDialogContent;
  return dialogContent;
}

function getTitle(classes, title, onClose) {
	let titleComp;
	if (title != null)
		titleComp = (
			<DialogTitle className={classNames(classes.title)} id="dialog-title">
				{title}
			</DialogTitle>
		);
	return (
		<header className={classes.header} >
			{titleComp}
			<Spacer grow />
			<IconButton style={{marginRight:8, marginTop:8}} onClick={onClose}>
				<CloseIcon />
			</IconButton>
		</header>
	);
}

export function closeDialog() {
	getUIContext().setDialogContent(null);
} 

export default function showDialog(title, dialogContent, dialogProps) {
	if (getUIContext().setDialogContent == null) {
		log.error("Cannot show dialog because main page context has not been set.");
		return;
	}
	if (dialogContent == null) {
		log.info("Dialog opened with no content.");
		return null;
	}
	if (typeof dialogContent === "function")
		dialogContent = dialogContent();
	log.debug("Showing dialog with content %o", dialogContent);
	let contentStyle;
	if (dialogProps != null && dialogProps.contentStyle)
		contentStyle = dialogProps.contentStyle;
	let dialog = (
		<Dialog {...dialogProps} open={true} title={title} onClose={closeDialog}>
			<DialogContent style={contentStyle}>{dialogContent}</DialogContent>
		</Dialog>
	);
	getUIContext().setDialogContent(dialog);
}