import { createContext } from "core/utils/context";

export const initialRowState = {
  rowIdx: null,
  row: [],
  expandable: false,
  lastOfType: false
};

export const initialActiveRowState = {
  activeRowId: null,
  setActiveRow: () => undefined,
  activeRowInlineIndex: 0,
  setActiveRowInlineIndex: () => undefined
};

export const initialTableState = {
  columns: [],
  size: undefined,
  inline: false,
  expandable: false,
  stickyHeader: false,
  border: false
};

export const { context: TableContext } = createContext("TableContext", initialTableState);
export const { context: RowContext } = createContext("RowContext", initialRowState);
export const { context: ActiveRowContext } = createContext(
  "ActiveRowContext",
  initialActiveRowState
);
