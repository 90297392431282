import { TableBody as MuiTableBody } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { classNames } from "core/utils";
import { ActiveRowContext, initialActiveRowState } from "./context";
import { TableRow } from "./TableRow";
import { useStyles } from "./TableBody.styles";

export function TableBody({ rows = [], loading }) {
  const classes = useStyles();
  const [activeRowId, setActiveRow] = useState(null);
  const [activeRowInlineIndex, setActiveRowInlineIndex] = useState(0);

  const activeRowContextValue = {
    ...initialActiveRowState,
    activeRowId,
    setActiveRow,
    activeRowInlineIndex,
    setActiveRowInlineIndex
  };

  const memoedTableRows = useMemo(
    () =>
      rows.map(({ id, row, expansionRows }, rowIdx) => [
        <TableRow
          // eslint-disable-next-line react/no-array-index-key
          key={`table-row-${rowIdx}`}
          id={id}
          rowIdx={rowIdx}
          row={row}
        />,
        activeRowId === id
          ? expansionRows.map((expansionRow, idx) => (
              <TableRow
                // eslint-disable-next-line react/no-array-index-key
                key={`table-expansion-row-${idx}`}
                id={id}
                rowIdx={rowIdx}
                row={expansionRow}
                lastOfType={idx === expansionRows.length - 1}
                expandable
              />
            ))
          : null
      ]),
    [activeRowId, rows]
  );

  return (
    <MuiTableBody
      className={classNames({
        [classes.loading]: loading
      })}
    >
      <ActiveRowContext.Provider value={activeRowContextValue}>
        {memoedTableRows}
      </ActiveRowContext.Provider>
    </MuiTableBody>
  );
}
