import React from "react";
import { Panel, Button, ButtonActions } from "lib/components";
import { closeDialog } from "./Dialog";

export default function SubmitPanel({submitButtonCaption="OK", fixedData, ...props}) {
	return (
		<Panel {...props} fixedData={fixedData}>
			{props.children}
			<Panel fillRow noRowBreak />
			<Button caption={submitButtonCaption} color="primary" adding action={ButtonActions.SUBMIT} onClick={closeDialog}/>
		</Panel>
	);
}