/* eslint-disable no-mixed-operators */
let data = {};

export function allocateData() {
	const result = (`${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`).replace(/[018]/g, c =>
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
	);
	data[result] = {};
	return result;
}

export function releaseData(id) {
	delete data[id];
}

export function getData(id) {
	return data[id];
}