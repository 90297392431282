import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    position: "relative",
    height: "100%"
  },
  inlineContainer: {
    paddingBottom: theme.spacing(5)
  },
  containerBorder: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.common.mist}`,
    borderBottom: "none"
  },
  tableBorder: {
    "& th": {
      "&:first-of-type": {
        borderTopLeftRadius: 4
      },
      "&:last-of-type": {
        borderTopRightRadius: 4
      }
    }
  },
  backgroundRow: {
    backgroundColor: theme.palette.common.dew
  },
  empty: {
    width: "100%",
    height: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  loading: {
    overflow: "hidden"
  },
  loadingSpinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));
