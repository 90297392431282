import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export const CheckIcon = memo(function CheckIcon(props) {
  return (
    <SvgIcon {...props}>
      <CheckCircleIcon />
    </SvgIcon>
  );
});
