import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialogActions-root": {
      paddingLeft: 0,
      paddingRight: 0
    },
    "& .MuiDialogActions-spacing > :not(:first-child)": {
      marginLeft: theme.spacing(3)
    }
  },
  content: {
    paddingBottom: theme.spacing(8)
  },
  header: {
    display: "flex",
    whiteSpace: "nowrap",
    alignItems: "flex-end",
    flexDirection: "row"
  },
  title: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));
