import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

/**
 * Uses pdfmake to create a PDF version of the incoming data, and then
 * saves it to the users disk in the proper PDF format.
 *
 * @export
 * @param {*} fileName
 * @param {*} data
 */
export function downloadPDF(fileName, data) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  pdfMake.createPdf(data).download(fileName);
}
