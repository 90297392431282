import React, {useState} from "react";
import { Table as MUITable, TableBody, TableContainer, CircularProgress} from '@material-ui/core';
import TableColumnHeadings from "./TableColumnHeadings";
import TableHeader from "./TableHeader";
import { useStyles } from "./TableStyles";
import TableRow from "./TableRow";
import { Label, Component, useComponentContext } from "lib/components";
import { useAsDataProvider } from "lib/components/Context";
import {isEmptyString, fetchModelData, useMountEffect, getLogger} from "lib/util";
import { appendComponentToHierarchy } from "lib/components/ComponentUtil";
import { sortTable, sortData } from "./TableSort";
import { useRef } from "react";
import { getServerSettings } from "lib/util/MainPageContext";

const log = getLogger("lib.components.Table");

export default function Table({maxHeight, minWidth, scrollY, ...props}) {
  let context = useComponentContext();
  appendComponentToHierarchy(context, props);
  let [data, setData] = useAsDataProvider(context, props.data, props.fieldName);
	let [loading, setLoading] = useState(false);
  let classes = useStyles();
	let [filter, setFilter] = useState("");
	let [expandedRowIndex, setExpandedRowIndex] = useState();
	let [sortedColumns, setSortedColumns] = useState([]);
  let filterDebounce = useRef();
  let countWithoutFilter = useRef(0);
	let sortToUse = sortedColumns;
	if (props.defaultSort && sortToUse.length === 0)
		sortToUse = props.defaultSort;

	useMountEffect(() => { loadData(props.modelName, data, setData, setLoading, sortToUse, countWithoutFilter)});
    let rows = buildRowsFromData(classes, props, data, filter, expandedRowIndex, setExpandedRowIndex);
	let emptyLabel = getEmptyLabel(props, rows, data, loading);
    let tableProps = {};
    if (props.rowSpacing != null)
        tableProps.size = props.rowSpacing;
	let containerClass;
	if (props.border === undefined)
		containerClass = classes.tableContainer;
	log.debug("Render data %o", data);
	let containerStyle = {maxHeight, minWidth, overflow: "auto"};
	if (scrollY === true)
		containerStyle.overflowY = "scroll";
    return (
        <Component {...props} className={classes.tableComponent}>
			<TableHeader {...props} loading={loading} rows={rows} setFilter={(value) => {
        setExpandedRowIndex(null);
        handleFilter(countWithoutFilter.current, value, setFilter, props.modelName, (updatedModelName) => { loadData(updatedModelName, data, setData, setLoading, sortToUse, null) }, filterDebounce);
        setFilter(value);}}
      />
            <TableContainer className={containerClass} style={containerStyle}>
                <MUITable stickyHeader {...tableProps} >
					<TableColumnHeadings {...props} sortedColumns={sortToUse}
						setSortedColumns={(newSort) => sortTable(data, setData, newSort, setSortedColumns, setExpandedRowIndex)} />
                    <TableBody >
                        {rows}
                    </TableBody>
                </MUITable>
            </TableContainer>
			{emptyLabel}
        </Component>
    );
}

function handleFilter(countWithoutFilter, filter, setFilter, modelName, setModelWithFilter, debounceRef) {
  if (modelName != null && countWithoutFilter >= getServerSettings().modelMaxSize) {
    if (debounceRef.current != null)
      clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      if (filter == null || filter.trim().length === 0)
        setModelWithFilter(modelName);
      else
        setModelWithFilter(modelName + "&adhoc_filter=" + encodeURIComponent(filter));
      debounceRef.current = null;
    }, 500);
  }
  else
    setFilter(filter);
}

function buildRowsFromData(classes, props, data, stateFilter, expandedRowIndex, setExpandedRowIndex) {
    let filterValue = stateFilter;
    if (props.filter != null)
        filterValue = props.filter;
	let bypassFilter = isEmptyString(filterValue);
	let passFunction = dataPassesFilter;
	if (props.dataPassesFilter)
		passFunction = props.dataPassesFilter;
	let result = [];
    for (let i = 0; data != null && i < data.length; i++)
		if (bypassFilter || passFunction(filterValue, data[i].modelData)) {
			result.push(<TableRow key={`row-${i}`} {...props} rowIndex={i}
				expandedRowIndex={expandedRowIndex} setExpandedRowIndex={setExpandedRowIndex}
				rowIndexAfterFilter={result.length} data={data} />);
		}
	return result;
}

function loadData(modelName, contextData, setData, setLoading, sortedColumns, countWithoutFilter) {
    if (modelName != null)
        fetchModelData(modelName, contextData, (data) => {
          if (countWithoutFilter != null) {
            if (data == null)
              countWithoutFilter.current = 0;
            else
              countWithoutFilter.current = data.length;
          }
          sortBeforeSetting(data, setData, sortedColumns)
        }, setLoading);
}

function sortBeforeSetting(data, setData, sortedColumns) {
	log.debug("Sort before setting %o    %o", data, sortedColumns);
	if (sortedColumns && sortedColumns.length > 0) {
		let sorted = sortData(data, sortedColumns);
		setData(sorted);
	} else
		setData(data);
}

function dataPassesFilter(filter, data) {
    if (isEmptyString(filter))
		return true;
    let filterLower = filter.toLowerCase();
    for (let name in data) {
        if (typeof(data[name]) === "string") { // really should handle other data types
            let compValue = data[name].toLowerCase();
            if (compValue.indexOf(filterLower) >= 0)
				return true;
        }
	}
    return false;
}

function getEmptyLabel(props, rows, data, loading) {
	log.debug("getEmptyLabel %o", data);
	if (loading)
		return (<CircularProgress size={32} style={{marginLeft:"50%", padding:-16, marginTop: 24}}/>)
	else if (rows.length === 0) {
		if (props.unsearchedCaption != null && data === undefined)
			return <Label marginTop={28} caption={props.unsearchedCaption} align="center" vAlign="top" fillRow/>
		else if (props.emptyCaption != null)
			return <Label marginTop={28} caption={props.emptyCaption} align="center" vAlign="top" fillRow/>
	}
	return null;
}
