import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    borderBottom: `1px solid ${theme.palette.common.mist}`
  },
  grow: {
    flexGrow: 1
  },
  container: {
    backgroundColor: theme.palette.common.white,
    display: "flex"
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column"
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
