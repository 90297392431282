import React, { useCallback } from "react";
import { setOrdersCategory } from "core/store/actions/OrdersActions";
import { connect } from "react-redux";
import { TableGroup } from "common";
import {
  ORDERS_CATEGORY_PAYMENTS_DUE,
  ORDERS_CATEGORY_PAYMENTS_RECEIVED
} from "core/utils/constCapsule";
import { PaymentsDueOrdersTable } from "features/PaymentsDueOrdersTable";
import { PaymentsReceivedOrdersTable } from "features/PaymentsReceivedOrdersTable";
import { getActiveCategory } from "core/store/selectors/OrdersSelectors";
import { DefaultPageContainer } from "../containers";

const tables = [
  { label: "Payments Due", value: ORDERS_CATEGORY_PAYMENTS_DUE, Table: <PaymentsDueOrdersTable /> },
  {
    label: "Payments Received",
    value: ORDERS_CATEGORY_PAYMENTS_RECEIVED,
    Table: <PaymentsReceivedOrdersTable />
  }
];

export function FactoringCompanyPortalPresentation({ pageTitle, dispatchSetOrdersCategory }) {
  const handleTabChange = useCallback((index) => dispatchSetOrdersCategory(tables[index].value), [
    dispatchSetOrdersCategory
  ]);

  return (
    <DefaultPageContainer pageTitle={pageTitle}>
      <TableGroup tables={tables} onChange={handleTabChange} defaultIndex={0} />
    </DefaultPageContainer>
  );
}

const mapStateToProps = (state) => ({
  activeCategory: getActiveCategory(state)
});

const mapDispatchToProps = {
  dispatchSetOrdersCategory: setOrdersCategory
};

export const FactoringCompanyPortal = connect(
  mapStateToProps,
  mapDispatchToProps
)(FactoringCompanyPortalPresentation);
