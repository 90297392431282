import { compile } from "path-to-regexp";
import queryString from "query-string";
import urlParse from "url-parse";
import urljoin from "url-join";
import { BASE_API_URL } from "core/config/urls";
import { isObject } from "core/utils/common";

function getUrlArgs(args) {
  if (isObject(args[0])) {
    if (typeof args[0].path !== "undefined") {
      const {
        path,
        basePath = "",
        pathArgs = {},
        queryParams = {},
        encode = false
      } = args[0];
      return [path, basePath, pathArgs, queryParams, encode];
    }
    throw new Error("path is required.");
  }
  return [args[0], "", {}, {}, false];
}

/**
 * Helper method that builds urls based on passed parameters
 *
 * @export
 * @param {string} url The "path" of the url
 * @param {object} [{ basePath = "", pathArgs = {}, queryParams = {}, encode = true }={}]
 * @returns
 */
export function buildUrl(...args) {
  const [path, basePath, pathArgs, queryParams, encode] = getUrlArgs(args);
  const compiledUrl = compile(path, { encode: encodeURIComponent })(pathArgs);
  const serializedParams = queryString.stringify(queryParams, { encode });
  const delimiter = path.includes("?") ? "&" : "?";
  return serializedParams
    ? urljoin(`${basePath}${compiledUrl}${delimiter}${serializedParams}`)
    : urljoin(`${basePath}${compiledUrl}`);
}

/**
 * Helper method wrapped around buildUrl that injects the BASE_API_URL by default to ease internal API calls.
 *
 * @export
 * @param {*} url
 * @param {*} [basePath=BASE_API_URL]
 * @returns
 */
export function buildAPIUrl(...args) {
  // eslint-disable-next-line no-unused-vars
  const [path, _, pathArgs, queryParams, encode] = getUrlArgs(args);
  return buildUrl({
    path,
    basePath: BASE_API_URL,
    pathArgs,
    queryParams,
    encode
  });
}

/**
 * Extract the URL and the query string as an object.
 *
 * @export
 * @param {string} [url=null] Url to parse.
 * @returns {object} {url: 'https://foo.bar', query: {foo: 'bar'}}
 */
export function parseUrl(url = null) {
  return urlParse(url || window.location.href);
}

/**
 * Parse a query string into an object. Leading ? or # are ignored, so you can pass location.search or location.hash directly.
 * The returned object is created with Object.create(null) and thus does not have a prototype.
 *
 * @export
 * @param {string} [queryStringParams=null] Query String Parameter to parse.
 * @returns {object}  {foo: ['1', '2', '3']}
 */
export function parseQueryParams(queryStringParams = null) {
  return queryString.parse(
    queryStringParams || queryString.extract(window.location.href)
  );
}

/**
 * Returns the query string object from the passed in url string, or uses the window's url if one is not passed.
 *
 * @export
 * @param {string} [url=null] Url to parse query paramters from.
 * @returns {object} {foo: ['1', '2', '3']}
 */
export function getQueryParams(url = null) {
  return url ? parseQueryParams(parseUrl(url).query) : parseQueryParams();
}

/**
 * Returns the value of the specified paramName if one exists, otherwise returns undefined.
 *
 * @export
 * @param {string} paramName Query String Parameter name used to return the value from in the parse query string object.
 * @param {string} [url=null] Url to parse query paramters from.
 * @returns {string} "foo"
 */
export function getQueryParam(paramName, url = null) {
  return getQueryParams(url)[paramName];
}
