import axios from "axios";
import produce from "immer";
import { isObject } from "core/utils/common";
import {getCompanyId} from "lib/util/CompanyId";
import { getAuthToken, handleAuthToken } from "./auth";
import {
  HTTP_RESPONSE_STATUS_OK,
  HTTP_RESPONSE_CONTENT_TYPE_STREAM,
  HTTP_RESPONSE_DATA_TYPE_JSON,
  HTTP_RESPONSE_DATA_TYPE_ARRAY,
  HTTP_RESPONSE_CONTENT_TYPE_BLOB,
  FETCH_RESPONSE_TYPE_ARRAY_BUFFER
} from "./constCapsule";

/**
 * Examines the passed in args and returns the arguments in a consistent format for internal use.
 *
 * @param {*} args
 * @returns
 */
function getFetchArgs(args) {
  if (isObject(args[0])) {
    if (typeof args[0].url !== "undefined") {
      const { url, ...configArgs } = args[0];
      return [url, configArgs, args[1] || {}];
    }
    throw new Error("Url is required.");
  }

  return [args[0], {}, {}];
}

/**
 * Returns the configured headers from the default axios config.
 *
 * @returns {object} The default headers defined in the Axios default headers config.
 */
function getDefaultHeaders() {
  return axios.defaults.headers.common;
}

/**
 * Returns headers specific to making internal API calls.
 *
 * @returns {object} The headers needed to make calls to our internal API.
 */
function getDefaultAuthHeaders() {
  const authToken = getAuthToken();
  const companyId = getCompanyId();
  return {
    ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
    ...(companyId ? { "company-id": companyId } : {})
  };
}
/**
 * General fetch method that uses Axios under the hood to handle external and internal HTTP requests.
 *
 * @export
 * @param {string} url The url endpoint to send the request to.
 * @param {object} [config={}] The object used to configure the fetch request
 * see: https://github.com/axios/axios#request-config for more details.
 * @param {object} [options={}] The options used to change default functionality.
 * @returns {object} The parsed response from the request.
 */
export async function fetch(...args) {
  const [url, config, options] = getFetchArgs(args);
  const { transformResponse = null, ...restConfig } = config;
  const { defaultHeaders = true, defaultAuthHeaders = true, resolveOnError = false } = options;
  try {
    const response = await axios({
      url,
      headers: {
        ...(defaultHeaders ? getDefaultHeaders() : {}),
        ...(defaultAuthHeaders ? getDefaultAuthHeaders() : {}),
        ...config.headers
      },
      transformResponse: transformResponse
        ? axios.defaults.transformResponse.concat(transformResponse)
        : undefined,
      ...restConfig
    });
    return config.key ? { [config.key]: response } : response;
  } catch (error) {
    if (resolveOnError) return Promise.resolve(error.response);
    throw error;
  }
}

/**
 * Utility fetch method for parallel fetching in scenarios where axios.all is used
 * to prevent errors from break out and killing the other fetch requests.
 *
 * @export
 * @param {string} url The url endpoint to send the request to.
 * @param {object} [config={}] The object used to configure the fetch request
 * see: https://github.com/axios/axios#request-config for more details.
 * @param {object} [options={}] The options used to change default functionality.
 * @returns {object} The parsed response from the request.
 * @returns
 */
export async function parrallelFetch(...args) {
  const [url, config, options] = getFetchArgs(args);
  return fetch(
    { url, ...config },
    {
      resolveOnError: true,
      ...options
    }
  );
}

/**
 * Utility fetch method to handle multiple fetches in parallel by taking in multiple
 * fetch configs, and converting them to promises to be awaited upon.
 *
 * @export
 * @param {object} configs The fetch config used to build a parrallelFetch
 * @returns
 */
export async function fetchAll(configs) {
  return axios.all(configs.map((config) => parrallelFetch(config)));
}

/**
 * Fetch utility that informs the fetch to parse the response as an array buffer.
 *
 * @export
 * @param {} args
 * @returns
 */
export async function fetchBuffer(...args) {
  const [url, config, options] = getFetchArgs(args);
  return fetch({ url, responseType: FETCH_RESPONSE_TYPE_ARRAY_BUFFER, ...config }, options);
}

/**
 * Ensures some form of data is returned from an HTTP response's data property.
 * Mostly uses for parallel fetching to handle errors in "axios/promise.all".
 *
 * @export
 * @param {object} response - HTTP Response object.
 * @param {object} [defaultData={}] - Default data to use if response data cannot be returned.
 * @returns
 */
function resolveResponse(response = {}, dataType = HTTP_RESPONSE_DATA_TYPE_JSON) {
  let initialDataValue;
  switch (dataType) {
    case HTTP_RESPONSE_DATA_TYPE_ARRAY:
      initialDataValue = [];
      break;
    default:
      initialDataValue = {};
      break;
  }

  const { data: responseData, status: responseStatus } = response;
  const data = typeof responseData === "object" ? responseData : initialDataValue;
  return responseStatus === HTTP_RESPONSE_STATUS_OK ? data : initialDataValue;
}

/**
 * Function that exposes the resolveResponse utility method with the Array
 * data type baked in to handle missing data from API calls.
 *
 * @export
 * @param {object} response HTTP response
 * @returns
 */
export function resolveArrayResponse(response) {
  return resolveResponse(response, HTTP_RESPONSE_DATA_TYPE_ARRAY);
}

/**
 * Function that exposes the resolveResponse utility method with the JSON
 * data type baked in to handle missing data from API calls.
 *
 * @export
 * @param {object} response - HTTP response
 * @returns
 */
export function resolveJSONResponse(response) {
  return resolveResponse(response, HTTP_RESPONSE_DATA_TYPE_JSON);
}

/**
 * Converts the HTTP stream response array into a base64 encoded string.
 *
 * @export
 * @param {object} response HTTP response object
 * @returns
 */
function handleOctetStreamResponse(response) {
  let binary = "";
  const bytes = new Uint8Array(response.data);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return produce(response, (draft) => {
    draft.data = window.btoa(binary);
  });
}

/**
 * Appends listeners on the response using Axios' "interceptors" to examine the response
 * and handle the auth token properly depending on the response status.
 *
 * @export
 * @returns {object} The response object from the Axios request.
 */
export function appendAuthInterceptors(dispatch = () => undefined) {
  axios.interceptors.response.use(
    (response) => {
      handleAuthToken(response);
      return response;
    },
    (error) => {
      if (error.response) handleAuthToken(error.response);
      return Promise.reject(error);
    }
  );
}

/**
 * Appends listeners on the response using Axios' "interceptors" to examine the response
 * and handle the content type property properly depending on the response's "content-type".
 *
 * @export
 * @returns {object} The response object from the Axios request.
 */
export function appendContentTypeInterceptors() {
  axios.interceptors.response.use(
    (response) => {
      const { "content-type": contentType } = response.headers;
      switch (contentType) {
        case HTTP_RESPONSE_CONTENT_TYPE_STREAM: {
          return handleOctetStreamResponse(response);
        }
        case HTTP_RESPONSE_CONTENT_TYPE_BLOB: {
          return handleOctetStreamResponse(response);
        }
        default:
          return response;
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

/**
 * Kicks off any general interceptor attachments needed on App start.
 *
 */
export function appendAxiosInterceptors() {
  appendContentTypeInterceptors();
}
