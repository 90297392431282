import React from "react";

export const initialState = {
  files: [],
  whitelist: [],
  docTypes: [],
  setFiles: () => undefined,
  removeFiles: () => undefined,
  setDocType: () => undefined
};

export const FileUploaderContext = React.createContext(initialState);

export function useFileUploader() {
  return React.useContext(FileUploaderContext);
}
