import React, { useState, useEffect } from "react";
import { Typography } from "common";
import { Button } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import {
  useOfferOrderTermsQuery,
  useLegalTermsQuery,
  useAcceptOfferQuery
} from "pages/Offer/utils";
import { PendingOfferDialog } from "features/PendingOfferButton/PendingOfferDialog";
import { useQueryParams } from "core/hooks/useQueryParams";
import { OFFER_ACCEPT } from "core/utils/constCapsule";
import { useComplete } from "pages/Offer/Offer";
import { useStyles } from "../../Offer.styles";

export function Accept({ offer, onResponse }) {
  const classes = useStyles();
  const { offerId } = useQueryParams();
  const [open, setOpen] = useState(false);
  const { complete, setComplete } = useComplete();
  const [processing, setProcessing] = useState(false);
  const { data: acceptOfferResponse, handleAcceptOffer } = useAcceptOfferQuery(offerId);
  const { data: legalTerms } = useLegalTermsQuery();
  const { data: orderTerms } = useOfferOrderTermsQuery(offer);

  const handleDialogAction = async ({ currentTarget: { value } } = {}) => {
    if (value !== OFFER_ACCEPT) return setOpen(false);
    setProcessing(true);
    await handleAcceptOffer();
    setProcessing(false);
    setOpen(false);
    setComplete(true);
  };

  useEffect(() => {
    if (acceptOfferResponse) onResponse(acceptOfferResponse);
  }, [acceptOfferResponse, onResponse]);

  if (complete) return null;
  return (
    <>
      <div className={classes.column}>
        <Typography variant="h6" value="Accept Rate" />
        <Button
          className={classes.warningButton}
          onClick={() => setOpen(true)}
          variant="outlined"
          startIcon={<HelpOutline />}
        >
          Confirm Acceptance
        </Button>
      </div>
      <PendingOfferDialog
        legalTermsName="Legal Terms"
        orderTerms
        legalTerms
        showDialog={open}
        handleDialogAction={handleDialogAction}
        pdfData={legalTerms}
        orderConfPdfData={orderTerms}
        processing={processing}
      />
    </>
  );
}
