import * as React from "react";
import { createHOCDisplayName } from "core/utils/common";

/**
 * Wraps mapped actions to use dispatch.
 *
 * @param {Function} dispatch Dispatch method from the context providers
 * @param {Object} actions Actions to wrap with dispatch
 * @returns
 */
function withDispatch(dispatch, actions) {
  return Object.keys(actions).reduce((wrappedActions, key) => {
    return {
      ...wrappedActions,
      [key]: (...args) => dispatch(actions[key](...args))
    };
  }, {});
}

/**
 * Wrapper for React.createContext
 * Returns a provider component and a HOC creator to use on consuming components
 * @param name "ExampleContext" Used to give generated components relevant displayName values
 * @param fallbackState The context value given to consumers that get mounted without a provider parent
 */
export function createContext(name, fallbackState) {
  const context = React.createContext(fallbackState);

  /**
   * Consumer HOC for accessing this context
   */
  function withContext(mapContextToProps = null, mapDispatchToProps = null) {
    return (WrappedComponent) => {
      const HocContextConsumer = (props) => {
        const { state, dispatch } = React.useContext(context);
        const injectedProps =
          typeof mapContextToProps === "function"
            ? mapContextToProps(state, props)
            : {};
        const injectedActions =
          typeof mapDispatchToProps === "function"
            ? withDispatch(dispatch, mapDispatchToProps())
            : {};

        return (
          <WrappedComponent
            {...props}
            {...injectedProps}
            {...injectedActions}
          />
        );
      };

      HocContextConsumer.displayName = createHOCDisplayName(
        name,
        WrappedComponent
      );
      return HocContextConsumer;
    };
  }

  return {
    context,
    [`with${name}`]: withContext
  };
}
