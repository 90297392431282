import { makeStylesLight } from "lib/util";

let styles;

export function useStyles() {
  if (styles == null)
    styles = makeStylesLight("tbl", {
      tableComponent: { overflow: "hidden", display: "flex", flexDirection: "column" },
      tableContainer: { borderTop: "1px solid #CDCDCD", borderRadius: "4px", marginBottom: "12px", overflowX: "hidden" },
      tableCell: { verticalAlign: "top", paddingTop: "4px", paddingBottom: "4px" },
      tableCellPanel: { paddingLeft: "0px" },
      activeRow: { background: "rgb(249, 249, 249)" },
      expansion: { background: "rgb(249, 249, 249)", transition: "height 4s", paddingLeft: "0px", paddingRight: "0px", paddingTop: "8px", paddingBottom: "8px" },
      expansionCell: { borderBottom: "solid 5px rgb(19, 124, 221)", padding: "0px" },
      expandedRow: {
        "& .MuiTableCell-root": {
          borderBottom: "none", borderTop: "solid 1px rgb(19, 124, 221)"
        }
      },
      hidden: { display: "none" },
      tableHeaderCell: {
        fontWeight: "400",
        fontSize: "0.75rem",
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        MsUserSelect: "none",
        "userSelect": "none",
        paddingTop: "0px",
        paddingBottom: "0px"
      },
      headerSpan: {
        paddingTop: "8px",
        paddingBottom: "8px",
        marginTop: "4px",
        marginBottom: "4px",
        marginLeft: "4px",
        cursor: "pointer",
        display: "inline-block",
        height: "100%"
      },
      noClick: {
        cursor: "unset"
      },
      sortArrow: {
        height: "18px",
        width: "18px",
        top: "16px",
        marginLeft: "4px",
        marginTop: "-2px",
        position: "absolute"
      },
      sortNumber: {
        left: "22px",
        top: "1px",
        position: "relative",
        fontSize: "11px"
      },
      defaultAlternateRow: {
        backgroundColor: "#e7f2fc"
      },
      expandColumn: {
        minWidth: "48px",
        maxWidth: "128px"
      }
    });
  return styles;
}
