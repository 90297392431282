import React, { useState } from "react";
import { Tab, Panel, Tabset, Switch, ComboBox, Label, showDialog } from "lib/components";
import Map from "lib/components/Map";
import TableCustomerOrders from "./TableCustomerOrders";
import { DefaultPageContainer } from "pages/containers";
import { getLogger } from "lib/util";
import { PanelCustomerOrderFullDetail } from "./PanelCustomerOrderFullDetail";
import { getMapFilterProps, filterByDays } from "./CustomerPortalMapFilter";
import { LegacyButton } from "./LegacyButton";
import { PageWrapper } from "lib/pages/PageWrapper";
import { isPermissionsAllowed } from "lib/util/Permissions";

const log = getLogger("ar.CustomerPortal");

export default function CustomerPortal() {
	return (
		<DefaultPageContainer>
			<PageWrapper>
				<PortalContent />
			</PageWrapper>
		</DefaultPageContainer>
	);
}

function PortalContent() {
	let [showAsMap, setShowAsMap] = useState(false);
	let [tabIndex, setTabIndex] = useState(0);
	const [showDest, setShowDest] = useState(false);
	let innerProps = { tabIndex, showAsMap, fillHeight: true, showDest, setShowDest };
	if (isPermissionsAllowed("InternetModule", "InternetModuleOrderTracking")) {
		let tabsetComponent = (
			<Panel fillRow>
				<Panel fillRow noRowBreak />
				<LegacyButton paddingRight={32} noRowBreak />
				<Switch leftLabel="List" leftImage="list" rightLabel="Map" rightImage="map-pin" onChange={setShowAsMap} />
			</Panel>
		);

		return (
			<Tabset name="tabset" fillRow fillHeight onChange={setTabIndex} extraComponent={tabsetComponent} >
				<Tab caption="In Transit" fillHeight >
					<TableOrMap modelFilter="status=P" {...innerProps} description="in transit" showStatusColumn={false}  />
				</Tab>
				<Tab caption="Scheduled" fillHeight >
					<TableOrMap modelFilter="status=A" {...innerProps} description="available" showStatusColumn={false}  />
				</Tab>
				<Tab caption="Delivered" fillHeight >
					<TableOrMap modelFilter="status=D" {...innerProps} description="delivered" showStatusColumn={false}  />
				</Tab>
				<Tab name="ordersTab" caption="All Orders" fillHeight fillRow>
					<TableOrMap modelFilter="status=O" {...innerProps} showETAColumn={false}  />
				</Tab>
			</Tabset>
		);
	} else {
		return (
			<Panel>
				<Label caption="You do not have permission to track loads." fontSize="xlarge" fontBold fillRow noRowBreak />
				<LegacyButton />
			</Panel>
		);
	}
}

function TableOrMap({ modelFilter, showAsMap, filter, tabIndex, showDest, setShowDest, description, ...tableProps }) {
	const modelName = `ar/customer-orders?${modelFilter}`;
	const [filterIndex, setFilterIndex] = useState();
	const filterProps = getMapFilterProps(tabIndex, showDest);
	let filterValue = filterIndex;
	if (filterIndex == null)
		filterValue = filterProps.items.length - 1;
	const today = new Date();
	log.debug("Filter props %o %o %o %o", tabIndex, showDest, filterValue, filterProps);
	if (showAsMap) {
		return (
			<Panel fillHeight fillRow>
				<Switch leftLabel="Origin" leftImage="map-pin" rightLabel="Destination" rightImage="map-pin-semi-filled" checked={showDest} onChange={setShowDest} marginTop={14} noRowBreak />
				<ComboBox {...filterProps} selectedIndex={filterValue} onChange={setFilterIndex} width={280} marginLeft={64} />
				<Map name="customerMap" modelName={modelName} {...getMapProps(showDest)}
					filter={data => filterByDays(data, tabIndex, showDest, filterProps.items[filterValue], today)}
					onPinClick={pinClick} fillRow fillHeight />
			</Panel>
		);
	}
	else
		return <TableCustomerOrders name="table" description={description}autoSearch modelName={modelName} filter={filter} {...tableProps} fillRow fillHeight />
}

function pinClick(pins) {
	log.debug("Clicked %o", pins);
	let data = []; // not sure how often we'll pass data in as props, but this is complicated - consider making a utility
	pins.forEach(pin => data.push({ modelData: pin.data }));
	if (data.length === 0) // don't know how this will happen, but weird things happen
		return;
	const firstPinData = data[0].modelData;
	if (data.length === 1) {
		showDialog(`Detail for order ${firstPinData["orders.id"]}`, (
			<PanelCustomerOrderFullDetail data={data} width={1200} />
		), { titleBackgroundColor: "#137CDD", titleColor: "white" });
	}
	else {
		showDialog(`Orders in ${firstPinData["shipper.city_name"]}, ${firstPinData["shipper.state"]}`, (
			<Panel width={1200} data={data}>
				<TableCustomerOrders fillRow />
			</Panel>
		), { titleBackgroundColor: "#137CDD", titleColor: "white" });
	}
}

function getMapProps(showDest) {
	if (showDest)
		return { latField: "consignee.latitude", longField: "consignee.longitude" };
	else
		return { latField: "shipper.latitude", longField: "shipper.longitude" };
}
