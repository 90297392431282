import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  inlineTabTransitionOut: {
    transition: theme.transitions.create("border", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  inlineTabTransitionIn: {
    transition: theme.transitions.create("border", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  inlineTabs: {
    top: 2,
    position: "relative"
  },
  inlineTab: {
    extend: "inlineTabTransitionOut",
    minHeight: 47,
    minWidth: 100,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.common.mist,
    borderBottomColor: "transparent",
    marginRight: theme.spacing(1)
  },
  activeInlineTab: {
    extend: "inlineTabTransitionIn",
    backgroundColor: theme.palette.common.white,
    borderBottomColor: "transparent"
  },
  wrapper: {
    flexDirection: "row",
    "& svg": {
      marginBottom: "0 !important",
      marginRight: theme.spacing(1)
    }
  }
}));
