import React from "react";
import { Route, Redirect } from "react-router-dom";
import { PATHS } from "core/config/urls";
import { AUTH_TYPE_AUTHENTICATED } from "core/store/states/UserState";
import { connect } from "react-redux";
import { getAuthType, getUserType } from "core/store/selectors/UserSelectors";

function ProtectedRouteComponent({
  children,
  routeAuthType,
  routeUserType,
  authType,
  userType,
  ...rest
}) {
  // User's can hit the desired route as long as these criteria are met:
  // 1. The route's auth type and the user's context auth type match.
  // 2. The route doesn't have a user type specified, OR the
  //    route's user type matches the user's context user type.
  if (
    authType === routeAuthType &&
    (!routeUserType || userType === routeUserType)
  ) {
    return <Route {...rest} render={rest.render} />;
  }

  let redirectPath = PATHS.LOGIN;
  if (authType === AUTH_TYPE_AUTHENTICATED && userType) {
    redirectPath = PATHS.ROOT;
  }

  return (
    <Redirect
      to={{
        pathname: redirectPath,
        state: { from: rest.location }
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  authType: getAuthType(state),
  userType: getUserType(state)
});

export const ProtectedRoute = connect(mapStateToProps)(ProtectedRouteComponent);
