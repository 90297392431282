import React from "react";
import { Typography, TableCellList } from "common";

export function ShippingDeliveryDatesTableCell({ shipDate, deliveryDate }) {
  const { date: sdate = null } = shipDate || {};
  const { date: ddate = null } = deliveryDate || {};
  // const { id = null, date = null } = rowData[dataKey] || {};
  return (
    <TableCellList>
      <Typography component="li" fontSize="large" value={sdate || "N/A"} />
      <Typography component="li" label value="Delivery Date" />
      <Typography
        component="li"
        fontWeight="bold"
        fontSize="medium"
        value={ddate || "N/A"}
      />
    </TableCellList>
  );
}
