import React from "react";
import { Tabs, Tab } from "@material-ui/core";
import { classNames } from "core/utils";
import { useStyles } from "./TableGroupTabs.styles";

export function TableGroupTabs({ onChange, index, tabs = [], inline = false }) {
  const classes = useStyles();
  return (
    <Tabs
      className={classNames({
        [classes.inlineTabs]: inline
      })}
      variant="scrollable"
      scrollButtons="auto"
      indicatorColor="primary"
      textColor="primary"
      value={index}
      onChange={onChange}
      aria-label="Table tabs"
      TabIndicatorProps={
        inline
          ? {
              style: { display: "none" }
            }
          : {}
      }
    >
      {tabs.map(({ label, Icon = null }, idx) => (
        <Tab
          key={label}
          label={label}
          icon={Icon}
          classes={
            inline
              ? {
                  root: classNames(classes.inlineTab, {
                    [classes.activeInlineTab]: idx === index
                  }),
                  wrapper: classes.wrapper
                }
              : {}
          }
        />
      ))}
    </Tabs>
  );
}
