import React from "react";
import { Typography, Table, TableColumn, ButtonGroup } from "common";
import { getActiveCallinsByOrderId } from "core/store/selectors/OrdersSelectors";
import { connect } from "react-redux";
import dotProp from "dot-prop";
import { useCallinTimezone } from "core/hooks/useCallinTimezone";

const options = [
  { label: "Local", path: "callDateTimes.local" },
  { label: "Origin", path: "callDateTimes.origin" },
  { label: "Dest", path: "callDateTimes.destination" }
];

function CallinsTablePresentation({ data: rawData = [] }) {
  const [data, handleChange] = useCallinTimezone(rawData, options);
  return (
    <Table
      data={data}
      emptyText="No Callins Available for Display"
      inline
      pagination
      disableFilter
      renderToolbarLeft={() => (
        <ButtonGroup label="Time Zone" options={options} onChange={handleChange} />
      )}
    >
      <TableColumn
        label="Date"
        colSpan={1}
        minWidth={180}
        Component={(rowData) => (
          <Typography
            component="div"
            value={`${dotProp.get(rowData, rowData.dateTimePath).date}`}
          />
        )}
      />
      <TableColumn
        label="Time"
        colSpan={1}
        minWidth={115}
        Component={(rowData) => (
          <Typography
            component="div"
            value={`${dotProp.get(rowData, rowData.dateTimePath).time} ${
              dotProp.get(rowData, rowData.dateTimePath).timezone
            }`}
          />
        )}
      />
      <TableColumn
        label="City"
        minWidth={90}
        Component={({ cityName }) => <Typography component="div" value={cityName} />}
      />
      <TableColumn
        label="State"
        Component={({ state }) => <Typography component="div" value={state} />}
      />
      <TableColumn
        label="Zipcode"
        Component={({ zipCode }) => <Typography component="div" value={zipCode} />}
      />
      <TableColumn
        label="Entered By"
        minWidth={80}
        Component={({ enteredBy }) => <Typography component="div" value={enteredBy} />}
      />
      <TableColumn
        label="Comments"
        minWidth={100}
        Component={({ remark }) => <Typography component="div" value={remark} />}
      />
      <TableColumn
        label="Return Air"
        minWidth={70}
        Component={({ temperature }) => <Typography component="div" value={temperature} />}
      />
      <TableColumn
        label="Set Point"
        minWidth={70}
        Component={({ setPoint }) => <Typography component="div" value={setPoint} />}
      />
    </Table>
  );
}

const mapStateToProps = (state, { orderId }) => ({
  data: getActiveCallinsByOrderId(state, orderId)
});

export const CallinsTable = connect(mapStateToProps)(CallinsTablePresentation);
