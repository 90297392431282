import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  switchRoot: {
    height: 44,
    width: 67
  },
  switchBase: {
    top: 1,
    "&$checked": {
      transform: "translateX(25px)"
    }
  },
  thumb: ({ thumbSvg = "", checked = false }) => ({
    position: "relative",
    "&::before": {
      content: `" "`,
      display: "block",
      width: "100%",
      height: 24,
      backgroundImage: `url("${thumbSvg}")`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "object-fit",
      backgroundPosition: "center center"
    },
    width: 24,
    height: 24,
    backgroundColor: theme.palette.primary.main
  }),
  track: ({ trackSvg = "", checked = false }) => ({
    "&::before": {
      content: `" "`,
      display: "block",
      width: "100%",
      height: 20,
      opacity: ".50",
      transition: theme.transitions.create("backgroundPosition", {
        duration: theme.transitions.duration.shortest
      }),
      backgroundImage: `url("${trackSvg}")`,
      backgroundRepeat: "no-repeat",
      backgroundSize: 10,
      backgroundPosition: checked ? "22% center" : "78% center"
    },
    height: 20,
    borderRadius: 40,
    opacity: "1 !important",
    display: "block",
    backgroundColor: `${theme.palette.primary.dark} !important`
  }),
  checked: {},
  label: {
    opacity: "38%"
  },
  activeLabel: {
    opacity: "100"
  }
}));
