import { useCallback, useState } from "react";

export function useDialogContext(defaultContext = null) {
  const [dialogContext, setDialogContext] = useState(defaultContext);
  const handleSetDialog = useCallback(
    (context) => () => {
      setDialogContext((prevContext) => (prevContext === context ? null : context));
    },
    []
  );
  return [dialogContext, handleSetDialog];
}
