import { mapObject } from "core/utils/mapper";

const keyMap = {
  headerBgColor: "colors.headerBackground",
  headerTextColor: "colors.headerText"
};

const valueFormatters = {};

export function mapUserBranding(branding) {
  return mapObject(branding, keyMap, valueFormatters);
}
