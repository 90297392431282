import { ReactComponent as DispatchTruckIconSVG } from "images/svg/dry-van-with-arrow-right.svg";
import React, { memo } from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";
import classNames from "core/utils/classNames";

const useStyles = makeStyles((theme) => ({
  root: {
    fill: theme.palette.primary.main,
    width: 173,
    height: 126
  }
}));

export const DispatchTruckIcon = memo(function CalendarIcon({
  className,
  ...restProps
}) {
  const classes = useStyles();
  return (
    <SvgIcon
      className={classNames(classes.root, className)}
      viewBox="0 0 173 126"
      component={DispatchTruckIconSVG}
      {...restProps}
    />
  );
});
