import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";

export const DocumentIcon = memo(function DocumentIcon(props) {
  return (
    <SvgIcon {...props}>
      <DescriptionIcon />
    </SvgIcon>
  );
});
