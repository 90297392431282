import * as dfns from "date-fns";

export function addDays(days, date = new Date()) {
  return dfns.add(date, { days });
}

export function subDays(days, date = new Date()) {
  return dfns.sub(date, { days });
}

export function getMilliTime(date = new Date()) {
  return dfns.getTime(date);
}

export function parse(date, format, fallbackDate) {
  return dfns.parse(date, format, fallbackDate);
}

export function parseDateString(dateString, format = "MM/dd/yyyy", fallbackDate = new Date()) {
  return parse(dateString, format, fallbackDate);
}

export function parseDateTimeString(
  dateTimeString,
  format = "MM/dd/yyyy hh:mm a",
  fallbackDate = new Date()
) {
  return parse(dateTimeString, format, fallbackDate);
}

export function formatDate(date, format = "MM/dd/yyyy hh:mm a") {
  return dfns.format(date, format);
}
