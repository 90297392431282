import React from "react";
import { AuthPageContainer } from "pages/containers";
import { Form } from "antd";
import { SetupAccount as SetupAccountPresentation } from "lib/pages/login/SetupAccount";

export function SetupAccount(props) {
  const SetupAccountForm = Form.create({ name: "normal_setup_account" })(SetupAccountPresentation);
  return (
    <AuthPageContainer pageTitle={props.pageTitle}>
      <SetupAccountForm {...props} />
    </AuthPageContainer>
  );
}
