import React from "react";
import { IconButton } from "@material-ui/core";
import { useActiveRow } from "./Table/hooks";

export function IndicatorActionButton({
  movementId,
  index,
  icon,
  disabled = false,
  color = "success"
}) {
  const { setInlineIndex } = useActiveRow(movementId);

  const handleClick = (event) => {
    event.stopPropagation();
    if (index !== undefined) setInlineIndex(index);
  };

  const Icon = icon;
  return (
    <IconButton disabled={disabled} onClick={handleClick} color={color}>
      <Icon />
    </IconButton>
  );
}
