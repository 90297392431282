import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

export const ClockIcon = memo(function ClockIcon(props) {
  return (
    <SvgIcon {...props}>
      <AccessTimeIcon />
    </SvgIcon>
  );
});
