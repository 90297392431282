import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DateTimePicker, TextField, Typography } from "common";
import { Divider, Grid } from "@material-ui/core";
import { toDateTimeFormat } from "common/DateTimePicker";
import { addCallin } from "core/store/actions/OrdersActions";
import { connect } from "react-redux";
import { LocationDateTimeField } from "common/LocationDateTimeField";
import { FormActions } from "./FormActions";
import { useStyles } from "./CallinsForm.styles";

const validationSchema = Yup.object().shape({
  orderId: Yup.string().required(),
  cityStateZipId: Yup.object().nullable().required("City, State, Zipcode is required"),
  dateTime: Yup.date()
    .nullable()
    .typeError("Invalid Date Format")
    .required("Scheduled Date & Time is Required")
});

function CallinsFormPresentation({
  id,
  onClose,
  movementId,
  nextSchedCallin,
  loading = false,
  dispatchAddCallin,
  onCompletion = () => undefined
}) {
  const classes = useStyles();

  async function handleSubmit(values, locationHelpers) {
    try {
      await dispatchAddCallin(values);
      locationHelpers.resetForm({});
      onCompletion();
      onClose();
    } catch (error) {
      locationHelpers.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{
        orderId: id,
        cityStateZipId: null,
        airTemp: "",
        comments: "",
        cityId: 0,
        dateTime: toDateTimeFormat(new Date()),
        nextSchedCallin,
        pointTemp: "",
        movementId
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => {
        const { cityStateZipId } = values;
        return (
          <Form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              <LocationDateTimeField
                locationName="cityStateZipId"
                dateTimeName="dateTime"
                loading={loading}
              />
              <Grid item xs={1}>
                <Typography
                  fontWeight="bold"
                  className={classes.timeZone}
                  value={cityStateZipId ? cityStateZipId.timeZone : "N/A"}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              {nextSchedCallin && (
                <Grid item xs={6}>
                  <DateTimePicker
                    disabled // Permanently disabled
                    name="nextSchedCallin"
                    label="Next Scheduled Callin"
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item xs={3}>
                <TextField name="airTemp" label="Return Air Temp" disabled={loading} />
              </Grid>
              <Grid item xs={3}>
                <TextField name="pointTemp" label="Set Point Temp" disabled={loading} />
              </Grid>
              <TextField
                name="comments"
                label="Comments"
                fullWidth
                multiline
                rows={3}
                disabled={loading}
              />
            </Grid>
            <FormActions onCancel={onClose} />
          </Form>
        );
      }}
    </Formik>
  );
}

const mapDispatchToProps = {
  dispatchAddCallin: addCallin
};

export const CallinsForm = connect(null, mapDispatchToProps)(CallinsFormPresentation);
