import React, { useMemo } from "react";
import {
  ListItem,
  ListItemSecondaryAction,
  List,
  IconButton,
  FormHelperText
} from "@material-ui/core";
import { Typography, Select } from "common";
import { Close } from "@material-ui/icons";
import classNames from "core/utils/classNames";
import { formatBytes } from "core/utils/common";
import { Spacer } from "common/Spacer";
import { useStyles } from "./FileList.styles";
import { useFileUploader } from "../context";

export function FileList({ error = false, helperText = "" }) {
  const classes = useStyles();
  const { maxSize, files, docTypes, removeFile, setDocType } = useFileUploader();

  const options = useMemo(() => docTypes.map((type) => ({ label: type, value: type })), [docTypes]);
  const handleChange = (file) => ({ target: { value } }) => setDocType(file, { docType: value });
  const handleClick = (index) => () => removeFile(index);

  return (
    <>
      <FormHelperText error={error}>{helperText}</FormHelperText>
      <List dense className={classes.root}>
        {!files.length && <Typography variant="caption" value="No files uploaded." />}
        {files.map((file, idx) => (
          <ListItem
            key={file.name}
            className={classNames(classes.item, {
              [classes.error]: file.size > maxSize,
              [classes.itemOffset]: idx % 2 === 0
            })}
          >
            <ul className={classes.fileInfo}>
              <Typography component="li">{file.name}</Typography>
              <Typography component="li">{formatBytes(file.size)}</Typography>
            </ul>
            <Spacer />
            {!!docTypes.length && (
              <Select
                variant="standard"
                className={classes.fileOption}
                placeholder="Select File Type"
                options={options}
                value={file.docType}
                onChange={handleChange(file)}
              />
            )}
            <ListItemSecondaryAction>
              <IconButton onClick={handleClick(idx)}>
                <Close />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
}
