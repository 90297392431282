import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paddingReset: {
    padding: 0
  },
  defaultFontSize: {
    fontSize: theme.typography.pxToRem(14)
  },
  optionsItem: {
    padding: theme.spacing(2),
    "& div": {
      lineHeight: 0
    },
    "& span": {
      extend: "defaultFontSize"
    }
  },
  statusItem: {
    extend: "optionsItem",
    textAlign: "center"
  },
  paper: {
    extend: "defaultFontSize",
    marginTop: 0
  }
}));
