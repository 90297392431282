import React, { useState } from "react";
import { getBranding, getCompanyMetaDataValue } from "core/store/selectors/UserSelectors";
import WhiteMcLeodLogo from "images/mcleod_logo_500.png";
import BlackMcLeodLogo from "images/company_logo.png";
import { connect } from "react-redux";
import { USER_COMPANY_METADATA } from "core/config/companyMetadata";
import { classNames } from "core/utils";
import { useStyles } from "./Logo.styles";
import { Typography } from "./Typography";

export const LogoPresentation = ({
  branding: { logo },
  className,
  companyName,
  variant = "light",
  style = {}
}) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleError = () => setError(true);

  if (error || !logo) {
    if (loading) setLoading(false);
    if (companyName)
      return (
        <Typography
          style={style}
          className={classNames(classes.name, className)}
          value={companyName}
        />
      );
    return (
      <img
        className={classNames(classes.logo, className)}
        style={style}
        src={variant !== "light" ? BlackMcLeodLogo : WhiteMcLeodLogo}
        alt="McLeod Software"
      />
    );
  }

  return (
    <img
      onError={handleError}
      style={style}
      onLoad={() => setLoading(false)}
      className={classNames(classes.logo, className, {
        [classes.hidden]: loading,
        [classes.visible]: !loading
      })}
      src={logo}
      alt={companyName}
    />
  );
};

const mapStateToProps = (state) => ({
  branding: getBranding(state),
  companyName: getCompanyMetaDataValue(state, USER_COMPANY_METADATA.COMPANY_NAME)
});

export const Logo = connect(mapStateToProps)(LogoPresentation);
