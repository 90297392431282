import React from "react";
import { Typography, TableCellList, Email } from "common";
import { formatUSDCurrency } from "core/utils/formatters";

export function OfferHistoryDetailsTableCell({ id, moveDistance, contact = {}, counterAmount }) {
  const subject = `Question about Order #${id}`;
  return (
    <TableCellList row>
      <li>
        {counterAmount && (
          <>
            <Typography label component="div" value="Countered" />
            <Typography
              fontWeight="bold"
              fontSize="medium"
              value={formatUSDCurrency(counterAmount)}
            />
          </>
        )}
        <Typography label component="div" value="Distance" />
        <Typography fontWeight="bold" fontSize="medium" value={`${moveDistance} MI`} />
      </li>
      <li>
        <Typography label component="div" value="Contact" />
        <Typography
          fontWeight="bold"
          fontSize="medium"
          value={contact.name ? contact.name : "N/A"}
        />
        <Email fontWeight="bold" fontSize="medium" value={contact.email} subject={subject} />
      </li>
    </TableCellList>
  );
}
