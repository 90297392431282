import React from "react";
import { TableCellList, Typography } from "common";

export function DeliveryDetailsTableCell({ dataKey, label, ...rowData }) {
  const { id } = rowData[dataKey] || {};
  return (
    <TableCellList>
      <Typography label component="div" value={label} />
      <Typography fontWeight="bold" component="div" value={id || "N/A"} />
    </TableCellList>
  );
}
