import React, {useState } from "react";
import { InputAdornment, IconButton, Input} from '@material-ui/core';
import {Search, Close} from '@material-ui/icons';
import { getLogger, makeStyles } from "lib/util";

const log = getLogger("lib.components.Table.TableFilterBox");

const useStyles = makeStyles((theme) => ({
	input: { marginBottom: 6 },
}));

export default function TableFilterBox({onChange}) {
	const [value, setValue] = useState("");
	let classes = useStyles();
    function changeValue(newValue) {
        log.debug("Filter changed %o", newValue);
        setValue(newValue);
        if (onChange != null)
            onChange(newValue);
    }

    function handleChange(event) {
        changeValue(event.target.value);
    }

    return (
			<Input onChange={handleChange}
				className = {classes.input}
                placeholder="Filter"
                value={value}
                startAdornment = { <InputAdornment position="start"> <Search/> </InputAdornment> }
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton size="small" disabled={!value} onClick={() => changeValue("")}>
                            <Close />
                        </IconButton>
                    </InputAdornment>
                }
            />
    );
}