import React from "react";
import { Formik, Form as FormikForm } from "formik";

export function Form({ children, ...restProps }) {
  return (
    <Formik {...restProps}>
      <FormikForm>{children}</FormikForm>
    </Formik>
  );
}
