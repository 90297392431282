import React from "react";
import { useField } from "formik";
import { resolveArrayResponse, fetch } from "core/utils/fetch";
import { buildAPIUrl } from "core/utils/urls";
import { API_PATHS } from "core/config/urls";
import { getMilliTime, parseDateTimeString } from "core/utils/dateTime";
import { AutoComplete } from "./AutoComplete";

export function CityStateZipField(props) {
  const { dateField } = props;
  // eslint-disable-next-line no-unused-vars
  const [_, meta, helpers] = useField(props);
  const [field] = useField(dateField);

  async function handleFetch(query = "", setOptions) {
    if (query.trim().length < 2) return;
    const response = await fetch(
      buildAPIUrl({
        path: API_PATHS.QUERY_LOCATION,
        queryParams: { location: query, dateTime: getMilliTime(parseDateTimeString(field.value)) }
      })
    );
    const options = resolveArrayResponse(response);
    setOptions(options.slice(0, 25));
  }

  function handleGetOptionSelected(option, value) {
    return option.cityId === value.cityId;
  }

  function handleGetOptionLabel(option) {
    if (!option) return "";
    return `${option.cityName}, ${option.state} ${option.zip}`;
  }

  return (
    <AutoComplete
      {...props}
      {...helpers}
      meta={meta}
      label="City, State, Zipcode"
      onFetch={handleFetch}
      getOptionSelected={handleGetOptionSelected}
      getOptionLabel={handleGetOptionLabel}
    />
  );
}
