import React, { useCallback } from "react";
import { useLocation } from "react-router";
import { parseQueryParams } from "core/utils/urls";
import { classNames } from "core/utils";
import { OFFER_COUNTER, OFFER_ACCEPT, OFFER_DECLINE } from "core/utils/constCapsule";
import { useStyles } from "../Offer.styles";
import { Accept } from "./Accept";
import { Counter } from "./Counter";
import { useAlerts } from "../Offer";
import { Decline } from "./Decline";

const getResponseAlert = ({ success = false, message = null } = {}) => {
  return message ? [{ valid: success, message }] : [];
};

const getValidationAlerts = ({ validation = null }) => {
  return validation && validation.ruleList && validation.ruleList.length ? validation.ruleList : [];
};

const getEmailsAlert = ({ notificationEmails = null, success }, action) => {
  const prefix =
    action === OFFER_COUNTER ? "An email has been sent to" : "Emails have been sent to";
  return notificationEmails
    ? [{ valid: success, message: ` ${prefix}: ${notificationEmails}` }]
    : [];
};

export function OfferActions({ offer = {} }) {
  const location = useLocation();
  const { action } = parseQueryParams(location.search);
  const { setAlerts } = useAlerts();

  const handleResponse = useCallback(
    (response, customAlert) => {
      const responseAlert = getResponseAlert(response);
      const validationAlerts = getValidationAlerts(response);
      const emailAlerts = getEmailsAlert(response, action);
      const alerts = [...responseAlert, ...validationAlerts, ...emailAlerts];
      return customAlert ? setAlerts(alerts.concat([customAlert])) : setAlerts(alerts);
    },
    [setAlerts]
  );

  const classes = useStyles();
  return (
    <div className={classNames(classes.row, classes.actions)}>
      {action === OFFER_ACCEPT && <Accept offer={offer} onResponse={handleResponse} />}
      {action === OFFER_COUNTER && <Counter rate={offer.rate} onResponse={handleResponse} />}
      {action === OFFER_DECLINE && <Decline onResponse={handleResponse} />}
    </div>
  );
}
