import React from "react";
import { PageTitle } from "common";
import { DefaultPageContainer } from "pages/containers";
import { connect } from "react-redux";
import { CallinsForm } from "features/CallinsDialog/CallinsForm";
import { buildAPIUrl, getQueryParams } from "core/utils/urls";
import { API_PATHS, PATHS } from "core/config/urls";
import { resolveJSONResponse, fetch } from "core/utils/fetch";
import { convertMilliToDateTimeFormat } from "core/utils/formatters";
import { useQuery } from "react-query";
import { Typography } from "common";
import { CircularProgress, Container } from "@material-ui/core";
import { useStyles } from "./CallinEntry.styles";
import {
  SESSION_STORAGE_USER_TYPE,
  SESSION_STORAGE_LOGIN_TOKEN,
  SESSION_STORAGE_TEMP_TOKEN
} from "core/utils/constCapsule";


async function fetchCallinData(movementId) {
  const response = await fetch({
    url: buildAPIUrl({
      path: API_PATHS.CARRIER_CALLIN_NEXT_SCHEDULED_DATE,
      queryParams: { movementId }
    })
  });
  const { nextSchedCallin = null } = resolveJSONResponse(response);
  if (nextSchedCallin === -1) return null;
  return convertMilliToDateTimeFormat(nextSchedCallin);
}

function CallinEntryPresentation({
  pageTitle
}) {
  const [statusOpen, setStatusOpen] = React.useState(false);
  const { orderId, movementId } = getQueryParams();
  const { data: nextSchedCallin = null, isFetching } = useQuery(
    [movementId, "callin"],
    fetchCallinData,
    {
      retry: false,
      staleTime: 120000 // 2 Minutes
    }
  );

  function onClose() {
    setTimeout(() => redirectToRoot(), 2000);
  }

  function redirectToRoot() {
    clearSessionTokens();
    window.location.href = PATHS.ROOT;
  }

  function onCompletion() {
    setStatusOpen(true);
  }

  function clearSessionTokens() {
    sessionStorage.removeItem(SESSION_STORAGE_LOGIN_TOKEN);
    sessionStorage.removeItem(SESSION_STORAGE_TEMP_TOKEN);
    sessionStorage.removeItem(SESSION_STORAGE_USER_TYPE);
  }

  const classes = useStyles();

  return (
    <DefaultPageContainer pageTitle={pageTitle}>
      <Container maxWidth="md">
        <PageTitle fontWeight="regular" value={pageTitle} />
        {isFetching && (
          <div className={classes.overlay}>
            <CircularProgress
              className={classes.loadingSpinner}
              color="primary"
            />
          </div>
        )}
        <header className={classes.header}>
          <Typography value="Order Number" label />
          <Typography variant="h6" fontWeight="bold" value={orderId} />
        </header>
        <CallinsForm
          id={orderId}
          loading={isFetching}
          onCompletion={onCompletion}
          onClose={onClose}
          nextSchedCallin={nextSchedCallin}
          movementId={movementId}
        />
        {statusOpen &&
        (<p className={classes.statusMessage}>
          Callin successfully added
        </p>)}
      </Container>

    </DefaultPageContainer>
  );
}

export const CallinEntry = connect(
)(CallinEntryPresentation);
