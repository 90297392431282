export function getCompanyId() {
  let host = window.location.host;
  let result;
  if (host.indexOf('www.') === 0) {
    host = host.replace('www.', '');
  }
  let dashPos = host.indexOf("-");
  if (dashPos > 1)
    result = host.substring(0, dashPos);
  if (result == null)
    result = host.split(".")[0];
  return result;
}
