import { Table as MuiTable, TableContainer, CircularProgress } from "@material-ui/core";
import classNames from "core/utils/classNames";
import React, { useMemo } from "react";
import { Typography } from "common/Typography";
import { TableContext } from "./context";
import { useStyles } from "./styles";
import { TableBody } from "./TableBody";
import { TableHead } from "./TableHead";
import { createTable } from "./utils";
import { TableToolbar } from "./TableToolbar";
import { useTableSort, useTablePagination, useTableFilter } from "./hooks";

export function Table({
  id,
  size,
  options = {},
  data = [],
  children = [],
  inline = false,
  border = false,
  loading = false,
  stickyHeader = false,
  filterKeys = [],
  defaultSortKey = null,
  emptyText = "No Table Data Available",
  disableToolbar = false,
  disableFilter = false,
  pagination = false,
  renderToolbarRight = () => null,
  renderToolbarLeft = () => null
}) {
  const classes = useStyles();
  const [columns, rows, tableProps] = useMemo(() => createTable(children, data), [children, data]);
  const [filteredRows, filterProps] = useTableFilter(rows, { keys: filterKeys });
  const [sortedRows, sortProps] = useTableSort(filteredRows, defaultSortKey);
  const [paginatedRows, paginationProps] = useTablePagination(
    sortedRows,
    disableToolbar || !pagination
  );

  const tableOptions = useMemo(
    () => ({
      ...tableProps,
      ...sortProps,
      inline,
      stickyHeader,
      border,
      size,
      columns,
      id
    }),
    [tableProps, sortProps, inline, stickyHeader, border, size, columns, id]
  );

  return (
    <>
      {!disableToolbar && (
        <TableToolbar
          inline={inline}
          count={filteredRows.length}
          options={options}
          disableFilter={disableFilter}
          pagination={pagination}
          filterProps={filterProps}
          paginationProps={paginationProps}
          renderLeft={renderToolbarLeft}
          renderRight={renderToolbarRight}
        />
      )}
      <TableContainer
        className={classNames(classes.container, {
          [classes.containerBorder]: border,
          [classes.inlineContainer]: inline,
          [classes.loading]: loading
        })}
      >
        <MuiTable
          size={size}
          aria-label="Data Table"
          className={classNames(classes.table, {
            [classes.tableBorder]: border
          })}
          stickyHeader={stickyHeader}
          id={id}
        >
          <TableContext.Provider value={tableOptions}>
            <TableHead columns={columns} />
            <TableBody rows={paginatedRows} loading={loading} />
          </TableContext.Provider>
        </MuiTable>
        {!rows.length && !loading && (
          <Typography className={classes.empty} component="div" value={emptyText} />
        )}
        {loading && <CircularProgress className={classes.loadingSpinner} color="primary" />}
      </TableContainer>
    </>
  );
}
