import React from "react";
import { Typography, TableCellList } from "common";
import { formatUSDCurrency } from "core/utils/formatters";

export function PayDetailsTableCell({
  totalPay = "N/A",
  netPay = "N/A",
  label = "N/A",
  advanceDeduction = "N/A",
  payType,
  okPayDate = "N/A"
}) {
  return (
    <TableCellList>
      <Typography
        component="li"
        fontSize="large"
        value={
          payType === "total"
            ? formatUSDCurrency(totalPay)
            : formatUSDCurrency(netPay)
        }
      />
      <Typography component="li" label value={label || "N/A"} />
      <Typography
        component="li"
        fontWeight="bold"
        fontSize="medium"
        value={
          payType === "total" ? formatUSDCurrency(advanceDeduction) : okPayDate
        }
      />
    </TableCellList>
  );
}
