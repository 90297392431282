import { formatComment, splitLocation, splitTimeAndTimezone } from "core/utils/formatters";
import { mapModelDataFromAPI } from "core/utils/mapper";

const keyMap = {
  order_id: "id",
  isLtl: "ltl",
  origin_loc: "pickup",
  pickup_date: "pickup",
  destination_loc: "delivery",
  delivery_date: "delivery",
  commodity: "commodity.name",
  equipment_descr: "commodity.description",
  equipment_length: "commodity.length",
  contact_email: "contact.email",
  contact_name: "contact.name",
  contact_phone: "contact.phone",
  comment: "comments",
  comment2: "comments",
  addl_pickups: "additionalPickups",
  addl_dropoffs: "additionalDropoffs",
  allow_cntr_offer: "counterOfferEnabled",
  offer_date: "offerDate",
  movement_id: "movementId",
  offer_id: "offerId",
  offer_status: "offerStatus"
};

const valueFormatters = {
  comment: formatComment,
  comment2: formatComment,
  origin_loc: splitLocation,
  destination_loc: splitLocation,
  delivery_date: splitTimeAndTimezone,
  pickup_date: splitTimeAndTimezone
};

export function mapAvailableOrders(orders) {
  return mapModelDataFromAPI(orders, keyMap, valueFormatters);
}
