/* eslint-disable */

import fetch from "isomorphic-fetch";
import { SESSION_STORAGE_USER_TYPE } from "core/utils/constCapsule";
import { BASE_API_URL } from "core/config/urls";
import getLogger from "./Logger";
import {getCompanyId} from "./CompanyId";

export const SESSION_STORAGE_LOGIN_TOKEN = "login-token";
export const SESSION_STORAGE_TEMP_TOKEN = "temp-token";
export const LOGIN_PATH = "/login";
export const LOGIN_PATH_INVALID_TOKEN = "/login?invalidToken";
export const RESET_PATH = "/reset";
export const TRACK_PATH = "/track";
export const CALLIN_PATH = "/callin";
export const OFFER_PATH = "/offer";
// this can be set to false when you are having trouble with a request being redirected and you can't see the log because the login page comes up
export const allowLoginRedirect = true;
const log = getLogger("lib.util.api");

export function callApi(endpoint, options) {
	var fullURL = BASE_API_URL + endpoint;

	if (options != undefined) {
		var method = options.method;
		var body = options.body;
		var allowLogin = options.allowLogin;
		var addlParams = options.addlParams;
	}
	if (method === undefined) method = "get";
	if (allowLogin === undefined) allowLogin = true;

	if (addlParams != undefined) {
		var param = "";
		if (!fullURL.includes("?")) param += "?";
		else param += "&";
		for (var i = 0; i < addlParams.length; i++) {
			Object.keys(addlParams[i]).map(function (k) {
				param += k + "=" + addlParams[i][k];
				if (i != addlParams.length - 1) param += "&";
			});
		}
		fullURL += param;
	}

	log.debug("Call API: %s", fullURL);
	let token = sessionStorage.getItem(SESSION_STORAGE_LOGIN_TOKEN);
	if (!token) token = sessionStorage.getItem(SESSION_STORAGE_TEMP_TOKEN);
	let authHeader = null;
	if (
		token == null &&
		window.location.pathname != LOGIN_PATH &&
		window.location.pathname != RESET_PATH &&
		window.location.pathname != TRACK_PATH &&
		window.location.pathname != OFFER_PATH &&
		window.location.pathname != CALLIN_PATH
	) {
		if (allowLoginRedirect)
			window.location.href = LOGIN_PATH;
		return;
	} else if (token != null && window.location.pathname != LOGIN_PATH)
		authHeader = "Bearer " + token;
	//var headers = { "Accept": "application/json", authHeader, "Content-type": "text/plain"};
	let companyId = getCompanyId();
	var headers = {
		Accept: "application/json",
		Authorization: authHeader,
		"Content-type": "application/json",
		"company-id": companyId
	};
	return fetch(fullURL, { headers: headers, method: method, body: body }).then(
		response => {
			if (!response.ok) {
				//todo: add error logging. sentry?
				if (response.status === 403 && window.location.pathname != LOGIN_PATH) {
					clearSessionTokenShowInvalid();
					return;
				}
				return Promise.reject(response);
			}
			if (response.status === 204) {
				return;
			}
			var refreshToken = response.headers.get("refresh-token");
			if (refreshToken)
				sessionStorage.setItem(SESSION_STORAGE_LOGIN_TOKEN, refreshToken);
			if (response.headers.get("content-type") === "application/octet-stream")
				return Promise.resolve(getStream(response.body.getReader()));
			else if (options != null && options.rawResponse === true)
				return response.text();
			else
				return response.json();
		}
	);
}

async function getStream(reader) {
	return await parseReader(reader);
}

function parseReader(reader) {
	let result = [];
	return new Promise(resolve => {
		reader.read().then(function process({ done, value }) {
			if (done) {
				resolve(result);
				return;
			}
			result = result.concat(Array.from(value));
			return reader.read().then(process);
		});
	});
}

export function clearSessionTokenShowInvalid() {
	sessionStorage.removeItem(SESSION_STORAGE_LOGIN_TOKEN);
	sessionStorage.removeItem(SESSION_STORAGE_TEMP_TOKEN);
	sessionStorage.removeItem(SESSION_STORAGE_USER_TYPE);
	if (allowLoginRedirect)
		window.location.href = LOGIN_PATH_INVALID_TOKEN;
}