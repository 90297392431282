import React from "react";
import { CropOriginal } from "@material-ui/icons";
import { TableGroup } from "common";
import { ORDERS_TYPE_PAID } from "core/utils/constCapsule";
import { USER_LICENSES } from "core/config/licenses";
import { connect } from "react-redux";
import { getActiveOrdersType } from "core/store/selectors/OrdersSelectors";
import { hasLicense } from "core/store/selectors/UserSelectors";
import { ImagesTable } from "features/ImagesTable";
import { ImagesDialog } from "features/ImagesDialog/ImagesDialog";
import { AddImagesButton } from "features/AddImagesButton";
import { useDialogContext } from "core/hooks/useDialogContext";
import { useActiveRow } from "common/Table/hooks";
import { DownloadSettlementSummaryButton } from "lme/drs/DownloadSettlementSummaryButton";

const IMAGES_DIALOG = "IMAGES_DIALOG";

function DetailsDrawerPresentation({
  id,
  movementId,
  hasImagingLicense = false,
  ordersType = ORDERS_TYPE_PAID,
  check = {}
}) {
  const [dialogContext, toggleDialog] = useDialogContext(null);
  const { inlineIndex, setInlineIndex } = useActiveRow(movementId);

  const tables = [];
  if (hasImagingLicense) {
    tables.push({
      Table: <ImagesTable movementId={movementId} />,
      label: "Images",
      Icon: <CropOriginal />
    });
  }

  return (
    <>
      <TableGroup
        inline
        value={inlineIndex}
        onChange={setInlineIndex}
        tables={tables}
        renderActions={() => (
          <>
            {ordersType === ORDERS_TYPE_PAID && (
              <DownloadSettlementSummaryButton
                caption="Settlement Summary"
                checkNumber={check.id}
                checkDate={check.date}
              />
            )}
            {hasImagingLicense && <AddImagesButton onClick={toggleDialog(IMAGES_DIALOG)} />}
          </>
        )}
      />
      {hasImagingLicense && (
        <ImagesDialog
          orderId={id}
          movementId={movementId}
          open={dialogContext === IMAGES_DIALOG}
          onClose={toggleDialog(IMAGES_DIALOG)}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  ordersType: getActiveOrdersType(state),
  hasImagingLicense: hasLicense(state, USER_LICENSES.LOADMASTER_IMAGING_RUN_IMAGING_PROGRAM)
});

export const InlineOrderDetailsDrawer = connect(mapStateToProps)(DetailsDrawerPresentation);
