import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  DatePicker,
  DateTimePicker,
  TextField,
  CityStateZipField
} from "common";
import { FormikDebugger } from "core";
import { Container, Button } from "@material-ui/core";

const initialValues = {
  name: "",
  date: null,
  dateTime: null,
  cityStateZip: ""
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  date: Yup.date()
    .nullable()
    .typeError("Invalid Date Format")
    .required("Date Required"),
  dateTime: Yup.date()
    .nullable()
    .typeError("Invalid Date Time Format")
    .required("Date & Time is Required")
});

export function FormikFieldsPlayground() {
  return (
    <Container maxWidth="xs">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          alert(JSON.stringify(values, null, 2));
        }}
      >
        <Form>
          <TextField name="name" label="Name" fullWidth />
          <DatePicker name="date" label="Date" fullWidth />
          <DateTimePicker name="dateTime" label="Date Time" fullWidth />
          <CityStateZipField name="cityStateZip" label="City State Zip" />
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
          <FormikDebugger />
        </Form>
      </Formik>
    </Container>
  );
}
