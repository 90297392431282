import { getQueryParam } from "core/utils/urls";
import { SESSION_STORAGE_LOGIN_TOKEN, SESSION_STORAGE_TEMP_TOKEN } from "core/utils/constCapsule";

export const AUTH_TYPE_GUEST = "GUEST";
export const AUTH_TYPE_TRUSTED_GUEST = "TRUSTED_GUEST";
export const AUTH_TYPE_AUTHENTICATED = "AUTHENTICATED";

function getAuthType() {
  const tempToken = getQueryParam("token");
  if (sessionStorage.getItem(SESSION_STORAGE_LOGIN_TOKEN)) {
    return AUTH_TYPE_AUTHENTICATED;
  }
  if (sessionStorage.getItem(SESSION_STORAGE_TEMP_TOKEN) || tempToken) {
    if (tempToken) sessionStorage.setItem(SESSION_STORAGE_TEMP_TOKEN, tempToken);
    return AUTH_TYPE_TRUSTED_GUEST;
  }
  return AUTH_TYPE_GUEST;
}

export const initialBrandingState = {
  logo: null,
  colors: {}
};

export const initialState = {
  token: null,
  authType: getAuthType(),
  userType: null,
  userName: null,
  displayName: null,
  companyName: null,
  adminEmail: null,
  legacyBaseUrl: null,
  permissions: [],
  licenses: [],
  controls: {},
  companyMetaData: [],
  branding: initialBrandingState
};
