import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row"
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  root: {
    extend: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& > *": {
      marginBottom: theme.spacing(2)
    },
    "& > *:last-child": {
      marginBottom: theme.spacing(9)
    }
  },
  alerts: {
    width: "100%",
    padding: `0 ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  actions: {
    "& $column": {
      marginRight: theme.spacing(7)
    }
  },
  divider: {
    width: "100%"
  },
  warningButton: {
    "&.MuiButton-outlined": {
      color: theme.palette.warning.main,
      border: `1px solid ${theme.palette.warning.main}`,
      "&:hover": {
        backgroundColor: theme.palette.warning.hover
      }
    }
  }
}));
