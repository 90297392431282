import React from "react";
import { withSnackbar } from "notistack";
import { Panel, Label, Button, Spinner } from ".";
import { getLogger } from "lib/util";

let enqueue, close;

const log = getLogger("lib.components.Snackbar");

function SnackbarPresentation(props) {
	enqueue = props.enqueueSnackbar;
	close = props.closeSnackbar;
	return null;
}

export function showProgressSnackbar(titleText, message, message2 = "Please feel free to keep working.") {
	let title = ( 
		<Panel noRowBreak fillRow > 
			<Label caption={titleText} fontSize="xlarge" color="white" marginRight={24} noRowBreak />
			<Spinner size={24} noRowBreak/>
		</Panel>
	);
	let label1 = <Label caption={message} marginRight={32} fontSize="large" color="white" />
	let label2;
	if (message2)
		label2 = <Label caption={message2} marginRight={32} fontSize="large" color="white" />
	let body = (
		<Panel>
			{label1}
			{label2}
		</Panel>
	);
	log.info("Show progress snackbar %s %s", message, message2);
	showSnackbar(null, true, title, body);
}

export default function showSnackbar(message, persist, titleContent, bodyContent) {
	let props = {};
	if (persist)
		props.persist = true;
	props.content = (key, message) => {
		return <SnackWrapper key={key} message={message} titleContent={titleContent} bodyContent={bodyContent} />
	}
	log.info("Show snackbar %s", message);
	return enqueue(message, props);
}

export function closeSnackbar(key) {
	close(key);
}

export const Snackbar = withSnackbar(SnackbarPresentation)

class SnackWrapper extends React.Component {
	render() {
		let titleContent = this.props.titleContent;
		let bodyContent = this.props.bodyContent;
		let panelContent;
		let closeButton = <Button image="chevron-left-white" marginRight={0} onClick={() => close(this.props.key)} />; 

		if (titleContent == null && bodyContent == null) {
			panelContent = (
				<>
					<Label marginLeft={16} marginTop={12} marginBottom={14} marginRight={32} color="white" 
						fontSize="large" caption={this.props.message} fillRow noRowBreak />
					{closeButton}
				</>
			);
		}
		else {
			if (titleContent == null)
				titleContent = <Panel fillRow noRowBreak />
			if (bodyContent == null)
				bodyContent = <Label marginLeft={16} marginTop={-18} marginBottom={14} color="white" fontSize="large" caption={this.props.message} />
			panelContent = (
				<Panel> 
					{titleContent}
					{closeButton}
					{bodyContent}
				</Panel>
			);
		}
    	return (
      		<div>
				<Panel style={{backgroundColor:"#313131", borderRadius:4, minWidth: 280}}>
					{panelContent}
				</Panel>
			</div>
		);
  }
}

// want this on MuiButtonBase-root
    // padding-right: 12px;
    // padding-top: 0px;
    // min-width: unset;