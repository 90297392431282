import { ReactComponent as PhoneIconSVG } from "images/svg/phone-right.svg";
import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

export const PhoneIcon = memo(function PhoneIcon(props) {
  return (
    <SvgIcon {...props}>
      <PhoneIconSVG />
    </SvgIcon>
  );
});
