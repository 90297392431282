import React from "react";
import { Panel, Label, TextBox, Table, Column, Button, useData, showSnackbar, showDialog, showProgressSnackbar } from "lib/components";
import { DataContext } from "lib/components/Context";
import { getLogger, fetchModelData } from "lib/util";
import { copyTextToClipboard } from "lib/util/Clipboard";
import { ShareOrder } from "./ShareOrder";
import { generateReport } from "lib/util/ReportUtil";

const log = getLogger("lme.ar.CustomerPanel.PanelAdditionalOrderDetails");

export function PanelAdditionalOrderDetails({ isLTL, data, listIndex, ...props }) {
	let bolButton;
	if (isLTL)
		bolButton = <Button caption="BOL" image="documents-white" 
			variant="contained" color="primary" marginTop={8}
			onClick = {() => downloadBOL(data[listIndex].modelData["orders.id"])} 
			labelVShift={-4} height={32} noRowBreak />
	return (
		<Panel fillRow {...props} backgroundColor="white">
			<Label caption="Additional details" fontSize="large" fontBold fillRow noRowBreak />
			{bolButton}
			<MailButton noRowBreak />
			<ShareButton />
			{getAdditionalDetailsHeader(isLTL, data, listIndex)}
			<Panel fillRow>
				<Label caption="Reference numbers" />
				<Table fieldName="reference_numbers" emptyCaption="No reference numbers available at this time"
					showLinesBetweenRows={false} headerVisible={false} alternateRowColor border="none" fillRow >
					<Column fieldName="order_sequence" caption="Stop" align="right" />
					<Column fieldName="description" caption="Description" />
					<Column fieldName="reference_number" caption="Numbers" align="right" />
					<Column fieldName="weight" caption="Weight" align="right" />
					<Column fieldName="pieces" caption="Pieces" align="right"  />
				</Table>
			</Panel>			
		</Panel>
	);
}

function MailButton(props) {
	let data = useData();
    log.debug("Mail data %o", data);
	let mailTo;
	if (data != null) {
		let address = data["email_address"];
        if (address != null) {
            let subject = "Question about order #" + data["orders.id"];
            if (address != null)
                mailTo = "mailto:" + encodeURIComponent(address) + "?subject=" + encodeURIComponent(subject);
        }
	}
	return <Button image="mail" href={mailTo} visible={mailTo != null}/>
}

function getAdditionalDetailsHeader(isLTL, data, listIndex) {
	// ideally we would have access to the "parent" datacontext provider here without having to do this.  
	// Not sure how I envision the code looking to access the parent.
	// As mentioned in other comments, I don't like that data is passed in here in the first place.
	if (isLTL) { 
		let billTo;
		log.debug("LTL %o", data[listIndex]);
		let brLtl = data[listIndex].modelData["isBrLtl"] === true;
		if (data[listIndex].modelData["isAssetLtl"] === true)
			billTo = ( 
				<Panel marginLeft={0} fillRow>
					<TextBox fieldName="customer.name" caption="Bill to" variant="label" nullDisplayValue="hide" fillRow noRowBreak/>
					<TextBox fieldName="billing_method" caption="Billing method" variant="label" nullDisplayValue="hide" fillRow/>
				</Panel>
			);
		return (
			<Panel fillRow>
				<DataContext.Provider value={{list:data, listIndex: listIndex}} >
					<Panel marginLeft={0} fillRow>
						<Label caption="Shipper contact" color="primary" fillRow noRowBreak />
						<Label caption="Consignee contact" color="primary" fillRow />
						<Label fieldName="shipper.contact_name" nullDisplayValue="N/A" fontBold fillRow noRowBreak />
						<Label fieldName="consignee.contact_name" nullDisplayValue="N/A" fontBold fillRow />
						<Label fieldName="shipper.phone" fontSize="small" fillRow noRowBreak />
						<Label fieldName="consignee.phone" fontSize="small" fillRow />
					</Panel>						
				</DataContext.Provider>
				{billTo}
				<TextBox caption="Last known location" fieldName="last_known_location" variant="label" nullDisplayValue="N/A" fillRow noRowBreak /> 
				<TextBox caption="Carrier" fieldName="payee.name" variant="label" visible={brLtl} nullDisplayValue="N/A" fillRow /> 
			</Panel>
		);
	} else {
		return (
			<Panel fillRow>
				<Label caption="Tractor" color="primary" fillRow noRowBreak />
				<Label caption="Trailer" color="primary" fillRow />
				<Label fieldName="tractor" fillRow noRowBreak marginTop={-4}/>
				<Label fieldName="trailer" fillRow marginTop={-4}/>
				<Label caption="Commodity" fontSize="small" />
				<Label fieldName="commodity.descr" nullDisplayValue="N/A" marginTop={-4}/>
			</Panel>
		);
	}

}

function downloadBOL(orderId) {
	let filters = [];
	filters.push({paramName: "orderId", paramValue: orderId});
	showProgressSnackbar("BOL", "We are generating your BOL and it will download automatically."); 
	generateReport("dispatch/bill-of-lading", `Order ${orderId} - BOL.pdf`, filters);
}

function copyLink(orderId) {
	fetchModelData("ar/share-order?orderId=" + orderId, null, (response) => {
		log.debug("Share Order link response: %o", response);
		const link = response[0].modelData.link;
		copyTextToClipboard(link);
		showSnackbar("A link to this order been copied to your clipboard.");
	});
}

function ShareButton(props) {
	const data = useData();
	let order;
	if (data)
		order = data["orders.id"];
	let dropDown = [
		<Label caption="Copy link to clipboard" image="chain-link" imageSpacing={16}
			onClick={() => copyLink(order)} />,
		<Label caption="Share link via email" image="mail-outlined-gray" imageSpacing={16}
			onClick={() => showDialog(`Share order ${order} via email`, <ShareOrder orderId={order} />)} />,
	];
	return <Button image="box-arrow-up" dropDown={dropDown} dropDownAlign="right" />
}