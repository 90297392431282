import React, { useState, useEffect } from "react";
import { Collapse } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useStyles } from "../Offer.styles";

export function OfferAlerts({ alerts }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (alerts.length && !open) setOpen(true);
  }, [alerts, open]);

  return (
    <div className={classes.alerts}>
      <Collapse in={open}>
        {alerts.map(({ message, valid }) => (
          <Alert className={classes.alert} key={message} severity={valid ? "success" : "error"}>
            {message}
          </Alert>
        ))}
      </Collapse>
    </div>
  );
}
