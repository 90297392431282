import React from "react";
import { CropOriginal } from "@material-ui/icons";
import { TableGroup } from "common";
import { DIALOG_CONTEXT_IMAGES } from "core/utils/constCapsule";
import { connect } from "react-redux";
import { ImagesTable } from "features/ImagesTable";
import { CommentsTable } from "features/CommentsTable";
import { ChatBubbleDotsIcon } from "common/SVG/ChatBubbleDots";
import { resolveArrayResponse, fetch } from "core/utils/fetch";
import { useQuery } from "react-query";
import { mapComments } from "core/mappers/comments";
import { buildAPIUrl } from "core/utils/urls";
import { API_PATHS } from "core/config/urls";
import { useDialogContext } from "core/hooks/useDialogContext";
import { AddImagesButton } from "features/AddImagesButton";
import { ImagesDialog } from "features/ImagesDialog";
import { useActiveRow } from "common/Table/hooks";
import { USER_LICENSES } from "core/config/licenses";
import { hasLicense } from "core/store/selectors/UserSelectors";

async function fetchComments(id, ordersType) {
  const response = await fetch({
    url: buildAPIUrl({
      path: API_PATHS.FACTORING_COMPANY_PAYMENTS_DUE_ORDERS_COMMENTS,
      queryParams: { orderId: id, ordersType }
    }),
    transformResponse: mapComments
  });
  return resolveArrayResponse(response);
}

const queryConfig = {
  retry: false,
  staleTime: 120000 // 2 Minutes
};

function DetailsDrawerPresentation({ id, movementId, hasImagingLicense = false }) {
  const [dialogContext, toggleDialog] = useDialogContext(null);
  const commentsQuery = useQuery([id, "comments"], fetchComments, queryConfig);
  const { inlineIndex, setInlineIndex } = useActiveRow(movementId);
  const handleChange = (idx) => setInlineIndex(idx);

  const tables = [
    {
      Table: <CommentsTable {...commentsQuery} />,
      label: "Comments",
      Icon: <ChatBubbleDotsIcon />
    }
  ];

  if (hasImagingLicense) {
    tables.push({
      Table: <ImagesTable movementId={movementId} />,
      label: "Images",
      Icon: <CropOriginal />
    });
  }

  return (
    <>
      <TableGroup
        inline
        tables={tables}
        value={inlineIndex}
        onChange={handleChange}
        renderActions={() =>
          hasImagingLicense ? (
            <AddImagesButton onClick={toggleDialog(DIALOG_CONTEXT_IMAGES)} />
          ) : null
        }
      />
      {hasImagingLicense && (
        <ImagesDialog
          orderId={id}
          movementId={movementId}
          open={dialogContext === DIALOG_CONTEXT_IMAGES}
          onClose={toggleDialog(DIALOG_CONTEXT_IMAGES)}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  hasImagingLicense: hasLicense(state, USER_LICENSES.LOADMASTER_IMAGING_RUN_IMAGING_PROGRAM)
});

export const InlineOrderDetailsDrawer = connect(mapStateToProps)(DetailsDrawerPresentation);
