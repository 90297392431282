import { getDateTimeStringWithMillis } from "./Date";
import { addToCircularArray, createCircularArray, getFlattenedArray } from "./CircularArray";

let loggers = {};
const DEBUG = "DEBUG", INFO = "INFO", ERROR = "ERROR";
let buffer = createCircularArray(0);

let config = {
	defaultLogLevel: INFO,
	bufferSize: 0,
	logLevels: {}
}

const logger = getLogger("lib.util.Logger");

export function setLogConfig(value) {
	config = value;
	buffer = createCircularArray(config.bufferSize);
	for (let name in loggers)
		updateLogger(loggers[name], name);
	logger.debug("New log config %o", config);
}

export default function getLogger(name) {
	let result = loggers[name];
	if (result == null) {
		result = {};
		updateLogger(result, name);
		loggers[name] = result;
	}
	return result;
}

function updateLogger(logger, name) {
	let logLevel = config.logLevels[name];
	if (logLevel == null)
		logLevel = config.defaultLogLevel;
	logger.name = name;
	logger.debug = noop;
	logger.info = noop;
	if (logLevel === DEBUG)
		logger.debug = (...args) => log(DEBUG, logger, ...args);
	if (logLevel === DEBUG || logLevel === INFO)
		logger.info = (...args) => log(INFO, logger, ...args);
	logger.error = (...args) => log(ERROR, logger, ...args);
}

function noop() {
}

export function getLog() {
	return getFlattenedArray(buffer);
}

function log(...args) {
	let level = args[0];
	let logger = args[1];
	let format = args[2];
	let rest = args.slice(3);
	let line = getDateTimeStringWithMillis(new Date()) + " " + level + " [" + logger.name + "] " + level + "  " + format;
	if (config.enableConsoleLog)
		console.log(line, ...rest);
	let formatted = line;	// need to bring in the args into the string but don't feel like doing that
	if (config.bufferSize > 0)
		addToCircularArray(buffer, formatted);
}