import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as ChatBubble } from "images/svg/chat-bubble.svg";

export const ChatBubbleDotsIcon = memo(function ImageIcon(props) {
  return (
    <SvgIcon viewBox="0 0 21 24" {...props}>
      <ChatBubble />
    </SvgIcon>
  );
});
