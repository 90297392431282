import { mapObject } from "core/utils/mapper";
import { USER_CONTROLS } from "core/config/controls";

const keyMap = {
  loadboard_active: USER_CONTROLS.LOAD_BOARD_ACTIVE,
  include_rate_info: USER_CONTROLS.INCLUDE_RATE_INFO,
  delivered_load_day: USER_CONTROLS.DELIVERED_LOAD_DAY,
  legal_terms_name: USER_CONTROLS.LEGAL_TERMS_NAME,
  require_order_term: USER_CONTROLS.REQUIRE_ORDER_TERM,
  accept_order: USER_CONTROLS.ACCEPT_ORDER,
  conf_fax_template: USER_CONTROLS.CONF_FAX_TEMPLATE,
  def_move_status: USER_CONTROLS.DEF_MOVE_STATUS,
  require_legal_term: USER_CONTROLS.REQUIRE_LEGAL_TERM,
  make_offer: USER_CONTROLS.MAKE_OFFER,
  decline_offer: USER_CONTROLS.DECLINE_OFFER,
  factor_company_search_days: USER_CONTROLS.FACTORING_COMPANY_SEARCH_DAYS,
  factoring_company_emails: USER_CONTROLS.FACTORING_COMPANY_EMAILS,
  api_key: USER_CONTROLS.API_KEY,
  url: USER_CONTROLS.MAP_URL
};

const valueFormatters = {};

export function mapUserControls(controls) {
  const flattendObject = Object.assign({}, ...controls);
  return mapObject(flattendObject, keyMap, valueFormatters);
}
