import React from "react";
import { Panel, Label, Table, Column } from "lib/components";
import { getDataFromContext } from "lib/util/ModelUtil";

export function PanelLTLDetails(props) {
	return (
		<Panel modelName="dispatch/ltl-order-details?search={orders.id}" autoSearch fillRow>
			<Label caption="Freight Items" fontBold fontSize="large" />
			<Label caption="Pieces" width={80} align="right" fontBold noRowBreak />
			<Label caption="Weight" width={140} align="right" fontBold noRowBreak />
			<Label caption="Spots" width={140} align="right" fontBold />
			<Label fieldName="pieces" width={80} align="right" fontBold noRowBreak />
			<Label fieldName="{weight} {weight_uom}" width={140} align="right" displayType="decimal" replaceModelData fontBold noRowBreak />
			<Label fieldName="spots" width={140} align="right" fontBold fontSize="large" />
			<Table fieldName="freight_items" headerVisible={false} emptyCaption="No freight items available at this time" fillRow > 
				<Column caption="#" fieldName="fgi_sequence_nbr" />
				<Column caption="Pieces" align="right" fieldName="pieces" />
				<Column caption="Packaging" fieldName="fgi_packaging_type_code" />
				<Column caption="Description" fieldName="description" />
				<Column caption="Weight" fieldName="{weight} {weight_uom_type_code}" displayType="decimal" replaceModelData align="right" sortField="weight" />
				<Column caption="Spots" align="right" fieldName="req_spots" />
				<Column caption="Class" fieldName="nmfc_class_code" />
				<Column caption="SKU" fieldName="product_sku" />
				<Column caption="HU" fieldName="handling_units" />
				<Column caption="L" fieldName="length" />
				<Column caption="W" fieldName="width" />
				<Column caption="H" fieldName="height" />
				<Column caption="Cube" fieldName="cubic_units" align="right"/>
				<Column caption="Density" fieldName="density" align="right"/>
			</Table>
			<Panel visible={ratingsVisible} fillRow>
				<Label caption="Ratings" fontBold fontSize="large" />
				<Label caption="Total charges" noRowBreak />
				<Label fieldName="total_charge" displayType="currency" nullDisplayValue="N/A"/>
				<Table fieldName="charges" headerVisible={false} emptyCaption="This order has no rating detail" fillRow >
					<Column caption="Charge type" fieldName="description" />
					<Column caption="Amount" fieldName="fgi_revenue_amount" align="right" displayType="currency"/>
				</Table>
			</Panel>
		</Panel>
	);
}

function ratingsVisible(componentContext) {
	console.log("in %o", componentContext);
	let data = getDataFromContext(componentContext);
	console.log("LTL charges %o", data);
	return data["charges"] != null;
}