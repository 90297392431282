import React from "react";
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton
} from "@material-ui/core";
import { Spacer } from "common";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "core/utils/classNames";
import { useStyles } from "./Dialog.styles";

export function Dialog({
  className,
  classes: extClasses = {},
  title = null,
  children = [],
  actions = null,
  open = false,
  onClose,
  ...restProps
}) {
  const classes = useStyles();
  return (
    <MuiDialog
      {...restProps}
      className={classNames(classes.root, className, extClasses.root)}
      open={open}
      onClose={onClose}
      aria-labelledby="dialog"
    >
      <header className={classes.header}>
        {title && (
          <DialogTitle className={classNames(classes.title, extClasses.title)} id="dialog-title">
            {title}
          </DialogTitle>
        )}
        <Spacer grow />
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </header>
      <DialogContent className={classNames(classes.content, extClasses.content)}>
        {children}
      </DialogContent>
      {actions && (
        <DialogActions className={classNames(extClasses.actions)}>{actions}</DialogActions>
      )}
    </MuiDialog>
  );
}
