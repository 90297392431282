import React, {useState} from "react";
import { useMountEffect } from "lib/util";
import { loadAuthenticatedContext } from "lib/util/MainPageContext";

export function PageWrapper({children}) {
	const [loaded, setLoaded] = useState(false);
	useMountEffect(() => loadSettings(setLoaded));
	let fragmentChildren;
	if (loaded)
		fragmentChildren = children;
	return (
		<React.Fragment> 
			{fragmentChildren}
		</React.Fragment>
	);
}

function loadSettings(setLoaded) {
	loadAuthenticatedContext(() => setLoaded(true));
}