import getLogger from "./Logger";

const log = getLogger("lib.util.Clipboard");

export function copyTextToClipboard(text) {
	log.debug("Copying text [%s] to clipboard", text);
	if (window.clipboardData && window.clipboardData.setData)
		return window.clipboardData.setData("Text", text);
	else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
		var textarea = document.createElement("textarea");
		textarea.textContent = text;
		textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
		document.body.appendChild(textarea);
		textarea.select();
		try {
			return document.execCommand("copy");  // Security exception may be thrown by some browsers.
		}
		catch (ex) {
			log.error("Copy to clipboard failed. %o", ex);
			return false;
		}
		finally {
			document.body.removeChild(textarea);
		}
	}
}

export function copyElementToClipboard(elementId) {
	let element = document.getElementById(elementId);
	if (document.selection) {
		document.selection.empty();
		let range = document.body.createTextRange();
		range.moveToElementText(element);
		range.select().createTextRange();
		document.execCommand('copy');
		document.selection.empty();
	} else if (window.getSelection) {
		window.getSelection().removeAllRanges();
		var range = document.createRange();
		range.selectNode(element);
		window.getSelection().addRange(range);
		document.execCommand('copy');
		window.getSelection().removeAllRanges();
	}
}