import React from "react";
import { Dialog, Typography } from "common";
import { resolveJSONResponse, fetch } from "core/utils/fetch";
import { buildAPIUrl } from "core/utils/urls";
import { useQuery } from "react-query";
import { API_PATHS } from "core/config/urls";
import { convertMilliToDateTimeFormat } from "core/utils/formatters";
import { CircularProgress } from "@material-ui/core";
import { useStyles } from "./CallinsDialog.styles";
import { CallinsForm } from "./CallinsForm";

const queryOptions = {
  retry: false,
  staleTime: 120000 // 2 Minutes
};

async function fetchCallinData(movementId) {
  const response = await fetch({
    url: buildAPIUrl({
      path: API_PATHS.CARRIER_CALLIN_NEXT_SCHEDULED_DATE,
      queryParams: { movementId }
    })
  });
  const { nextSchedCallin = null } = resolveJSONResponse(response);
  if (nextSchedCallin === -1 || nextSchedCallin === 0) return null;
  return convertMilliToDateTimeFormat(nextSchedCallin);
}

export function CallinsDialog({
  open,
  onClose,
  id,
  movementId,
  pickup = {},
  delivery = {},
  commodity = {}
}) {
  const classes = useStyles();
  const { data: nextSchedCallin = null, isFetching } = useQuery(
    open && [movementId, "callin"],
    fetchCallinData,
    queryOptions
  );

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={onClose}
      classes={{
        content: classes.dialogContent
      }}
      title="Add Callin"
    >
      {isFetching && (
        <div className={classes.overlay}>
          <CircularProgress className={classes.loadingSpinner} color="primary" />
        </div>
      )}
      <header className={classes.header}>
        <div className={classes.column}>
          <Typography value="Order Number" label />
          <Typography variant="body1" fontWeight="bold" value={id} />
        </div>
        <div className={classes.column}>
          <Typography value="Pickup" label />
          <Typography
            variant="body1"
            fontWeight="bold"
            color="primary"
            value={pickup.city && pickup.state ? `${pickup.city}, ${pickup.state}` : "N/A"}
          />
        </div>
        <div className={classes.column}>
          <Typography value="Delivery" label />
          <Typography
            variant="body1"
            fontWeight="bold"
            color="primary"
            value={delivery.city && delivery.state ? `${delivery.city}, ${delivery.state}` : "N/A"}
          />
        </div>
        <div className={classes.column}>
          <Typography value="Commodity" label />
          <Typography variant="body1" fontWeight="bold" value={commodity.name || "N/A"} />
        </div>
      </header>
      <CallinsForm
        id={id}
        loading={isFetching}
        onClose={onClose}
        nextSchedCallin={nextSchedCallin}
        movementId={movementId}
      />
    </Dialog>
  );
}
