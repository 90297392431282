import React, { memo } from "react";
import { Component } from ".";
const svgs = require.context("../../images/svg", false);
const base = require.context("../../images", false);
// really we want the equivalent of "import ReactComponent from " each of these files so that we can control the size/fill/etc 

export function getSVG(name) {
    try {
		if (name.indexOf(".") < 0)
			name += ".svg"
		return svgs("./" + name);
    } catch (err) {
        console.log("Error loading image " + name);
        return null; // should display an error image of some sort
    }
}

function Image(props) {
	let imgProps = {};
	if (props.height != null)
		imgProps.height = props.height;
	if (props.width != null)
		imgProps.width = props.width;
	if (props.fill)
		imgProps.fill = props.imageFill;
	let imgSrc; 
	if (props.image && props.image.endsWith(".png"))
		imgSrc = base("./" + props.image);
	else
		imgSrc = getSVG(props.image);
	return (
		<Component {...props}>
			<img style={imgProps} alt="" src={imgSrc} />		
		</Component>
	);
}

export default memo(Image);