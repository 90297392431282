import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { CalendarIcon } from "common/SVG";
import { useField } from "formik";
import { useFieldMeta } from "core/hooks";
import { format as dateFnsFormat } from "date-fns";

const format = "MM/dd/yyyy";
const placeholder = "MM/DD/YYYY";

/**
 * Returns a formatted date based off the default format used by the DateTimePicker
 *
 * @export
 * @param {date} date
 * @returns
 */
export function toDateFormat(date) {
  return dateFnsFormat(date, format);
}

export function DatePicker(props) {
  const [field, meta, helpers] = useField(props);
  const [error, helperText] = useFieldMeta(meta);

  function handleChange(_, formattedDate) {
    helpers.setValue(formattedDate);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...field}
        {...props}
        format={format}
        placeholder={placeholder}
        onChange={handleChange}
        ampm
        showTodayButton
        error={error}
        helperText={helperText}
        variant="dialog"
        inputVariant="outlined"
        keyboardIcon={<CalendarIcon />}
        KeyboardButtonProps={{
          disableRipple: true,
          disableFocusRipple: true,
          style: { backgroundColor: "transparent" }
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
