import React from "react";
import { Panel, Label, Button } from "lib/components";
import TableFilterBox from "./TableFilterBox";
import { downloadText, downloadBinary, downloadBlob } from "lib/util/DownloadUtil";
import { exportToCSV, exportToXLS, exportToPDF } from "./ExportTable";
import showSnackbar from "../Snackbar";

export default function TableHeader(props) {
	if (props.headerVisible === false)
		return null;
	let countCaption;
	if (props.rows) {
		if (props.rows.length === 0)
			countCaption = "No results";
		else if (props.rows.length === 1)
			countCaption = "1 result";
		else
			countCaption = props.rows.length + " results";
	}
	let extraComponent;
	if (props.headerComponent)
		extraComponent = props.headerComponent; 
	return (
		<Panel>
			<TableFilterBox width={200} noRowBreak onChange={props.setFilter} />
			<Label caption={countCaption} marginLeft={12} fontSize="large" color="#888888" visible={props.loading !== true} noRowBreak />
			<Panel fillRow noRowBreak />
			{extraComponent}
			<Button image="ellipsis" enabled={props.rows.length > 0} dropDown={getDropDown(props)} dropDownAlign="right" height={32} margin={0} />
		</Panel>
	)
}

function getDropDown(props) {
	let result = [];
	if (props.allowExport !== false) {// also check permissions
		result.push(<Label caption="Export to XLS" onClick={() => downloadXLS(props)} />);
		result.push(<Label caption="Export to CSV" onClick={() => downloadCSV(props)} />);
		result.push(<Label caption="Export to PDF" onClick={() => downloadPDF(props)} />);
	}
	return result;
}

function getFileName(fileName, extension) {
	if (fileName == null)
		fileName = "Export";
	if (!fileName.toLowerCase().endsWith("." + extension))
		fileName += "." + extension;
	return fileName;
}

function downloadCSV(props) {
	exportToCSV(props).then(csv => {
		downloadText(csv, getFileName(props.exportName, "csv"));
		showSnackbar("Listing has been exported to CSV.  Check your download area.");
	});
}

function downloadXLS(props) {
	const xls = exportToXLS(props);
	downloadBinary(xls, getFileName(props.exportName, "xlsx"));
	showSnackbar("Listing has been exported to XLS.  Check your download area.");
}

function downloadPDF(props) {
	exportToPDF(props).then(pdf => {
		pdf.getBlob(blob => {
			downloadBlob(blob, getFileName(props.exportName, "pdf"));
			showSnackbar("Listing has been exported to PDF.  Check your download area.");
		});
	});
}