import React from "react";
import { FileUploader } from "common";
import { Container, makeStyles } from "@material-ui/core";
import { uploadImages } from "core/store/actions/OrdersActions";
import { getControlValue } from "core/store/selectors/UserSelectors";
import { USER_CONTROLS } from "core/config/controls";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center"
  },
  form: {
    width: 450
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  actionButton: {
    minWidth: 100,
    marginRight: theme.spacing(1),
    "$:last-of-type": {
      marginRight: 0
    }
  }
}));

export function FileUploaderPlaygroundPresentation({ maxFileSize, dispatchUploadImages }) {
  const handleSubmit = async ({ files }, helpers) => {
    try {
      await dispatchUploadImages(files, 317626);
      helpers.setSubmitting(false);
    } catch (error) {
      helpers.setSubmitting(false);
    }
  };
  const classes = useStyles();
  const docTypes = ["Option1", "Option2", "Option3"];
  return (
    <Container maxWidth="sm" className={classes.root}>
      <FileUploader
        onSubmit={handleSubmit}
        docTypes={docTypes}
        maxSize={maxFileSize * 1000000}
        debugger
      />
    </Container>
  );
}

const mapStateToProps = (state) => ({
  maxFileSize: getControlValue(state, USER_CONTROLS.MAX_FILE_SIZE)
});

const mapDispatchToProps = {
  dispatchUploadImages: uploadImages
};

export const FileUploaderPlayground = connect(mapStateToProps, mapDispatchToProps)(FileUploaderPlaygroundPresentation);
