export const UI_SET = "UI_SET";
export const UI_RESET = "UI_RESET";

export function setUI(payload) {
  return {
    type: UI_SET,
    payload
  };
}

export function resetUI() {
  return {
    type: UI_RESET
  };
}

export function openNavDrawer() {
  return (dispatch) => {
    dispatch(
      setUI({
        navDrawerOpen: true
      })
    );
  };
}

export function closeNavDrawer() {
  return setUI({ navDrawerOpen: false });
}
