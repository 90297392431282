import React, { useMemo, useState, useEffect } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Divider,
  Dialog
} from "@material-ui/core";

import { Document, Page, pdfjs } from "react-pdf";
import { Typography, PDFFileDownloader } from "common";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloseIcon from "@material-ui/icons/Close";
import { MEDIA_TYPE_OCTET_STREAM, FETCH_RESPONSE_TYPE_ARRAY_BUFFER } from "core/utils/constCapsule";
import { API_PATHS } from "core/config/urls";
import { buildAPIUrl } from "core/utils/urls";
import { fetch } from "core/utils/fetch";
import { useStyles } from "./TermsAndConditionsDialog.styles";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function TermsAndConditionsDialog({ showDialog, onClose, movementId, id }) {
  const classes = useStyles();
  const [orderConfPdfData, setOrderConfPdfData] = useState(null);
  useEffect(() => {
    if (!showDialog) return;
    async function loadOrderConfPdfData() {
      let response = "";
      response = await fetch({
        url: buildAPIUrl({
          path: API_PATHS.ORDER_TERMS_RETRIEVE,
          queryParams: { id, movementId }
        }),
        responseType: FETCH_RESPONSE_TYPE_ARRAY_BUFFER
      });
      setOrderConfPdfData(atob(response.data));
    }
    loadOrderConfPdfData();
  }, [showDialog, id, movementId]);

  const OrderConfDoc = useMemo(
    () =>
      orderConfPdfData ? (
        <Document
          file={{
            data: orderConfPdfData
          }}
        >
          <Page pageNumber={1} />
        </Document>
      ) : (
        "Loading PDF..."
      ),
    [orderConfPdfData]
  );
  return (
    <Dialog
      open={showDialog}
      fullWidth
      maxWidth="md"
      disableBackdropClick
      onClose={onClose}
      classes={{
        paperScrollPaper: classes.paper
      }}
    >
      <DialogTitle
        disableTypography
        classes={{
          root: classes.title
        }}
        id="offer-dialog-title"
      >
        Rate Confirmation
        <IconButton classes={{ root: classes.iconButton }} onClick={onClose} value="cancel">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div className={classes.header}>
        {orderConfPdfData && (
          <PDFFileDownloader
            fileName="RateConfirmation.pdf"
            fileContent={btoa(orderConfPdfData)}
            mediaType={MEDIA_TYPE_OCTET_STREAM}
          >
            {({ loading }) => (
              <Button
                style={{ cursor: loading ? "wait !important" : "pointer" }}
                startIcon={<CloudDownloadIcon />}
                id="rate-confirmation-link"
                size="small"
                color="primary"
              >
                <Typography
                  component="span"
                  fontWeight="bold"
                  fontSize="medium"
                  value="Download Rate Confirmation"
                />
              </Button>
            )}
          </PDFFileDownloader>
        )}
      </div>
      <DialogContent>{OrderConfDoc}</DialogContent>
      <Divider />
      <DialogActions className={classes.footer}>
        <Typography variant="button">
          <Button
            classes={{
              outlined: classes.cancelButton
            }}
            onClick={onClose}
            variant="outlined"
            value="cancel"
            size="large"
          >
            Close
          </Button>
        </Typography>
      </DialogActions>
    </Dialog>
  );
}
