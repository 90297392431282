import React from "react";
import { Typography } from "common";

export function OrderNumberTableCell({ id }) {
  return (
    <Typography
      color="primary"
      component="div"
      fontSize="large"
      fontWeight="bold"
      value={id || "N/A"}
    />
  );
}
