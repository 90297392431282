import React from "react";
import { CheckCircle, CheckCircleOutline } from "@material-ui/icons";
import { classNames } from "core/utils";
import { Typography } from "common/Typography";
import { useStyles } from "./DocTypes.styles";
import { useFileUploader } from "../context";

export function FileTypes() {
  const classes = useStyles();
  const { docTypes, files } = useFileUploader();

  return (
    <ul className={classes.root}>
      {docTypes.map((type) => {
        const match = !!files.find((file) => file.docType === type);
        return (
          <Typography
            key={type}
            component="li"
            variant="subtitle2"
            className={classNames(classes.item, {
              [classes.itemValid]: match
            })}
          >
            {match && (
              <CheckCircle className={classNames(classes.itemIcon, classes.itemIconValid)} />
            )}
            {!match && <CheckCircleOutline className={classes.itemIcon} />}
            {type}
          </Typography>
        );
      })}
    </ul>
  );
}
