import React from "react";
import { Select, MenuItem, InputBase } from "@material-ui/core";
import { formatUSDCurrency } from "core/utils/formatters";
import classNames from "core/utils/classNames";
import { useStyles } from "./PendingOfferSelect.styles";

export function PendingOfferSelect({
  menuItems,
  rate,
  handleChange,
  handleClose,
  handleOpen,
  menuOpen,
  targetMenuList,
  disabled
}) {
  const classes = useStyles();
  return (
    <Select
      disabled={disabled}
      classes={{
        root: classes.select,
        icon: classNames(classes.selectIcon, {
          [classes.selectIconDisabled]: disabled
        }),
        selectMenu: classNames(classes.selectMenu, {
          [classes.selectMenuDisabled]: disabled
        })
      }}
      id="offer"
      value="default"
      onChange={handleChange}
      renderValue={() => {
        if (targetMenuList === "decline") {
          return "Reason";
        }
        if (rate === 0) return "MAKE OFFER";
        return `${formatUSDCurrency(rate)}`;
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
      onClose={handleClose}
      onOpen={handleOpen}
      open={menuOpen}
      input={
        <InputBase
          classes={{
            root: classes.bootstrapRoot,
            input: classes.bootstrapInput
          }}
        />
      }
    >
      {/** Adding this as a place holder as the menu item changes dynamically
       * and will cause warnings in console if value isn't in the list.
       */}
      <MenuItem value="default" disabled style={{ visibility: "hidden" }} />
      {menuItems[targetMenuList].map((menuItem) => (
        <MenuItem
          alignItems="flex-start"
          className={classes.menuItem}
          key={menuItem.name}
          value={menuItem.value}
        >
          {menuItem.icon &&
            React.cloneElement(menuItem.icon, {
              className: classes.menuItemIcon
            }) && <div className={classes.grow} />}

          {menuItem.name}
        </MenuItem>
      ))}
    </Select>
  );
}
