import { Collapse } from "@material-ui/core";
import classNames from "core/utils/classNames";
import React from "react";
import { useStyles } from "./ExpansionWrapper.styles";

export function ExpansionWrapper({
  open = false,
  fullWidth = false,
  children = []
}) {
  const classes = useStyles();
  return (
    <Collapse in={open}>
      <div
        className={classNames(classes.cellSpacing, {
          [classes.disableGutters]: fullWidth
        })}
      >
        {children}
      </div>
    </Collapse>
  );
}
