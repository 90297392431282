import React from "react";
import { InlineInput } from "features/InlineInput";
import { Typography } from "common";
import * as Yup from "yup";
import { HTTP_REQUEST_POST } from "core/utils/constCapsule";
import { API_PATHS } from "core/config/urls";
import { resolveJSONResponse, fetch } from "core/utils/fetch";
import { useQueryParams } from "core/hooks/useQueryParams";
import { buildAPIUrl } from "core/utils/urls";
import { formatUSDCurrency } from "core/utils/formatters";
import { useComplete } from "pages/Offer/Offer";
import { useStyles } from "../../Offer.styles";

const validationSchema = Yup.object().shape({
  offer: Yup.number().required("Offer is required")
});

export function Counter({ rate, onResponse }) {
  const classes = useStyles();
  const { offerId } = useQueryParams();
  const { complete, setComplete } = useComplete();

  const onSubmit = async ({ offer: newAmount }) => {
    const response = await fetch({
      url: buildAPIUrl(API_PATHS.COUNTER_OFFER),
      method: HTTP_REQUEST_POST,
      data: { offerId, newAmount }
    });
    onResponse(resolveJSONResponse(response));
    setComplete(true);
  };

  if (complete) return null;
  return (
    <div className={classes.column}>
      <Typography variant="h6" value="Counter Rate" />
      <InlineInput
        onSubmit={onSubmit}
        name="offer"
        type="number"
        validationSchema={validationSchema}
        placeholder={formatUSDCurrency(rate, { prefix: false })}
      />
    </div>
  );
}
