import json2xls from "json2xls";
import { flatten, binaryToArrayBuffer, saveAsBlob } from "./common";
import { MEDIA_TYPE_OCTET_STREAM } from "./constCapsule";

/**
 * Converts JSON date into XLS binary and then converts the binary
 * into an array buffer for client side downloading.
 * Based of this solution: https://github.com/rikkertkoppes/json2xls/issues/33#issuecomment-448494417
 *
 * @export
 * @param {Object} data
 * @param {Array} fields
 * @returns
 */
export function toXLS(data, fields) {
  if (!data) return undefined;
  const rows = data.map((row) => flatten(row, { separator: "_" }));
  const XLSBinary = json2xls(rows, fields);
  const XLS = binaryToArrayBuffer(XLSBinary);
  return XLS;
}

/**
 * Uses toXLS to convert data to XLS binary then saves the file contents
 * as a blob using filesaver.js
 *
 * @export
 * @param {*} fileName
 * @param {*} data
 */
export async function downloadXLS(fileName, data, fields) {
  const fileContent = toXLS(data, fields);
  saveAsBlob(`${fileName}.xlsx`, fileContent, MEDIA_TYPE_OCTET_STREAM);
}
