import React from "react";
import { TextField } from "@material-ui/core";
import { useFieldMeta } from "core/hooks";

export function AutoCompleteInput({ meta, renderParams, label, options }) {
  const [error, helperText] = useFieldMeta(meta);
  return (
    <TextField
      {...renderParams}
      error={error}
      helperText={helperText}
      label={label}
      InputProps={{
        ...renderParams.InputProps,
        endAdornment: options.length
          ? renderParams.InputProps.endAdornment
          : null
      }}
    />
  );
}
