import React from "react";
import * as MUI from "@material-ui/core";

export function Select({
  options = [],
  children,
  variant = "outlined",
  placeholder,
  ...restProps
}) {
  if (!options.length) return null;
  const _options = placeholder ? [{ label: placeholder, value: placeholder }, ...options] : options;
  const defaultValue = _options[0].value;

  return (
    <MUI.Select size="small" variant={variant} defaultValue={defaultValue} {...restProps}>
      {_options.map(({ value, label }) => (
        <MUI.MenuItem key={value} value={value} disabled={placeholder && value === placeholder}>
          {label}
        </MUI.MenuItem>
      ))}
      {children}
    </MUI.Select>
  );
}
