import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  toolbar: {
    height: "unset",
    minHeight: "unset"
  },
  caption: {
    fontSize: 12
  },
  selectRoot: {
    fontSize: 12
  },
  actions: {
    marginLeft: 0
  }
}));
