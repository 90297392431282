export function formatCityStateZip(city, state, zip) {
	let result = "";
	if (city)
		result += city;
	if (result.length > 0 && state)
		result += ", ";
	if (state)
		result += state;
	if (result.length > 0 && zip)
		result += " ";
	if (zip)
		result += zip;
	return result;
}