import { useDropzone } from "react-dropzone";
import React, { useEffect } from "react";
import { classNames } from "core/utils";
import { Typography } from "common/Typography";
import { IconButton } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { useStyles } from "./FileDropzone.styles";
import { useFileUploader } from "../context";

export function FileDropzone({ whitelist = [] }) {
  const classes = useStyles();
  const { setFiles } = useFileUploader();
  const { acceptedFiles, isDragActive, getRootProps, getInputProps } = useDropzone({
    accept: whitelist ? whitelist.join(",") : undefined
  });

  useEffect(() => {
    setFiles(acceptedFiles);
  }, [acceptedFiles, setFiles]);

  return (
    <div
      {...getRootProps()}
      className={classNames(classes.root, {
        [classes.rootActive]: isDragActive
      })}
    >
      <input {...getInputProps()} className={classes.input} />
      <Typography variant="h4" wordWrap>
        Drag and Drop document or Add
      </Typography>
      <IconButton color="primary">
        <AddCircle className={classes.addButton} />
      </IconButton>
    </div>
  );
}
