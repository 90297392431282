import React, { useState } from "react";
import { Select, FormControl, MenuItem, InputLabel } from '@material-ui/core';
import {Component, useComponentContext} from ".";
import { useMountEffect } from "lib/util";
import {makeStyles} from "lib/util";

const useStyles = makeStyles(theme => ({
	formControl: {
			marginTop: 6,
			width: "100%"
	},
	label: {
		fontSize: 11.5
	}
}));

export default function ComboBox(props) {
	let [selectedIndex, setSelectedIndex] = useState();
	let value = props.selectedIndex;
	if (value === undefined)
		value = selectedIndex;
	const classes = useStyles();

	let context = useComponentContext();
    useMountEffect(() => {updateValueOwner(context, props, value)});
	let items = buildMUIItems(getItems(context, props.items));
	let selectProps = {};
	if (value !== undefined)
		selectProps.value = value;

	return (
		<Component {...props} >
			<FormControl variant="outlined" className={classes.formControl}>
				<InputLabel className={classes.label}>{props.caption}</InputLabel>
				<Select	{...selectProps}  
					MenuProps={{
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left"
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left"
					},
					getContentAnchorEl: null
					}}				
					onChange={(event) => handleChange(context, props, event.target.value, setSelectedIndex)} 
					label={props.caption} 
				>
					{items}
				</Select>
			</FormControl>
		</Component>
	);
}

function getItems(context, items) {
	if (typeof items === "function")
		return items(context);
	else
		return items;
}

function buildMUIItems(items) {
	let result = [];
	for (let i = 0; items != null && i < items.length; i++) {
		let caption = items[i];
		if (caption.caption)
			caption = caption.caption;
		// can also handle image names and complex components here
		result.push(<MenuItem key={"item" + i} value={i}>{caption}</MenuItem>); // items should be able to be an array of objects that contains the "value" here
	}
	return result;
}

function updateValueOwner(context, props, value) {
	if (context != null && context.valueOwner != null)
		context.valueOwner.valueChanged(props, value);
}

function handleChange(context, props, value, setSelectedIndex) {
	setSelectedIndex(value);
	updateValueOwner(context, props, value);
	if (props.onChange)
		props.onChange(value);
}