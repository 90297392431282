import React from "react";
import { useData, showProgressSnackbar, Button } from "lib/components";
import { isPermissionsAllowed, generateReport } from "lib/util";

export function DownloadSettlementSummaryButton({checkNumber, checkDate, caption}) {
	const data = useData();
	if (!isPermissionsAllowed("InternetModule", "InternetModuleCarrierSettlementHistory"))
		return null;
	if (data != null && checkNumber == null)
		checkNumber = data["check_number"];	
	if (data != null && checkDate == null)
		checkDate = data["pay_date"];	
	if (caption == null)
		caption = checkDate;
	function downloadSummary() {
		let filters = [{ paramName: "check_number", paramValue:checkNumber }, { paramName: "check_date", paramValue:checkDate }];
		showProgressSnackbar("Settlement Summary","We are generating your settlement summary and it will download automatically."); 
		generateReport("drs/settlement-summary", `Settlement Summary ${checkDate}.pdf`, filters); 
	}
	if (checkDate == null)
		return null;
	else
		return <Button caption={caption} marginLeft={-8} marginTop={0} image="document-single" color="primary" 
			variant="text" fontSize="small" onClick={downloadSummary} />
}