import React, {useState, useContext} from "react";
import { Switch as MUISwitch, Box } from "@material-ui/core";
import {Label, Component} from ".";
import {getModelDataDisplayValue} from "../util/ModelUtil";
import { makeStyles } from "lib/util";
import {DataContext} from "./Context";
import { getSVG } from "./Image";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  switchRoot: {
    height: 44,
    width: 67
  },
  switchBase: {
    top: 1,
    "&$checked": {
      transform: "translateX(25px)"
    }
  },
  thumb: ({ thumbSvg = "", checked = false }) => ({
    position: "relative",
    "&::before": {
      content: `" "`,
      display: "block",
      width: "100%",
      height: 24,
      backgroundImage: `url("${thumbSvg}")`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "object-fit",
      backgroundPosition: "center center"
    },
    width: 24,
    height: 24,
    backgroundColor: theme.palette.primary.main
  }),
  track: ({ trackSvg = "", checked = false }) => ({
    "&::before": {
      content: `" "`,
      display: "block",
      width: "100%",
      height: 20,
      opacity: ".50",
      transition: theme.transitions.create("backgroundPosition", {
        duration: theme.transitions.duration.shortest
      }),
      backgroundImage: `url("${trackSvg}")`,
      backgroundRepeat: "no-repeat",
      backgroundSize: 10,
      backgroundPosition: checked ? "22% center" : "78% center"
    },
    height: 20,
    borderRadius: 40,
    opacity: "1 !important",
    display: "block",
    backgroundColor: `${theme.palette.primary.dark} !important`
  }),
  checked: {},
  label: {
    opacity: "38%"
  },
  activeLabel: {
    opacity: "100"
  }
}));

export default function Switch(props) {
    const dataContext = useContext(DataContext);
    let leftIcon, rightIcon;
    if (props.leftImage)
        leftIcon = getSVG(props.leftImage);
    if (props.rightImage)
        rightIcon = getSVG(props.rightImage);
	const [checked, setChecked] = useState(getModelValue(dataContext, props.fieldName));
	let value = props.checked;
	if (value == null)
		value = checked;

    const classes = useStyles({
        value,
        thumbSvg: value ? rightIcon : leftIcon,
        trackSvg: value ? leftIcon : rightIcon
    });

    function handleChange(event, newValue) {
        if (props.fieldName != null && dataContext != null)
			dataContext[props.fieldName] = newValue;
		if (props.onChange)
			props.onChange(newValue);
        setChecked(newValue);
    }

    let startLabel, endLabel;
    if (props.leftLabel)
        startLabel = <Label fontSize="medium" caption={props.leftLabel} rowBreak={false} width="unset"/>
    if (props.rightLabel)
        endLabel = <Label fontSize="medium" caption={props.rightLabel} />
    return (
        <Component {...props}>
            <Box className={classes.root}>
                {startLabel}
                <MUISwitch color="primary" checked={value} onChange={handleChange} classes={{
                    root: classes.switchRoot,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked
                }}/>
            {endLabel}
            </Box>
        </Component>
  );
}

function getModelValue(dataContext, fieldName) {
    let modelValue = getModelDataDisplayValue(dataContext, fieldName);
    return modelValue === 'Y' || modelValue === true || modelValue === "true";
}