import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  dialogContent: {
    extend: "flex",
    maxWidth: 767
  },
  header: {
    extend: "flex",
    marginBottom: theme.spacing(4),
    "&:nth-child(3)": {
      marginBottom: theme.spacing(2)
    },
    flexDirection: "row"
  },
  column: {
    marginRight: theme.spacing(6)
  },
  loadingSpinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    zIndex: 1,
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.5)"
  }
}));
