import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from "@material-ui/pickers";
import { CalendarIcon } from "common/SVG";
import { format as dateFnsFormat } from "date-fns";
import { withStyles } from "@material-ui/styles";
import { getDateString } from "lib/util/Date";
import { getLogger } from "lib/util";

const format = "MM/dd/yyyy";
const placeholder = "MM/DD/YYYY";

export function toDateFormat(date) {
	return dateFnsFormat(date, format);
}

const log = getLogger("lib.components.DatePicker");

const styles = {
	root: {
		width: "100%",
		"& .MuiOutlinedInput-root": {
			marginBottom: 2,
			marginTop: -1,
		},
		marginTop: 1,
		"& input": {
			height: 14
		},
		"& input:focus": {
			borderColor: "#137CDD"
		}
	},
};


function DatePicker(props) {
	const [value, setValue] = useState(props.value);
	function handleChange(value) {
		setValue(value);
		if (props.onChange != null)
			props.onChange(getDateString(value));
	}
	let display = value;
	if (value === undefined)
		display = null;
	const inputProps = { onFocus: props.onFocus };
	log.debug("Input props: %o  %o", inputProps, props);
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				{...props}
				format={format}
				inputProps={inputProps}
				placeholder={placeholder}
				onChange={handleChange}
				ampm
				value={display}
				showTodayButton
				variant="dialog"
				invalidDateMessage={null}
				keyboardIcon={<CalendarIcon />}
				KeyboardButtonProps={{
					disableRipple: true,
					disableFocusRipple: true,
					style: { backgroundColor: "transparent" }
				}}
			/>
		</MuiPickersUtilsProvider>
	);
}

export default withStyles(styles)(DatePicker);
