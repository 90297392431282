import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row"
  },
  menuItem: {
    minWidth: 110,
    maxWidth: 140,
    backgroundColor: "white",
    transition: "0s",
    color: "#9E9E9E",
    fontSize: 13,
    fill: "#9E9E9E",
    "&:hover": {
      color: "#FF6600",
      "& $menuItemIcon": {
        fill: "#458719"
      }
    }
  },
  menuItemIcon: {
    fill: "#9E9E9E"
  },

  select: {
    minWidth: 110,
    maxWidth: 140,
    height: 30,
    fontSize: 15,
    backgroundColor: "#FF6600",
    color: "white",
    "&:focus": {
      backgroundColor: "#FF6600",
      color: "white",
      borderRadius: "5px"
    },
    display: "flex",
    alignItems: "center"
  },
  selectMenu: {
    maxWidth: 140,
    minWidth: 110,
    backgroundColor: "#FF6600",
    color: "white",
    justifyContent: "center",
    "&:focus": {
      backgroundColor: "#FF6600",
      color: "white",
      borderRadius: "5px"
    }
  },
  selectMenuDisabled: {
    minWidth: 110,
    maxWidth: 140,
    height: 30,
    fontSize: 15,
    backgroundColor: "white",
    color: "#9E9E9E",
    "&:focus": {
      backgroundColor: "white",
      color: "#9E9E9E",
      borderRadius: "5px"
    },
    display: "flex",
    justifyContent: "center",
    BorderColor: "#9E9E9E",
    border: "1px solid #9E9E9E"
  },
  selectIcon: {
    backgroundColor: "#FF6600",
    color: "white"
  },
  selectIconDisabled: {
    backgroundColor: "white",
    color: "#9E9E9E"
  },
  bootstrapRoot: {
    marginTop: 12,
    lineHeight: 2.5
  },
  bootstrapInput: {}
});
