import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row"
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  root: {
    extend: "column",
    justifyContent: "space-between",
    height: 400,
    alignItems: "flex-start"
  },
  details: {
    maxWidth: 795,
    margin: 0,
    justifyContent: "space-between"
  },
  rate: {
    extend: "row"
  }
}));
