import React from "react";
import classNames from "core/utils/classNames";
import { IconButton } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { useStyles } from "./DrawerButton.styles";

export function DrawerButton({ onClick, open }) {
  const classes = useStyles();
  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={onClick}
      edge="start"
      className={classNames(classes.root, open && classes.hide)}
    >
      <MenuIcon />
    </IconButton>
  );
}
