import React from "react";
import { DefaultPageContainer } from "pages/containers";
import { useLocation, Redirect } from "react-router";
import { Typography } from "common";
import { Container, Button } from "@material-ui/core";
import { BrokePalletIcon } from "common/SVG";
import { PATHS } from "core/config/urls";
import { Link } from "react-router-dom";
import { useStyles } from "./Error.styles";

const messages = {
  500: {
    message: "Oh no, something went wrong",
    caption: "Looks like something went wrong, trying refreshing the page"
  },
  404: {
    message: "Page Not Found"
  }
};

export function Error() {
  const classes = useStyles();
  const { state } = useLocation();
  if (!state) return <Redirect to={PATHS.ROOT} />;
  const { message, caption, status = 500 } = state;
  return (
    <DefaultPageContainer>
      <Container className={classes.root} maxWidth="md">
        <BrokePalletIcon className={classes.spacing} />
        <Typography className={classes.status} variant="h1" value={status} />
        <Typography
          className={classes.spacing}
          variant="h5"
          value={
            message ||
            (messages[status]
              ? messages[status].message
              : messages[500].message)
          }
        />
        <Typography
          className={classes.spacing}
          variant="caption"
          value={
            caption || (messages[status] ? messages[status].caption : null)
          }
        />
        <Button
          component={Link}
          to={PATHS.ROOT}
          variant="contained"
          color="primary"
        >
          Go Home
        </Button>
      </Container>
    </DefaultPageContainer>
  );
}
