import React, { useState } from "react";
import { Drawer } from "@material-ui/core";
import classNames from "core/utils/classNames";
import { getLogger, getUIContext, makeStyles } from "lib/util";
import { Button, Panel, Label } from ".";

const log = getLogger("lib.components.Slideout");

const useStyles = makeStyles((theme) => ({
	shiftDown: {
		marginTop: theme.dimensions.navHeight
	},
	wrapper: {
		padding: 24,
		paddingLeft: 40,
		height: "100%",
		marginBottom: 32,
		display: "flex",
		flexDirection: "column",
		overflow: "hidden"
	}
}));

export function Slideout() {
	let [slideoutContent, setSlideoutContent] = useState(null);
	getUIContext().setSlideoutContent = setSlideoutContent;
	const classes = useStyles();
	return (
	    <Drawer	classes={{paper: classNames(classes.paper, classes.shiftDown)}}	open={slideoutContent != null} onClose={onClose} >
			<Panel>
				<Label caption={slideoutContent && slideoutContent.title} fontSize="xlarge" paddingLeft={24} paddingTop={12} fontBold fillRow noRowBreak />
				<Button image="x" onClick={onClose} />
			</Panel>
			<div className={classes.wrapper} >
				{slideoutContent && slideoutContent.content}
			</div>
		</Drawer>
	);	
}

function onClose() {
	if (getUIContext().setSlideoutContent == null)
		log.error("Cannot show dialog because main page context has not been set.");
	else
		getUIContext().setSlideoutContent(null);
}

export default function showSlideout(title, content, dialogProps) {
	log.debug("Show slideout %o %o", title, content);
	if (getUIContext().setSlideoutContent == null) {
		log.error("Cannot show dialog because main page context has not been set.");
		return;
	}	
	if (content == null)
		return null;
	if (!!(content && content.constructor && content.call && content.apply))
		content = content();
	let contentStyle;
	if (dialogProps != null && dialogProps.contentStyle)
		contentStyle = dialogProps.contentStyle;
	getUIContext().setSlideoutContent({content: content, title: title, style: contentStyle});
}