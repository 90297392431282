import React from "react";
import { Table, Column, Button, Panel, Label, ButtonActions } from "../components";
import { DefaultPageContainer } from "pages/containers";

export default function ChangeLicense(props) {
    return (
		<DefaultPageContainer >
			<Panel name="ChangeLicense" modelName="lib/license-modules">
				<Label name="labelInstructions" caption="Select the modules you would like to enable and click" fontSize="large" noRowBreak />
				<Button name="buttonSubmit" caption=">> HERE <<" color="primary" action={ButtonActions.SUBMIT} />
				<Table name="tableModules" showLinesBetweenRows={false} filterVisible rowSpacing="4" maxHeight="600px" minWidth="600px">
					<Column name="columnModule" caption="Module" fieldName="module_name" />
					<Column name="columnLicensed" caption="Licensed" fieldName="licensed" checkable />
				</Table>
			</Panel>
		</DefaultPageContainer>		
    );
}