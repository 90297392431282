import React from "react";
import { TextField as MuiTextField } from "@material-ui/core";
import { useField } from "formik";
import { useFieldMeta } from "core/hooks";

export function TextField(props) {
  const [field, meta] = useField(props);
  const [error, helperText] = useFieldMeta(meta);
  return <MuiTextField {...field} {...props} error={error} helperText={helperText} />;
}
