import {
  ORDERS_TYPE_ACCEPTED,
  ORDERS_TYPE_OFFERED,
  ORDERS_TYPE_PENDING,
  ORDERS_TYPE_UNPAID,
  ORDERS_CATEGORY_AVAILABLE,
  ORDERS_TYPE_PAYMENTS_DUE,
  ORDERS_TYPE_PAYMENTS_RECEIVED,
  ORDERS_CATEGORY_PAYMENTS_DUE,
  USER_TYPE_CARRIER,
  USER_TYPE_FACTORING_COMPANY
} from "core/utils/constCapsule";

const initialFactoringOrdersState = {
  isFetching: true,
  orders: null,
  imagesByMovementId: {}
};

const initialCarrierOrdersState = {
  ...initialFactoringOrdersState,
  callinsByOrderId: {}
};

export const initialState = {
  activeCategories: {
    [USER_TYPE_CARRIER]: ORDERS_CATEGORY_AVAILABLE,
    [USER_TYPE_FACTORING_COMPANY]: ORDERS_CATEGORY_PAYMENTS_DUE
  },
  available: {
    ordersType: ORDERS_TYPE_PENDING,
    all: initialCarrierOrdersState,
    pending: initialCarrierOrdersState
  },
  accepted: {
    ordersType: ORDERS_TYPE_ACCEPTED,
    accepted: initialCarrierOrdersState
  },
  delivered: {
    ordersType: ORDERS_TYPE_UNPAID,
    paid: initialCarrierOrdersState,
    unpaid: initialCarrierOrdersState
  },
  offerHistory: {
    ordersType: ORDERS_TYPE_OFFERED,
    offered: initialCarrierOrdersState
  },
  paymentsDue: {
    ordersType: ORDERS_TYPE_PAYMENTS_DUE,
    paymentsDue: initialFactoringOrdersState
  },
  paymentsReceived: {
    ordersType: ORDERS_TYPE_PAYMENTS_RECEIVED,
    paymentsReceived: initialFactoringOrdersState
  }
};
