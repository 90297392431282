import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  header: {
    border: "none",
    boxShadow: "none",
    fontSize: "26px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "25px",
    marginRight: "25px",
    marginTop: theme.spacing(2)
  },
  title: {
    background: theme.palette.common.pantone,
    fontSize: "26px",
    color: "white",
    display: "flex",
    justifyContent: "space-between"
  },
  cancelButton: {
    minWidth: 200,
    margin: "10px"
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "25px",
    marginRight: "25px"
  },
  paper: { maxHeight: "calc(100% - 110px)" },
  iconButton: {
    "& .MuiSvgIcon-root": {
      color: "white"
    }
  }
}));
