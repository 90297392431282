import React from "react";
import { TableCell, TableSortLabel } from "@material-ui/core";
import { useTableProps } from "common/Table/hooks";
import { Typography } from "common/Typography";
import classNames from "core/utils/classNames";
import { useStyles } from "./TableHeadCell.styles";

export function TableHeadCell({ minWidth = 70, label, sortKey } = {}) {
  const { inline, orderBy, order, handleSort } = useTableProps();
  const classes = useStyles();

  const createSortHandler = (property) => () => handleSort(property);

  return (
    <TableCell
      style={{ minWidth }}
      className={classNames(classes.cell, {
        [classes.inlineCell]: inline
      })}
      sortDirection={orderBy === sortKey ? order : false}
    >
      {!!sortKey && (
        <TableSortLabel
          active={orderBy === sortKey}
          direction={orderBy === sortKey ? order : "asc"}
          onClick={createSortHandler(sortKey)}
        >
          <Typography fontSize={inline ? "small" : "medium"} component="div">
            {label}
          </Typography>
        </TableSortLabel>
      )}
      {!sortKey && (
        <Typography fontSize={inline ? "small" : "medium"} component="div">
          {label}
        </Typography>
      )}
    </TableCell>
  );
}
