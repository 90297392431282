import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  spacing: {
    marginBottom: theme.spacing(2)
  },
  root: {
    paddingTop: theme.spacing(8),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  status: {
    extend: "spacing",
    margin: 0,
    fontSize: 175,
    lineHeight: "145px"
  }
}));
