import React from "react";
import { connect } from "react-redux";
import { Table, TableColumn, TableDrawer, TableDrawerColumn, Typography } from "common";
import { TransactionDetailsTableCell } from "features/DeliveredOrdersTable/TransactionDetailsTableCell";
import { ShippingDeliveryDatesTableCell } from "common/TableCells";
import { factoringFilterKeys } from "core/config/filterKeys";
import { getActiveOrdersAndTypeByCategory } from "core/store/selectors/OrdersSelectors";
import { ORDERS_CATEGORY_PAYMENTS_DUE, ORDERS_TYPE_PAYMENTS_DUE } from "core/utils/constCapsule";
import {
  CommentsIndicatorAction,
  ImagesIndicatorAction,
  EmailIndicatorAction
} from "features/IndicatorActions";
import { IndicatorActions } from "common/TableCells/IndicatorActions";
import { fetchOrders } from "core/store/actions/OrdersActions";
import { useMountEffect } from "core/hooks";
import { OrderDetailsTableCell } from "./OrderDetailsTableCell";
import { PaymentsDueStatusTableCell } from "./PaymentsDueStatusTableCell";
import { InlineOrderDetailsDrawer } from "./DetailsDrawer";
import { PayDetailsTableCell } from "./PayDetailsTableCell";
import { usePDFFormatter, useXLSFormatter } from "./hooks";

export function PaymentsDueOrdersTablePresentation({ isFetching, orders, dispatchFetchOrders }) {
  const pdfArgs = usePDFFormatter("payments-due", orders);
  const xlsArgs = useXLSFormatter("payments-due", orders);

  useMountEffect(() => {
    dispatchFetchOrders(ORDERS_CATEGORY_PAYMENTS_DUE, ORDERS_TYPE_PAYMENTS_DUE);
  });

  return (
    <Table
      loading={!orders && isFetching}
      data={orders}
      defaultSortKey="id"
      stickyHeader
      emptyText="No Payments Due Orders Available for Display"
      filterKeys={factoringFilterKeys}
      options={{
        onPDF: () => pdfArgs,
        onXLS: () => xlsArgs
      }}
    >
      <TableColumn
        label="Order Number"
        sortKey="id"
        Component={<OrderDetailsTableCell label="BOL #" />}
      />
      <TableColumn
        label="MC Number"
        sortKey="mcNumber"
        Component={({ mcNumber }) => <Typography component="div" value={mcNumber} />}
      />
      <TableColumn
        label="Carrier Information"
        sortKey="carrierName"
        Component={({ carrierName }) => <Typography component="div" value={carrierName} />}
      />
      <TableColumn
        label="Carrier Invoice #"
        sortKey="invoice.id"
        minWidth={100}
        Component={<TransactionDetailsTableCell dataKey="invoice" label="Invoice Received Date" />}
      />
      <TableColumn
        label="Shipping Date"
        sortKey="shipDate.milli"
        minWidth={80}
        Component={<ShippingDeliveryDatesTableCell />}
      />
      <TableColumn
        label="Pay"
        minWidth={80}
        Component={<PayDetailsTableCell label="Advances/Credits" payType="total" />}
      />
      <TableColumn
        label="Net Pay"
        minWidth={80}
        Component={<PayDetailsTableCell label="OK to Pay Date" payType="net" />}
      />
      <TableColumn label="Status" minWidth={80} Component={<PaymentsDueStatusTableCell />} />
      <TableColumn
        label="Actions"
        minWidth={80}
        Component={
          <IndicatorActions>
            <EmailIndicatorAction />
            <CommentsIndicatorAction index={0} />
            <ImagesIndicatorAction index={1} />
          </IndicatorActions>
        }
      />

      <TableDrawer>
        <TableDrawerColumn fullWidth Component={<InlineOrderDetailsDrawer />} />
      </TableDrawer>
    </Table>
  );
}

const mapStateToProps = (state) => ({
  ...getActiveOrdersAndTypeByCategory(state, ORDERS_CATEGORY_PAYMENTS_DUE)
});

const mapDispatchToProps = {
  dispatchFetchOrders: fetchOrders
};

export const PaymentsDueOrdersTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentsDueOrdersTablePresentation);
