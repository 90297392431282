import { ReactComponent as DocumentsIconSVG } from "images/svg/documents.svg";
import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

export const DocumentsIcon = memo(function DocumentsIcon(props) {
  return (
    <SvgIcon {...props}>
      <DocumentsIconSVG />
    </SvgIcon>
  );
});
