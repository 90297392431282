import React, { useEffect } from "react";
import { Typography } from "common";
import { Button } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { useDeclineOfferQuery } from "pages/Offer/utils";
import { useQueryParams } from "core/hooks/useQueryParams";
import { useComplete } from "pages/Offer/Offer";
import { useStyles } from "../../Offer.styles";

const reasons = {
  L: "Not My Lane",
  E: "No Equipment"
};

export function Decline({ onResponse }) {
  const classes = useStyles();
  const { complete, setComplete } = useComplete();
  const { offerId, reason } = useQueryParams();
  const { data: declineOfferResponse, handleDeclineOffer } = useDeclineOfferQuery(offerId, reason);

  useEffect(() => {
    if (declineOfferResponse) onResponse(declineOfferResponse);
  }, [declineOfferResponse, onResponse]);

  const handleClick = async () => {
    await handleDeclineOffer();
    setComplete(true);
  };

  if (!reasons[reason]) return null;
  return (
    <div className={classes.column}>
      {complete && (
        <Typography
          color="warning"
          variant="h6"
          fontWeight="bold"
          value={`Declined Reason - ${reasons[reason]}`}
        />
      )}
      {!complete && (
        <>
          <Typography variant="h6" value="Decline Offer" />
          <Button
            className={classes.warningButton}
            onClick={handleClick}
            variant="outlined"
            startIcon={<HelpOutline />}
          >
            {`Confirm Decline: ${reasons[reason]}`}
          </Button>
        </>
      )}
    </div>
  );
}
