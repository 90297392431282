import React from "react";
import { AddCircle } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { Typography } from "common";

export function AddCallinButton({ onClick }) {
  return (
    <Button size="small" color="primary" startIcon={<AddCircle />} onClick={onClick}>
      <Typography color="primary" value="Add Callin" />
    </Button>
  );
}
