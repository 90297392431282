import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    position: "relative",
    width: "100%",
    height: 213,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    padding: theme.spacing(3),
    textAlign: "center",
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: `" "`,
      position: "absolute",
      borderWidth: 10,
      borderStyle: "dashed",
      borderColor: theme.palette.common.smoke,
      top: -8,
      bottom: -8,
      left: -8,
      right: -8,
      transition: theme.transitions.create("border", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    "&:focus::before": {
      borderColor: theme.palette.primary.main,
      transition: theme.transitions.create("border", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen
      })
    }
  },
  rootActive: {
    "&::before": {
      borderColor: theme.palette.primary.main,
      transition: theme.transitions.create("border", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen
      })
    }
  },
  addButton: {
    width: 48,
    height: 48
  }
}));
