import React, {useState} from "react";
import { Spinner } from "lib/components";
import { loadUnauthenticatedContext } from "lib/util/MainPageContext";
import { DefaultPageContainer } from "pages/containers";

export function UnauthenticatedPageContainer({children, searchQueryString, ...props}) {
	let [loading, setLoading] = useState(false);
	let [init, setInit] = useState(true);
	if (init) {
		setInit(false);
		loadUnauthenticatedContext(null, setLoading, searchQueryString);
	}
	if (loading)
		return <DefaultPageContainer><Spinner /></DefaultPageContainer>
	else
		return <DefaultPageContainer>{children}</DefaultPageContainer>
}