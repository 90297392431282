import React from "react";
import { getChildArray } from "../ComponentUtil";
import { TableRow, TableHead } from "@material-ui/core";
import Column from "./Column";
import ExpandRowCell from "./ExpandRowCell";

export default function TableColumnHeadings({children, sortedColumns, setSortedColumns, columnHeadingsVisible, expandComponent}) {
	if (columnHeadingsVisible !== false) {
		children = getChildArray(children);
		let visible = [];
		for (let i = 0; i < children.length; i++)
			if (children[i].props != null && children[i].props.visible !== false)
				visible.push(<Column key={`col-${i}`} columnIndex={i}
					sortedColumns={sortedColumns} setSortedColumns={setSortedColumns} 
					{...children[i].props} />);
        if (expandComponent != null)
            visible.push(<ExpandRowCell key="expand" noImage/>);
        return (
            <TableHead>
                <TableRow>
                    {visible}
                </TableRow>
            </TableHead>
        );
	}
	else
		return null;
}
