import React from "react";
import { Typography, TableCellList, Email } from "common";

export function ContactDetailsTableCell({ contact = {}, id, pickup = {}, delivery = {} }) {
  const { name, email, phone } = contact;
  const subject = `Question about Available Order #${id} ${pickup.city}, ${pickup.state} to ${delivery.city}, ${delivery.state}`;
  return (
    <TableCellList>
      <Typography component="li" label>
        Contact
      </Typography>
      <Typography component="li">{name || "N/A"}</Typography>
      <Email component="li" fontSize="small" value={email} subject={subject} />
      <Typography component="li" fontSize="small">
        {phone || "N/A"}
      </Typography>
    </TableCellList>
  );
}
