import React from "react";
import { Form, Icon, Input, Button } from "antd";
import Image from "lib/components/Image";
import queryString from "query-string";
import { Logo } from "common/Logo";
import { withStyles } from "@material-ui/core";
import { callApi, SESSION_STORAGE_LOGIN_TOKEN } from "../../util/api";
import "./login.css";

const images = require.context("../../../images", true);
const API_URL_RESET = "users/reset";
const API_URL_REQUIREMENTS = "users/password/requirements";
const API_URL_TOKEN_VALIDATE = "token/validate";
const styles = (theme) => ({
	logo: {
	  width: 174,
	  height: 67
	},
	logoContainer: {
	  padding: theme.spacing(4),
	  backgroundColor: theme.branding.colors.headerBackground || "transparent"
	}
  })

class UnstyledResetPassword extends React.Component {
	state = {
		confirmDirty: false
	};

	static defaultProps = {
		headerMessage: <p className="login-welcome-text">Reset Password</p>,
		referrer: "/",
		tokenValidationMessage: (
			<div>
				<p className="login-error-text">This link to reset your password has expired.</p>
				<p className="login-error-text-secondary">
					To reset your password, please request again from the{" "}
					<a href="/login">login page</a>.
        </p>
			</div>
		),
		isDisabled: true
	};

	constructor(props, state) {
		super(props, state);
		this.state = { ...props };
	}

	componentDidMount = () => {
		let resetToken = this.getTokenFromQueryString();
		if (resetToken) {
			sessionStorage.setItem(SESSION_STORAGE_LOGIN_TOKEN, resetToken);
			callApi(API_URL_TOKEN_VALIDATE, { method: "GET" })
				.then(tokenValidationResponse => {
					console.log(
						"Result from token validation call %o",
						tokenValidationResponse
					);
					if (
						tokenValidationResponse != null &&
						tokenValidationResponse.valid === true
					) {
						this.setState({
							tokenValidationMessage: null,
							isDisabled: false
						});
					}
				})
				.catch(() => this.setState({ error: true }));
		}
	};

	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				let payload = { password: values.password };
				let resetToken = this.getTokenFromQueryString();
				if (resetToken)
					sessionStorage.setItem(SESSION_STORAGE_LOGIN_TOKEN, resetToken);

				callApi(API_URL_RESET, {
					method: "POST",
					body: JSON.stringify(payload)
				})
					.then(resetResponse => {
						console.log("Result from password reset request %o", resetResponse);
						if (
							resetResponse != null &&
							resetResponse.success === true &&
							resetResponse.validation.valid === true
						) {
							window.location.href = "/login?resetSuccess";
							return;
						} else {
							this.setState({
								resetResponse: resetResponse
							});
						}
					})
					.catch(() => this.setState({ error: true }));
			}
		});
	};

	showPasswordRequirements = () => {
		if (!this.state.passwordRequirements) {
			callApi(API_URL_REQUIREMENTS, { method: "GET" })
				.then(requirementsResponse => {
					console.log(
						"Result from password requirements call %o",
						requirementsResponse
					);
					if (requirementsResponse) {
						this.setState({
							passwordRequirements: requirementsResponse.requirements
						});
					}
				})
				.catch(() => this.setState({ error: true }));
		}
	};

	compareToFirstPassword = (rule, value, callback) => {
		const { form } = this.props;
		if (value && value !== form.getFieldValue("password")) {
			callback("The passwords you entered do not match!");
		} else {
			callback();
		}
	};

	validateToNextPassword = (rule, value, callback) => {
		const { form } = this.props;
		if (value && this.state.confirmDirty) {
			form.validateFields(["confirm"], { force: true });
		}
		callback();
	};

	handleConfirmBlur = e => {
		const { value } = e.target;
		this.setState({ confirmDirty: this.state.confirmDirty || !!value });
	};

	getTokenFromQueryString() {
		let token = null;
		if (this.props.location !== undefined) {
			var result = queryString.parse(this.props.location.search);
			token = result["token"];
		}
		return token;
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const formItemLayout = {
			labelCol: { span: 24 },
			wrapperCol: { span: 24 }
		};
		let statusMessageRender = null;
		if (this.state.resetResponse) {
			let resetResponse = this.state.resetResponse;
			let validationResponse = this.state.resetResponse.validation;
			let statusClassName = "reset-status-message-text";
			let statusMessage = "";
			if (resetResponse) {
				if (
					resetResponse.success === false &&
					validationResponse.valid === false
				) {
					statusClassName += " validation-failed";
					statusMessage = validationResponse.message;
				} else if (
					resetResponse.success === true &&
					validationResponse.valid === true
				) {
					statusClassName += " validation-passed";
					statusMessage = <span>{resetResponse.message}</span>;
				}
				statusMessageRender = (
					<div>
						<p className={statusClassName}>{statusMessage}</p>
					</div>
				);
			}
		}

		let passwordRequirementsRender = null;
		if (this.state.passwordRequirements) {
			let requirementsList = [];
			for (let i = 0; i < this.state.passwordRequirements.length; i++) {
				requirementsList.push(<li>{this.state.passwordRequirements[i]}</li>);
			}
			passwordRequirementsRender = <ul>{requirementsList}</ul>;
		}

		return (
			<div className="reset-form-container">
				<div className={this.props.classes.logoContainer}>
				<Logo className={this.props.classes.logo} variant="dark" />
				</div>
				<div className="reset-form-controls">
					{this.state.tokenValidationMessage}
					<Form {...formItemLayout} onSubmit={this.handleSubmit}>
						<Form.Item
							label="New Password"
							hasFeedback
							className="input-confirm-password"
						>
							{getFieldDecorator("password", {
								rules: [
									{
										required: true,
										message: "Please input your password!"
									},
									{
										validator: this.validateToNextPassword
									}
								]
							})(
								<Input
									prefix={
										<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
									}
									type="password"
									placeholder="Password"
									className="login-input"
									disabled={this.state.isDisabled}
								/>
							)}
						</Form.Item>
						<Form.Item
							label="Confirm New Password"
							hasFeedback
							className="input-confirm-password"
						>
							{getFieldDecorator("confirm", {
								rules: [
									{
										required: true,
										message: "Please confirm your password!"
									},
									{
										validator: this.compareToFirstPassword
									}
								]
							})(
								<Input
									prefix={
										<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
									}
									type="password"
									placeholder="Confirm Password"
									className="login-input"
									onBlur={this.handleConfirmBlur}
									disabled={this.state.isDisabled}
								/>
							)}
						</Form.Item>
						<div>
							<p>
								Password Criteria&nbsp;&nbsp;
                <img
									src={images("./question-circle-o.png")}
									alt="question-circle"
									onClick={this.showPasswordRequirements}
									style={{ cursor: "pointer" }}
								/>
							</p>
							{passwordRequirementsRender}
						</div>
						{statusMessageRender}
						<Form.Item className="button-reset">
							<Button
								type="primary"
								htmlType="submit"
								className="login-form-button"
								disabled={this.state.isDisabled}
							>
								Save
              </Button>
						</Form.Item>
						<Form.Item className="button-reset">
							<Button
								type="default"
								href="/login"
								className="login-form-button"
								disabled={this.state.isDisabled}
							>
								Cancel
              </Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		);
	}
}
export const ResetPasswordPresentation = withStyles(styles)(UnstyledResetPassword);