import { Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { classNames } from "core/utils";
import { useStyles } from "./TableGroup.styles";
import { Spacer } from "../Spacer";
import { TableGroupTabs } from "./TableGroupTabs";

export function TableGroup({
  tables,
  renderActions = () => undefined,
  inline = false,
  disableGutters = false,
  maxWidth = false,
  defaultIndex = 0,
  value = undefined,
  onChange = () => undefined
}) {
  const classes = useStyles();
  const [index, setIndex] = useState(defaultIndex);

  useEffect(() => {
    if (value !== undefined && value !== index) setIndex(value);
  }, [value, index]);

  const handleChange = (_, idx) => {
    setIndex(idx);
    onChange(idx);
  };

  return (
    <>
      <Container
        className={classNames(classes.container, {
          [classes.inline]: inline
        })}
        maxWidth={maxWidth}
        disableGutters={disableGutters}
      >
        <TableGroupTabs index={index} onChange={handleChange} tabs={tables} inline={inline} />
        <Spacer />
        {renderActions()}
      </Container>
      {tables[index] && tables[index].Table}
    </>
  );
}
