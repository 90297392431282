import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  buttonGroup: {
    border: '1px solid #979797',
    boxShadow: 'none',
  },
  button: () => {
    return ({
      backgroundColor: 'transparent',
      textAlign: 'center',
      color: '#458719',
      fontSize: 14,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    });
  },
  dropDown: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    color: '#979797',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menuItem: {
    backgroundColor: 'white',
    transition: '0s',
    color: '#9E9E9E',
    fontSize: 22,
    fill: '#9E9E9E',
    borderRadius: '5px',
    border: '1px solid #979797',
    "&:not(:last-child)": {
      borderBottom: 'none'
    },
    '&:hover': {
      color: '#458719',
      "& $menuItemIcon": {
        fill: '#458719'
      }
      //filter: 'invert(45%) sepia(15%) saturate(2044%) hue-rotate(53deg) brightness(96%) contrast(95%)'
    }
  },
  menuItemIcon: {
    //width: 100,
    //height: 100,
    marginRight: 50,
    fill: '#9E9E9E'
  },
  grow: {
    flexGrow: 1
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  }
});
