import React from "react";
import produce from "immer";
import dotProp from "dot-prop";
import { TableColumn, TableDrawer } from "./proxies";

/**
 * Utility method for reaching into a Component and getting the "props" value.
 *
 * @param {*} [children=[]]
 * @returns
 */
function getProps(children = []) {
  const [props] = React.Children.map(children, (child) => child.props);
  return props;
}

/**
 * Returns an array of prop objects from TableColumn components.
 *
 * @param {*} [children=[]]
 * @returns
 */
function getTableColumnsProps(children = []) {
  const tableColumns = React.Children.map(children, (child) => {
    if (!child || child.type !== TableColumn) return undefined;
    return child;
  });
  return tableColumns.map((column) => getProps(column));
}

/**
 * Returns an array of prop objects from TableDrawerColumns in a TableDrawer component
 *
 * @param {*} [children=[]]
 * @returns
 */
function getExpansionRowColumns(children = []) {
  const tableDrawers = React.Children.map(children, (child) => {
    if (!child || child.type !== TableDrawer) return undefined;
    return child;
  });
  return tableDrawers.map((drawer) =>
    React.Children.map(drawer.props.children, (child) => getProps(child))
  );
}

/**
 * Gets additional props that may have been set on a Proxy component besides "Component", and "dataKey" since those
 * properties are used under the hood.
 *
 * @param {*} columnProps
 * @returns
 */
function getCellProps(tableColumns, columnProps) {
  const { Component: _, dataKey: __, fullWidth, colSpan, ...restProps } = columnProps;
  return {
    ...restProps,
    fullWidth,
    colSpan: fullWidth ? tableColumns.length + 1 : colSpan
  };
}

/**
 * Get's the CellComponent specified on the Proxy component and passes the related row data to it.
 *
 * @param {*} columnProps
 * @param {*} data
 * @returns
 */
function getCellComponent(columnProps = {}, data = {}) {
  const { Component = null } = columnProps;
  if (!Component) return null;
  return typeof Component === "function" ? Component(data) : React.cloneElement(Component, data);
}

/**
 * Takes the passed in Component with it's now attached row data, and any additional props that were
 * attached to the proxy component and builds a prop object in the shape that the internal working Table components
 * expect it to be.
 *
 * @param {*} columnProps
 * @param {*} rowData
 * @returns
 */
function buildCell(tableColumns, columnProps, rowData) {
  return {
    CellComponent: getCellComponent(columnProps, rowData),
    cellProps: getCellProps(tableColumns, columnProps)
  };
}

function buildTableColumns(tableColumns) {
  return tableColumns.map(({ CellComponent: _, ...restProps }) => restProps);
}

/**
 * Iterates through each row of data and builds the table row, and related drawers if neccesarry.
 *
 * @param {*} [rowColumns=[]]
 * @param {*} [data=[]]
 * @returns
 */
function buildTableRows(tableColumns = [], expansionRowColumns = [], data = []) {
  if (!data) return [];
  const rowReducer = produce((rows, rowData = {}) => {
    rows.push({
      id: rowData.movementId ? rowData.movementId : rowData.id,
      rowData,
      row: tableColumns.map((columnProps) => buildCell(tableColumns, columnProps, rowData)),
      expansionRows: expansionRowColumns.map((rowColumns) =>
        rowColumns.map((columnProps) => buildCell(tableColumns, columnProps, rowData))
      )
    });
  });
  return data.reduce(rowReducer, []);
}

/**
 * Iterates throught the different children inside the Table component, and builds
 * the Table's columns, rows and cells.
 *
 * @export
 * @param {*} children [children=[]]
 * @param {*} data [data=[]]
 * @param {Object} options [{ expandable = false }={}]
 * @returns {Array} [columns, rows, drawers] Returns a collection of the columns, rows, and drawers that make
 * up the different parts of the table.
 */
export function createTable(children = [], data = []) {
  const tableColumns = getTableColumnsProps(children);
  const expansionRowColumns = getExpansionRowColumns(children);
  const columns = buildTableColumns(tableColumns);
  const rows = buildTableRows(tableColumns, expansionRowColumns, data);
  return [
    columns,
    rows,
    {
      expandable: expansionRowColumns.length
    }
  ];
}

export function descendingComparator(a, b, orderBy) {
  const aValue = dotProp.get(a.rowData, orderBy) || "";
  const bValue = dotProp.get(b.rowData, orderBy) || "";
  if (bValue < aValue) return -1;
  if (bValue > aValue) return 1;
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function fuzzySearch(needle, haystack = []) {
  let i = 0;
  let n = -1;
  let l;
  const hay = haystack.toLowerCase();
  needle = needle.toLowerCase();
  for (; (l = needle[i++]);) {
    if (!~(n = hay.indexOf(l, n + 1))) return false;
  }
  return true;
}
