import produce from "immer";

export const append = (incomingFiles, defaultDocType) =>
  produce((draft) => {
    const fileSet = new Set(draft.map((file) => file.name));
    incomingFiles.forEach((file) => {
      if (!file.docType) file.docType = defaultDocType;
      if (!fileSet.has(file.name)) draft.push(file);
    });
  });

export const remove = (index) =>
  produce((draft) => {
    draft.splice(index, 1);
  });

export const update = (file, payload) =>
  produce((draft) => {
    draft.forEach((draftFile) => {
      if (draftFile.name === file.name) {
        // eslint-disable-next-line no-param-reassign
        Object.assign(draftFile, payload);
      }
    });
  });
