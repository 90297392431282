import React from "react";
import { TextBox, SubmitPanel } from "lib/components";

export function ShareOrder(props) {
	return (
		<SubmitPanel modelName="ar/email-order" submitButtonCaption="Send" width={500} 
			fixedData={{"order_id": props.orderId}}>
			<TextBox caption="To" fieldName="recipients" displayType="email" required tooltip="For multiple emails, separate with a comma" fillRow />
			<TextBox caption="Comments (optional)" fieldName="comment" multiline height={120} fillRow />
		</SubmitPanel>
	);
}