import { mapModelDataFromAPI } from "core/utils/mapper";
import { convertMilliToDateTimeObject, convertMilliToDateFormat } from "core/utils/formatters";

const convertMilli = (milli) => convertMilliToDateTimeObject(milli, "MM/dd/yyyy");

const keyMap = {
  order_number: "id",
  bol: "bolNum",
  ship_date: "shipDate",
  delivery_date: "deliveryDate",
  carrier_name: "carrierName",
  total_pay: "totalPay",
  net_pay: "netPay",
  status: "status",
  invoice_num: "invoice.id",
  invoice_date: "invoice.date",
  ok2pay_date: "ok2PayDate",
  advances_other_pay: "advancesOtherPay",
  movement_id: "movementId",
  mc_number: "mcNumber",
  comments_count: "commentsCount"
};

const valueFormatters = {
  ship_date: convertMilli,
  delivery_date: convertMilli,
  invoice_date: convertMilliToDateFormat,
  ok2pay_date: convertMilliToDateFormat
};

export function mapPaymentsDueOrders(orders) {
  return mapModelDataFromAPI(orders, keyMap, valueFormatters);
}
