import React, { useEffect } from "react";
import { Table, TableColumn, TableDrawerColumn, TableDrawer } from "common";
import {
  ORDERS_TYPE_UNPAID,
  ORDERS_TYPE_PAID,
  ORDERS_CATEGORY_DELIVERED
} from "core/utils/constCapsule";
import { connect } from "react-redux";
import { getActiveOrdersAndTypeByCategory } from "core/store/selectors/OrdersSelectors";
import { carrierFilterKeys } from "core/config/filterKeys";
import { IndicatorActions } from "common/TableCells/IndicatorActions";
import { ImagesIndicatorAction } from "features/IndicatorActions";
import { fetchOrders } from "core/store/actions/OrdersActions";
import { useMountEffect } from "lib/util";
import { usePrevious } from "core/hooks";
import { OrdersTypeSwitch } from "./OrdersTypeSwitch";
import { TransactionDetailsTableCell } from "./TransactionDetailsTableCell";
import { InlineOrderDetailsDrawer } from "./DetailsDrawer";
import { OrderNumberTableCell } from "./OrderNumberTableCell";
import { ShippingDetailsTableCell } from "./ShippingDetailsTableCell";
import { LocationDetailsTableCell } from "./LocationDetailsTableCell";
import { BalanceDetailsTableCell } from "./BalanceDetailsTableCell";
import { DeliveryDetailsTableCell } from "./DeliveryDetailsTableCell";

function DeliveredOrdersTablePresentation({
  isFetching,
  orders,
  ordersType = ORDERS_TYPE_UNPAID,
  dispatchFetchOrders
}) {
  const previousOrdersType = usePrevious(ordersType);

  useMountEffect(() => {
    dispatchFetchOrders(ORDERS_CATEGORY_DELIVERED, ordersType);
  });

  useEffect(() => {
    if (!previousOrdersType || previousOrdersType === ordersType) return;
    dispatchFetchOrders(ORDERS_CATEGORY_DELIVERED, ordersType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersType]);

  return (
    <Table
      loading={!orders && isFetching}
      key={ordersType}
      data={orders}
      defaultSortKey="shipping.milli"
      stickyHeader
      emptyText={
        ordersType === ORDERS_TYPE_UNPAID
          ? "No Unpaid Orders Available for Display"
          : "No Paid Orders Available for Display"
      }
      renderToolbarRight={() => <OrdersTypeSwitch />}
      filterKeys={carrierFilterKeys}
    >
      <TableColumn
        label="Order Number"
        sortKey="id"
        minWidth={70}
        Component={<OrderNumberTableCell />}
      />
      <TableColumn
        label="Invoice Number"
        sortKey="invoice.id"
        minWidth={100}
        Component={<TransactionDetailsTableCell dataKey="invoice" label="Invoice Received Date" />}
      />
      {ordersType === ORDERS_TYPE_PAID && (
        <TableColumn
          label="Check Number"
          sortKey="check.milli"
          minWidth={100}
          Component={<TransactionDetailsTableCell dataKey="check" label="Check Date" />}
        />
      )}
      <TableColumn
        label="Shipping Date"
        sortKey="shipping.milli"
        minWidth={80}
        Component={<ShippingDetailsTableCell label="Ok to Pay Date" ordersType={ordersType} />}
      />
      <TableColumn
        label="Pickup Location"
        sortKey="pickup.city"
        minWidth={80}
        Component={<LocationDetailsTableCell dataKey="pickup" />}
      />
      <TableColumn
        label="Delivery Location"
        sortKey="delivery.city"
        minWidth={80}
        Component={<LocationDetailsTableCell dataKey="delivery" />}
      />
      <TableColumn
        label={ordersType === ORDERS_TYPE_PAID ? "Check Amount" : "Balance"}
        sortKey="balance.value"
        minWidth={80}
        Component={<BalanceDetailsTableCell />}
      />
      <TableColumn
        label="Actions"
        minWidth={120}
        Component={
          <IndicatorActions>
            <ImagesIndicatorAction index={0} />
          </IndicatorActions>
        }
      />
      <TableDrawer>
        <TableDrawerColumn />
        <TableDrawerColumn
          Component={<DeliveryDetailsTableCell dataKey="tractor" label="Tractor" />}
        />
        <TableDrawerColumn
          Component={<DeliveryDetailsTableCell dataKey="trailer" label="Trailer" />}
        />
        <TableDrawerColumn
          Component={<DeliveryDetailsTableCell dataKey="driver" label="Driver Name" />}
        />
        <TableDrawerColumn colSpan={5} />
      </TableDrawer>
      <TableDrawer>
        <TableDrawerColumn fullWidth Component={<InlineOrderDetailsDrawer />} />
      </TableDrawer>
    </Table>
  );
}

const mapStateToProps = (state) => ({
  ...getActiveOrdersAndTypeByCategory(state, ORDERS_CATEGORY_DELIVERED)
});

const mapDispatchToProps = {
  dispatchFetchOrders: fetchOrders
};

export const DeliveredOrdersTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveredOrdersTablePresentation);
