import { makeStyles } from "lib/util";

export const useStyles = makeStyles((theme) => ({
	header: props => ({
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 0,
		paddingBottom: 1,
		backgroundColor: props.headerBackgroundColor
	}),
	grow: {
		flexGrow: 1
	},
	tabs: {
		top: 2,
		position: "relative",
	},
	headerOutlined: {
		borderBottom: `1px solid ${theme.palette.common.mist}`
	},
	tabset: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
	},
	tab: {
		extend: "tabTransitionOut",
		minHeight: 47,
		minWidth: 100,
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		borderWidth: 1,
		borderStyle: "solid",
		borderColor: theme.palette.common.mist,
		borderBottomColor: "transparent",
		marginRight: theme.spacing(1),
	},
	tabPanel: {
		height: "100%",
		overflow: "hidden",
		paddingTop: 16,
		paddingBottom: 16,
		margin: 0,
		backgroundColor: theme.palette.common.white,
		flex: 1
	},
	activeTab: {
		extend: "tabTransitionIn",
		backgroundColor: theme.palette.common.white,
		borderBottomColor: "transparent"
	},
	wrapper: {
		flexDirection: "row",
		"& svg": {
			marginBottom: "0 !important",
			marginRight: theme.spacing(1)
		}
	}
}));
