/* eslint-disable */

import React from "react";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import Image from "lib/components/Image";
import ComboSearch from "./ComboSearch";
import { callApi } from "../../util/api";
import { parseUrl } from "core/utils/urls";
import {
  AUTH_TYPE_GUEST,
  AUTH_TYPE_AUTHENTICATED
} from "core/store/states/UserState";
import queryString from "query-string";
import "./login.css";
import "antd/dist/antd.css";
import { DEFAULT_USER_TYPE_PATH } from "core/config/urls";
import {
  SESSION_STORAGE_USER_TYPE,
  SESSION_STORAGE_LOGIN_TOKEN,
  SESSION_STORAGE_TEMP_TOKEN,
  SESSION_STORAGE_USER_NAME,
  SESSION_STORAGE_USER_COMPANY,
  SESSION_STORAGE_USER_DISPLAY_NAME
} from "core/utils/constCapsule";
import { loadUnauthenticatedContext } from "lib/util/MainPageContext";
import { showLegacyIM } from "lme/dsp/LegacyInternetModule";
import { getLogger } from "lib/util";
import { Logo } from "common/Logo";
import { withStyles } from "@material-ui/core";
const API_URL_LOGIN = "users/login";
const API_URL_RESET_REQUEST = "users/reset/request";

const log = getLogger("lib.pages.login.WebLoginPresentation");

const styles = (theme) => ({
  logo: {
    width: 174,
    height: 67
  },
  logoContainer: {
    padding: theme.spacing(4),
    backgroundColor: theme.branding.colors.headerBackground || "transparent"
  }
})

class WebLoginPresentation extends React.Component {
  static defaultProps = {
    headerMessage: getWelcome(),
    defaultReferrer: "/",
    loading: false
  };

  constructor(props, state) {
    super(props, state);
    this.state = { ...props };
  }

  componentWillMount() {
    loadUnauthenticatedContext((data) =>
      this.setState({ companySettings: data.companySettings })
    );
  }


  getHeaderErrorMessage() {
    return (
      <div>
        <p className="login-error-text">Something Went Wrong</p>
        <p className="login-error-text-secondary">Login Failed. Try Again.</p>
      </div>
    );
  }

  getHeaderErrorMessageConnection() {
    return (
      <div>
        <p className="login-error-text">Something Went Wrong</p>
        <p className="login-error-text-secondary">Connection to server failed. Try Again.</p>
      </div>
    );
  }

  getHeaderErrorMessageLocked() {
    return (
      <div>
        <p className="login-error-text">Your account has been locked</p>
        <p className="login-error-text-secondary">Click the Forgot password link to reset your password.</p>
      </div>
    );
  }

  getForgottenPasswordRequestedMessage() {
    return (
      <div>
        <p className="reset-request">
          If this is a valid Login ID, we sent a password reset link to your
          email address.
        </p>
      </div>
    );
  }

  getForgottenPasswordChangedMessage() {
    return (
      <p className="reset-status-message-text validation-passed">
        Your password was successfully changed
      </p>
    );
  }

  getSetupSuccessMessage() {
    return (
      <p className="reset-status-message-text validation-passed">
        Your account was successfully created
      </p>
    );
  }

  getSetupEnabledMessage() {
    return (
      <p className="reset-status-message-text validation-passed">
        Your account is already enabled
      </p>
    );
  }

  getInvalidTokenMessage() {
    return <p className="invalid-token">Your session has expired. Please log in again to continue.</p>;
  }

  enterLoading() {
    this.setState({ loading: true, headerMessage: getWelcome() });
  }

  endLoading() {
    this.setState({ loading: false });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.enterLoading();
        let payload = { username: values.username, password: values.password };
        let headerErrorMessage = this.getHeaderErrorMessage();
        callApi(API_URL_LOGIN, {
          method: "POST",
          body: JSON.stringify(payload)
        })
          .then(async loginResponse => {
            const { success, ...restResponse } = loginResponse;
            log.debug("Result from user login %o", loginResponse);
            if (loginResponse && success === true) {
              sessionStorage.setItem(
                SESSION_STORAGE_LOGIN_TOKEN,
                loginResponse.token
              );
              sessionStorage.setItem(
                SESSION_STORAGE_USER_TYPE,
                loginResponse.userType
              );
              sessionStorage.setItem(
                SESSION_STORAGE_USER_NAME,
                loginResponse.userName
              )
              sessionStorage.setItem(
                SESSION_STORAGE_USER_DISPLAY_NAME,
                loginResponse.displayName
              )
              sessionStorage.setItem(
                SESSION_STORAGE_USER_COMPANY,
                loginResponse.companyName
              )
              await this.props.dispatchSignIn({
                ...restResponse,
                authType: loginResponse.token
                  ? AUTH_TYPE_AUTHENTICATED
                  : AUTH_TYPE_GUEST,
              });
              let referrer = "";
              if (
                document.referrer &&
                document.referrer.split("/")[3] !== "login" &&
                document.referrer.split("/")[3].split("?")[0] !== "login" &&
                document.referrer.split("/")[3].split("?")[0] !== "reset" &&
                document.referrer.split("/")[3].split("?")[0] !== "setup" &&
                document.referrer.split("/")[3].split("?")[0] !== "track" &&
                document.referrer.split("/")[3].split("?")[0] !== "callin"
              ) {
                let parsedUrl = parseUrl(document.referrer);
                referrer = parsedUrl.pathname + parsedUrl.query;
                if (
                  Object.values(DEFAULT_USER_TYPE_PATH).indexOf(referrer) > -1
                )
                  referrer = DEFAULT_USER_TYPE_PATH[loginResponse.userType];
              } else referrer = DEFAULT_USER_TYPE_PATH[loginResponse.userType];
              this.props.history.push(referrer);
              return;
            } else {
              this.setState({
                headerMessage: headerErrorMessage
              });
            }
            this.endLoading();
          })
          .catch(response => {
            log.error("Error during user login %o", response);
            let errorMessageRender = headerErrorMessage;
            if (response) {
              if (response.status === 423)
                errorMessageRender = this.getHeaderErrorMessageLocked();
              else if (response.message === "Failed to fetch")
                errorMessageRender = this.getHeaderErrorMessageConnection();
            }

            this.setState({ headerMessage: errorMessageRender });
            this.endLoading();
          });
      }
    });
  };

  handleForgottenPassword = e => {
    e.preventDefault();
    this.props.form.validateFields(["username"], (err, values) => {
      if (!err) {
        let payload = { username: values.username };
        callApi(API_URL_RESET_REQUEST, {
          method: "POST",
          body: JSON.stringify(payload)
        })
          .then(resetResponse => {
            log.debug("Result from user reset request %o", resetResponse);
          })
          .catch(() =>
            this.setState({ headerMessage: this.getHeaderErrorMessage() })
          );
        this.setState({
          forgottenPasswordMessage: this.getForgottenPasswordRequestedMessage()
        });
      }
    });
  };

  validateLoginId = () => {
    let loginId = this.props.form.values.username;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let resetSuccessMessage = null;
    let setupSuccessMessage = null;
    let setupEnabledMessage = null;
    let invalidTokenMessage = null;

    if (this.props.location !== undefined) {
      var result = queryString.parse(this.props.location.search);
      let resetSuccess = result["resetSuccess"];
      let setupSuccess = result["setupSuccess"];
      let setupEnabled = result["setupEnabled"];
      let invalidToken = result["invalidToken"];

      if (resetSuccess !== undefined)
        resetSuccessMessage = this.getForgottenPasswordChangedMessage();
      else if (setupSuccess !== undefined)
        setupSuccessMessage = this.getSetupSuccessMessage();
      else if (setupEnabled !== undefined)
        setupEnabledMessage = this.getSetupEnabledMessage();

      if (invalidToken !== undefined)
        invalidTokenMessage = this.getInvalidTokenMessage();
    }
    return (
      <div className="login-form-container">
        <div className={this.props.classes.logoContainer}>
          <Logo className={this.props.classes.logo} variant="dark" />
        </div>
        <div className="login-form-controls">
          {this.state.headerMessage}
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator("username", {
                rules: [{ required: true, message: "Login ID required" }]
              })(
                <Input
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Login ID"
                  className="login-input"
                  autoComplete="username"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [{ required: true, message: "Password required" }]
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                  className="login-input"
                  autoComplete="current-password"
                />
              )}
            </Form.Item>
            {invalidTokenMessage}
            {resetSuccessMessage}
            {setupSuccessMessage}
            {setupEnabledMessage}
            {this.state.forgottenPasswordMessage}
            <Form.Item>
              {getFieldDecorator("remember", {
                valuePropName: "checked",
                initialValue: true
              })(<Checkbox>Remember me</Checkbox>)}
              <a
                className="login-form-forgot"
                href="#"
                onClick={this.handleForgottenPassword}
              >
                Forgot password
              </a>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={this.state.loading}
              >
                Sign In
              </Button>
            </Form.Item>
          </Form>
          <hr style={{ border: "1px solid #CDCDCD" }} />
          <span>Track Without Sign In</span>
          <ComboSearch companySettings={this.state.companySettings} history={this.props.history} />
          {this.state.companySettings && this.state.companySettings.allowDriverRecruiting &&
            <div className="driver-recruit" >
              <button className="link"
                onClick={() => showLegacyIM("Driver Recruitment", "/driverapplication", "yes")}
              >
                Driver Recruitment
              </button>

            </div>
          }
        </div >
      </div >

    );
  }
}

export function clearSessionToken() {
  sessionStorage.removeItem(SESSION_STORAGE_LOGIN_TOKEN);
  sessionStorage.removeItem(SESSION_STORAGE_TEMP_TOKEN);
  sessionStorage.removeItem(SESSION_STORAGE_USER_TYPE);
}

function getWelcome() {
  return <p className="login-welcome-text">Welcome</p>
}


export default withStyles(styles)(WebLoginPresentation);
