export const USER_CONTROLS = {
  LOAD_BOARD_ACTIVE: "LoadBoardActive",
  INCLUDE_RATE_INFO: "IncludeRateInfo",
  DELIVERED_LOAD_DAY: "DeliveredLoadDay",
  LEGAL_TERMS_NAME: "LegalTermsName",
  REQUIRE_ORDER_TERM: "RequireOrderTerm",
  ACCEPT_ORDER: "AcceptOrder",
  CONF_FAX_TEMPLATE: "ConfFaxTemplate",
  DEF_MOVE_STATUS: "DefMoveStatus",
  REQUIRE_LEGAL_TERM: "RequireLegalTerm",
  DECLINE_OFFER: "DeclineOffer",
  MAKE_OFFER: "MakeOffer",
  FACTORING_COMPANY_SEARCH_DAYS: "FactoringCompanySearchDays",
  FACTORING_COMPANY_EMAILS: "FactoringCompanyEmails",
  MAX_FILE_SIZE: "order_history",
  API_KEY: "MapApiKey",
  MAP_URL: "MapUrl"
};

export const CONTROL_CHARS = {
  C: "C",
  D: "D",
  N: "N"
};
