import React from "react";
import { CircularProgress, Button } from "@material-ui/core";
import { useStyles } from "./SubmitButton.styles";

export function SubmitButton({ loading, children = [], ...restProps }) {
  const classes = useStyles();
  return (
    <Button disabled={loading} {...restProps}>
      {children}
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Button>
  );
}
