import { AppBar, Toolbar } from "@material-ui/core";
import classNames from "core/utils/classNames";
import React from "react";
import { AUTH_TYPE_AUTHENTICATED } from "core/store/states/UserState";
import { connect } from "react-redux";
import { getAuthType } from "core/store/selectors/UserSelectors";
import { getDrawerOpen } from "core/store/selectors/UISelectors";
import { openNavDrawer, closeNavDrawer } from "core/store/actions/UIActions";
import MainMenu from "lib/components/MainMenu";
import { UserMenu } from "lib/pages/UserMenu";
import { Logo } from "common/Logo";
import { DrawerButton } from "./DrawerButton";
import { useStyles } from "./Nav.styles";

function NavPresentation({
  navDrawerOpen,
  authType,
  dispatchCloseNavDrawer,
  dispatchOpenNavDrawer
}) {
  const classes = useStyles();

  function toggleDrawer() {
    return navDrawerOpen ? dispatchCloseNavDrawer() : dispatchOpenNavDrawer();
  }

  return (
    <>
      <AppBar
        color="inherit"
        position="relative"
        elevation={0}
        className={classNames(classes.root, {
          [classes.rootShift]: navDrawerOpen
        })}
      >
        <Toolbar className={classes.toolBar}>
          {authType === AUTH_TYPE_AUTHENTICATED && (
            <DrawerButton open={navDrawerOpen} onClick={toggleDrawer} />
          )}
          <Logo />
          <div className={classes.grow} />
          <UserMenu authType={authType} />
        </Toolbar>
      </AppBar>
      {authType === AUTH_TYPE_AUTHENTICATED && (
        <MainMenu open={navDrawerOpen} onClose={toggleDrawer} />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  authType: getAuthType(state),
  navDrawerOpen: getDrawerOpen(state)
});

const mapDispatchToProps = {
  dispatchOpenNavDrawer: openNavDrawer,
  dispatchCloseNavDrawer: closeNavDrawer
};

export const Nav = connect(mapStateToProps, mapDispatchToProps)(NavPresentation);
