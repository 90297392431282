import React, { useState } from "react";
import { MEDIA_TYPE_PDF } from "core/utils/constCapsule";
import FileSaver from "file-saver";

// FIXME: Make this work with FileDownloader
export function PDFFileDownloader({
  fileName = "",
  fileContent = null,
  onClick = () => {},
  mediaType = MEDIA_TYPE_PDF,
  children = []
}) {
  const [loading, setLoading] = useState(false);

  function toggleLoading() {
    setLoading((prevLoading) => !prevLoading);
  }

  async function handleClick() {
    toggleLoading();
    const content = fileContent;
    fetch(`data:application/pdf;base64,${content}`)
      .then(function (resp) {
        return resp.blob();
      })
      .then(function (blob) {
        FileSaver.saveAs(blob, fileName);
      });
    toggleLoading();
  }
  return (
    <div onClick={handleClick} style={{ display: "inherit" }}>
      {typeof children === "function" ? children({ loading }) : children}
    </div>
  );
}
