import { isEmptyString } from "./StringUtil";
import { fetchModelData } from "./ModelUtil";
import { useMountEffect } from "./Hooks";
import { submitModel, searchModel } from "./ModelUtil";
import { callApi } from "./api";
import { getCompanySettings, getUserSettings, getUIContext } from "./MainPageContext";
import getLogger from "./Logger";
import classNames from "./classNames";
import makeStyles, { makeStylesLight } from "./Styles";
import { generateReport } from "./ReportUtil";
import { isPermissionsAllowed } from "./Permissions";

export {
  isEmptyString,
  fetchModelData,
  useMountEffect,
  searchModel,
  submitModel,
  getLogger,
  callApi,
  getCompanySettings,
  getUserSettings,
  getUIContext,
  makeStyles,
  makeStylesLight,
  classNames,
  generateReport,
  isPermissionsAllowed
};
