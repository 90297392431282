import React from "react";
import Component from "./Component";
import { getLogger, fetchModelData, useMountEffect } from "lib/util";
import { GoogleMap } from "./map-impl/GoogleMap";
import ALKMap from "./map-impl/ALKMap";
import { useComponentContext, useAsDataProvider } from "./Context";
import { getSVG } from "./Image";

const log = getLogger("lib.components.Map");

export default function Map(props) {
	let result;
	const context = useComponentContext();
	const [data, setData] = useAsDataProvider(context, props.data, props.fieldName);
	log.debug("Map %o %o", props, data);
	let pins = props.pins;
	if (pins == null && data != null)
		pins = createPins(data, props);
	useMountEffect(() => { loadData(props.modelName, data, setData) });
	if (props.mapType == null || props.mapType === "google")
		result = <GoogleMap pins={pins} crumbs={props.crumbs} onPinClick={props.onPinClick}
			name={props.name} zoom={props.zoom} />
	else if (props.mapType === "alk")
		result = <ALKMap pins={pins} zoom={props.zoom} />
	else
		result = <div>Unrecognized map type {props.mapType} </div>
	result = (
		<Component {...props} >
			{result}
		</Component>
	);
	log.debug("Map result %o", result);
	return result;
}

function createPins(data, props) {
	log.debug("create pins with data %o props %o", data, props);
	if (data == null || (props.setPinProps == null && (props.latField == null || props.longField == null)))
		return null;
	let result = [];
	for (let i = 0; i < data.length; i++) {
		if (dataPassesFilter(data[i].modelData, props.filter)) {
			let pin = { pinId: "pin-" + i, data: data[i].modelData };
			if (props.latField)
				pin.lat = pin.data[props.latField];
			if (props.longField)
				pin.lng = pin.data[props.longField];
			if (props.captionField) {
				if (pin.data[props.captionField])
					pin.caption = "" + pin.data[props.captionField];
			}
			if (props.imageField)
				pin.image = getSVG(pin.data[props.imageField]);
			if (props.captionColorField)
				pin.captionColor = pin.data[props.captionColorField];
			if (props.tooltipField)
				pin.tooltip = pin.data[props.tooltipField];
			if (props.clusterPins === false)
				pin.clusterable = false;
			else if (props.clusterableField) 
				pin.clusterable = pin.data[props.clusterableField];
			if (props.setPinProps)
				props.setPinProps(pin);
			if (pin.visible !== false && pin.lat && pin.lng)
				result.push(pin);
		}
	};
	log.debug("Pins %o", result);
	return result;
}

function dataPassesFilter(data, filter) {
	if (filter == null)
		return true;
	else
		return filter(data);
}

function loadData(modelName, contextData, setData) {
	if (modelName != null) {
		log.debug("Loading map data %s %o", modelName, contextData);
		fetchModelData(modelName, contextData, setData);
	}
}
