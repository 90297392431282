import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  pointer: {
    cursor: "pointer"
  },
  borderReset: {
    border: "none"
  },
  rowCellTransitionOut: {
    transition: theme.transitions.create("backgroundColor, border", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  rowCellTransitionIn: {
    transition: theme.transitions.create("backgroundColor, border", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  rowCell: {
    verticalAlign: "top",
    extend: "rowCellTransitionOut"
  },
  activeRowCell: {
    backgroundColor: theme.palette.common.slate,
    extend: "rowCellTransitionIn"
  },
  baseRowCell: {
    padding: theme.spacing(2),
    borderTop: "1px solid transparent"
  },
  activeBaseRowCell: {
    borderTop: `1px solid ${theme.palette.primary.main}`
  },
  expansionRowCell: {
    padding: 0
  },
  lastOfRowType: {
    borderBottom: `1px solid ${theme.palette.common.mist}`
  },
  activeLastOfRowType: {
    borderBottom: `5px solid ${theme.palette.primary.main}`
  },
  inlineRowCellOffset: {
    backgroundColor: theme.palette.common.dew
  },
  inlineRow: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  inlineRowBackgroundOffset: {
    backgroundColor: theme.palette.common.dew
  }
}));
