import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  root: {
    display: "grid",
    gridGap: 5,
    gridTemplateColumns: "auto auto auto",
    marginBottom: theme.spacing(1) / 2
  },
  item: {
    extend: "flexRow",
    color: theme.palette.common.mist,
    alignItems: "center",
    "&:last-of-type": {
      marginRight: 0
    }
  },
  itemValid: {
    color: theme.palette.common.asphalt
  },
  itemIcon: {
    marginRight: theme.spacing(1),
    fill: theme.palette.common.mist
  },
  itemIconValid: {
    fill: theme.palette.success.main
  }
}));
