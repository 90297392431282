import { ReactComponent as PieChartIconSVG } from "images/svg/pie-chart.svg";
import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

export const PieChartIcon = memo(function PieChartIcon(props) {
  return (
    <SvgIcon {...props}>
      <PieChartIconSVG />
    </SvgIcon>
  );
});
