import { mapObject } from "core/utils/mapper";
import produce from "immer";
import { convertMilliToDateTimeObject } from "core/utils/formatters";

const convertMilliObject = ({ dateMillis, timezone }) => ({
  ...convertMilliToDateTimeObject(dateMillis),
  timezone
});

const keyMap = {
  localCallDateTime: "callDateTimes.local",
  pickupCallDateTime: "callDateTimes.origin",
  deliveryCallDateTime: "callDateTimes.destination"
};

const valueFormatters = {
  localCallDateTime: convertMilliObject,
  pickupCallDateTime: convertMilliObject,
  deliveryCallDateTime: convertMilliObject
};

export function mapOrderCallinHistoryResponse({ callinHistoryList = [] }) {
  if (!callinHistoryList.length) return [];
  const reducer = produce((callins, draft) => {
    callins.push(mapObject(draft, keyMap, valueFormatters));
  });

  return callinHistoryList.reduce(reducer, []);
}
