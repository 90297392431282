import React, { useState } from "react";
import { resolveArrayResponse, fetch } from "core/utils/fetch";
import { Typography, AutoComplete } from "common";
import { Box } from "@material-ui/core";
import { buildAPIUrl } from "core/utils/urls";
import { API_PATHS } from "core/config/urls";

export function AutoCompletePlayground() {
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);

  async function handleFetch(query, setOptions) {
    const response = await fetch(
      buildAPIUrl({
        path: API_PATHS.QUERY_LOCATION,
        queryParams: { location: query }
      })
    );
    const options = resolveArrayResponse(response);
    setOptions(options.slice(0, 25));
  }

  function handleGetOptionsSelected(option, value) {
    return option.id === value.id;
  }

  function handleGetOptionLabel(option) {
    if (!option) return null;
    return `${option.city}, ${option.abbr} ${option.zipcode}`;
  }

  function handleChange(_, value) {
    if (!value) setAutoCompleteValue(null);
    setAutoCompleteValue(handleGetOptionLabel(value));
  }

  return (
    <Box m={2}>
      <Typography value={`Current Selection: ${autoCompleteValue || "None"}`} />
      <AutoComplete
        label="City, State, Zipcode"
        onFetch={handleFetch}
        onChange={handleChange}
        getOptionSelected={handleGetOptionsSelected}
        getOptionLabel={handleGetOptionLabel}
      />
    </Box>
  );
}
