import React from "react";
import { InputAdornment, Button } from "@material-ui/core";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import { Form, TextField } from "common";
import { useFieldMeta } from "core/hooks";
import { useField } from "formik";
import { classNames } from "core/utils";
import { useStyles } from "./InlineInput.styles";

function InlineSubmitAction({ name }) {
  const classes = useStyles();
  const [field, meta, _] = useField(name);
  const [error] = useFieldMeta(meta);
  return (
    <Button
      className={classNames(classes.button, {
        [classes.errorButton]: error,
        [classes.warningButton]: !error
      })}
      type="submit"
      disabled={error || !field.value}
      fontSize="large"
      variant={!field.value ? "outlined" : "contained"}
    >
      <ArrowForwardOutlinedIcon />
    </Button>
  );
}

export function InlineInput({
  name = "input",
  defaultValue = "",
  validationSchema = undefined,
  onSubmit = () => undefined,
  ...restProps
}) {
  const classes = useStyles();
  return (
    <Form
      initialValues={{ [name]: defaultValue }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <TextField
        variant="outlined"
        name={name}
        InputProps={{
          className: classes.input,
          startAdornment: <InputAdornment position="start">$</InputAdornment>
        }}
        {...restProps}
      />
      <InlineSubmitAction name={name} />
    </Form>
  );
}
