import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    "&:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.warning.main} !important`
    },
    "&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.warning.main}`
    },
    margin: 0,
    borderRight: "none",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    height: 37
  },
  button: {
    border: `1px solid ${theme.palette.warning.main}`,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: `${theme.palette.warning.hover} !important`
    },
    "&.Mui-disabled": {
      border: `1px solid ${theme.palette.warning.main}`,
      backgroundColor: "transparent"
    },

    width: 36,
    minWidth: 36,
    height: 37,
    marginLeft: -1
  },
  errorButton: {
    border: `1px solid ${theme.palette.error.main} !important`
  }
}));
