import React from "react";
import { Typography } from "common";
import {
  OrderNumberTableCell,
  ExchangeeDetailsTableCell,
  CommodityDetailsTableCell,
  AdditionalDetailsTableCell
} from "common/TableCells";
import { Container } from "@material-ui/core";
import { classNames } from "core/utils";
import { formatUSDCurrency } from "core/utils/formatters";
import { useStyles } from "./styles";

function ColumnWrapper({ children, label }) {
  return (
    <div>
      <Typography label value={label} />
      {children}
    </div>
  );
}

export function OfferDetails({ offer = {} }) {
  const classes = useStyles();
  return (
    <>
      <ColumnWrapper label="Order Number">
        <OrderNumberTableCell {...offer} />
      </ColumnWrapper>
      <Container
        className={classNames(classes.row, classes.details)}
        maxWidth={false}
        disableGutters
      >
        <ColumnWrapper label="Pickup">
          <ExchangeeDetailsTableCell {...offer} dataKey="pickup" label="Pickup" />
        </ColumnWrapper>
        <ColumnWrapper label="Delivery">
          <ExchangeeDetailsTableCell {...offer} dataKey="delivery" label="Delivery" />
        </ColumnWrapper>
        <ColumnWrapper label="Commodity">
          <CommodityDetailsTableCell {...offer} label="Equipment" />
        </ColumnWrapper>
        <ColumnWrapper label="Additional Details">
          <AdditionalDetailsTableCell {...offer} disableRate />
        </ColumnWrapper>
      </Container>
      <div>
        <Typography label value="Rate" />
        <div className={classNames(classes.row, classes.rate)}>
          <Typography variant="caption" fontWeight="bold" color="primary" value="$" />
          <Typography
            variant="h4"
            color="primary"
            value={formatUSDCurrency(offer.rate, { prefix: false })}
          />
        </div>
      </div>
    </>
  );
}
