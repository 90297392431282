import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row"
  },
  grow: {
    flexGrow: 1
  },
  Button: {
    marginTop: 12,
    fontSize: 10,
    height: 40,
    minWidth: 140,
    backgroundColor: "#FF6600",
    color: "white",
    "&:disabled": {
      backgroundColor: "#FF6600",
      color: "white"
    }
  },
  offerTextField: {
    height: "40px",
    minWidth: "110px",
    maxWidth: "140px",
    marginBottom: "10px",
    fontSize: "16px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#FF6600 !important"
      },
      "&:hover fieldset": {
        borderColor: "#FF6600 !important"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FF6600 !important"
      }
    },
    "& .MuiTypography-root": {
      fontSize: "12px",
      marginRight: 1
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "8px"
    },
    "& .MuiInputAdornment-positionStart": {
      marginRight: "4px"
    },
    "& .MuiFormHelperText-contained": {
      flexWrap: "nowrap"
    }
  },
  offerTextFieldOutline: {
    borderColor: "#FF6600 !important"
  },
  cssOutlinedInput: {
    "&:hover $notchedOutline": {
      borderColor: "#FF6600 !important"
    },
    fontSize: "16px"
  },
  formControl: {
    minWidth: "110px",
    maxWidth: "140px"
  },
  notchedOutline: {
    borderColor: "#FF6600 !important"
  },
  input: {
    fontSize: "16px",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "gray",
      fontSize: "16px"
    }
  },
  focus: {
    borderColor: "#FF6600 !important",
    borderWidth: 2
  },
  adornedStart: {
    fontSize: "12px"
  },
  responseDialog: {
    textAlign: "center",
    padding: "20px"
  },
  responseDialogButton: {
    marginBottom: "40px",
    height: 40,
    minWidth: 150
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "10%",
    marginTop: -14,
    marginLeft: -10,
    color: "white"
  }
});
