import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: theme.spacing(2)
  },
  timeZone: {
    marginTop: 23,
    color: theme.palette.common.smoke
  }
}));
