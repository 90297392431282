import { ReactComponent as DownloadIconSVG } from "images/svg/download.svg";
import React, { memo } from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";
import classNames from "core/utils/classNames";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 16
  }
}));

export const DownloadIcon = memo(function DownloadIcon({ className, ...restProps }) {
  const classes = useStyles();
  return (
    <SvgIcon
      className={classNames(classes.root, className)}
      viewBox="0 0 16 15"
      component={DownloadIconSVG}
      {...restProps}
    />
  );
});
