import { buildAPIUrl } from "core/utils/urls";
import { API_PATHS } from "core/config/urls";
import {
  resolveArrayResponse,
  resolveJSONResponse,
  appendAuthInterceptors,
  fetchAll
} from "core/utils/fetch";
import { mapUserControls } from "core/mappers/userControls";
import { mapUserBranding } from "core/mappers/userBranding";
import { arrayBufferToUrl } from "core/utils/common";
import { FETCH_RESPONSE_TYPE_ARRAY_BUFFER } from "core/utils/constCapsule";
import { initialState, initialBrandingState, AUTH_TYPE_GUEST } from "../states/UserState";
import { resetUI } from "./UIActions";
import { getBranding, getCompanyMetaData } from "../selectors/UserSelectors";

export const USER_SET = "USER_SET";
export const USER_SET_PERMISSIONS = "USER_SET_PERMISSIONS";
export const USER_SET_COMPANY_META_DATA = "USER_SET_COMPANY_META_DATA";
export const USER_SET_LICENSES = "USER_SET_LICENSES";

export function setUser(payload) {
  return {
    type: USER_SET,
    payload
  };
}

export function fetchUnauthedUserInfo() {
  return async (dispatch) => {
    const fetches = [
      { path: API_PATHS.BRANDING_LOGO, responseType: FETCH_RESPONSE_TYPE_ARRAY_BUFFER },
      { path: API_PATHS.BRANDING, mapper: mapUserBranding },
      { path: API_PATHS.COMPANY_META_DATA }
    ];

    const [logo, branding, companyMetaData] = await fetchAll(
      fetches.map(({ path, mapper, ...rest }) => ({
        url: buildAPIUrl(path),
        transformResponse: mapper,
        ...rest
      }))
    );

    const logoBuffer = resolveJSONResponse(logo);
    dispatch(
      setUser({
        companyMetaData: resolveJSONResponse(companyMetaData),
        branding: {
          ...initialBrandingState,
          logo: logoBuffer ? arrayBufferToUrl(logoBuffer) : null,
          ...resolveJSONResponse(branding)
        }
      })
    );
  };
}

export function fetchAuthedUserInfo() {
  return async (dispatch) => {
    const fetches = [
      { path: API_PATHS.USER_ADMIN_EMAIL },
      { path: API_PATHS.USER_LEGACY_BASE_URL },
      { path: API_PATHS.USER_PERMISSIONS },
      { path: API_PATHS.USER_LICENSES },
      { path: API_PATHS.USER_CONTROLS, mapper: mapUserControls }
    ];

    const [adminEmail, legacyBaseUrl, permissions, licenses, controls] = await fetchAll(
      fetches.map(({ path, mapper, ...rest }) => ({
        url: buildAPIUrl(path),
        transformResponse: mapper,
        ...rest
      }))
    );

    dispatch(
      setUser({
        adminEmail: resolveJSONResponse(adminEmail).email,
        legacyBaseUrl: resolveJSONResponse(legacyBaseUrl).url,
        permissions: resolveArrayResponse(permissions),
        licenses: resolveArrayResponse(licenses),
        controls: resolveJSONResponse(controls)
      })
    );
  };
}

export function signIn(payload) {
  return async (dispatch) => {
    appendAuthInterceptors();
    await dispatch(fetchAuthedUserInfo());
    dispatch(setUser(payload));
  };
}

export function signOut() {
  return async (dispatch, getState) => {
    const state = getState();
    const branding = getBranding(state);
    const companyMetaData = getCompanyMetaData(state);
    sessionStorage.clear();
    dispatch(resetUI());
    dispatch(
      setUser({
        ...initialState,
        branding,
        companyMetaData,
        authType: AUTH_TYPE_GUEST
      })
    );
  };
}
