import React from "react";
import { TableCell } from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { useStyles } from "./TableStyles";
import { getLogger } from "lib/util";

const log = getLogger("lib.components.Table.Column");

export default function Column({
	columnIndex, caption, fieldName, 
	displayType, align, minWidth, maxWidth,
	sortedColumns, setSortedColumns, 
	sortFieldName, sortDataType}) {
	let classes = useStyles();
	let domProps = {};
	if (align)
		domProps.align = align;
	let onClick, className = classes.headerSpan;
	let sortImage = getSortImage(classes, sortedColumns, columnIndex, fieldName, sortFieldName);
	if (fieldName == null)
		fieldName = sortFieldName;
	if (fieldName == null)
		className += " " + classes.noClick;
	else
		onClick = (event) => columnClicked(event, columnIndex, fieldName, displayType, sortDataType, sortFieldName, sortedColumns, setSortedColumns);
	return (
		<TableCell className={classes.tableHeaderCell} {...domProps} style={{minWidth, maxWidth}}>
			<span className={className} onClick={onClick} >{caption}{sortImage}</span>
		</TableCell>
	);
}

function getSortImage(classes, sortedColumns, columnIndex, fieldName, sortFieldName) {
	for (let i = 0; sortedColumns != null && i < sortedColumns.length; i++) {
		let isThisColumn = false;
		if (sortedColumns.columnIndex)
			isThisColumn = sortedColumns[i].columnIndex === columnIndex;
		else if (sortedColumns[i].fieldName) {
			if (fieldName)
				isThisColumn = sortedColumns[i].fieldName === fieldName;
			else if (sortFieldName)
				isThisColumn = sortedColumns[i].fieldName === sortFieldName;
		} 

		if (isThisColumn) {
			let arrow;
			if (sortedColumns[i].ascending === false)
				arrow = <ArrowDownward className={classes.sortArrow} />
			else
				arrow = <ArrowUpward className={classes.sortArrow}/>
			return <span>{arrow}<span className={classes.sortNumber}>{"" + (i + 1)}</span></span>
		}
	}
}

function columnClicked(event, columnIndex, fieldName, displayType, sortDataType, sortFieldName, sortedColumns, setSortedColumns) {
	let sort = [...sortedColumns];
	log.debug("Clicked %o %o %o", columnIndex, fieldName, sortFieldName);
	if (fieldName == null)
		fieldName = sortFieldName;
	if (fieldName == null)
		return;
	let found = false;
	if (event.ctrlKey || sort.length === 1)
		for (let i = 0; sort != null && i < sort.length; i++) {
			if (sort[i].columnIndex === columnIndex) {
				found = true;
				sort[i].ascending = !sort[i].ascending;
			}
		}
	if (found === false) {
		const def = {columnIndex: columnIndex, ascending: true, fieldName: fieldName }
		if (displayType)
			def.dataType = displayType;
		if (sortDataType)
			def.dataType = sortDataType;
		log.debug("Add sort %o", def);
		if (event.ctrlKey)
			sort.push(def);
		else
			sort = [def];
	}
	log.debug("New sort %o", sort);
	setSortedColumns(sort);
}