import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    margin: 0,
    listStyle: "none"
  },
  gutters: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  row: {
    display: "flex",
    flexDirection: "row",
    "& li": {
      marginLeft: theme.spacing(7),
      "&:first-of-type": {
        marginLeft: 0
      }
    }
  },
  alignBetween: {
    justifyContent: "space-between"
  },
  alignStart: {
    justifyContent: "flex-start"
  }
}));
