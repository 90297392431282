import { useState, useCallback, useMemo } from "react";
import produce from "immer";

export function useCallinTimezone(data, options) {
  const [dateTimePath, setDateTimePath] = useState(options[0].path);
  const handleDateTimeChange = useCallback(({ path }) => setDateTimePath(path), []);
  const mappedData = useMemo(
    () =>
      data.map(
        produce((draft) => ({
          ...draft,
          dateTimePath
        }))
      ),
    [data, dateTimePath]
  );
  return [mappedData, handleDateTimeChange];
}
