import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  header: {
    border: "none",
    boxShadow: "none",
    fontSize: "26px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "25px",
    marginRight: "25px",
    marginTop: "10px"
  },
  title: {
    background: "#FF6600",
    fontSize: "26px",
    color: "white",
    display: "flex",
    justifyContent: "space-between"
  },
  acceptButton: {
    background: "#FF6600",
    minWidth: 200,
    color: "white",
    margin: "10px",
    "&:hover": {
      color: "#9E9E9E"
    }
  },
  cancelButton: {
    minWidth: 200,
    margin: "10px"
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "25px",
    marginRight: "25px"
  },
  paper: { maxHeight: "calc(100% - 110px)" },
  iconButton: {
    "& .MuiSvgIcon-root": {
      color: "white"
    }
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    color: "#FF6600"
  }
});
