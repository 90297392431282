import React from "react";
import { SESSION_STORAGE_LOGIN_TOKEN } from "lib/util/api";
import { showDialog } from "lib/components";
import { getLogger, getCompanySettings } from "lib/util";

const log = getLogger("lme.dsp.LegacyInternetModule");

export function showLegacyIM(title, servlet, scroll) {
	if (getCompanySettings().legacyBaseUrl == null) {
		log.error("Couldn't open legacy frame because company settings weren't populated %o", getCompanySettings());
		return null;
	}
	const dialogProps = { maxWidth:1540, disableBackdropClick: true, contentStyle:{padding:0}, titleColor: "#D3D3D3"};
	const legacyFrame = <LegacyIFrame title={title} servlet={servlet} scroll={scroll || "no"} />
	showDialog(title, legacyFrame, dialogProps);
}

function LegacyIFrame(props) {
	const loginToken = sessionStorage.getItem(SESSION_STORAGE_LOGIN_TOKEN);
	let src = getCompanySettings().legacyBaseUrl;
	log.debug("settings %s", src);
	if (src.endsWith("/"))
		src = src.substring(0, src.length - 1);
	log.debug("now %s", src);
	src += `${props.servlet}?useIFrame=true&iFrameWidth=100%&skipDecoration=true&vnext_token=${loginToken}&currentCompany=${getCompanySettings().id}`;
	log.debug("Show legacy IM src %o", src);
	return (
		<iframe id="legacy-iframe" title={props.title} style={{ backgroundColor: "#ffffff", border:"none" }}
			src={src} height="800" width="1700" scrolling={props.scroll} />
	);
}