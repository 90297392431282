import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  dialogContent: {
    extend: "flex",
    minWidth: 471
  },
  header: {
    extend: "flex",
    marginBottom: theme.spacing(4),
    "&:nth-child(3)": {
      marginBottom: theme.spacing(2)
    }
  },
  dispatchTruckIcon: {
    marginBottom: theme.spacing(3)
  },
  location: {
    height: 20
  }
}));
