import React from "react";
import { FormikConsumer } from "formik";
import { useStyles } from "./FormikDebugger.styles";

export function FormikDebugger() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <header className={classes.header}>Formik State</header>
      <FormikConsumer>
        {({ validationSchema, validate, onSubmit, ...rest }) => (
          <pre className={classes.state}>{JSON.stringify(rest, null, 2)}</pre>
        )}
      </FormikConsumer>
    </div>
  );
}
