import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    toolBar: {
      height: theme.props.navHeight,
      backgroundColor: theme.branding.colors.headerBackground
    },
    grow: {
      flexGrow: 1
    }
  };
});
