import { createSelector } from "reselect";

// Base Selectors
const AuthTypeSelector = ({ user }) => user.authType;
export const UserTypeSelector = ({ user }) => user.userType;
const UserNameSelector = ({ user }) => user.userName;
const UserDisplayNameSelector = ({ user }) => user.displayName;
const UserCompanySelector = ({ user }) => user.companyName;
const AdminEmailSelector = ({ user }) => user.adminEmail;
const LegacyBaseUrlSelector = ({ user }) => user.legacyBaseUrl;
const CompanyMetaData = ({ user }) => user.companyMetaData;
const CompanyMetaDataArgSelector = (_, companyMetaDataKey) => companyMetaDataKey;
const PermissionsSelector = ({ user }) => user.permissions;
const BrandingSelector = ({ user }) => user.branding;
const PermissionArgSelector = (_, permission) => permission;
const LicensesSelector = ({ user }) => user.licenses;
const LicenseArgSelector = (_, license) => license;
const ControlsSelector = ({ user }) => user.controls;
const ControlsArgSelector = (_, controlKey, controlValue) => ({
  controlKey,
  controlValue
});

// Selectors
export const getAuthType = createSelector([AuthTypeSelector], (authType) => authType);
export const getUserType = createSelector([UserTypeSelector], (userType) => userType);
export const getUserName = createSelector([UserNameSelector], (userName) => userName);
export const getPermissions = createSelector([PermissionsSelector], (permissions) => permissions);
export const getLicenses = createSelector([LicensesSelector], (licenses) => licenses);
export const getAdminEmail = createSelector([AdminEmailSelector], (adminEmail) => adminEmail);
export const getBranding = createSelector([BrandingSelector], (branding) => branding);

export const getUserDisplayName = createSelector(
  [UserDisplayNameSelector],
  (displayName) => displayName
);

export const getUserCompanyName = createSelector(
  [UserCompanySelector],
  (companyName) => companyName
);

export const getLegacyBaseUrl = createSelector(
  [LegacyBaseUrlSelector],
  (legacyBaseUrl) => legacyBaseUrl
);

export const getCompanyMetaData = createSelector(
  [CompanyMetaData],
  (companyMetaData) => companyMetaData
);

export const getCompanyMetaDataValue = createSelector(
  [CompanyMetaData, CompanyMetaDataArgSelector],
  (companyMetaData, companyMetaDataKey) => companyMetaData[companyMetaDataKey]
);

export const hasPermission = createSelector(
  [PermissionsSelector, PermissionArgSelector],
  (permissions, permission) => permissions.includes(permission)
);

export const hasLicense = createSelector(
  [LicensesSelector, LicenseArgSelector],
  (licenses, license) => licenses.includes(license)
);

export const doesControlMatch = createSelector(
  [ControlsSelector, ControlsArgSelector],
  (controls, { controlKey, controlValue }) =>
    controls[controlKey] && controls[controlKey] === controlValue
);

export const doesControlNotMatch = createSelector(
  [ControlsSelector, ControlsArgSelector],
  (controls, { controlKey, controlValue }) =>
    controls[controlKey] && controls[controlKey] !== controlValue
);

export const getControlValue = createSelector(
  [ControlsSelector, ControlsArgSelector],
  (controls, { controlKey }) => controls[controlKey]
);
