import React from "react";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { Typography } from "common/Typography";
import classNames from "core/utils/classNames";
import { useStyles } from "./styles";

export function AutoCompleteOption({ option, renderProps, getOptionLabel }) {
  const classes = useStyles();
  const matches = match(getOptionLabel(option), renderProps.inputValue);
  const parts = parse(getOptionLabel(option), matches);
  return (
    <div>
      {parts.map((part, index) => (
        <Typography
          wordWrap
          key={index}
          className={classNames({
            [classes.highlight]: part.highlight
          })}
          component="span"
          fontSize="large"
          fontWeight={part.highlight ? "bold" : "regular"}
        >
          {part.text}
        </Typography>
      ))}
    </div>
  );
}
