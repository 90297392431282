import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2)
    }
  }
}));

export function IndicatorActions({ children, ...restProps }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { ...child.props, ...restProps })
      )}
    </div>
  );
}
