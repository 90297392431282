import React from "react";
import classNames from "core/utils/classNames";
import { useStyles } from "./Spacer.styles";

export function Spacer({ grow = true }) {
  const classes = useStyles();
  return (
    <div
      className={classNames({
        [classes.grow]: grow
      })}
    />
  );
}
