import produce from "immer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import { groupImagesById, groupCallinsById } from "core/utils/reducers";
import {
  REMOVE_ORDER,
  SET_ORDERS,
  SET_ORDERS_CALLINS,
  SET_ORDERS_IMAGES,
  SET_ORDERS_IS_FETCHING,
  SET_ORDERS_TYPE,
  SET_ACTIVE_CATEGORY
} from "../actions/OrdersActions";
import { initialState } from "../states/OrdersState";

/* eslint-disable default-case */
// eslint-disable-next-line consistent-return
export const reducer = produce((draft, action) => {
  const { type, ordersCategory, ordersType, payload } = action;
  const draftPath = ordersType ? draft[ordersCategory][ordersType] : draft[ordersCategory];

  switch (type) {
    case SET_ORDERS:
      draftPath.orders = payload.orders;
      break;
    case SET_ORDERS_IS_FETCHING:
      draftPath.isFetching = payload.isFetching;
      break;
    case SET_ACTIVE_CATEGORY:
      draft.activeCategories = {
        ...draft.activeCategories,
        ...payload
      };
      break;
    case SET_ORDERS_TYPE:
      draftPath.ordersType = payload.ordersType;
      break;
    case SET_ORDERS_IMAGES:
      draftPath.imagesByMovementId = {
        ...draftPath.imagesByMovementId,
        ...groupImagesById(payload, "movementId")
      };
      break;
    case SET_ORDERS_CALLINS:
      draftPath.callinsByOrderId = groupCallinsById(payload, "orderId");
      break;
    case REMOVE_ORDER:
      draftPath.orders = draftPath.orders.filter((order) => order.id !== payload.orderId);
      break;
    default:
      return draft;
  }
}, initialState);

export const OrdersReducer = persistReducer(
  {
    key: "orders",
    storage
  },
  reducer
);
