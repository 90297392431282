import React from "react";
import { CarrierPortal, FactoringCompanyPortal } from "pages";
import { getUserType } from "core/store/selectors/UserSelectors";
import { connect } from "react-redux";
import {
  USER_TYPE_LOCATION,
  USER_TYPE_CUSTOMER,
  USER_TYPE_CARRIER,
  USER_TYPE_FACTORING_COMPANY,
  USER_TYPE_DRIVER
} from "core/utils/constCapsule";
import { DriverPortal } from "./DriverPortal";
import { CustomerPortal } from "lme/ar/CustomerPortal";

function PortalComponent({ userType, ...props }) {
  function getPortalComponent() {
    let component = null;
    if (userType == USER_TYPE_CARRIER) component = <CarrierPortal pageTitle="Carrier Portal" />;
    else if (userType == USER_TYPE_CUSTOMER)
      component = <CustomerPortal pageTitle="Customer Portal"/>
    else if (userType == USER_TYPE_LOCATION)
      component = <CustomerPortal pageTitle="Location Portal" />;
    else if (userType == USER_TYPE_FACTORING_COMPANY)
      component = <FactoringCompanyPortal pageTitle="Factoring Company Portal" />;
    else if (userType == USER_TYPE_DRIVER) component = <DriverPortal pageTitle="Driver Portal" />;
    return component;
  }

  return getPortalComponent();
}

const mapStateToProps = (state) => ({
  userType: getUserType(state)
});

export const Portal = connect(mapStateToProps)(PortalComponent);
