import React from "react";
import { CircularProgress } from '@material-ui/core';
import Component from "./Component";

export default function Spinner(props) {
	let progressProps = {};
	if (props.size)
		progressProps.size = props.size;
	else if (props.width)
		progressProps.size = props.width;
	else if (props.height)
		progressProps.size = props.height;
	return (
		<Component {...props} >
			<CircularProgress {...progressProps}/>
		</Component>
	); 
}