import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  label: {
    marginRight: theme.spacing(2)
  },
  button: {
    minWidth: 53
  },
  activeButton: {
    border: "1px solid transparent",
    padding: "3px 9px"
  }
}));
