import React, { useState } from "react";
import { Tabs, Tab, Container } from "@material-ui/core";
import { useStyles } from "./Tabset.styles";
import { Component, Image, Panel } from ".";
import classNames from "lib/util/classNames";
import { getChildArray } from "./ComponentUtil";
import { useComponentContext } from "./Context";

export default function Tabset(props) {
	const componentContext = useComponentContext();
	const [value, setValue] = useState(0);
	const classes = useStyles();
	const handleChange = (event, newValue) => {
		event.stopPropagation();
		setValue(newValue);
		if (props.onChange)
			props.onChange(newValue);
	};
	let tabs = [], tabPanels = [];
	let children = getChildArray(props.children);
	for (let i = 0; i < children.length; i++) {
		let childProps = { ...children[i].props };
		if (!isTabVisible(childProps, componentContext))
			continue;
		let tabIndex = tabPanels.length;
		let tabClasses;
		if (props.variant === 'outlined')
			tabClasses = { root: classNames(classes.tab, { [classes.activeTab]: tabIndex === value }), wrapper: classes.wrapper };
		const image = getTabImage(childProps, tabIndex === value);
		const tab = <Tab key={"tab-" + tabIndex} label={childProps.caption} classes={tabClasses} icon={image} />
		tabs.push(tab);
		const tabPanel = (
			<TabPanel name={"tab-panel-" + tabIndex} className={classes.tabPanel} key={"tab-panel" + tabIndex} value={value} index={tabPanels.length} fillRow>
				{childProps.children}
			</TabPanel>
		);
		tabPanels.push(tabPanel);
	}

	let tabsetProps, headerClassName = classes.header;
	if (props.variant === 'outlined') {
		tabsetProps = { className: classes.tabs, TabIndicatorProps: { style: { display: "none" } } };
		headerClassName += " " + classes.headerOutlined;
	}
	return (
		<Component {...props} className={classes.tabset}>
			<Container className={headerClassName} maxWidth={false}>
				<Tabs value={value} variant="scrollable" onChange={handleChange} indicatorColor="primary" textColor="primary" {...tabsetProps} >
					{tabs}
				</Tabs>
				<div className={classes.grow} />
				{props.extraComponent}
			</Container>
			{tabPanels}
		</Component>
	);
}

function isTabVisible(props, componentContext) {
	if (props.visible === false)
		return false;
	else if (typeof props.visible === "function")
		return props.visible(componentContext);
	else
		return true;
}

function getTabImage(colProps, isActive) {
	if (colProps.image != null)
		return <Image image={colProps.image} />
	else if (isActive && colProps.activeImage != null)
		return <Image image={colProps.activeImage} />
	else if (!isActive && colProps.inactiveImage != null)
		return <Image image={colProps.inactiveImage} />
}

function TabPanel(props) {
	if (props.value === props.index)
		return (
			<Panel {...props} >
				{props.children}
			</Panel>
		);
	else
		return null;
}