import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  row: {
    "&:last-of-type": {
      color: theme.palette.primary.main
    }
  },
  container: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    paddingRight: 0
  },
  empty: {
    backgroundColor: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 100,
    width: "100%"
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    paddingTop: theme.spacing(2),
    flexShrink: 0
  }
}));
