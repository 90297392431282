import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { downloadCSV } from "core/utils/CSV";
import { downloadXLS } from "core/utils/XLS";
import { downloadPDF } from "core/utils/PDF";

export function TableOptions({ onPDF, onXLS, onCSV }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleDownloadClick = (format, argsCallback) => async () => {
    const handlers = {
      CSV: downloadCSV,
      XLS: downloadXLS,
      PDF: downloadPDF
    };
    await handlers[format](...argsCallback());
  };

  const options = [
    ...(onCSV ? [{ label: "Download CSV", onClick: handleDownloadClick("CSV", onCSV) }] : []),
    ...(onXLS ? [{ label: "Download XLS", onClick: handleDownloadClick("XLS", onXLS) }] : []),
    ...(onPDF ? [{ label: "Download PDF", onClick: handleDownloadClick("PDF", onPDF) }] : [])
  ];

  if (!options.length) return null;
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="more-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map(({ label, onClick }) => (
          <MenuItem key={label} onClick={onClick}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
