import { mapFromAPI } from "core/utils/mapper";
import {
  convertMilliToDateTimeObject,
  convertMilliToDateFormat
} from "core/utils/formatters";

const keyMap = {
  orderId: "id",
  originCity: "pickup.city",
  originState: "pickup.state",
  originSchedArriveEarly: "pickup",
  destinationCity: "delivery.city",
  destinationState: "delivery.state",
  destinationSchedArriveEarly: "delivery",
  commodity: "commodity.name",
  equipmentDescription: "commodity.description",
  equipment_length: "commodity.length",
  contactEmail: "contact.email",
  contactName: "contact.name",
  offerDate: "offerDate",
  id: "offerId",
  offerStatus: "offerStatus",
  offerAmount: "offerAmount",
  counterAmount: "counterAmount",
  moveDistance: "moveDistance"
};

const valueFormatters = {
  offerDate: convertMilliToDateFormat,
  destinationSchedArriveEarly: convertMilliToDateTimeObject,
  originSchedArriveEarly: convertMilliToDateTimeObject
};

export function mapOfferHistoryOrders({ offerList = [] } = {}) {
  return mapFromAPI(offerList, keyMap, valueFormatters);
}
