export const USER_PERMISSIONS = {
  INTERNET_MODULE_AGED_RECEIVABLES_REPORT:
    "InternetModuleAgedReceivablesReport",
  INTERNET_MODULE_ORDER_ENTRY: "InternetModuleOrderEntry",
  INTERNET_MODULE_LTL_QUOTE_ENTRY: "InternetModuleQuoteEntry",
  INTERNET_MODULE_PICKUP_ENTRY: "InternetModuleQuoteEntry",
  INTERNET_MODULE_CARRIER_ENTERED_CALLINS:
    "InternetModuleCarrierEnteredCallins",
  INTERNET_MODULE_CARRIER_SETTLEMENT_HISTORY:
    "InternetModuleCarrierSettlementHistory",
  INTERNET_MODULE_CARRIER_DISPATCH:
    "InternetModuleCarrierDispatch",
};
