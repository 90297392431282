import React, { useState } from "react";
import { PanelCustomerOrderFullDetail } from "./PanelCustomerOrderFullDetail";
import { Panel, ComboBox, TextBox, Button, showSnackbar } from "lib/components";
import { UnauthenticatedPageContainer } from "lib/pages/UnauthenticatedPageContainer";
import { getCompanySettings, getLogger } from "lib/util";

const log = getLogger("lme.ar.CustomerPortal.PanelViewOrderUnauthenticated");

export function PanelViewOrderUnauthenticated(props) {
	let [searchType, setSearchType] = useState();
	let [searchValue, setSearchValue] = useState();
	if (!props || !props.location || !props.location.search)
		return null;
	return (
		<UnauthenticatedPageContainer searchQueryString={props.location.search}>
			<Panel marginBottom={24} variant="form">
				<ComboBox caption="Search type" width={200} items={getComboItems} onChange={(value) => setSearchType(getComboItems()[value].value)} noRowBreak/>
				<TextBox caption="Tracking number" name="combo_search_trackingNum" width={240} noRowBreak marginLeft={8} onChange={setSearchValue} />
				<Button caption="Search" color="primary" height={38} action
					variant="contained" width={100} marginLeft={16} marginTop={24} 
					onClick={() => track(props.history, searchType, searchValue)}/>
			</Panel>
			<PanelCustomerOrderFullDetail unauthenticated searchQueryString={props.location.search}/>
		</UnauthenticatedPageContainer>
	);
}

function getComboItems() {
	const companySettings = getCompanySettings();
	const comboItems = [
		{caption: "BOL#", value: "BOLNum"}, 
		{caption: "Consignee ref #", value: "ConsigneeRefNum"}, 
		{caption: "Order #", value: "OrderNum"},
	];
	if (companySettings.isAssetLTL)
		comboItems.push({caption: "Pro #", value: "ProNum"});
	if (companySettings.isBrokerageLTL) {
		comboItems.push({caption: "Carrier pro #", value: "CarrierProNum"});
		comboItems.push({caption: "Shipper PO #", value: "ShipperPONum"});
	}
	return comboItems;
}

function track(history, searchType, searchValue) {
	log.debug("Track %o %o", searchType, searchValue);
	if (!searchType || searchType.length === 0 || !searchValue || searchValue.length === 0)
		showSnackbar("You need to enter tracking information to search.");
	else {
		const url = `/track?searchType=${searchType}&searchValue=${searchValue}`;
		window.location = url;
//	history.push(url);
	}
}