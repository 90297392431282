import produce from "immer";
import dotProp from "dot-prop";

/**
 * Uses the found value mapper function in the valueMap or returns undefined
 *
 * @param {*} [valueMap=null]
 * @param {*} [key=null]
 * @returns
 */
function getValueFormatter(valueMap = null, key = null) {
  if (!key || !valueMap) return null;
  return typeof valueMap[key] === "function" ? valueMap[key] : undefined;
}

/**
 * Returns the found path in keyMap or returns the kay passed in.
 *
 * @param {*} [keyMap=null]
 * @param {*} [key=null]
 * @returns
 */
function getPath(keyMap = null, key = null) {
  if (!keyMap) return key;
  return keyMap[key] || key;
}

/**
 * Returns the fromatted value from the formatter or uses the value from the input object.
 *
 * @param {*} inputObj
 * @param {*} valueMap
 * @param {*} key
 * @returns
 */
function getValue(inputObj, valueMap, key) {
  const valueFormatter = getValueFormatter(valueMap, key);
  const value = inputObj[key];
  return valueFormatter ? valueFormatter(value) : value;
}

function mapKeyValue(inputObj, path, value) {
  const result = produce(inputObj, (draft) => {
    const valueExists = dotProp.has(draft, path);

    if (!valueExists) {
      dotProp.set(draft, path, value);
      return draft;
    }

    const existingValue = dotProp.get(draft, path);
    const existingValueType = existingValue.constructor;
    const valueType = value.constructor;

    // In the case the incoming value is an empty string,
    // don't overwrite the existing value with an empty string.
    if (!value) {
      dotProp.set(draft, path, existingValue);
      return draft;
    }

    // Use the incoming value type and overwrite the previous
    // value.
    if (existingValueType !== valueType) {
      dotProp.set(draft, path, value);
      return draft;
    }

    // Based off the type we have to merge
    // the two values differently.
    let newValue;
    switch (value.constructor) {
      case Object:
        newValue = {
          ...existingValue,
          ...value
        };
        break;
      case Array:
        newValue = [...existingValue, ...value];
        break;
      default:
        // If a matching type isn't found use the incoming value.
        newValue = value;
        break;
    }

    dotProp.set(draft, path, newValue);
    return draft;
  });
  return result;
}

/**
 * Transforms an object of data into the desired shape, using a keyMap, a collection
 * of value formatters, and a schema shape as a general starting point.
 *
 * @export
 * @param {object} obj The object to transform
 * @param {object} [keyMap={}] The map that tells the transformer what to conver the key into e.g { "old_key": "newKey" }
 * @param {object} [valueFormatters={}] An object of function using the related key to transform it's value.
 * @param {object} [objectSchema={}] The shape of the transformed object.
 * @returns
 */
export function mapObject(
  inputObj,
  keyMap = null,
  valueMap = null,
  initialObject = {}
) {
  if (!keyMap && !valueMap) return inputObj;
  const reducer = produce((draftObject, draftKey) => {
    const path = getPath(keyMap, draftKey);
    const value = getValue(inputObj, valueMap, draftKey);
    return mapKeyValue(draftObject, path, value);
  });

  return Object.keys(inputObj).reduce(reducer, initialObject);
}

export function mapModelDataFromAPI(data = [], keyMap, valueFormatters) {
  if (!data.length) return [];
  const dataMapper = produce((delta) =>
    mapObject(delta.modelData, keyMap, valueFormatters)
  );

  return data.map(dataMapper);
}

export function mapFromAPI(data = [], keyMap, valueFormatters) {
  if (!data.length) return [];
  const dataMapper = produce((delta) =>
    mapObject(delta, keyMap, valueFormatters)
  );

  return data.map(dataMapper);
}
