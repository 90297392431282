import React, { useEffect, useState, useCallback } from "react";
import { OutlinedInput } from "@material-ui/core";
import { useDebounce } from "core/hooks";
import { Typography } from "common";
import { useStyles } from "./DaysFilter.styles";

export function DaysFilter({
  value: _value = 365,
  onChange = () => undefined
}) {
  const [value, setValue] = useState(_value);
  const debouncedValue = useDebounce(value);

  const handleBlur = ({ target: { value: blurredValue } }) => {
    if (blurredValue > 365) setValue(365);
    if (blurredValue < 0) setValue(0);
  };

  const onChangeCallback = useCallback(onChange, [onChange]);
  useEffect(() => {
    onChangeCallback(debouncedValue);
  }, [debouncedValue, onChangeCallback]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography
        className={classes.label}
        fontSize="small"
        value="Display Last"
      />
      <OutlinedInput
        onBlur={handleBlur}
        className={classes.input}
        label="Display Last"
        type="number"
        onChange={({ target }) => setValue(target.value)}
        value={value}
      />
      <Typography className={classes.helperText} value="Days of Checks" label />
    </div>
  );
}
