import React from "react";
import { Typography } from "common";
import { useStyles } from "./PageTitle.styles";

export function PageTitle({ value = "", variant = "h6", fontWeight = "bold" }) {
  const classes = useStyles();
  return (
    <Typography
      className={classes.root}
      fontWeight={fontWeight}
      variant={variant}
      value={value}
    />
  );
}
