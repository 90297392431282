import React, { useContext, useState } from "react";
import { getLogger } from "lib/util";

export const DataContext = React.createContext();
export const HierarchyContext = React.createContext();
export const ValueOwnerContext = React.createContext();

const log = getLogger("lib.components.Context");

export function useComponentContext() {
	const dataContext = useDataContext();
	const hierarchyContext = useHierarchyContext();
	const valueOwnerContext = useValueOwnerContext();
	return { data: dataContext, hierarchy: hierarchyContext, valueOwner: valueOwnerContext };
}

export function useDataContext() {
	return useContext(DataContext);
}

export function useData() {
	let context = useContext(DataContext);
	if (!context || context.list == null)
		return null;
	const listItem = context.list[context.listIndex];
	if (listItem == null)
		return null;
	return listItem.modelData;
}


export function useHierarchyContext() {
	return useContext(HierarchyContext);
}

export function useValueOwnerContext() {
	return useContext(ValueOwnerContext);
}

export function updateValueOwner(context, props, value) {
	if (context != null && context.valueOwner != null) {
		log.debug("Update value owner %o %o", props, value);
		context.valueOwner.valueChanged(props, value);
	}
}

export function useAsDataProvider(context, propsData, fieldName) {
	let result;
	let [stateData, setStateData] = useState();
	log.debug("state data %o", stateData);
	if (stateData != null)
		result = stateData;
	else if (propsData != null)
		result = propsData;
	else if (context.data != null)
		result = context.data.list;
	if (result != null && fieldName != null && stateData == null) { // probably not right to check stateData==null but added it because setData is called from sort.  May be a better solution.
		log.debug("Field name %s with table %o  Result %o", fieldName, context, result);
		let index = context.data.listIndex;
		let something;
		if (result.list)
			something = result.list[index];
		else
			something = result[index];
		if (something) {
			something = something.modelData;
			result = something[fieldName];
		}
	}
	log.debug("useDataProvider %o", result);
	return [result, setStateData];
}