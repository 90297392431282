import { useMemo } from "react";

export function useFieldMeta(meta) {
  const error = useMemo(() => !!(meta.touched && meta.error), [
    meta.error,
    meta.touched
  ]);
  const helperText = useMemo(() => (error ? meta.error : undefined), [
    error,
    meta.error
  ]);
  return [error, helperText];
}
