import React from "react";
import { Typography, TableCellList } from "common";
import { formatUSDCurrency } from "core/utils/formatters";

export function PayDetailsTableCell({
  totalPay = "N/A",
  netPay = "N/A",
  label,
  advancesOtherPay = "N/A",
  payType,
  ok2PayDate = "N/A"
}) {
  const topValue =
    payType === "total"
      ? formatUSDCurrency(totalPay)
      : formatUSDCurrency(netPay);
  const bottomValue =
    payType === "total" ? formatUSDCurrency(advancesOtherPay) : ok2PayDate;

  return (
    <TableCellList>
      <Typography
        color={payType === "total" ? "inherit" : "primary"}
        component="li"
        fontSize="large"
        value={topValue}
      />
      <Typography component="li" label value={label || "N/A"} />
      <Typography
        color={
          payType === "total" && advancesOtherPay < 0 ? "error" : "inherit"
        }
        component="li"
        fontWeight="bold"
        fontSize="medium"
        value={bottomValue}
      />
    </TableCellList>
  );
}
