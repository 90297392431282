import React from "react";
import { ChatBubbleDotsIcon } from "common/SVG/ChatBubbleDots";
import { IndicatorActionButton } from "common";

export function CommentsIndicatorAction({ movementId, commentsCount, index }) {
  return (
    <IndicatorActionButton
      index={index}
      movementId={movementId}
      disabled={!commentsCount}
      color="primary"
      icon={ChatBubbleDotsIcon}
    />
  );
}
