import React from "react";
import classNames from "core/utils/classNames";
import { Typography as MuiTypography } from "@material-ui/core";
import { useStyles } from "./Typography.styles";

export function Typography({
  className = "",
  children = [],
  color = "initial",
  component = null,
  fontWeight = "regular",
  textTransform = "regular",
  fontSize = null,
  wordWrap = false,
  label = false,
  value = null,
  variant = "body2",
  ...restProps
}) {
  const classes = useStyles();
  const customColors = ["light", "main", "dark", "warning"];
  const colorOverride = customColors.includes(color) ? "initial" : color;
  return (
    <MuiTypography
      className={classNames(classes.root, className, {
        [classes.noWrap]: !wordWrap,
        [classes.wordWrap]: wordWrap,
        [classes.fontWeightRegular]: fontWeight === "regular",
        [classes.fontWeightBold]: fontWeight === "bold",
        [classes.textTransformRegular]: textTransform === "regular",
        [classes.textTransformUppercase]: textTransform === "uppercase",
        [classes.textTransformCapitalize]: textTransform === "capitalize",
        [classes.textTransformLowerCase]: textTransform === "lowercase",
        [classes.fontSizeSmall]: fontSize === "small",
        [classes.fontSizeMedium]: fontSize === "medium",
        [classes.fontSizeLarge]: fontSize === "large",
        [classes.fontColorLight]: color === "light",
        [classes.fontColorMain]: color === "main",
        [classes.fontColorWarning]: color === "warning",
        [classes.fontColorDark]: color === "dark",
        [classes.label]: label === true
      })}
      variant={variant}
      component={component}
      color={colorOverride}
      {...restProps}
    >
      {value}
      {children}
    </MuiTypography>
  );
}
