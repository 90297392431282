import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

export const drawerWidth = 240;
export const navHeight = 60;
export const breakPointValues = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1440,
  xl: 1920
};

export const colors = {
  slate: "#F9F9F9",
  mist: "#CDCDCD",
  smoke: "#666666",
  tar: "#000000",
  asphalt: "#212121",
  smog: "#1f1f1f",
  bub: "#FFD24F",
  vibe: "#B620E0",
  dew: "#e7f2fc",
  pantone: "#FF6600"
};

export const successColors = {
  light: "#74a553",
  main: "#458719",
  dark: "#326412"
};

export const errorColors = {
  light: "#f87171",
  main: "#dd2c2c",
  dark: "#b60909"
};

export const warningColors = {
  light: "#ff983f",
  main: "#ff6600",
  dark: "#c43300",
  hover: "#ff983f33"
};

export const primaryColors = {
  light: "#4296e3",
  main: "#137CDD",
  dark: "#054075"
};

export function createTheme({ branding }) {
  const spacing = 6;
  const fontSize = 14;
  const htmlFontSize = 16;

  function pxToRem(size) {
    const coef = fontSize / 14;
    return "".concat((size / htmlFontSize) * coef, "rem");
  }

  function toSpacing(size) {
    return size * spacing;
  }

  return createMuiTheme({
    branding,
    spacing,
    dimensions: {
      drawerWidth,
      navHeight
    },
    breakpoints: {
      values: breakPointValues
    },
    palette: {
      common: colors,
      primary: primaryColors,
      error: errorColors,
      warning: warningColors,
      success: successColors
    },
    typography: {
      button: {
        textTransform: "none"
      }
    },
    mixins: {
      toolbar: {
        height: navHeight
      }
    },
    props: {
      MuiInputLabel: {
        shrink: true
      },
      MuiOutlinedInput: {
        notched: false,
        margin: "dense"
      },
      MuiTextField: {
        variant: "outlined"
      },
      MuiButton: {
        disableElevation: true
      }
    },
    overrides: {
      MuiAppBar: {
        root: {
          backgroundColor: colors.asphalt
        }
      },
      MuiButton: {
        root: {
          fontSize: pxToRem(12),
          fontWeight: 400,
          lineHeight: 1.86,
          borderRadius: 2
        },
        sizeSmall: {
          fontSize: pxToRem(12),
          fontWeight: 400,
          lineHeight: 1.3
        },
        sizeLarge: {
          fontSize: pxToRem(12),
          fontWeight: 400,
          height: 40,
          borderRadius: 4
        },
        startIcon: {
          marginRight: spacing
        }
      },
      MuiDivider: {
        root: {
          backgroundColor: colors.mist
        }
      },
      MuiInput: {
        underline: {
          "&:before": {
            borderBottom: `1px solid ${colors.mist}`
          }
        }
      },
      MuiOutlinedInput: {
        root: {
          marginTop: toSpacing(2) + 2,
          marginBottom: 19.4,
          "&.Mui-error": {
            marginBottom: 0
          },
          "& .MuiSvgIcon-root": {
            width: 20,
            height: 20
          },
          "&$focused $notchedOutline": {
            borderWidth: 1
          }
        },
        input: {
          padding: "8px 0 8px 10px",
          fontSize: pxToRem(14),
          color: colors.smog,
          "&::placeholder": {
            fontSize: pxToRem(12)
          }
        },
        notchedOutline: {
          borderColor: colors.mist
        },
        adornedEnd: {
          paddingRight: 0
        }
      },
      MuiInputLabel: {
        root: {
          color: colors.smoke,
          fontSize: pxToRem(10)
        },
        outlined: {
          "&$shrink": {
            transform: "unset",
            transformOrigin: "unset"
          }
        }
      },
      MuiFormHelperText: {
        contained: {
          marginTop: 0,
          marginBottom: 3,
          marginLeft: 0,
          fontSize: pxToRem(10)
        }
      },
      MuiTableCell: {
        root: {
          padding: `12px 12px 24px 12px`,
          borderBottom: `1px solid ${colors.mist}`
        }
      },
      MuiTablePagination: {
        root: {
          overflow: "unset"
        }
      },
      MuiSvgIcon: {
        root: {
          transition: "none"
        }
      }
    }
  });
}
