import React from "react";
import { TextBox, ComboBox, CheckBox, ReportPanel, DisplayTypes, Panel } from "lib/components";

export default function ParamARAging() {
	const reportType = [{caption: "Details", value: 0}, {caption: "Summary", value: 2}, {caption: "Totals", value: 3}];
	return (
		<ReportPanel name="reportArAging" title="AR Aging Report" reportName="ar/aging" width={500}>
			<ComboBox name="comboReportBy" caption="Report by" fieldName="reportBy" items={["Ship date", "Bill date"]} noRowBreak fillRow/>
			<ComboBox name="comboReportType" caption="Report type" fieldName="detailReportType" items={reportType} fillRow/>
			<TextBox name="textInvoiceCutoff" caption="Invoice cutoff date" fieldName="cutoffDate" default="t" displayType={DisplayTypes.DATE} noRowBreak fillRow/>
			<TextBox name="textTrxCutoff" caption="Payment, credit, debit cutoff date" default="t" fieldName="paymentCutoffDate" displayType={DisplayTypes.DATE} fillRow/>
			<TextBox name="textAgeDate" caption="Age date" fieldName="ageDate" default="t" displayType={DisplayTypes.DATE} noRowBreak fillRow/>
			<Panel fillRow/>
			<CheckBox name="checkPastDue" caption="Print past due balances only" fieldName="pastDueOnly"/>
			<CheckBox name="checkOpenCredits" caption="Include open credits in total" fieldName="show_open_credits" default={true}/>
		</ReportPanel>
	);
}