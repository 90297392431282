import React from "react";
import { ListItem } from "@material-ui/core";
import { Label, showDialog } from "./";
import { getLogger } from "lib/util";
import showSlideout from "./Slideout";

const log = getLogger("lib.components.MainMenuItem");
const modules = {};
modules["lme"] = require.context("lme", true);
modules["lib"] = require.context("lib", true);

export default function MainMenuItem(props) {
	log.debug("Menu item %o", props);
	let onClick = () => openMenuItem(props.id, props.caption, props.type, props.onClose);
	return (
		<ListItem button onClick={onClick} variant="outlined" color="primary">
			<Label image={props.image} caption={props.caption} />
		</ListItem>
	);
}

function openMenuItem(id, caption, type, onClose) {
	onClose();
	let component = getComponentForIdentifer(id);
	log.debug("Open menu item %o", component);
	if (type === "D") // dialog
		showDialog(caption, component);
	else if (type === "S") // slideout from side {}
		showSlideout(caption, component);
	else {
		// make the Router navigate to this component - do we need URL piece?  Should we have made it a Link in the ListItem? 
	}
}

function getComponentForIdentifer(id) {
	let index = id.indexOf(".");
	if (index < 0) {
		log.error("Invalid menu item id %s", id);
		return null;
	}
	let module = modules[id.substring(0, index)];
	if (module == null) {
		log.error("Module %s not found for menu id %s", module, id);
		return null;
	}
	let path = id.substring(index).replace(/\./g, "/");
	let file = "." + path + ".jsx";
	try {
		let result = module(file);
		log.debug("Component for %s is %o", id, result);
		return result.default();
	}
	catch (err) {
		log.info("Module contents %o", module.keys());
		log.error("Could not find menu item %s at path %s", id, file);
		log.error(err);
	}
}