import { fetchModelData } from "./ModelUtil";
import getLogger, { setLogConfig } from "./Logger";
import { setDeniedItems } from "./Permissions";

const log = getLogger("lib.util.MainPageContext");

loadUnauthenticatedContext();

const mainPageContext = {
  uiContext: {},
  userSettings: {},
  serverSettings: {}
};

export function getUIContext() {
  return mainPageContext.uiContext;
}

export function getCompanySettings() {
  if (mainPageContext.companySettings == null) {
    loadUnauthenticatedContext();
    return {};
  }
  else
    return mainPageContext.companySettings;
}

export function getUserSettings() {
  return mainPageContext.userSettings;
}

export function getServerSettings() {
  return mainPageContext.serverSettings;
}

export function loadUnauthenticatedContext(onComplete, setLoading, queryString = "") {
  fetchModelData(`lme/unauth-settings${queryString}`, null, (data) => {
    data = data[0].modelData;
    if (data.companySettings != null)
      mainPageContext.companySettings = { ...mainPageContext.companySettings, ...data.companySettings[0].modelData };

    mainPageContext.serverSettings.modelMaxSize = data.modelMaxSize;
    if (data.logConfig != null)
      setLogConfig(data.logConfig);
    if (data.permissions != null) // permissions will only be populated if we passed a token on queryString
      setDeniedItems(data.permissions);
    if (onComplete)
      onComplete(mainPageContext);
    log.debug("Main context %o", data);
  }, setLoading);
}

export function loadAuthenticatedContext(onComplete, setLoading) {
  fetchModelData("lme/auth-settings", null, (data) => {
    data = data[0].modelData;
    mainPageContext.companySettings = { ...mainPageContext.companySettings, ...data };
    delete mainPageContext.companySettings.license;
    delete mainPageContext.companySettings.userSettings;
    delete mainPageContext.companySettings.permissions;
    if (data.permissions != null)
      setDeniedItems(data.permissions);
    if (data.userSettings != null)
      mainPageContext.userSettings = data.userSettings[0].modelData;
    log.debug("Main context %o", data);
    if (onComplete)
      onComplete(mainPageContext);
  }, setLoading);
}
