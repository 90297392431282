import { buildAPIUrl } from "core/utils/urls";
import { API_PATHS } from "core/config/urls";
import { mapAvailableOrders } from "core/mappers/availableOrders";
import { ORDERS_TYPE_PENDING, HTTP_REQUEST_POST } from "core/utils/constCapsule";
import { resolveJSONResponse, fetchBuffer, fetch } from "core/utils/fetch";
import { useQuery } from "react-query";

const queryOptions = {
  staleTime: Infinity
};

const manualQueryOptions = {
  staleTime: Infinity,
  manual: true
};

const getOfferQuery = async (offerId) => {
  if (!offerId) return undefined;
  const res = await fetch({
    url: buildAPIUrl({
      path: API_PATHS.CARRIER_AVAILABLE_ORDERS,
      queryParams: { type: ORDERS_TYPE_PENDING, offerId }
    }),
    transformResponse: mapAvailableOrders
  });
  return resolveJSONResponse(res).find((offer) => offer.offerId === offerId);
};

export const useGetOfferQuery = (offerId) => {
  const query = useQuery([offerId, "offer"], getOfferQuery, queryOptions);
  return query;
};

const acceptOfferQuery = async (offerId) => {
  const response = await fetch({
    url: buildAPIUrl(API_PATHS.ACCEPT_OFFER),
    method: HTTP_REQUEST_POST,
    data: { offerId }
  });
  return resolveJSONResponse(response);
};

export const useAcceptOfferQuery = (offerId) => {
  const { refetch: handleAcceptOffer, ...restQuery } = useQuery(
    [offerId, "acceptOffer"],
    acceptOfferQuery,
    manualQueryOptions
  );

  return {
    ...restQuery,
    handleAcceptOffer
  };
};

const declineOfferQuery = async (offerId, declineReason) => {
  const response = await fetch({
    url: buildAPIUrl(API_PATHS.DECLINE_OFFER),
    method: HTTP_REQUEST_POST,
    data: { offerId, declineReason }
  });
  return resolveJSONResponse(response);
};

export const useDeclineOfferQuery = (offerId, reason) => {
  const { refetch, ...restQuery } = useQuery(
    [offerId, reason, "declineOffer"],
    declineOfferQuery,
    manualQueryOptions
  );

  return {
    ...restQuery,
    handleDeclineOffer: refetch
  };
};

const getLegalTermsQuery = async () => {
  const response = await fetchBuffer(buildAPIUrl(API_PATHS.LEGAL_TERMS));
  return atob(response.data);
};

export const useLegalTermsQuery = () => {
  const { refetch: handleGetLegalTerms, ...restQuery } = useQuery(
    "legalTerms",
    getLegalTermsQuery,
    queryOptions
  );
  return {
    ...restQuery,
    handleGetLegalTerms
  };
};

const getOrderTermsQuery = async (movementId) => {
  const response = await fetchBuffer(
    buildAPIUrl({
      path: API_PATHS.ORDER_TERMS_GENERATE,
      queryParams: { movementId }
    })
  );
  return atob(response.data);
};

export const useOfferOrderTermsQuery = ({ movementId = null } = {}) => {
  const { refetch: handleGetOrderTerms, ...restQuery } = useQuery(
    [movementId, "orderTerms"],
    getOrderTermsQuery,
    queryOptions
  );

  return {
    ...restQuery,
    handleGetOrderTerms
  };
};
