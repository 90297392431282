import React from "react";
import { IndicatorActionButton } from "common";
import { connect } from "react-redux";
import { getActiveCallinsByOrderId } from "core/store/selectors/OrdersSelectors";
import { ReticleIcon } from "common/SVG";

function CallinsIndicatorActionPresentation({ movementId, callins = [], index = 0 }) {
  return (
    <IndicatorActionButton
      color="primary"
      index={index}
      movementId={movementId}
      disabled={!callins.length}
      icon={ReticleIcon}
    />
  );
}

const mapStateToProps = (state, { id }) => ({
  callins: getActiveCallinsByOrderId(state, id)
});

export const CallinsIndicatorAction = connect(mapStateToProps)(CallinsIndicatorActionPresentation);
