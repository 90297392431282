import React, { useState } from "react";
import { Button as MUIButton, CircularProgress} from '@material-ui/core';
import {Image, useComponentContext, Component, ButtonActions, showSnackbar } from ".";
import { getComponentName } from "./ComponentUtil";
import Popup from "./Popup";
import { submitModel, searchModel, getLogger } from "lib/util";
import { makeStyles } from "lib/util";

const log = getLogger("lib.components.Button");

const useStyles = makeStyles((theme) => ({
	button : props => ({
		width: "100%",
		height: "100%",
		"& .MuiButton-root": {
			padding: 0,
			borderRadius:6,
		},
 		"& .MuiButton-text": {
			padding: 0,
		},
		"& .MuiButton-label": {
			marginTop: props.labelVShift // I'm doing a lot of things I'm not proud of
		}
	}),
	caption: props => ({
		paddingTop:5
	}),
	progress: {position: "absolute"},
	image: {
		margin: 0
	}
}));

export default function Button({color="default", fontSize, overrideImageClassName, variant, dropDown, dropDownAlign, dropDownClassName, ...props}) {
	const context = useComponentContext();
	let [dropDownAnchor, setDropDownAnchor] = useState(null);
	let [loading, setLoading] = useState();
	let classes = useStyles({labelVShift: props.labelVShift});
	let loadingValue = loading;
	if (props.loading !== undefined)
		loadingValue = props.loading;
	let compProps = {};
	if (!isMuiColor(color))
		compProps.color = color;
	let progress;
	if (loadingValue)
		progress = <CircularProgress size={24} className={classes.progress} />
	let popup = getPopup(dropDown, dropDownAnchor, dropDownAlign, dropDownClassName, setDropDownAnchor);
    return (
        <Component {...props} {...compProps}>
			{ getButton(context, overrideImageClassName, props, classes, color, fontSize, variant, progress, loadingValue, setLoading, dropDown, setDropDownAnchor) }
			{popup}
        </Component>
    );
}

function getPopup(dropDown, dropDownAnchor, dropDownAlign, dropDownClassName, setDropDownAnchor) {
	if (dropDownAnchor != null && dropDown != null) {
		return (
			<Popup anchorEl={dropDownAnchor} anchorAlign={dropDownAlign}
				className={dropDownClassName} onClose={()=>setDropDownAnchor(null)} components={dropDown} />
		);
	}
	return undefined;
}

function isMuiColor(color) {
	return color === "primary" || color === "default" || color === "secondary";
}

function getButton(context, overrideImageClassName, props, classes, color, fontSize, variant, progress, loading, setLoading, dropDown, setDropDownAnchor) {
	if (variant === undefined) {
		if (props.caption == null)
			variant = "text";
		else
			variant = "outlined";
	}
	let buttonProps = {};
	if (props.action) {
		buttonProps.type = "submit"
	}
	if (color && !isMuiColor(color))
		color = "inherit";
	if (fontSize) {
		if (!props.buttonStyle)
			props.buttonStyle = {};
		props.buttonStyle.fontSize = fontSize;
	}
	return (
		<MUIButton variant={variant} style={props.buttonStyle} className={classes.button} disabled={props.enabled === false || loading}
			color={color} href={props.href} {...buttonProps} onClick={(event) => handleClick(event, context, props, setLoading, dropDown, setDropDownAnchor)}>
			{progress}
			{getContent(props, classes, overrideImageClassName)}
		</MUIButton>
	);
}

function handleClick(event, context, props, setLoading, dropDown, setDropDownAnchor) {
	log.debug("Button %s clicked", getComponentName(context, props.name));
	if (dropDown != null)
		setDropDownAnchor(event.currentTarget);
	else if (props.action === ButtonActions.SUBMIT)
		submitModel(context, setLoading,
			(response) => modelSubmitted(response, props),
			(reason) => submitError(reason));
	else if (props.action === ButtonActions.SEARCH)
		searchModel(context, setLoading);
	if (props.onClick)
		props.onClick();
	event.stopPropagation();
}

function submitError(reason) {
	log.info("Error submitting form %s", reason);
	showSnackbar("Error submitting this page.");
}

function modelSubmitted(response, props) {
	showSnackbar(response.message);
}

function getCaption(props) {
    let result = props.caption;
    if (result == null && props.action != null)
        result = props.action.caption;
    return result;
}

function getImage(props, classes, overrideImageClassName) {
	if (props.image != null)
	    return <Image className={overrideImageClassName != null ? overrideImageClassName : classes.image} image={props.image} fill={props.imageFill} marginRight={props.imageSpacing} width={props.imageWidth} height={props.imageHeight}/>
}

function getContent(props, classes, overrideImageClassName) {
    let caption = getCaption(props);
	let result = caption;
    if (props.image != null) {
        // add prop for text/image position (so image can be above/below/left/right of caption)
        result = (
            <React.Fragment>
				{getImage(props, classes, overrideImageClassName)}
				<span className={classes.caption}>{caption}</span>
            </React.Fragment>
        );
    }
    return result;
}
