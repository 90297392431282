import React from "react";
import { Typography, TableCellList } from "common";

export function CommentsDetailsTableCell({ comments = [] }) {
  return (
    <TableCellList>
      <Typography component="li" label>
        Comments
      </Typography>
      {!!comments.length &&
        comments.map((comment) => (
          <Typography key={comment} component="li" value={comment} />
        ))}
      {!comments.length && <Typography value="N/A" />}
    </TableCellList>
  );
}
