import React from "react";
import { Typography } from "common";
import { formatUSDCurrency } from "core/utils/formatters";

export function BalanceDetailsTableCell({ balance: { value, symbol } = {} }) {
  return (
    <Typography
      component="div"
      fontSize="large"
      fontWeight="bold"
      color="primary"
      value={symbol && value ? `${symbol}${formatUSDCurrency(value, { prefix: false })}` : "N/A"}
    />
  );
}
