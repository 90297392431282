import { isEmptyString } from "lib/util/StringUtil";
import { parse, format, differenceInDays, differenceInMinutes, differenceInSeconds, differenceInMilliseconds } from "date-fns";
import { getUserSettings } from ".";

const dtf = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false, fractionalSecondDigits: 3 })
const dateKeyWords = ["T", "N", "ME", "WE", "QE", "YE", "M", "W", "Q", "Y"];

// can't use a Logger in this class because Logger uses Date!  Not sure if it's worth breaking the dependency.
const parseContextDate = new Date();

export function getDateTimeStringWithMillis(date) {
	// fractional doesn't seem to be working, and would prefer in format of YYYY-MM-dd HH:mm:ss.sss
	// need to drop dtf (who comes up with these variable names?) and use date-fns 
	return dtf.format(date);
}

export function dateDiff(which, date1, date2) {
	if ("days" === which)
		return differenceInDays(date1, date2);
	else if ("minutes" === which)
		return differenceInMinutes(date1, date2);
	else if ("seconds" === which)
		return differenceInSeconds(date1, date2);
	else if ("millis" === which)
		return differenceInMilliseconds(date1, date2);
	else
		throw new Error("Unrecognized type of date diff " + which);
}

export function parseDateTime(value) {
	if (value == null)
		return null;
	const result = parse(value, "MM/dd/yyyy HHmm", parseContextDate);
	return result;
}

export function getDateString(date) {
	if (date == null)
		return null;
	const year = date.getFullYear();
	if (isNaN(year))
		return null;
	let month = (1 + date.getMonth()).toString();
	month = month.length > 1 ? month : '0' + month;
	let day = date.getDate().toString();
	day = day.length > 1 ? day : '0' + day;
	return month + '/' + day + '/' + year;
}

export function formatDateTime(value, formatString) {
	if (value == null)
		return null;
	if (isNaN(value.getTime()))
		return null;
	// console.log("formatting %o with %s", value, formatString);
	if (formatString == null)
		formatString = "MM/dd/yyyy HH:mm";
	try {
		return format(value, formatString);
	}
	catch (reason) {
		return null;
	}
}

export function getDateFormat(which, overrideFormat) {
	if (which === "datetime") {
		if (overrideFormat === "long")
			return "iiii MMMM dd, yyyy hh:mma";
		else
			return getUserDateTimeFormat();
	} else if (which === "date") {
		if (overrideFormat === "long")
			return "iiii MMMM dd, yyyy";
		else
			return getUserDateFormat();
	}
	else if (which === "time") {
		if (overrideFormat === "long")
			return "hh:mma";
		else
			return getUserTimeFormat();
	}
	else
		throw new Error("Unrecognized parameter to getDateFormat " + which);
}

export function getUserDateFormat() {
	return getUserSettings().dateFormat;
}

export function getUserTimeFormat() {
	return getUserSettings().timeFormat;
}

export function getUserDateTimeFormat() {
	return getUserSettings().dateTimeFormat;
}

export function parseDateWithKeywords(value) {
	let result = value;
	if (result == null)
		return result;
	value = value.toUpperCase();
	for (let i = 0; i < dateKeyWords.length; i++) {
		if (value.indexOf(dateKeyWords[i]) === 0) {
			let result = new Date();
			let aftKeyword = value.substring(dateKeyWords[i].length, value.length).trim();
			let valueToAdd = 0;
			if (!isEmptyString(aftKeyword))
				valueToAdd = parseInt(aftKeyword);
			if (i === 2) // ME
				result.setDate(daysInMonth(result.getMonth(), result.getYear()));
			else if (i === 3) // WE
				result.setDate(result.getDate() + (6 - result.getDay()));
			else if (i === 4) { //QE
				let quarterEndMonth = ((result.getMonth() / 3) * 3) + 2;
				result.setMonth(quarterEndMonth);
				result.setDate(daysInMonth(quarterEndMonth, result.getFullYear()));
			}
			else if (i === 5) { // YE
				result.setMonth(11);
				result.setDate(31);
			}
			else if (i === 6) // M
				result.setDate(1);
			else if (i === 7) // W
				result.setDate(result.getDate() - result.getDay());
			else if (i === 8) { // Q 
				result.setMonth((result.getMonth() / 3) * 3);
				result.setDate(1);
			}
			else if (i === 9) { // Y
				result.setDate(1);
				result.setMonth(0);
			}
			if (valueToAdd !== 0)
				result.setDate(result.getDate() + valueToAdd);
			return getDateString(result);
		}
	}
	return parseDateTime(result);
}

export function daysInMonth(month, year) {
	return new Date(year, month + 1, 0).getDate();
}