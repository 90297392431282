import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  root: {
    margin: "3rem 1rem",
    borderRadius: 4,
    background: "#f6f8fa",
    boxShadow: "0 0 1px  #eee inset"
  },
  header: {
    textTransform: "uppercase",
    fontSize: 11,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    fontWeight: 500,
    padding: ".5rem",
    background: "#555",
    color: "#fff",
    letterSpacing: "1px"
  },
  state: {
    fontSize: ".85rem",
    padding: ".25rem .5rem",
    overflowX: "scroll"
  }
}));
