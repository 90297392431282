import React from "react";
import { Typography } from "common";

export function LocationDetailsTableCell({ dataKey, ...rowData }) {
  const { city, state } = rowData[dataKey] || {};
  return (
    <Typography
      component="div"
      fontSize="large"
      value={city && state ? `${city}, ${state}` : "N/A"}
    />
  );
}
