import React from "react";
import { getLogger, getCompanySettings } from "lib/util";
import { isPermissionsAllowed } from "lib/util/Permissions";
import {Panel, Button, Image, Label } from "lib/components";
import { showLegacyIM } from "lme/dsp/LegacyInternetModule";

const log = getLogger("lme.ar.CustomerPortal.LegacyButton");

const buttonColor = "rgb(69, 135, 25)"; // really should be some "tertiary" theme to a button 
export function LegacyButton(props) {
	const settings = getCompanySettings();
	settings.isTL = true;
	let items = [];
	let tlOrder = false, ltlOrder = false, tlQuote = false, ltlQuote = false;
	if (isPermissionsAllowed("InternetModule", "InternetModulePickupEntry")) {
		if (settings.isAssetLTL)
			ltlOrder = true;
	}
	if (isPermissionsAllowed("InternetModule", "InternetModuleQuoteEntry")) {
		if (settings.isAssetLTL || settings.isLTL)
			ltlQuote = true;
	}
	if (isPermissionsAllowed("InternetModule", "InternetModuleOrderEntry")) {
		tlOrder = true;
    	tlQuote = true;
	}

	if (tlOrder && ltlOrder) {
		items.push({ caption: "Add TL Order", dropDownText: "Order", image: "ftl-dry-van", page: "vaadintlorderentry" });
		items.push({ caption: "Add LTL Order", dropDownText: "Order", image: "ltl-pallet", page: "vaadinorderentry" });
	}
	else if (tlOrder)
		items.push({ caption: "Add Order", dropDownText: "Order", image: "ftl-dry-van", page: "vaadintlorderentry" });
	else if (ltlOrder)
		items.push({ caption: "Add Order", dropDownText: "Order", image: "ltl-pallet", page: "vaadinorderentry" });
	if (tlQuote && ltlQuote) {
		items.push({ caption: "Add TL quote", dropDownText: "Quote", image: "ftl-dry-van", page: "vaadintlquoteentry" });
		items.push({ caption: "Add LTL quote", dropDownText: "Quote", image: "ltl-pallet", page: "vaadinquoteentry" });
	}
	else if (tlQuote)
		items.push({ caption: "Add quote", dropDownText: "Quote", image: "ftl-dry-van", page: "vaadintlquoteentry" });
	else if (ltlQuote)
		items.push({ caption: "Add quote", dropDownText: "Quote", image: "ltl-pallet", page: "vaadinquoteentry" });
	log.debug("Legacy settings %o items %o", settings, items);
	if (items.length === 0)
		return null;
	let dropDownButton;
	if (items.length > 1) {
		let dropDown = [];
		for (let i = 0; i < items.length; i++) {
			dropDown.push(
				<Panel onClick={() => showLegacyIM(items[i].caption, "/apps/" + items[i].page)}>
					<Image image={items[i].image} width={140} fillRow noRowBreak />
					<Label caption={items[i].dropDownText} fontSize="xlarge" marginLeft={32} color="#4E7E4E"/>
				</Panel>
			);
		}
		dropDownButton = (
			<Button 
				image="dropdown-gray.svg" 
				imageHeight={24} 
				buttonStyle={{padding:"4px 0px 4px 0px", borderBottomLeftRadius: 0, borderTopLeftRadius: 0}} 
				variant="outlined" 
				marginLeft={-5}
				height={40}
				dropDown={dropDown} 
				dropDownAlign="right" 
				color={buttonColor}
				{...props}
				noRowBreak 
			/>
		); 
	}
	return (
		<React.Fragment>
			<Button
				caption={items[0].caption}
				buttonStyle={{borderBottomRightRadius: 0, borderTopRightRadius: 0}} 
				variant="outlined"
				width={140}
				height={40}
				fontSize="14px"
				noRowBreak
				color={buttonColor}
				onClick={() => showLegacyIM(items[0].caption, "/apps/" + items[0].page)} 
			/>
			{dropDownButton}
		</React.Fragment>
	);
}