/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useField } from "formik";
import { useFieldMeta, usePrevious } from "core/hooks";
import { Form } from "common/Form";
import { formatBytes } from "core/utils/common";
import * as Yup from "yup";
import { FormActions } from "common/FormActions";
import { FormikDebugger } from "core";
import { initialState, FileUploaderContext } from "./context";
import { useStyles } from "./FileUploader.styles";
import { FileList } from "./FileList";
import { FileDropzone } from "./FileDropzone";
import { append, remove, update } from "./utils";
import { FileTypes } from "./DocTypes";

export function FileUploaderPresentation({
  docTypes = [],
  whitelist = [],
  maxSize,
  defaultDocType
}) {
  const classes = useStyles();
  const [files, _setFiles] = useState(initialState.files);
  const [_, meta, helpers] = useField("files");
  const [error, helperText] = useFieldMeta(meta);

  const setFiles = useCallback((_files) => _setFiles(append(_files, defaultDocType)), []);
  const removeFile = useCallback((index) => _setFiles(remove(index)), []);
  const setDocType = useCallback(
    (file, payload) => {
      _setFiles(update(file, payload));
      // Force update Formik since useEffect won't recognize a
      // change due to the data being File types.
      helpers.setValue(files);
    },
    [files]
  );

  // Report to Formik when file changes happen.
  useEffect(() => {
    helpers.setTouched(!!files.length);
    helpers.setValue(files);
  }, [files]);

  const memoedValue = useMemo(
    () => ({
      ...initialState,
      maxSize,
      files,
      whitelist,
      docTypes,
      setFiles,
      removeFile,
      setDocType
    }),
    [files, maxSize, docTypes, whitelist]
  );
  return (
    <div className={classes.root}>
      <FileUploaderContext.Provider value={memoedValue}>
        <FileTypes />
        <FileDropzone />
        <FileList error={error} helperText={helperText} />
      </FileUploaderContext.Provider>
    </div>
  );
}

export function FileUploader({
  onSubmit,
  onCancel,
  maxSize,
  defaultDocType = "Other",
  debugger: enableDebugger = false,
  ...restProps
}) {
  const validationSchema = Yup.object().shape({
    files: Yup.mixed().test(
      "files",
      `One or more files exceeds the max file size limit of ${formatBytes(maxSize)}`,
      (files) => {
        const largeFiles = files.find((file) => file.size > maxSize);
        if (largeFiles) return false;
        return true;
      }
    )
  });

  return (
    <Form initialValues={{ files: [] }} validationSchema={validationSchema} onSubmit={onSubmit}>
      <FileUploaderPresentation maxSize={maxSize} defaultDocType={defaultDocType} {...restProps} />
      <FormActions onClose={onCancel} submitLabel="Save" />
      {enableDebugger && <FormikDebugger />}
    </Form>
  );
}
