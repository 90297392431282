import React from "react";
import { Typography, TableCellList } from "common";
import { capitalCase } from "change-case";
import { ORDERS_TYPE_ALL } from "core/utils/constCapsule";
import { connect } from "react-redux";
import { formatUSDCurrency } from "core/utils/formatters";
import { USER_CONTROLS, CONTROL_CHARS } from "core/config/controls";
import { doesControlNotMatch } from "core/store/selectors/UserSelectors";

function AdditionalDetailsTableCellPresentation({
  distance,
  weight,
  ltl,
  rate,
  rateIsVisible,
  ordersType,
  disableRate = false
}) {
  const renderRate =
    (ordersType === ORDERS_TYPE_ALL && rateIsVisible) || ordersType !== ORDERS_TYPE_ALL;

  return (
    <TableCellList>
      <Typography fontSize="large" value={distance ? `Distance: ${distance} Mi` : "N/A"} />
      <TableCellList row>
        <li>
          <Typography label component="div" value="Weight" />
          <Typography fontWeight="bold" fontSize="medium" component="div" value={weight || "N/A"} />
        </li>
        <li>
          <Typography label component="div" value="LTL" />
          <Typography
            fontWeight="bold"
            fontSize="medium"
            component="div"
            value={ltl ? capitalCase(`${ltl}`) : "N/A"}
          />
        </li>
        {renderRate && !disableRate && (
          <li>
            <Typography label component="div" value="Rate" />
            <Typography
              fontWeight="bold"
              fontSize="medium"
              component="div"
              value={formatUSDCurrency(rate, { prefix: false }) || "N/A"}
            />
          </li>
        )}
      </TableCellList>
    </TableCellList>
  );
}

const mapStateToProps = (state) => ({
  rateIsVisible: doesControlNotMatch(state, USER_CONTROLS.INCLUDE_RATE_INFO, CONTROL_CHARS.N)
});

export const AdditionalDetailsTableCell = connect(mapStateToProps)(
  AdditionalDetailsTableCellPresentation
);
