import React from "react";
import { TableCell, TableRow as MUITableRow } from '@material-ui/core';
import { DataContext } from "lib/components/Context";
import { useStyles } from "./TableStyles";
import ExpandRowCell from "./ExpandRowCell";
import { Label, Panel, Switch } from "lib/components";
import { getChildArray } from "../ComponentUtil";

export default function TableRow({rowIndex, expandedRowIndex, rowIndexAfterFilter, setExpandedRowIndex, data, ...props}) {
	let classes = useStyles();
	let cells = [];
	const columns = getChildArray(props.children);
	for (let i = 0; columns != null && i < columns.length; i++)
		if (columns[i] != null && (columns[i].props.visible === undefined || columns[i].props.visible === true))
			cells.push(createCell(classes, rowIndex, i, props, columns[i].props));
    if (props.expandComponent)  // probably will need a property on each row that determines whether or not row expansion is enabled for that row
		cells.push(<ExpandRowCell key="expand" expandComponent={props.expandComponent} rowIndex={rowIndex} expandedRowIndex={expandedRowIndex} setExpandedRowIndex={setExpandedRowIndex} />);
	let rowClassName, expansionRow;
	// passing the data in props.expandComponent() is not right at all - should use Context, but context doesn't make it there (since it's probably not an "offical" mounted component) 
    if (rowIndex === expandedRowIndex && props.expandComponent) {
		rowClassName = classes.expandedRow + " " + classes.activeRow;
		let expandComponent = props.expandComponent({data:data, listIndex: rowIndex});
		let rowStyle;
		if (expandComponent.props.backgroundColor)
			rowStyle = {backgroundColor: expandComponent.props.backgroundColor};			 
		expansionRow = (
			<MUITableRow key={"expansion-" + rowIndex} className={classes.expansion} style={rowStyle}>
				<TableCell colSpan={cells.length} className={classes.expansionCell}>
					{expandComponent}
				</TableCell>
			</MUITableRow>
        );
    }
	// using the Collapse was a little jittery with a table with lots of data 
	// if (props.expandComponent) {
	// 	let expandRowClassName = classes.expansion;
	// 	if (rowIndex === expandedRowIndex)
	// 		rowClassName = classes.expandedRow + " " + classes.activeRow;
	// 	else
	// 		expandRowClassName += " " + classes.hidden;
		
	// 	expansionRow = (
	// 			<MUITableRow key={"expansion-" + rowIndex} className={expandRowClassName}>
	// 					<TableCell colSpan={cells.length} className={classes.expansionCell}>
	// 						<Collapse in={rowIndex===expandedRowIndex} className={classes.collapse}>
	// 							{props.expandComponent()}
	// 						</Collapse>
	// 					</TableCell>

	// 			</MUITableRow>
    //     );
	// }
	let rowColorProps = getRowColorProps(classes, props, rowIndexAfterFilter);
	let extraProps;
	if (rowColorProps && rowColorProps.className)
		rowClassName += " " + rowColorProps.className;
	else
		extraProps = rowColorProps;
    return (
        <DataContext.Provider key={"context-" + rowIndex} value={{name: props.modelName, list: data, listIndex: rowIndex}}>
            <MUITableRow key={"row-" + rowIndex} className={rowClassName} {...extraProps}> 
				{cells}
            </MUITableRow>
			{ expansionRow }
        </DataContext.Provider>
    );
}

function getRowColorProps(classes, props, rowIndex) {
	let result = null;
	if (props.alternateRowColor && rowIndex % 2 === 0) {
		if (typeof props.alternateRowColor === "boolean")
			result = {className: classes.defaultAlternateRow};
		else
			result = {style: {backgroundColor: props.alternateRowColor}};
	}	
	return result;
}


function createCell(classes, rowIndex, colIndex, tableProps, colProps) {
	let style = getCellStyleFromTableProps(tableProps);
	if (colProps.width)
		style.width = colProps.width;
    let passProps = {...colProps};
	delete passProps.caption;
	let key = "cell-" + rowIndex + "-" + colIndex;
	let spacingProp = {marginTop: "24px"};
	if (tableProps.rowSpacing != null) {
		spacingProp.marginBottom = tableProps.rowSpacing;
		if (rowIndex > 0)
			spacingProp.marginTop = tableProps.rowSpacing;
	}
	let contents;
    if (colProps.fieldName) {
		if (colProps.checkable)
			contents = <Switch {...passProps} {...spacingProp} />
        else
            contents = <Label {...passProps} {...spacingProp} marginRight={0} fillRow />
	}
	else 
		contents = colProps.children;
	if (contents)
        return (
            <TableCell key={key} className={classes.tableCell} style={style}>
                <Panel className={classes.tableCellPanel} {...spacingProp} margin={0} >
                    {contents}
                </Panel>
            </TableCell>
        );
}

function getCellStyleFromTableProps(tableProps) {
    let style = {};
    if (tableProps.rowSpacing != null) {
		style.paddingBottom = tableProps.rowSpacing + "px";
		style.paddingTop = tableProps.rowSpacing + "px";
	}
    if (tableProps.showLinesBetweenRows === false)
		style.borderBottom = "none";
	if (tableProps.cellVerticalAlign != null)
		style.verticalAlign = tableProps.cellVerticalAlign;
    return style;
}