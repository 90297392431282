import React from "react";
import { Typography, TableCellList } from "common";

export function ExchangeeDetailsTableCell({ dataKey, label, ...rowData }) {
  const { city = null, state = null, date = null, time = null } = rowData[dataKey] || {};
  return (
    <TableCellList>
      <Typography
        component="li"
        color="primary"
        textTransform="uppercase"
        fontWeight="bold"
        fontSize="large"
        value={city && state ? `${city}, ${state}` : "N/A"}
      />
      <Typography component="li" label value={label || "N/A"} />
      <Typography component="li" fontWeight="bold" fontSize="medium" value={date || "N/A"} />
      <Typography component="li" fontWeight="bold" fontSize="medium" value={time || "N/A"} />
    </TableCellList>
  );
}
