import React from "react";
import { Tab, Panel, Label, Tabset, Map, useData } from "lib/components";
import { TableStopDetail } from "./TableStopDetail";
import { PanelAdditionalOrderDetails } from "./PanelAdditionalOrderDetails";
import { getSVG } from "lib/components/Image";
import { getLogger } from "lib/util";
import { TableImagingList } from "lme/imaging/TableImagingList";
import { PanelLTLDetails } from "./PanelLTLDetails";
import { getDataFromContext } from "lib/util/ModelUtil";

const log = getLogger("lme.ar.CustomerPortal.PanelCustomerOrderDetail");

export default function PanelCustomerOrderDetail({unauthenticated = false, setLoading, ...props}) {
	let data = useData();
	log.debug("Order detail %o", props);
	let isLTL = false;
	if (props && props.data) // really bad to pass data this way - workaround for data context not being available here currently.  See TableRow.jsx.
		data = props.data[props.listIndex].modelData;
	isLTL = data.isLtl === true && !unauthenticated;
	log.debug("Incoming data %o ", data);
	let modelName = "ar/customer-order-detail?search={orders.id}";
	if (props.fieldName != null)
		modelName = undefined;
	return (
		<Panel modelName={modelName} fieldName={props.fieldName} 
			setLoading={setLoading} marginTop={24} 
			marginRight={0} marginLeft={-4} autoSearch fillRow  
		>
			<Tabset variant="outlined" widthFillWeight={3} noRowBreak marginRight={0} fillHeight minHeight={520}>
				<Tab caption="Stop details" activeImage="check-with-blue-circle" inactiveImage="check-with-gray-circle" >
					<Label fieldName="eta_label" noRowBreak fontBold fontSize="large" />
					<Label fieldName="eta_date_time" displayType="datetime" format="long" fontBold fontSize="large" />
					<TableStopDetail height={300} fieldName="stops" border="none" fillRow />
				</Tab>
				<Tab caption="Map view" activeImage="pin-outlined-blue" inactiveImage="pin-outlined-gray">
					<Label fieldName="eta_label" noRowBreak fontBold fontSize="large" />
					<Label fieldName="eta_date_time" displayType="datetime" format="long" fontBold fontSize="large" />
					<Map name="map-order-detail" fieldName="positions"
						captionField="sequence" imageField="image" captionColorField="caption_color" tooltipField="descr" 
						latField="latitude" longField="longitude" 
						setPinProps={setPinProps} clusterPins={false} 
						height={400} fillRow fillHeight />
				</Tab>
				<Tab caption="Images" activeImage="picture-outlined-blue" inactiveImage="picture-outlined-gray" visible={shouldShowImagesTab}>
					<TableImagingList />
				</Tab>
				<Tab caption="LTL details" activeImage="ltl-package-blue" inactiveImage="ltl-package-gray" visible={isLTL}>
					<PanelLTLDetails />
				</Tab>
			</Tabset>
			<PanelAdditionalOrderDetails isLTL={isLTL} data={props.data} visible={!unauthenticated}  
				listIndex={props.listIndex} marginTop={56} marginLeft={0} paddingTop={4} 
				borderTopWidth={1} borderTopColor="#CDCDCD"
			/>
		</Panel>
	);
}

function setPinProps(pin) {
	const status = pin.data["status"];
	if (status === "D") {
		pin.image = getSVG("map-pin-filled");
		pin.fontColor = '#FFFFFF';
	}
	else if (status === "A") {
		pin.image = getSVG("map-pin-outlined");
		pin.fontColor = '#000000';
	}
}

function shouldShowImagesTab(context) {
	let data = getDataFromContext(context);
	return data && data["images"] != null;
}