import { useState } from "react";
import { removeSnackbar } from "core/store/actions/SnackbarActions";
import { getSnackbars } from "core/store/selectors/SnackbarSelectors";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

function NotifierPresentation({
  snackbars = [],
  enqueueSnackbar: notistackEnqueueSnackbar,
  removeSnackbar: dispatchRemoveSnackbar
}) {
  const [displayed, setDisplayed] = useState([]);
  snackbars.forEach(({ key = "", message, options }) => {
    setTimeout(() => {
      // If snackbar already displayed, abort
      if (displayed.includes(key)) return;
      // Display snackbar using notistack
      notistackEnqueueSnackbar(message, options);
      // Add snackbar's key to the local state
      setDisplayed([...displayed, key]);
      // Dispatch action to remove the snackbar from the redux store
      dispatchRemoveSnackbar(key);
    }, 1);
  });
  return null;
}

const mapStateToProps = (state) => ({
  snackbars: getSnackbars(state)
});

const mapDispatchToProps = {
  removeSnackbar
};

export const Notifier = withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(NotifierPresentation)
);
