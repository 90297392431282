import React from "react";
import { ImageIcon } from "common/SVG";
import { connect } from "react-redux";
import { getActiveImagesByMovementId } from "core/store/selectors/OrdersSelectors";
import { IndicatorActionButton } from "common";

function ImagesIndicatorActionPresentation({ movementId, images = [], index = 0 }) {
  return (
    <IndicatorActionButton
      color="primary"
      index={index}
      movementId={movementId}
      disabled={!images.length}
      icon={ImageIcon}
    />
  );
}

const mapStateToProps = (state, { movementId }) => ({
  images: getActiveImagesByMovementId(state, movementId)
});

export const ImagesIndicatorAction = connect(mapStateToProps)(ImagesIndicatorActionPresentation);
