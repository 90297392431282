import { TableRow as MuiTableRow } from "@material-ui/core";
import classNames from "core/utils/classNames";
import React, { useMemo, useCallback } from "react";
import { useActiveRow, useTableProps } from "./hooks";
import { TableRowCell } from "./TableRowCell";
import { TableRowAdornment } from "./TableRowAdornment";
import { useStyles } from "./TableRow.styles";

export function TableRow({ id, rowIdx, row, expandable, lastOfType } = {}) {
  const classes = useStyles();
  const { isActive: active, setActiveRow } = useActiveRow(id);
  const { expandable: expandableTable, inline } = useTableProps();

  const isBaseRow = expandableTable && !expandable;
  const isExpansionRow = expandableTable && expandable;

  const rowCellClassNames = classNames(classes.rowCell, {
    [classes.activeRowCell]: active,
    [classes.baseRowCell]: isBaseRow,
    [classes.expansionRowCell]: isExpansionRow,
    [classes.activeBaseRowCell]: isBaseRow && active,
    [classes.lastOfRowType]: lastOfType,
    [classes.activeLastOfRowType]: active && lastOfType,
    [classes.inlineRowBackgroundOffset]: inline && rowIdx % 2 === 0,
    [classes.borderReset]: expandableTable && active,
    [classes.inlineRow]: inline,
    [classes.inlineRowBackgroundOffset]: inline && rowIdx % 2 === 0
  });

  const handleClickCallback = useCallback(
    function handleClick(event) {
      event.stopPropagation();
      setActiveRow(id);
    },
    [id, setActiveRow]
  );

  const memoedRowCellProps = useMemo(
    () => ({
      className: rowCellClassNames,
      expandable,
      active,
      onClick: isBaseRow ? handleClickCallback : undefined
    }),
    [rowCellClassNames, expandable, active, isBaseRow, handleClickCallback]
  );

  const memoedRowCells = useMemo(
    () =>
      row.map(({ cellProps, CellComponent }, cellIdx) => {
        return (
          <TableRowCell
            // eslint-disable-next-line react/no-array-index-key
            key={`table-row-${rowIdx}-cell-${cellIdx}`}
            {...memoedRowCellProps}
            {...cellProps}
          >
            {CellComponent}
          </TableRowCell>
        );
      }),
    [row, rowIdx, memoedRowCellProps]
  );

  const memoedRowAdornmentCell = useMemo(() => {
    return (
      <TableRowCell {...memoedRowCellProps} verticalAlign="middle" align="center">
        <TableRowAdornment onClick={handleClickCallback} active={active} />
      </TableRowCell>
    );
  }, [active, handleClickCallback, memoedRowCellProps]);

  return (
    <MuiTableRow
      className={classNames({
        [classes.pointer]: isBaseRow
      })}
    >
      {memoedRowCells}
      {!!isBaseRow && memoedRowAdornmentCell}
    </MuiTableRow>
  );
}
