import { useEffect } from "react";

/**
 * Hook that only calls the passed in function once.
 *
 * @export
 * @param {*} fn
 */
export function useMountEffect(fn) {
  useEffect(fn, []);
}
