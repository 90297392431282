import { parseAsync } from "json2csv";
import json2xls from "json2xls";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getLogger } from ".";

const log = getLogger("lib.util.ExportUtil");

export function exportDataToXLS(data, fields) {
	let result = json2xls(data, fields);
	log.debug("Export to excel %o %o %o", data, result);
	return result;
}

export async function exportDataToCSV(data = [], fields) {
	log.debug("Export data %o fields %o", data, fields);
	return await parseAsync(data, {
		fields: fields,
		encoding: "utf-8",
	});
}

export async function exportDataToPDF(data = [], fields) {
	let rows = [];
	data.forEach(item => {
		let row = [];
		for (let key in item) {
			row.push(item[key]);
		}
		rows.push(row);
	});
	const docDefinition = {
		content: [
			{
				table: {
					headerRows: 1,
					body: rows
				},
				layout: {
					fillColor(rowIndex) {
						return rowIndex % 2 === 0 ? "#CCCCCC" : null;
					}
				}
			}
		],
		defaultStyle: {
			fontSize: 8,
			bold: false
		}
	};
	log.debug("Export to pdf %o", docDefinition);
	pdfMake.vfs = pdfFonts.pdfMake.vfs;
	return pdfMake.createPdf(docDefinition);
}