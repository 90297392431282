import React from "react";
import { IconButton, InputAdornment, TextField, ClickAwayListener } from "@material-ui/core";
import { object, string } from "yup";
import { Formik, Form, Field } from "formik";
import { formatUSDCurrency } from "core/utils/formatters";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import { useStyles } from "./PendingOfferInput.styles";

const validationSchema = object().shape({
  offerAmount: string()
    .max(14, "The amount entered can contain at most 14 digits")
    .required("Amount Required")
});

export function PendingOfferInput({
  rate,
  handleOfferButtonClick,
  onClickOutlinedInput,
  handleClickAway
}) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        offerAmount: ""
      }}
      validationSchema={validationSchema}
      onSubmit={(e) => handleOfferButtonClick(e)}
    >
      {(props) => {
        const { errors, setFieldValue, handleSubmit } = props;
        return (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Form>
              <Field
                id="offerAmount"
                name="offerAmount"
                autoFocus
                variant="outlined"
                onClick={onClickOutlinedInput}
                component={TextField}
                type="number"
                error={!!errors.offerAmount}
                onChange={(e) => {
                  setFieldValue("offerAmount", e.target.value);
                }}
                helperText={errors.offerAmount ? errors.offerAmount : ""}
                className={classes.offerTextField}
                classes={{
                  root: classes.cssOutlinedInput
                }}
                placeholder={formatUSDCurrency(rate, { prefix: false })}
                InputProps={{
                  classes: {
                    input: classes.input
                  },
                  startAdornment: (
                    <InputAdornment
                      id="startAdornment"
                      position="start"
                      classes={{
                        root: classes.adornedStart,
                        positionStart: classes.adornedStart
                      }}
                    >
                      $
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit" fontSize="large" onClick={handleSubmit}>
                        <ArrowForwardOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Form>
          </ClickAwayListener>
        );
      }}
    </Formik>
  );
}
