import React from "react";
import { Button } from "@material-ui/core";
import { DocumentIcon } from "common/SVG";
import { Typography } from "common";

export function RateConfirmationButton({ onClick }) {
  return (
    <Button size="small" color="primary" startIcon={<DocumentIcon />} onClick={onClick}>
      <Typography color="primary" value="Rate Confirmation" />
    </Button>
  );
}
