import React from "react";
import * as Mui from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Close } from "@material-ui/icons";
import { useStyles } from "./TableFilter.styles";

export function TableFilter({ placeholder = "Filter", value, onReset, ...restProps }) {
  const classes = useStyles();
  return (
    <Mui.TextField
      {...restProps}
      value={value}
      variant="standard"
      className={classes.root}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <Mui.InputAdornment position="start">
            <SearchIcon />
          </Mui.InputAdornment>
        ),
        endAdornment: (
          <Mui.InputAdornment position="end">
            <Mui.IconButton size="small" disabled={!value} onClick={onReset("")}>
              <Close />
            </Mui.IconButton>
          </Mui.InputAdornment>
        )
      }}
    />
  );
}
