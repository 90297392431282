import React from "react";
import { Link } from "@material-ui/core";
import { Typography } from "./Typography";

export const Email = ({ value, subject, ...restProps }) => {
  const child = <Typography {...restProps} value={value || "N/A"} />;
  return value ? (
    <Link href={subject ? `mailto:${value}?subject=${subject}` : `mailto:${value}`}>{child}</Link>
  ) : (
    child
  );
};
