import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "auto 80px",
    gridTemplateRows: "32px 16px",
    gridGap: theme.spacing(1),
    alignItems: "center",
    position: "relative",
    top: 10
  },
  label: {
    gridColumn: 1
  },
  input: {
    gridColumn: 2,
    justifySelf: "center",
    margin: 0,
    width: 70,
    "& .MuiInputBase-input": {
      margin: 0,
      paddingLeft: 0,
      textAlign: "center"
    }
  },
  helperText: {
    gridColumn: 2,
    fontSize: theme.typography.pxToRem(9),
    justifySelf: "center"
  }
}));
