import React from "react";
import { Typography, TableCellList } from "common";

export function CommodityDetailsTableCell({
  commodity: { name, description, length } = {},
  showLength = true,
  label = "Equipment"
}) {
  return (
    <TableCellList>
      <Typography component="li" color="dark" value={name || "N/A"} />
      <TableCellList row>
        <li>
          <Typography component="div" label value={label} />
          <Typography
            component="div"
            color="dark"
            fontWeight="bold"
            fontSize="medium"
            value={description || "N/A"}
          />
        </li>
        {showLength && (
          <li>
            <Typography component="div" label value="Length" />
            <Typography
              component="div"
              color="dark"
              fontWeight="bold"
              fontSize="medium"
              value={length || "N/A"}
            />
          </li>
        )}
      </TableCellList>
    </TableCellList>
  );
}
