import React from "react";
import { TableCell } from '@material-ui/core';
import { Button } from "lib/components";
import { useStyles } from "./TableStyles";

export default function ExpandRowCell({ rowIndex, noImage, expandedRowIndex, setExpandedRowIndex}) {
	let classes = useStyles();
	let button;
	if (!noImage) {
		let image;
		if (rowIndex === expandedRowIndex)
			image = "chevron-down";
		else
			image = "chevron-left";
		button = <Button variant="text" width={40} buttonStyle={{minWidth:40, borderRadius:"50%"}} image={image} onClick={() => toggleRowExpansion(rowIndex, expandedRowIndex, setExpandedRowIndex)} />
	}
	return (
		<TableCell className={classes.expandColumn}>
			{button}
		</TableCell>
	);	
}

function toggleRowExpansion(rowIndexToToggle, expandedRowIndex, setExpandedRowIndex) {
    let index = rowIndexToToggle;
    if (rowIndexToToggle === expandedRowIndex)
        index = -1;
    setExpandedRowIndex(index);
}