import React from "react";
import { ReactComponent as CheckIconYellow } from "images/svg/check-with-yellow-circle.svg";
import { ReactComponent as DollarIconRed } from "images/svg/dollar-with-red-circle.svg";
import { Typography } from "common";
import classNames from "core/utils/classNames";
import { useStyles } from "./PaymentsDueStatusTableCell.styles";

export function PaymentsDueStatusTableCell({ status }) {
  const classes = useStyles();
  const StatusDisplay = function getStatusDisplay() {
    switch (status) {
      case "Pending":
        return <CheckIconYellow className={classes.icon} />;
      case "Unpaid":
        return <DollarIconRed className={classes.icon} />;
      default:
        return null;
    }
  };

  return (
    <Typography className={classNames(classes.root)} component="div" fontSize="large" color="dark">
      {StatusDisplay()}
      {status}
    </Typography>
  );
}
