import React, { useMemo } from "react";
import { TableHead as MuiTableHead, TableRow } from "@material-ui/core";
import { useTableProps } from "./hooks";
import { TableHeadCell } from "./TableHeadCell";

export function TableHead({ columns = [] }) {
  const { expandable } = useTableProps();
  const tableCells = useMemo(
    () =>
      columns.map((columnProps, columnIdx) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableHeadCell key={`table-head-cell-${columnIdx}`} {...columnProps} />
      )),
    [columns]
  );

  return (
    <MuiTableHead>
      <TableRow>
        {tableCells}
        {!!expandable && <TableHeadCell align="center" style={{ minWidth: 30 }} />}
      </TableRow>
    </MuiTableHead>
  );
}
