import { callApi } from "./api";
import { showSnackbar } from "lib/components";
import getLogger from "./Logger";
import { closeSnackbar } from "lib/components/Snackbar";

const log = getLogger("lib.util.ReportUtil");

export function downloadText(text, downloadName) {
	downloadAnchor('data:text/plain;charset=utf-8,' + encodeURIComponent(text), downloadName);
}

export function downloadBlob(blob, downloadName) {
	downloadAnchor(window.URL.createObjectURL(blob), downloadName);
}

export function downloadBinary(binary, downloadName) {
	const len = binary.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; i++)
		bytes[i] = binary.charCodeAt(i);
	const blob = new Blob([bytes.buffer], { type: 'application/octet-stream' });
	return downloadBlob(blob, downloadName);
}

export function downloadURL(url, downloadName, onCompleteMessageOrFunction, onErrorMessageOrFunction, fetchOptions) {
	let loadingKey;
	callApi(url, fetchOptions)
		.then(response => {
			downloadResponse(downloadName, onCompleteMessageOrFunction, loadingKey, response);
		})
		.catch(reason => {
			if (loadingKey)
				closeSnackbar(loadingKey)
			if (onErrorMessageOrFunction)
				callMessageOrFunction(onErrorMessageOrFunction);
			log.debug(reason);
		});
}

function downloadResponse(downloadName, onCompleteMessageOrFunction, loadingKey, response) {
	if (downloadName == null)
		downloadName = "document.pdf";
	downloadAnchor(`data:application/octet-stream;base64,${Buffer.from(response).toString("base64")}`, downloadName);
	if (loadingKey)
		closeSnackbar(loadingKey)
	if (onCompleteMessageOrFunction)
		callMessageOrFunction(onCompleteMessageOrFunction);
}

function downloadAnchor(href, downloadName) {
	if (downloadName == null)
		throw new Error("Download name must be specified.");
	const download = document.createElement("a");
	download.href = href;
	download.download = downloadName;
	download.click();
	download.remove();
}

function callMessageOrFunction(messageOrFunction) {
	if (typeof messageOrFunction === "string")
		showSnackbar(messageOrFunction);
	else
		messageOrFunction();
}