import React from "react";
import { DialogActions, Button } from "@material-ui/core";
import { useFormikContext } from "formik";
import { SubmitButton } from "common";

export function FormActions({ onCancel }) {
  const props = useFormikContext();
  const { isSubmitting } = props;
  return (
    <DialogActions>
      <Button variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
      <SubmitButton
        loading={isSubmitting}
        variant="contained"
        size="medium"
        color="primary"
        type="submit"
      >
        OK
      </SubmitButton>
    </DialogActions>
  );
}
