import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  header: {
    extend: "flex",
    marginBottom: theme.spacing(4),
    "&:nth-child(3)": {
      marginBottom: theme.spacing(2)
    }
  },
  loadingSpinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  statusMessage: {
    float: "right",
    color: "green",
    fontWeight: "bold"
  }
}));
