import React from "react";
import { IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

export function TableRowAdornment({ active, onClick }) {
  return (
    <IconButton onClick={onClick}>
      {active ? <KeyboardArrowDownIcon /> : <ChevronLeftIcon />}
    </IconButton>
  );
}
