import { ReactComponent as ReticleIconSVG } from "images/svg/reticle.svg";
import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

export const ReticleIcon = memo(function ReticleIcon(props) {
  return (
    <SvgIcon viewBox="0 0 19 19" {...props}>
      <ReticleIconSVG />
    </SvgIcon>
  );
});
