import React from "react";
import { Typography, Table, TableColumn } from "common";
import { Container } from "@material-ui/core";
import classNames from "core/utils/classNames";
import { formatUSDCurrency } from "core/utils/formatters";
import { useStyles } from "./PaymentDetailsTable.styles";

export function PaymentDetailsTable({ data = [], isFetching }) {
  const classes = useStyles();
  const deductions = data.deductions || [];
  const _payments = [
    {
      type: "Linehaul",
      amount: data.linehaul
    },
    ...(deductions || []),
    {
      type: "Net Pay",
      amount: data.netPay
    }
  ];
  if (!data && isFetching) return "loading";
  return !data ? (
    <Container style={{ width: "70%" }}>
      <Typography
        fontWeight="bold"
        className={classes.title}
        variant="body2"
        value="Payment Details"
      />
      <Typography
        className={classes.empty}
        component="div"
        value="No Payment Details Available for Display"
      />
    </Container>
  ) : (
    <Container className={classes.container}>
      <Typography
        fontWeight="bold"
        className={classes.title}
        variant="body2"
        value="Payment Details"
      />
      <Table inline data={_payments} disableToolbar>
        <TableColumn
          label="Charge Type"
          colSpan={1}
          minWidth={70}
          width="8%"
          Component={({ type }) => (
            <Typography
              component="div"
              value={type}
              className={classNames({ [classes.row]: type === "Net Pay" })}
            />
          )}
        />
        <TableColumn
          label="Amount"
          colSpan={1}
          minWidth={70}
          width="8%"
          Component={({ amount, type }) => (
            <Typography
              component="div"
              value={formatUSDCurrency(amount)}
              className={classNames({ [classes.row]: type === "Net Pay" })}
            />
          )}
        />
      </Table>
    </Container>
  );
}
