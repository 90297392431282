import React from "react";
import { replaceAll } from "../util/StringUtil";

export function getComponentName(context, name) {
	let result = "";
	for (let i = 0; context != null && context.hierarchy != null && i < context.hierarchy.length; i++)
		result += context.hierarchy[i].name + ".";
	result += name;
	return result;
}

export function getChildArray(children) {
	let result = [];
	children = React.Children.toArray(children);
	if (children != null) {
		for (let i = 0; i < children.length; i++)
			if (children[i] != null)
				result.push(children[i]);
	}
	return result;
}

export function appendComponentToHierarchy(context, props) {
	if (context.hierarchy == null)
		context.hierarchy = [];
	else
		context.hierarchy = [...context.hierarchy];
	context.hierarchy.push({ name: props.name, props: props });
}

export function isSpecified(value) {
	if (value == null)
		return false;
	if (typeof (value) === "string" && value.length === 0)
		return false;
	return true;
}

export function getSizeSpecifier(size) {
	if (size == null)
		return null;
	else if (typeof (size) === 'string' && (size.endsWith("px") || size.endsWith("%")))
		return size;
	else
		return size + "px";
}

export function getParentDialog(context) {
	if (context == null || context.hierarchy == null)
		return null;
	for (let i = context.hierarchy.length - 1; i > 0; i--)
		if (context.hierarchy[i].isDialog) // this doesn't exist.  maybe hierarchy elements should have a type that we can compare here
			return context.hierarchy[i];
}


export function getMarginSpecifier(value) {
	if (value == null)
		return null;
	else if (typeof (value) === 'string')
		return replaceAll(value, " ", "px ") + "px";
	else
		return value + "px";
}