import React from "react";
import classNames from "core/utils/classNames";
import { useStyles } from "./TableCellList.styles";

export function TableCellList({
  children,
  className,
  gutters = false,
  row = false,
  alignContent = "start"
}) {
  const classes = useStyles();
  return (
    <ul
      className={classNames(classes.root, className, {
        [classes.gutters]: gutters,
        [classes.row]: row,
        [classes.alignBetween]: alignContent === "between",
        [classes.alignStart]: alignContent === "start"
      })}
    >
      {children}
    </ul>
  );
}
