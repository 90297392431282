/* eslint-disable */

import React, { useState } from "react";
import { Panel, Label, Button, Spinner } from "lib/components";
import { clearSessionToken } from "lib/pages/login/WebLoginPresentation";
import { connect } from "react-redux";
import { signOut } from "core/store/actions/UserActions";
import { useMountEffect } from "core/hooks";
import { loadAuthenticatedContext } from "lib/util/MainPageContext";
import { getLogger, makeStyles } from "lib/util";
import { AUTH_TYPE_AUTHENTICATED } from "core/store/states/UserState";

const log = getLogger("lib.pages.UserMenu");

const useStyles = makeStyles((theme) => {
  console.log(theme)
  return {
  dropDown: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.common.asphalt,
      "& .MuiButtonBase-root": {
        border: "1px solid #979797"
      }
    }
  },
  dropDownItems: {
    color: "white",
    fontSize: "14px",
    opacity: "0.75",
    fontWeight: 400,
    cursor: "pointer",
    "&:hover": {
      fontWeight: 600,
      color: theme.palette.primary.main,
      opacity: 1
    }
  },
  label: {
    color: theme.branding.colors.headerText || theme.palette.common.white
  },
  image: {
    stroke: theme.branding.colors.headerText || theme.palette.common.white,
    margin: 0
  }
}});

function UserMenuPresentation({ dispatchSignOut, authType }) {
  const isAuthed = authType === AUTH_TYPE_AUTHENTICATED;
  const classes = useStyles();
	const [mainPageContext, setMainPageContext] = useState();
	const [loading, setLoading] = useState(isAuthed ? true : false);
	useMountEffect(() => {
    if (isAuthed) loadAuthenticatedContext(setMainPageContext, setLoading);
  })

	if (loading)
		return <Spinner size={24}/>
	else if (mainPageContext == null || mainPageContext.userSettings == null)
		return <a className={classes.label} href="/login"> Sign In </a>;
	else
		return (
			<Panel>
				<Panel fillRow noRowBreak >
					<Label caption={mainPageContext.userSettings["userName"]} className={classes.label} fontSize="large" />
					<Label caption={mainPageContext.userSettings["companyName"]}  className={classes.label} fontSize="small" />
				</Panel>
				<Button image="slide-down-arrow" overrideImageClassName={classes.image} marginTop={14} dropDown={ dropDown(classes, dispatchSignOut) } dropDownClassName={classes.dropDown} dropDownAlign="right"/>
			</Panel>
		);
}

function handleSignOut(dispatchSignOut) {
	clearSessionToken();
	dispatchSignOut();
}

function dropDown(classes, dispatchSignOut) {
	return [
		<Label caption="Sign out" className={classes.dropDownItems} width={200} onClick={() => handleSignOut(dispatchSignOut)} />
	];
}

const mapDispatchToProps = {
	dispatchSignOut: signOut
};

export const UserMenu = connect(null, mapDispatchToProps)(UserMenuPresentation);
