import React from "react";
import { DialogActions, Button } from "@material-ui/core";
import { useFormikContext } from "formik";
import { SubmitButton } from "common";

export function FormActions({
  onCancel,
  submitButtonValue,
  cancelId = null,
  submitId = null,
  submitButtonVisible = true
}) {
  const props = useFormikContext();
  const { isSubmitting } = props;
  return (
    <DialogActions>
      <Button id={cancelId} variant="outlined" onClick={onCancel} fullWidth>
        Cancel
      </Button>
      {submitButtonVisible && (
        <SubmitButton
          id={submitId}
          loading={isSubmitting}
          variant="contained"
          size="medium"
          color="primary"
          type="submit"
          fullWidth
        >
          {submitButtonValue}
        </SubmitButton>
      )}
    </DialogActions>
  );
}
