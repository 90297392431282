import { capitalCase } from "change-case";
import { toDate, format, utcToZonedTime } from "date-fns-tz";

export function formatComment(comment) {
  return [comment];
}

export function formatTimezone(timezone) {
  return { timezone };
}

export function splitFormattedDateTimeString(dateTimeString) {
  const [date, time] = dateTimeString.split(" ");
  return { date, time };
}
export function splitLocation(value) {
  const [city, state] = value.split(", ");
  return { city, state };
}

/**
 * Formats a City State Zip string into the proper casing.
 *
 * @export
 * @param {*} string
 * @returns
 */
export function formatCityStateZip(string) {
  const [city, state, zip] = string.split(" ");
  return [`${capitalCase(city)},`, state.toUpperCase(), zip].join(" ");
}

export function convertMilliToDate(milli) {
  return toDate(parseInt(milli, 10));
}

export function convertMilliToDateFormat(milli) {
  const date = convertMilliToDate(milli);
  return format(date, "MM/dd/yyyy");
}

export function convertMilliToDateTimeFormat(milli) {
  const date = convertMilliToDate(milli);
  return format(date, "MM/dd/yyyy hh:mm a");
}

export function convertMilliToDateTimeObject(
  milli,
  dateFormat = "EEEE, MMMM dd, yyyy",
  timeFormat = "hh:mm a",
  timezone
) {
  const date = !timezone
    ? convertMilliToDate(milli)
    : utcToZonedTime(convertMilliToDate(milli), timezone);
  return {
    date: format(date, dateFormat),
    time: !timezone ? format(date, timeFormat) : format(date, timeFormat, { timeZone: timezone }),
    milli: parseInt(milli, 10),
    timezone
  };
}

export function splitTimeAndTimezone(time) {
  const [milli, timezone] = time.split(",");
  return convertMilliToDateTimeObject(milli, "EEEE, MMMM dd, yyyy", "hh:mm a zzz", timezone);
}

export function formatUSDCurrency(
  value,
  { locale, style = "currency", currency = "USD", prefix = true } = {}
) {
  if (typeof value !== "number" || Number.isNaN(value)) return value;
  const formatter = new Intl.NumberFormat(locale, { style, currency });
  let formattedValue = formatter.format(value.toString());
  if (!prefix && value < 0) {
    formattedValue = formattedValue.replace("$", "");
  } else if (!prefix && value > 0) {
    formattedValue = formattedValue.substr(1);
  }
  return formattedValue;
}
