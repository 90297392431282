import React, { useState } from "react";
import { appendComponentToHierarchy, getChildArray } from "./ComponentUtil";
import { Component, useComponentContext } from ".";
import { HierarchyContext, DataContext, ValueOwnerContext, useAsDataProvider } from "./Context";
import { isEmptyString, fetchModelData, useMountEffect, getLogger, makeStyles } from "lib/util";
import { allocateData, releaseData, getData } from "lib/util/DataStore";  // should come up with a better solution to this - just keep data in this module
import { useUnmountEffect } from "lib/util/Hooks";

const useStyles = makeStyles((theme) => ({
	panel: {display: "flex", flexDirection: "column"},
	panelRow : {display:"flex", flexDirection: "row", margin:"0px" },
	rowFillHeight: {height:"100%", overflow: "hidden"}
}));
const log = getLogger("lib.components.Panel");

export default function Panel({setLoading, fixedData, ...props}) {
    let context = useComponentContext();
    appendComponentToHierarchy(context, props);
	let classes = useStyles();
    let [data, setData] = useAsDataProvider(context, props.data, props.fieldName);
	let [modelIndex, setModelIndex] = useState(0);
	let [dataId, setDataId] = useState();
	if ((props.modelName || props.data != null || props.fieldName != null || props.adding) && dataId == null)
		setDataId(allocateData());
	log.debug("Panel props %o data %o", props, data);

	function childValueChanged(childProps, value) {
		let key = childProps.fieldName;
		if (childProps.searchType != null)
			key = childProps.searchType + "." + key;
		getData(dataId)[key] = { props: childProps, value: value };
	}

	useMountEffect(() => { 
		if (props.autoSearch && props.modelName != null) 
			loadData(props.modelName, context, setData, setLoading)
	});
	useUnmountEffect(() => {releaseData(dataId)});
	if (props.autoSearch === false && data == null)
		setData([]);
    var style = undefined;
    if (props.designer != null &&  props.designer.selectedComponents.length === 0)
        style = { minHeight: 64, minWidth: 100 };
    let content = renderCustomHTML(props);
    if (content == null) {
        let rows = getRows(props.children, props);
        content = getRowDivs(classes, rows);
    }
    var result = (
        <HierarchyContext.Provider value={context.hierarchy}>
            <Component style={style} className={classes.panel} {...props}>
                {content}
            </Component>
        </HierarchyContext.Provider>
	);
	// if (props.variant === "form") // causes id of components to be put in url
	// 	result = <form>{result}</form>; 

	if (props.modelName || props.data != null || props.fieldName != null) { // but not if ancestor data is just passing through to children
        result = (
			<ValueOwnerContext.Provider value={{ valueChanged: childValueChanged, dataId: dataId, fixedData: fixedData }} >
				<DataContext.Provider value={{modelName: props.modelName, list:data, listIndex:modelIndex, setListIndex: setModelIndex, setData: setData}}>
					{result}
				</DataContext.Provider>
			</ValueOwnerContext.Provider>
        );
    }
    return result;
}

function getRows(children, props) {
    var currentRow = [];
    var result = [];
    children = getChildArray(children);
    for (let i = 0; children != null && i < children.length; i++) {
        var child = children[i];
        currentRow.push(child);
        if (child.props != null && !child.props.noRowBreak) {
            result.push(currentRow);
            currentRow = [];
        }
    }
    if (currentRow.length > 0) 
        result.push(currentRow);
    return result;        
}

function loadData(modelName, context, setData, setLoading) {
    if (modelName != null) {
        log.debug("Loading data %o", modelName);
        fetchModelData(modelName, context.data, setData, setLoading);
    }
}

function getRowHeight(row) {
	let result;
	for (let i = 0; i < row.length; i++) {
		const comp = row[i].props;
		let height = comp.height;
		if (height && height > result)
			result = height;
	}
	return result;
}

function rowHasFillComponent(row) {
	for (let i = 0; i < row.length; i++)
		if (row[i].props.fillHeight)
			return true;
	return false;
}

function getRowDivs(classes, rows) {
    const result = [];
    for (let i = 0; rows != null && i < rows.length; i++) {
		var row = rows[i];
		const height = getRowHeight(row);
		let rowStyle = {};
		if (row.length > 0 && row[0].props != null) { 
// we need to do two things to make this work as envisioned:
// 1) consider hAlign of every component, not just the first component.  I think we would
//       collect all the "left," "center," and "right" components and stick them in their own divs.
//       If there are only "left" components then don't add any interior divs
// 2) consider the vAlign of components.  This complicates the collection described in item 1.
// Ryan will know what to do.
			if (row[0].props.hAlign === "right")
				rowStyle.justifyContent = "flex-end";
			else if (row[0].props.hAlign === "center")
				rowStyle.justifyContent = "center";
		}
		if (height)
			rowStyle.height = height;
		let className = classes.panelRow;
		if (rowHasFillComponent(row)) 
			className += " " + classes.rowFillHeight;
//		console.log("Panel row class %o %o  %o", row, className, rowStyle);
        result.push(
            <div key={"row" + i} style={rowStyle} className={className}>
                {row}
            </div>
        );
    }
    return result;
  };

// this is a POC that allows us to embed custom javascript in one of our panels.  Our POC involved displaying a third-party charting library
function renderCustomHTML(props) {
    if (isEmptyString(props.customHTML))
        return null;
    return (<div dangerouslySetInnerHTML={{ __html: props.customHTML }} /> );

// I didn't want the Helmet dependency in the rewrite.  I bet there's a better way to do this.  Ryan?
    // var helmet, jsSrc, js, content;
    // if (!isEmptyString(this.state.customJSSrc))
    //     jsSrc = <script src={this.state.customJSSrc} />;
    // if (!isEmptyString(this.state.customJS))
    //     js = <script>{this.state.customJS}</script>;
    // if (js != null || jsSrc != null)
    //     helmet = ( <Helmet> {jsSrc} {js} </Helmet>  );
    // if (helmet != null || content != null)
    //     return ( <div> {content} {helmet} </div> );
}