import { PATHS } from "core/config/urls";
import {
  Login,
  ResetPassword,
  SetupAccount,
  Playground,
  Portal,
  CallinEntry,
  Error,
  Offer
} from "pages";
import { AUTH_TYPE_AUTHENTICATED, AUTH_TYPE_GUEST } from "core/store/states/UserState";
import ChangeLicense from "lib/pages/ChangeLicense";
import { PanelViewOrderUnauthenticated } from "lme/ar/CustomerPortal/PanelViewOrderUnauthenticated";

export const routes = [
  {
    key: "offer",
    path: PATHS.OFFER,
    authType: AUTH_TYPE_GUEST,
    page: {
      Component: Offer,
      props: {
        pageTitle: "Email Offer Confirmation"
      }
    }
  },
  {
    key: "track",
    path: "/track",
    authType: AUTH_TYPE_GUEST,
    page: {
      Component: PanelViewOrderUnauthenticated,
      props: {
        passRouterProps: true,
        pageTitle: "Tracking"
      }
    }
  },
  {
    key: "login",
    path: PATHS.LOGIN,
    authType: AUTH_TYPE_GUEST,
    page: {
      Component: Login,
      props: {
        passRouterProps: true,
        pageTitle: "Login"
      }
    }
  },
  {
    key: "reset",
    path: PATHS.RESET_PASSWORD,
    authType: AUTH_TYPE_GUEST,
    page: {
      Component: ResetPassword,
      props: {
        passRouterProps: true,
        pageTitle: "Reset Password"
      }
    }
  },
  {
    key: "setup",
    path: PATHS.SETUP_ACCOUNT,
    authType: AUTH_TYPE_GUEST,
    page: {
      Component: SetupAccount,
      props: {
        passRouterProps: true,
        pageTitle: "Setup Account"
      }
    }
  },
  {
    key: "callin",
    path: PATHS.CALLIN,
    authType: AUTH_TYPE_GUEST,
    page: {
      Component: CallinEntry,
      props: {
        passRouterProps: true,
        pageTitle: "Add Callin"
      }
    }
  },
  {
    key: "playground",
    path: PATHS.PLAYGROUND,
    authType: AUTH_TYPE_AUTHENTICATED,
    page: {
      Component: Playground,
      props: {
        pageTitle: "Playground"
      }
    }
  },
  {
    key: "change-license",
    path: "/change-license",
    authType: AUTH_TYPE_AUTHENTICATED,
    page: {
      Component: ChangeLicense,
      props: {
        pageTitle: "Change License"
      }
    }
  },
  {
    key: "error",
    path: PATHS.ERROR,
    authType: AUTH_TYPE_AUTHENTICATED,
    page: {
      Component: Error,
      props: {}
    }
  },
  {
    // NOTE: We keep root at the bottom so react router can try and match a route with the
    // other configurations before matching to the root. If this was at the top, the React Router
    // would match it first and wouldn't go to the correct page.
    key: "root",
    exact: true,
    path: PATHS.ROOT,
    authType: AUTH_TYPE_AUTHENTICATED,
    page: {
      Component: Portal,
      props: {
        passRouterProps: true
      }
    }
  }
];
