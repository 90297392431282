import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingRight: 60,
    borderRadius: 4
  },
  itemOffset: {
    backgroundColor: theme.palette.common.dew
  },
  fileInfo: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    paddingRight: theme.spacing(3)
  },
  fileOption: {
    margin: 0
  },
  error: {
    backgroundColor: theme.palette.error.light
  }
}));
