import React, { useState } from "react";
import { Drawer, Divider, IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { List } from "@material-ui/core";
import MainMenuItem from "./MainMenuItem";
import { getLogger, useMountEffect, fetchModelData, getCompanySettings, makeStyles } from "lib/util";
import classNames from "lib/util/classNames";

const log = getLogger("lib.components.MainMenu");

export const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: theme.dimensions.drawerWidth,
		flexShrink: 0
	},
	rootPaper: {
		minWidth: theme.dimensions.drawerWidth
	},
	rootHeader: {
		height: theme.dimensions.navHeight,
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		justifyContent: "flex-end"
	},
	underAppBar: {
		marginTop: 60,
		paddingLeft: 16
	},
	menuItemContent: {
		paddingLeft: 32
	}
}));

export default function MainMenu({ open, onClose  }) {
	useMountEffect(() => loadMenu(setItems));
	let [items, setItems] = useState();
	const classes = useStyles();
	log.debug("Main menu item %o", items);
	const menuItems = [];
	for (let i = 0; items != null && i < items.length; i++)
		menuItems.push(<MainMenuItem key={"menu-" + i} {...getItemProps(items[i].modelData)} onClose={onClose} />);
	return (
		<Drawer className={classNames(classes.root)} variant="persistent" onClose={onClose} open={open}
			classes={{ paper: classes.rootPaper }}>
			<div className={classes.rootHeader}>
				<IconButton onClick={onClose}>
					<ChevronLeftIcon />
				</IconButton>
			</div>
			<Divider />
			<List>
				{menuItems}
			</List>
		</Drawer>
	);
}

function getItemProps(data) {
	return { image: data["image_name"], caption: data["caption"], id: data["page_identifier"], type: data["page_type"] };
}

function loadMenu(setItems) {
	fetchModelData("lib/menu", null, setItems);
	getCompanySettings();
}