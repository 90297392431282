import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  flex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  dialogContent: {
    extend: "flex",
    maxWidth: 767
  }
}));
