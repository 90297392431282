import React from "react";
import moneyBagIcon from "images/svg/money-bag-filled.svg";
import moneyBagIconLine from "images/svg/money-bag-outline.svg";
import {
  ORDERS_CATEGORY_DELIVERED,
  ORDERS_TYPE_PAID,
  ORDERS_TYPE_UNPAID
} from "core/utils/constCapsule";
import { IconSwitch } from "common";
import { getActiveOrdersType } from "core/store/selectors/OrdersSelectors";
import { setOrdersType } from "core/store/actions/OrdersActions";
import { connect } from "react-redux";

export function OrdersTypeSwitchPresentation({ ordersType, dispatchSetOrdersType }) {
  const handleChange = ({ target: { checked } }) =>
    dispatchSetOrdersType(
      ORDERS_CATEGORY_DELIVERED,
      checked ? ORDERS_TYPE_UNPAID : ORDERS_TYPE_PAID
    );

  return (
    <IconSwitch
      checked={ordersType === ORDERS_TYPE_UNPAID}
      startLabel="Paid"
      endLabel="Unpaid"
      rightIcon={moneyBagIconLine}
      leftIcon={moneyBagIcon}
      onChange={handleChange}
    />
  );
}

const mapStateToProps = (state) => ({
  ordersType: getActiveOrdersType(state)
});

const mapDispatchToProps = {
  dispatchSetOrdersType: setOrdersType
};

export const OrdersTypeSwitch = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersTypeSwitchPresentation);
