import { ReactComponent as BrokenPalletSVG } from "images/svg/broken-pallet.svg";
import React, { memo } from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";
import { classNames } from "core/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    fill: theme.palette.primary.main,
    width: 484,
    height: 183
  }
}));

export const BrokePalletIcon = memo(function BrokePaletteIcon({
  className,
  ...props
}) {
  const classes = useStyles();
  return (
    <SvgIcon
      className={classNames(classes.root, className)}
      viewBox="0 0 484 183"
      component={BrokenPalletSVG}
      {...props}
    />
  );
});
