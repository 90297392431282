import React from "react";
import { IconButton } from "@material-ui/core";
import { EmailOutlined } from "@material-ui/icons";
import { USER_TYPE_FACTORING_COMPANY, USER_TYPE_CARRIER } from "core/utils/constCapsule";
import {
  getUserType,
  getUserCompanyName,
  getControlValue
} from "core/store/selectors/UserSelectors";
import { USER_CONTROLS } from "core/config/controls";
import { connect } from "react-redux";

export function EmailIndicatorActionPresentation({
  contact = {},
  userType,
  id,
  pickup = {},
  delivery = {},
  companyName,
  factoringCompanyEmails,
  invoice: { id: invoiceId = "N/A" } = {}
}) {
  const mailTo = {
    [USER_TYPE_CARRIER]: contact.email,
    [USER_TYPE_FACTORING_COMPANY]: factoringCompanyEmails
  };

  const subject = {
    [USER_TYPE_CARRIER]: `Question about Available Order #${id} ${pickup.city}, ${pickup.state} to ${delivery.city}, ${delivery.state}`,
    [USER_TYPE_FACTORING_COMPANY]: `Question about Order #${id}, Invoice ${invoiceId}. From ${companyName}`
  };

  const handleClick = (event) => event.stopPropagation();

  return (
    <a href={`mailto:${mailTo[userType]}?subject=${subject[userType]}`}>
      <IconButton onClick={handleClick} color="primary">
        <EmailOutlined />
      </IconButton>
    </a>
  );
}

const mapStateToProps = (state) => ({
  userType: getUserType(state),
  companyName: getUserCompanyName(state),
  factoringCompanyEmails: getControlValue(state, USER_CONTROLS.FACTORING_COMPANY_EMAILS)
});

export const EmailIndicatorAction = connect(mapStateToProps)(EmailIndicatorActionPresentation);
