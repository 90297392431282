import { exportDataToCSV, exportDataToXLS, exportDataToPDF } from "lib/util/ExportUtil";
import { getLogger } from "lib/util";
import { getChildArray } from "../ComponentUtil";
import { formatDateTime } from "lib/util/Date";

const log = getLogger("lib.components.Table.ExportTable");

export async function exportToCSV(props) {
	return exportDataToCSV(getDisplayedData(props.children, props.rows, false));
}

export function exportToXLS(props) {
	return exportDataToXLS(getDisplayedData(props.children, props.rows, false));
}

export async function exportToPDF(props) {
	return exportDataToPDF(getDisplayedData(props.children, props.rows, true));
}

function getDisplayedData(children, rows, includeHeadingsAsFirstRow) {
	let result = [];
	let fields = getExportFields(getChildArray(children));
	log.debug("Fields %o", fields);
	let headings = {};
	fields.forEach(field => headings[field.name] = field.heading);
	if (includeHeadingsAsFirstRow)
		result.push(headings);
	rows.forEach(row => {
		const rowData = row.props.data[row.props.rowIndex].modelData;
		let exportData = {};
		fields.forEach(field => {
			let data = rowData[field.name];
			if (data == null)
				data = "";
			if (field.displayType === "date" || field.displayType === "datetime")
				data = formatDateTime(data, "MM/dd/yyyy hh:mm AM");
			exportData[field.heading] = data;
		});
		result.push(exportData);
	});
	log.debug("Displayed data %o", result);
	return result;
}

function getExportFields(columns) {
	let fields = [];
	for (let i = 0; i < columns.length; i++) {
		const col = columns[i].props;
		if (col.visible !== false) {
			if (col.fieldName)
				fields.push({ heading: col.caption, name: col.fieldName, displayType: col.displayType });
			addColsForChildren(fields, col.children, col.caption, 1);
		}
	}
	log.debug("Fields: %o", fields);
	return fields;
}

function addColsForChildren(fields, children, defaultHeading, defaultIndex) {
	if (children == null)
		return;
	children = getChildArray(children);
	for (let i = 0; i < children.length; i++) {
		const child = children[i].props;
		if (child.fieldName != null && child.export !== false) {
			let heading = child.displayLabel;
			if (heading == null)
				heading = child.caption;
			if (heading == null)
				heading = defaultHeading + "-" + defaultIndex++;
			fields.push({ heading: heading, name: child.fieldName });
		}
		addColsForChildren(fields, child.children, defaultHeading, defaultIndex);
	}
}