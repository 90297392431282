import React from "react";
import Image from "../Image";

export default class MapPin extends React.Component {
  	static defaultProps = {
		imageName: "map-pin-gray"
	  };

	render = () => {
		let image = null;
		if (this.props.imageName != null)
			image = <Image name={this.props.imageName} height={32}/>
		return (
			<div style={{
				color: 'red',
				padding: '15px 10px',
				display: 'inline-flex',
				textAlign: 'center',
				alignItems: 'center',
				justifyContent: 'center',
				transform: 'translate(-50%, -50%)'
				}}>
				{image}
				{this.props.text}
			</div>
		);
	}
}