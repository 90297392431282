import { ReactComponent as ReplyIconSVG } from "images/svg/reply-arrow-filled-orange.svg";
import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

export const ReplyIcon = memo(function ReplyIcon(props) {
  return (
    <SvgIcon {...props}>
      <ReplyIconSVG />
    </SvgIcon>
  );
});
