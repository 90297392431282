import React, { useState } from "react";
import {Checkbox, FormControlLabel} from '@material-ui/core';
import {Component, useComponentContext, updateValueOwner } from ".";
import { useMountEffect } from "lib/util";

export default function CheckBox(props) {
	let [checked, setChecked] = useState(props.default);
	let checkValue = props.checked;
	if (checkValue == null)
		checkValue = checked;
	if (checkValue == null)
		checkValue = false;

	let context = useComponentContext();
    useMountEffect(() => {updateValueOwner(context, props, checkValue)});
	let checkbox = (
		<Checkbox checked={checkValue} color="primary"
			onChange={(event) => {handleChange(context, props, event.target.checked, setChecked)} }/>
	); 
	return (
		<Component {...props}>
			<FormControlLabel control={checkbox} label={props.caption} />
		</Component>
	);
}

function handleChange(context, props, value, setChecked) {
	setChecked(value);
	updateValueOwner(context, props, value);
}



// Ryan, since we will have many context-aware components, I want to build this logic into a HOC.
// I think I want the code for CheckBox (and all the other context-aware components) to look like what's below.
//  How do I make that happen?

// function CheckBox(props) {
// 	let propsChecked = props.checked;
// 	if (propsChecked === undefined)
// 		propsChecked = false;
// 	let [checked, setChecked] = useState(propsChecked);
// 	let checkbox = (
// 		<Checkbox checked={checked} color="primary"
// 			onChange={(event) => {handleChange(props, event.target.checked, setChecked)} }/>
// 	); 
// 	return (
// 		<Component {...props}>
// 			<FormControlLabel control={checkbox} label={props.caption} />
// 		</Component>
// 	);
// }

// function handleChange(props, value, setChecked) {
// 	setChecked(value);
// 	props.updateValueOwner(value);
// }

// export default withContextBind(CheckBox);



// function withContextBind(Comp, propsValue) {
// 	let context = useComponentContext();
// 	useMountEffect(() => {updateValueOwner(context, component.props, propsValue)});
// 	let newProps = {...props};
// 	newProps.updateValueOwner = (value) => updateValueOwner(context, component.props, value);
// 	return <Comp {...newProps} />
// }
