import React from "react";
import { Typography, TableCellList } from "common";
import { ORDERS_TYPE_UNPAID } from "core/utils/constCapsule";

export function ShippingDetailsTableCell({
  shipping: { date } = {},
  label,
  ordersType,
  ok2PayDate: { date: payDate } = {}
}) {
  return (
    <TableCellList>
      <Typography component="div" fontSize="large" value={date || "N/A"} />
      {ordersType === ORDERS_TYPE_UNPAID ? (
        <>
          <Typography component="li" label value={label || "N/A"} />
          <Typography component="li" fontWeight="bold" fontSize="medium" value={payDate || "N/A"} />
        </>
      ) : null}
    </TableCellList>
  );
}
