import React from "react";
import { Typography, TableCellList } from "common";

export function TransactionDetailsTableCell({ dataKey, label, ...rowData }) {
  const { id = null, date = null } = rowData[dataKey] || {};
  return (
    <TableCellList>
      <Typography component="li" fontSize="large" value={id || "N/A"} />
      <Typography component="li" label value={label || "N/A"} />
      <Typography
        component="li"
        fontWeight="bold"
        fontSize="medium"
        value={date || "N/A"}
      />
    </TableCellList>
  );
}
