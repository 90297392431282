import React from "react";
import { Dialog, FileUploader } from "common";
import { uploadImages } from "core/store/actions/OrdersActions";
import { getControlValue } from "core/store/selectors/UserSelectors";
import { connect } from "react-redux";
import { USER_CONTROLS } from "core/config/controls";
import { useStyles } from "./ImagesDialog.styles";

export function ImagesDialogPresentation({
  open,
  onClose,
  movementId,
  orderId,
  maxFileSize,
  dispatchUploadImages
}) {
  const classes = useStyles();
  const handleSubmit = async ({ files }, helpers) => {
    try {
      await dispatchUploadImages(files, movementId, orderId);
      helpers.setSubmitting(false);
      onClose();
    } catch (error) {
      helpers.setSubmitting(false);
    }
  };

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={onClose}
      classes={{
        content: classes.dialogContent
      }}
      title="Upload Images"
    >
      <FileUploader
        onCancel={onClose}
        onSubmit={handleSubmit}
        maxSize={maxFileSize * 1000000}
        defaultDocType="Other"
        docTypes={[
          "Bill of Lading",
          "Delivery Receipt",
          "Toll Ticket",
          "Weight/Scale Ticket",
          "Invoice",
          "Receipt",
          "Other"
        ]}
      />
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  maxFileSize: getControlValue(state, USER_CONTROLS.MAX_FILE_SIZE)
});

const mapDispatchToProps = {
  dispatchUploadImages: uploadImages
};

export const ImagesDialog = connect(mapStateToProps, mapDispatchToProps)(ImagesDialogPresentation);
