import React from "react";
import { Table, Label, Image, Panel, Column, useDataContext, TextBox } from "lib/components";
import { getDataFromContext } from "lib/util/ModelUtil";
import { formatCityStateZip } from "lib/util/CityUtil";

export function TableStopDetail(props) {
	const closeSpacing={marginTop:-4};
	return (
		<Table {...props} columnHeadingsVisible={false} headerVisible={false} showLinesBetweenRows={false} rowSpacing={0} cellVerticalAlign="top">
			<Column width={32}>
				<StatusCell />
			</Column>
			<Column>
				<Label fieldName="stop_type_descr" color="primary" />
				<Label fieldName="location_name" nullDisplayValue="hide" style={closeSpacing} fontBold />
				<Label fieldName="address" nullDisplayValue="hide" style={closeSpacing}/>
				<Label caption={formatCity} noRowBreak style={closeSpacing}/>
			</Column>
			<Column>
				<TextBox caption={schedEarlyCaption} fieldName="sched_arrive_early" variant="label" fontBold displayType="datetime" format="long" nullDisplayValue="hide" />
				<TextBox caption={schedLateCaption} fieldName="sched_arrive_late" variant="label" fontBold displayType="datetime" format="long" nullDisplayValue="hide" style={closeSpacing}/>
			</Column>
			<Column>
				<TextBox caption="Actual arrival" variant="label" fieldName="actual_arrival" fontBold  displayType="datetime" format="long" nullDisplayValue="N/A"/>
				<TextBox caption="Actual departure" variant="label" fieldName="actual_departure" fontBold  displayType="datetime" format="long" nullDisplayValue="N/A" style={closeSpacing}/>
			</Column>
		</Table>
	);
}

function formatCity(context) {
	let data = getDataFromContext(context);
	return formatCityStateZip(data["city_name"], data["state"], data["zip_code"]);
}

function schedEarlyCaption(context) {
	let data = getDataFromContext(context);
	if (!data || data["sched_arrive_late"])
		return "Scheduled to arrive between";
	else
		return "Scheduled to arrive";
}

function schedLateCaption(context) {
	let data = getDataFromContext(context);
	if (!data || data["sched_arrive_early"])
		return "and";
	else
		return "Scheduled to arrive";	
}

function StatusCell() {
	let dataContext = useDataContext();
	let data = getDataFromContext(dataContext);
	let status = data["status"];
	let image, bottomConnector;
	let circleProps = {height: 25, width: 25, margin:0 }
	if (status === "D")
		image = <Image image="circle-blue-check" {...circleProps} />
	else  {
		let color;
		if (data["actual_arrival"] && !data["actual_departure"])
			color = "#137cdd";
		else
			color = "#979797";
		image = <Label caption={"" + (dataContext.listIndex + 1)} padding={8} {...circleProps} 
			borderRadius="50%" borderWidth={1} borderColor={color} color={color}/>
	}
	if (dataContext.listIndex < dataContext.list.length - 1) {
		let color = "#137cdd";
		let nextStatus = dataContext.list[dataContext.listIndex + 1].modelData["status"];
		if (nextStatus === "A")
			color = "#979797";
		bottomConnector = <Panel height={64} margin={0}><Label borderWidth={1} borderColor={color} fillHeight marginLeft={11.5} marginTop={0} marginBottom={-2} /></Panel>
	}

	return (
		<Panel marginTop={0} marginBottom={0}>
			{image}
			{bottomConnector}
		</Panel>
	)
}