import React from "react";
import { Button } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { Typography } from "common";

export function DispatchButton({ onClick }) {
  return (
    <Button size="small" color="primary" startIcon={<LaunchIcon />} onClick={onClick}>
      <Typography color="primary" value="Dispatch" />
    </Button>
  );
}
