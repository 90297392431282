import produce from "immer";

export function groupImagesById(data, identifierKey) {
  if (!identifierKey) return {};
  const reducer = produce((draft, item) => {
    const { [identifierKey]: idKey } = item;
    draft[idKey] = draft[idKey] ? [...draft[idKey], ...item.images] : item.images;
  });

  return data.reduce(reducer, {});
}

export function groupCallinsById(data, identifierKey) {
  if (!identifierKey) return {};
  const reducer = produce((draft, order) => {
    const { [identifierKey]: idKey } = order;
    draft[idKey] = draft[idKey] ? [...draft[idKey], order] : [order];
  });

  return data.reduce(reducer, {});
}
