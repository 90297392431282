import React from "react";
import { PageTitle } from "common";
import { Container } from "@material-ui/core";
import { useParams } from "react-router";
import { DefaultPageContainer } from "../containers/DefaultPageContainer";
import { AutoCompletePlayground } from "./AutoCompletePlayground";
import { FormikFieldsPlayground } from "./FormikFieldsPlayground";
import { FileUploaderPlayground } from "./FileUploaderPlayground";
import { ErrorPlayground } from "./ErrorPlayground";

export function Playground({ pageTitle }) {
  const { component } = useParams();

  function getPlaygroundComponent() {
    switch (component) {
      case "autocomplete":
        return <AutoCompletePlayground />;
      case "formikfields":
        return <FormikFieldsPlayground />;
      case "fileuploader":
        return <FileUploaderPlayground />;
      case "error":
        return <ErrorPlayground />;
      default:
        return (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img src="https://i.kinja-img.com/gawker-media/image/upload/c_fit,fl_progressive,q_80,w_636/1239128604271218863.gif" />
        );
    }
  }

  return (
    <DefaultPageContainer>
      <Container style={{ width: "100%", overflow: "auto", height: "100%" }}>
        <PageTitle>{pageTitle}</PageTitle>
        {getPlaygroundComponent()}
      </Container>
    </DefaultPageContainer>
  );
}
