import React from "react";
import { SEO } from "core";
import { Container } from "@material-ui/core";
import { useStyles } from "./AuthPageContainer.styles";

export function AuthPageContainer({ children = [], pageTitle }) {
  const classes = useStyles();
  return (
    <main data-test-selector="main" className={classes.root}>
      <SEO title={pageTitle} />
      <Container
        maxWidth={false}
        data-test-selector="content"
        className={classes.content}
      >
        {children}
      </Container>
    </main>
  );
}
