import { makeStyles } from "@material-ui/core/styles";
import { successColors, errorColors, primaryColors } from "core/config/theme";

// Do to where this stylesheet is created, the theme object
// passed along normally isn't fully established so we need to import
// directly from the theme config.

export const useStyles = makeStyles(() => {
  return {
    success: {
      backgroundColor: successColors.main
    },
    error: {
      backgroundColor: errorColors.main
    },
    warning: {},
    info: {
      backgroundColor: primaryColors.main
    }
  };
});
