import React, { useState, useEffect } from "react";
import { Dialog, Typography } from "common";
import { DispatchTruckIcon } from "common/SVG";
import { resolveJSONResponse, fetch } from "core/utils/fetch";
import { buildAPIUrl } from "core/utils/urls";
import { API_PATHS } from "core/config/urls";
import { formatCityStateZip, convertMilliToDateTimeFormat } from "core/utils/formatters";
import { useStyles } from "./DispatchConfirmationDialog.styles";
import { DispatchConfirmationForm } from "./DispatchConfirmationForm";

async function fetchDispatchInfo(movementId) {
  const response = await fetch(
    buildAPIUrl({
      path: API_PATHS.CARRIER_DISPATCH_INFO,
      queryParams: { movement_id: movementId }
    })
  );
  const {
    location = null,
    schedDate = null,
    actualArrival = null,
    earliestArrivalDate = null,
    stopId = null
  } = resolveJSONResponse(response);
  return {
    dispatchLocation: formatCityStateZip(location),
    scheduledDate: convertMilliToDateTimeFormat(schedDate.dateMillis),
    arrivalDate: actualArrival ? convertMilliToDateTimeFormat(actualArrival.dateMillis) : null,
    earliestArrivalDate: earliestArrivalDate
      ? convertMilliToDateTimeFormat(earliestArrivalDate.dateMillis)
      : null,
    earliestArrivalTimeZone: earliestArrivalDate ? earliestArrivalDate.timezone : null,
    stopId
  };
}

export function DispatchConfirmationDialog({ open, onClose, id, movementId }) {
  const [location, setLocation] = useState(null);
  const [scheduledDateTime, setScheduledDateTime] = useState(null);
  const [arrivalDateTime, setArrivalDateTime] = useState(null);
  const [arrivalDateTimeFlag, setArrivalDateTimeFlag] = useState(false);
  const [earliestArrivalDate, setEarliestArrivalDate] = useState(null);
  const [earliestArrivalTimeZone, setEarliestArrivalTimeZone] = useState(null);
  const [stopId, setStopId] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (!open) return;
    async function fetchEffect() {
      const {
        dispatchLocation,
        scheduledDate,
        arrivalDate,
        earliestArrivalDate,
        earliestArrivalTimeZone,
        stopId
      } = await fetchDispatchInfo(movementId);
      setLocation(dispatchLocation);
      setScheduledDateTime(scheduledDate);
      setEarliestArrivalDate(earliestArrivalDate);
      setEarliestArrivalTimeZone(earliestArrivalTimeZone);
      setStopId(stopId);
      if (arrivalDate) {
        setArrivalDateTime(arrivalDate);
        setArrivalDateTimeFlag(true);
      } else {
        setArrivalDateTime(null);
      }
    }
    fetchEffect();
  }, [movementId, open]);

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={onClose}
      classes={{
        content: classes.dialogContent
      }}
    >
      <header className={classes.header}>
        <DispatchTruckIcon className={classes.dispatchTruckIcon} />
        <Typography variant="h4" value="Dispatch Confirmation" />
        <Typography
          fontWeight="bold"
          variant="body1"
          color="primary"
          value={`Order Number ${id}`}
        />
        <Typography className={classes.location} variant="body2" value={location} />
      </header>
      <DispatchConfirmationForm
        movementId={movementId}
        onClose={onClose}
        location={location}
        scheduledDateTime={scheduledDateTime}
        arrivalDateTime={arrivalDateTime}
        arrivalDateTimeFlag={arrivalDateTimeFlag}
        setArrivalDateTime={setArrivalDateTime}
        earliestArrivalDate={earliestArrivalDate}
        earliestArrivalTimeZone={earliestArrivalTimeZone}
        stopId={stopId}
      />
    </Dialog>
  );
}
