import React from "react";
import { PageTitle } from "common";
import { DefaultPageContainer } from "pages/containers";

export function DriverPortal({ pageTitle }) {
  return (
    <DefaultPageContainer pageTitle={pageTitle}>
      <PageTitle fontWeight="regular" value={pageTitle} />
    </DefaultPageContainer>
  );
}
