import { callApi } from "lib/util/api";
import getLogger from "./Logger";
import { getData } from "lib/util/DataStore";
import { isEmptyString } from "./StringUtil";
import { parseDateTime, formatDateTime, getDateFormat } from "./Date";

const modelRegex = /[^{}]+(?=})/;
const log = getLogger("lib.util.ModelUtil");

// this function allows you to inline model data into another string like "The driver's name is {driver_name}"
export function replaceModelData(format, data, displayType, displayFormat) {
	if (format == null)
		return null;
	let match = modelRegex.exec(format);
	while (match != null) {
		let display = getModelDataDisplayValue(data, match[0], displayType, displayFormat);
		if (display == null)
			display = "";
		format = format.substring(0, match.index - 1) + display +
			format.substring(match.index + match[0].length + 1);
		match = modelRegex.exec(format);
	}
	return format;
}

export function getDataFromContext(dataContext) {
	if (dataContext == null)
		return null;
	if (dataContext.data)
		dataContext = dataContext.data;
	if (dataContext.list == null)
		return null;
	const listItem = dataContext.list[dataContext.listIndex];
	if (listItem == null)
		return null;
	return listItem.modelData;
}

export function getModelDataDisplayValue(dataContext, fieldName, displayType, format) {
	let data = getDataFromContext(dataContext);
	if (data == null)
		return "";
	let result = data[fieldName];
	if (result != null && displayType != null) {
		if (displayType === "currency") {
			if (typeof result === "string")
				result = parseFloat(result);
			result = "$" + result.toFixed(2);
		}
		else if (displayType === "datetime" || displayType === "date" || displayType === "time")
			result = formatDateTime(parseDateTime(result), getDateFormat(displayType, format));
		else if (displayType === "decimal") {
			if (typeof result === "string") {
				const num = parseFloat(result);
				if (!isNaN(num))
					result = num;
			}
			if (result.toFixed)
				result = result.toFixed(1);
		}
	}
	return result;
}

export function fetchModelData(modelName, dataContext, onComplete, setLoading) {
	log.debug("Fetching model data for %s with context %o", modelName, dataContext);
	if (dataContext != null) {
		modelName = replaceModelData(modelName, dataContext);
		log.debug("Model name updated to %s based on data context", modelName);
	}
	if (setLoading)
		setLoading(true);
	callApi("model/" + modelName).then(data => {
		log.debug("Fetched data from model %s %o", modelName, data);
		if (setLoading)
			setLoading(false);
		onComplete(data);
	}).catch(reason => {
		log.error("Fetch of %s failed %s - %s", modelName, dataContext, reason);
		if (setLoading)
			setLoading(false);
	})
}

export function submitModel(componentContext, setLoading, onComplete, onError) {
	let componentValues = getData(componentContext.valueOwner.dataId);
	let submitValues = {};
	log.debug("Submit model context %o components %o", componentContext, componentValues);
	for (let key in componentValues)
		submitValues[key] = componentValues[key].value;
	if (componentContext.valueOwner.fixedData != null)
		submitValues = { ...submitValues, ...componentContext.valueOwner.fixedData };
	log.debug("submit %o", submitValues);
	submitValues = [submitValues]; // server wants an array - only supporting submitting one record for 
	let dataString = JSON.stringify(submitValues);
	if (setLoading)
		setLoading(true);
	let url = "model/" + componentContext.data.modelName;
	if (componentContext.data.url)
		url = componentContext.data.url;
	callApi(url, { method: "post", body: dataString })
		.then(data => {
			onComplete(data);
			if (setLoading)
				setLoading(false);
		})
		.catch(reason => {
			log.debug("Error submitting model %o", componentContext);
			log.debug(reason);
			if (setLoading)
				setLoading(false);
			if (onError)
				onError(reason);
		});
}

export function searchModel(componentContext, setLoading) {
	let searchComponents = getData(componentContext.valueOwner.dataId);
	let searchValues = {};
	log.debug("Search model context %o components %o", componentContext, searchComponents);
	for (let key in searchComponents) {
		const comp = searchComponents[key];
		const value = comp.value;
		if (!isEmptyString(value))
			searchValues["filter." + key] = value;
	}
	let dataString = JSON.stringify(searchValues);
	log.debug("Search data string %s", dataString);
	if (setLoading)
		setLoading(true);
	callApi("model/" + componentContext.data.modelName, { method: "put", body: dataString })
		.then(data => {
			log.debug("Fetched data from model %o", data);
			componentContext.data.setData(data);
			if (setLoading)
				setLoading(false);
		})
		.catch(reason => {
			log.debug("Error searching model %o", componentContext);
			log.debug(reason);
			if (setLoading)
				setLoading(false);
		});
}