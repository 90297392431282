import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 0,
    height: 66,
    padding: 0,
    paddingBottom: theme.spacing(3)
  },
  inline: {
    backgroundColor: theme.palette.common.white
  },
  count: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.smoke,
    marginRight: theme.spacing(6)
  }
}));
