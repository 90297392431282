import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";

export const ImageIcon = memo(function ImageIcon(props) {
  return (
    <SvgIcon {...props}>
      <CropOriginalIcon />
    </SvgIcon>
  );
});
