import React, { useState } from "react";
import {Component, Image, DisplayTypes, useComponentContext, updateValueOwner } from ".";
import DatePicker from "./DatePicker";
import { makeStylesLight} from "lib/util";
import { useMountEffect, getLogger, isEmptyString } from "lib/util";
import { getModelDataDisplayValue, replaceModelData } from "lib/util/ModelUtil";
import { parseDateWithKeywords } from "lib/util/Date";

const log = getLogger("lib.components.TextBox");
let styles;

function useStyles() {
  if (styles == null)
    styles = makeStylesLight("txt", {
      container: {
		    position:"relative",
		    height:"100%"
	    },
    caption: {
      fontSize: "11.5px",
      fontFamily: "Roboto, Helevtica, Arial, sans-serif",
      fontWeight: "400",
      color: "#666666",
      whiteSpace: "nowrap",
      paddingLeft: "2px",
      marginBottom: "0px"
    },
    captionAsLabel: {
      paddingLeft: "0px",
    },
    captionFocused: {
      color: "#137CDD"
    },
    labelValue: { padding: "4px 8px" },
    content: {
      display: "flex",
      height: "calc(100% - 22px)"
    },
    fullHeight: {
      height: "100%"
    },
    contentNoCaption: {
      height: "100px"
    },
    input: {
      width:"99%",
      border: "1px solid #CDCDCD",
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: "8px",
      paddingRight: "2px",
      height:"38px",
      fontWeight: "400",
      outline: "none",
      borderRadius: "4px",
      "&:disabled": {
        backgroundColor: "unset"
      },
      "&:focus": {
        borderColor: "#137CDD"
      }

    },
    textarea: {
      resize: "none",
      border: "1px solid #CDCDCD",
      outline: "none",
      width: "100%",
      height: "100%",
      borderRadius: "4px",
      ":disabled": {
        backgroundColor: "unset"
      }

    },
    button: {
      width: "24px",
      padding: "3px",
      marginBottom: "1px",
      borderRadius: "2px",
      ":active": {
        marginTop: "1px"
      }
    }
    });
  return styles;
}

export default function TextBox(props) {
	let classes = useStyles();
	let context = useComponentContext();
	let [focused, setFocused] = useState();

	let propValue = props.value;
	if (propValue === undefined)
		propValue = getModelDataDisplayValue(context.data, props.fieldName, props.displayType, props.format);
	if (isEmptyString(propValue))
		propValue = parseDefault(props.default, props.displayType);
	let caption = props.caption;
	if (caption) {
		if (typeof caption === "function")
			caption = caption(context);
		caption = replaceModelData(caption, context.data);
	}
    log.debug("Props %o  Context %o  Value %o", props, context, propValue);
// RYAN, what's a good way to handle the context value changing but still allowing changing it through state?
// He's probably going to say we shouldn't keep the state here and should update the context's value.  That's probably true, but a
// TextBox isn't always data-bound so we need to allow for still changing the value.  Should be doable.
	let [value, setValue] = useState();
	if (value != null)
		propValue = value;

    //let value = propValue;
	useMountEffect(() => {updateValueOwner(context, props, propValue, setValue)});
	if (!propValue && props.nullDisplayValue) {
		propValue = props.nullDisplayValue;
		if (propValue === "hide")
			return null;
	}

    return (
        <Component {...props}>
            <div className={classes.container}>
                {renderInput(context, classes, props, caption, propValue, setValue, focused, setFocused)}
            </div>
        </Component>
    );
}

function renderInput(context, classes, props, caption, value, setValue, focused, setFocused) {
    if (getVariant(context, props) === "label")
        return renderAsLabel(context, classes, props, caption, value);
    else
        return renderAsInput(context, classes, props, caption, value, setValue, focused, setFocused);
}

function getVariant(context, props) {
	if (props.variant !== undefined)
		return props.variant;
	for (let i = context.hierarchy.length - 1; i >= 0; i--)
		if (context.hierarchy[i].props.textBoxVariant !== undefined)
			return context.hierarchy[i].props.textBoxVariant;
	return "default";
}

function renderAsLabel(context, classes, props, caption, value) {
    var input = (
        <div className={classes.fullHeight}>
            <label className={classes.caption + " " + classes.captionAsLabel}>{caption}</label>
            <div>{value}</div>
        </div>
    );
    return input;
}

function renderAsInput(context, classes, props, caption, value, setValue, focused, setFocused) {
	const focusProps = {
		onFocus: (event) => {handleFocus(event, props.onFocus, setFocused)},
		onBlur: (event) => {handleBlur(event, props.onFocus, setFocused)},
	};

    let editorProps = {
		...focusProps,
		placeholder: props.placeHolder,
		name: props.name, // common names, like "email" will tie to the user's auto-complete
        onChange:(event) => handleChange(context, props, event.target.value, setValue),
        onKeyDown: keyDown,
		value: value };
    let disabled = undefined;
    if (props.designer != null)
        disabled = { disabled:true };
    let contentClassName = classes.content;
    if (!caption)
		contentClassName += " " + classes.contentNoCaption;
	let captionClass = classes.caption;
	if (focused)
		captionClass += " " + classes.captionFocused;
	let button;
    if (props.button)
        button = <div {...disabled} onClick={buttonClick} className={classes.button}><Image image={props.button} height={20} /></div>
	let input;
	if (props.displayType === DisplayTypes.DATE)
		input = <DatePicker value={value} {...focusProps} onChange={(value) => handleDateChange(context, props, value, setValue)}/>
    else if (props.multiline)
        input = (<textarea {...disabled} className={classes.textarea} {...editorProps}></textarea>);
    else
		input = (<input {...editorProps} {...disabled} className={classes.input} {...editorProps}/>);
    var result = (
        <div className={classes.fullHeight} style={props.style} >
            <label className={captionClass}>{caption}</label>
            <div className={contentClassName}>
                {input}
                {button}
            </div>
        </div>
    );
    return result;
}

function buttonClick() {

}

function handleBlur(event, onBlur, setFocused) {
	setFocused(false);
	if (onBlur)
		onBlur(event);
}

function handleDateChange(context, props, value, setValue) {
	handleChange(context, props, value, setValue);
}

function handleChange(context, props, value, setValue) {
	setValue(value);
	updateValueOwner(context, props, value);
	if (props.onChange)
		props.onChange(value);
}

function keyDown() {

}

function handleFocus(event, onFocus, setFocused) {
	setFocused(true);
	if (onFocus)
		onFocus(event);
}

function parseDefault(value, displayType) {
	if (displayType === "date") {
		value = parseDateWithKeywords(value);
	}
	return value;
}
