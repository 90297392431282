import FileSaver from "file-saver";
import { MEDIA_TYPE_TEXT } from "./constCapsule";

/**
 * Converts a binary string into an array buffery.
 *
 * @export
 * @param {*} binaryString
 * @returns
 */
export function binaryToArrayBuffer(binaryString) {
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

/**
 * Convert an array buffer into a url
 *
 * @export
 * @param {*} arrayBuffer
 * @returns
 */
export function arrayBufferToUrl(arrayBuffer) {
  const blob = new Blob([arrayBuffer]);
  const urlCreator = window.URL || window.webkitURL;
  return urlCreator.createObjectURL(blob);
}

/**
 * Helper method to generate the HOC display name for debugging.
 * Returns a formatted string that combines both the HOC's name,
 * and the sniffed out WrappedComponent name.
 * @export
 * @param {*} name The HOC's name
 * @param {*} WrappedComponent The component that the HOC is wrapping
 */
export function createHOCDisplayName(name, WrappedComponent) {
  const componentName = WrappedComponent.displayName || WrappedComponent.name || "HOC";
  return `With${name}(${componentName})`;
}

/**
 * Functional programming utility that allows us to write deeply nested function transformations
 * without the rightward drift of the code.
 * We can write this: compose(func1, func2, func3, func4), instead of this: func1(func2(func3(func4)))).
 *
 * Learn more about compose here:
 * https://redux.js.org/api/compose/
 * https://stackoverflow.com/questions/41357897/understanding-compose-functions-in-redux
 *
 * @export
 * @param {functions} fns The functions to compose. Each function is expected to accept a single parameter.
 * Its return value will be provided as an argument to the function standing to the left, and so on.
 * The exception is the right-most argument which can accept multiple parameters,
 * as it will provide the signature for the resulting composed function.
 * @returns {function} The final function obtained by composing the given functions from right to left.
 */
export const compose = (...fns) =>
  fns.reduceRight(
    (prevFn, nextFn) => (...args) => nextFn(prevFn(...args)),
    (value) => value
  );

/**
 * Saves to client using raw/buffer data and downloading it as a blob.
 *
 * @export
 * @param {string} fileName - Name to save the file as
 * @param {string} filePath - Path/Endpoint to set as the "href" property to request the file from.
 * @param {string} [filePathType=""] = The media type prefix to append to the "href" property e.g. "data:application/pdf;base64,".
 */
export function saveAsBlob(fileName, fileContent, mediaType = MEDIA_TYPE_TEXT) {
  const blob = new Blob([fileContent], { type: mediaType });
  FileSaver.saveAs(blob, fileName, { autoBom: true });
}

/**
 * Saves to client using a Url as the source of content. URLs within the same origin will just use a[download].
 * Otherwise, it will first check if it supports cors header with a synchronous head request.
 * If it does, it will download the data and save using blob URLs. If not, it will try to download it using a[download].
 *
 * @export
 * @param {string} fileName - Name to save the file as
 * @param {string} fileUrl - Url used to get the file data
 * @param {string} [mediaType="MEDIA_TYPE_TEXT"] = The media type prefix to append to the "href" property e.g. "application/pdf;base64,".
 */
export function saveAsUrl(fileName, fileUrl, mediaType = MEDIA_TYPE_TEXT) {
  FileSaver.saveAs(`data:${mediaType},${fileUrl}`, fileName);
}

/**
 * Performs the flattening of an object recursively
 * @param {Object} obj Object to be flattened
 * @param {Object} options { objects = true, arrays = false, separator = "." }={}]
 * @returns {Object} Flattened object
 */
export function flatten(obj, { objects = true, arrays = false, separator = "." } = {}) {
  function step(obj, flatDataRow, currentPath) {
    Object.keys(obj).forEach((key) => {
      const newPath = currentPath ? `${currentPath}${separator}${key}` : key;
      const value = obj[key];

      if (
        objects &&
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value) &&
        Object.prototype.toString.call(value.toJSON) !== "[object Function]" &&
        Object.keys(value).length
      ) {
        step(value, flatDataRow, newPath);
        return;
      }

      if (arrays && Array.isArray(value)) {
        step(value, flatDataRow, newPath);
        return;
      }

      flatDataRow[newPath] = value;
    });

    return flatDataRow;
  }

  return step(obj, {});
}

/**
 * Returns a boolean if the passed argument is an object.
 *
 * @export
 * @param {*} a
 * @returns
 */
export function isObject(a) {
  return a && typeof a === "object" && !Array.isArray(a);
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}
