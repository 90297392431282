import React from "react";
import { CircularProgress } from "@material-ui/core";
import { useStyles } from "./styles";

export function Loading() {
  const classes = useStyles();
  return (
    <div className={classes.overlay}>
      <CircularProgress className={classes.loadingSpinner} color="primary" />
    </div>
  );
}
