import React from "react";
import { Typography, Table, TableColumn } from "common";

export function CommentsTable({ data = [] }) {
  return (
    <Table
      data={data}
      emptyText="No Comments Available for Display"
      inline
      disableFilter
      pagination
    >
      <TableColumn
        label="Date"
        colSpan={1}
        Component={({ enteredDate }) => <Typography component="div" value={enteredDate} />}
      />
      <TableColumn
        label="Entered By"
        Component={({ enteredBy }) => <Typography component="div" value={enteredBy} />}
      />
      <TableColumn
        label="Comments"
        Component={({ comment }) => <Typography component="div" value={comment} />}
      />
    </Table>
  );
}
