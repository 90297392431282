import React from "react";
import { Popover, MenuItem } from "@material-ui/core";

export default function Popup({onClose, anchorEl, anchorAlign="left", className, components }) {
	let items = [];
	for (let i = 0; components != null && i < components.length; i++)
		items.push(
			<MenuItem key={"popup-item-" + i} 
				onClick={(event) => itemClicked(event, components[i].props.onClick, onClose)}>
				{components[i]}
			</MenuItem>);
	return (
		<Popover 
			open={true}
			className={className} 
			onClose={onClose} 
			anchorEl={anchorEl} 
			anchorOrigin={{vertical: 'bottom', horizontal: anchorAlign }}
        	transformOrigin={{vertical: 'top', horizontal: anchorAlign }}>
			{items}
		</Popover>
	);
}

function itemClicked(event, childClick, onClose) {
	if (childClick && event.target === event.currentTarget) // make sure we don't fire onclick twice
		childClick(event);
	onClose();
}