import { parseAsync } from "json2csv";
import { flatten, saveAsBlob } from "core/utils/common";
import { MEDIA_TYPE_CSV } from "./constCapsule";

/**
 * Converts an array of objects into csv using json2csv's parseAsync method.
 *
 * @export
 * @param {*} [data=[]]
 * @param {*} [options={}]
 * @returns
 */
export async function toCSV(data = [], options = {}) {
  if (!data.length) return undefined;
  const columns = Object.keys(flatten(data[0], { separator: "_" }));
  const csv = await parseAsync(data, {
    fields: columns,
    encoding: "utf-8",
    transforms: [(row) => flatten(row, { separator: "_" })],
    ...options
  });
  return csv;
}

/**
 * Uses toCSV to convert data to CSV format then saves the file contents
 * as a blob using filesaver.js
 *
 * @export
 * @param {*} fileName
 * @param {*} data
 */
export async function downloadCSV(fileName, data) {
  const fileContent = await toCSV(data);
  saveAsBlob(`${fileName}.csv`, fileContent, MEDIA_TYPE_CSV);
}
