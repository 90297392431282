import React, { memo } from "react";
import Component from "./Component";
import {isSpecified} from "./ComponentUtil";
import { isEmptyString, getLogger, makeStylesLight } from "lib/util";
import {getModelDataDisplayValue, replaceModelData} from "../util/ModelUtil";
import {useComponentContext} from "./Context";
import Image from "./Image";

const log = getLogger("lib.components.Label");

let styles;

function useStyles() {
  if (styles == null)
    styles = makeStylesLight("lbl", {
      labelComp: { display: "flex", alignItems: "center", "letter-spacing": "0.01071em"}
    });
  return styles;
}

function Label(props) {
	let classes = useStyles();
    const context = useComponentContext();
	let caption = getCaption(props, context);

	let image;
	if (props.image != null)
		image = <Image image={props.image} marginRight={props.imageSpacing} />
	let style = {...props.style};
	if (props.vAlign) {
		if (props.vAlign === "top")
			style.alignItems = "flex-start";
		else if (props.vAlign === "bottom")
			style.alignItems = "flex-end";
	}
	let className = classes.labelComp;
	if (props.className != null)
		className += " " + props.className;
	if (caption == null && props.nullDisplayValue === "hide")
		return null;
    return (
        <Component {...props} className={className} style={style} >
			{image}
            {caption}
        </Component>
    );
}

function getCaption(props, context) {
	const dataContext = context.data;
    log.debug("Rendering label props %o context %o", props, context);
    let result = props.caption;
    if (!isSpecified(props.caption)) {
        if (isSpecified(props.fieldName)) {
			if (props.replaceModelData)
				result = replaceModelData(props.fieldName, dataContext, props.displayType, props.format);
			else
            	result = getModelDataDisplayValue(dataContext, props.fieldName, props.displayType, props.format);
            if (result == null && isEmptyString(props.caption)) {
                if (props.hideIfEmpty === true)
                    return null;
                else if (props.defaultCaption != null) // this should be deprecated in favor of more descriptive nullDisplayValue
                    result = props.defaultCaption;
                else if (props.nullDisplayValue) {
					if ("hide" !== props.nullDisplayValue)
						result = props.nullDisplayValue;
				}
                else if (isEmptyString(props.caption))
                    result = null;
            }
        }
        else if (props.designer == null)
            result = replaceModelData(props.caption, props.modelData);
    }

    if (isSpecified(props.linkURL) && props.designer == null)
		result = (<a href={replaceModelData(props.linkURL, props.modelData)}>{result}</a>);
	else if (props.variant === "link") {
		result = (<a href={replaceModelData(props.linkURL, props.modelData)}>{result}</a>);
	}
    if ((result == null || result.length === 0) && props.designer != null) {
        if (props.fieldName == null)
            result = props.name;
        else
            result = props.fieldName;
	}
	if (typeof result === "function")
		result = result(context);
    return result;
}

export default memo(Label);
