import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: theme.spacing(1)
  },
  icon: {
    marginRight: 4
  },
  grow: {
    flexGrow: 1
  }
}));
