import { createSelector } from "reselect";
import { UserTypeSelector } from "./UserSelectors";

const OrdersSelector = ({ orders }) => orders;
const ActiveCategorySelector = ({ orders }) => orders.activeCategories;
const OrdersCategoryArgSelector = (_, category) => category;
const IdArgSelector = (_, id) => id;

export const getOrders = createSelector([OrdersSelector], (orders) => orders);

export const getActiveCategory = createSelector(
  [UserTypeSelector, ActiveCategorySelector],
  (userType, activeCategories) => activeCategories[userType]
);

export const getActiveOrdersType = createSelector(
  [getOrders, getActiveCategory],
  (orders, activeCategory) => orders[activeCategory].ordersType
);

export const getActiveOrdersAndOrdersType = createSelector(
  [getOrders, getActiveCategory, getActiveOrdersType],
  (orders, activeCategory, activeOrdersType) => ({
    ordersType: activeOrdersType,
    ...orders[activeCategory][activeOrdersType]
  })
);

export const getActiveOrdersAndTypeByCategory = createSelector(
  [getOrders, OrdersCategoryArgSelector],
  (orders, ordersCategory) => ({
    ordersType: orders[ordersCategory].ordersType,
    ...orders[ordersCategory][orders[ordersCategory].ordersType]
  })
);

export const getActiveCallinsByOrderId = createSelector(
  [getActiveOrdersAndOrdersType, IdArgSelector],
  (activeOrders, orderId) => activeOrders.callinsByOrderId[orderId]
);

export const getActiveImagesByMovementId = createSelector(
  [getActiveOrdersAndOrdersType, IdArgSelector],
  (activeOrders, movementId) => activeOrders.imagesByMovementId[movementId]
);
