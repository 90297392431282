import React from "react";
import {
  Container,
  Button,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grow,
  Popper,
  ButtonGroup
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { PATHS } from "core/config/urls";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Typography } from "common";

const options = [
  { message: "Bad Request", code: "400" },
  { message: "Unauthorized", code: "401" },
  { message: "Payment Required", code: "402" },
  { message: "Not Found", code: "404" },
  { message: "Method Not Allowed", code: "405" },
  { message: "Not Acceptable", code: "406" },
  { message: "Proxy Authentication Required", code: "407" },
  { message: "Request Time-out", code: "408" },
  { message: "Conflict", code: "409" },
  { message: "Gone", code: "410" },
  { message: "Length Required", code: "411" },
  { message: "Precondition Failed", code: "412" },
  { message: "Request Entity Too Large", code: "413" },
  { message: "Request-URI Too Large", code: "414" },
  { message: "Unsupported Media Type", code: "415" },
  { message: "Requested Range Not Satisfiable", code: "416" },
  { message: "Expectation Failed", code: "417" },
  { message: "I'm a teapot", caption: "Secret subtitle :)", code: "418" },
  { message: "Unprocessable Entity", code: "422" },
  { message: "Locked", code: "423" },
  { message: "Failed Dependency", code: "424" },
  { message: "Unordered Collection", code: "425" },
  { message: "Upgrade Required", code: "426" },
  { message: "Precondition Required", code: "428" },
  { message: "Too Many Requests", code: "429" },
  { message: "Request Header Fields Too Large", code: "431" },
  { message: "Internal Server Error", code: "500" },
  { message: "Not Implemented", code: "501" },
  { message: "Bad Gateway", code: "502" },
  { message: "Service Unavailable", code: "503" },
  { message: "Gateway Time-out", code: "504" },
  { message: "HTTP Version Not Supported", code: "505" },
  { message: "Variant Also Negotiates", code: "506" },
  { message: "Insufficient Storage", code: "507" },
  { message: "Bandwidth Limit Exceeded", code: "509" },
  { message: "Not Extended", code: "510" },
  { message: "Network Authentication Required", code: "511" }
];

export function ErrorPlayground() {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () =>
    history.push(PATHS.ERROR, {
      message: !["500", "404"].includes(options[selectedIndex].code)
        ? options[selectedIndex].message
        : null,
      status: options[selectedIndex].code,
      caption: options[selectedIndex].caption
    });

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Container maxWidth="xs">
      <Typography>Select an Error Type:</Typography>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button onClick={handleClick}>
          {`${options[selectedIndex].code} ${options[selectedIndex].message}`}
        </Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  style={{ maxHeight: 300, overflow: "auto" }}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.code}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {`${option.code} ${option.message}`}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Container>
  );
}
