import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  root: {
    whiteSpace: "nowrap"
  },
  verticalAlignMiddle: {
    verticalAlign: "middle !important"
  },
  inlineCell: {
    backgroundColor: "transparent",
    border: "none"
  },
  disableSpacing: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important"
  },
  disableGutters: {
    paddingRight: "0 !important",
    paddingLeft: "0 !important"
  }
}));
