import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";
import { CancelOutlined as MuiCancelIcon } from "@material-ui/icons";

export const CancelIcon = memo(function CancelIcon(props) {
  return (
    <SvgIcon {...props}>
      <MuiCancelIcon />
    </SvgIcon>
  );
});
