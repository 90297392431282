/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { getLogger, getCompanySettings } from "lib/util";
import MarkerClusterer from "@google/markerclusterer";
import { getSVG } from "../Image";

const log = getLogger("lib.components.map-impl.GoogleMap");

export function GoogleMap(props) {
	const [map, setMap] = useState();
	const [markerClusterer, setMarkerClusterer] = useState();
	const [markers] = useState([]);
	log.debug("Render map props %o Map %o", props, map);
	useEffect(() => {
		const url = getCompanySettings()["google_maps_url"];
		const apiKey = getCompanySettings()["google_api_key"];
		if (url == null || apiKey == null)
			throw new Error("Google Maps URL and APIkey is not present in mapping vendor control.");
		if (window.google == null) {
			const script = document.createElement("script");
			script.src = url + apiKey;
			window.document.body.appendChild(script);
			script.onload = () => loadMap(props.name, setMap, props.pins, props.onPinClick, setMarkerClusterer, markers);
			log.debug("Added google map source element %o", script);
		}
		else
			loadMap(props.name, setMap, props.pins, props.onPinClick, setMarkerClusterer, markers);
	}, []); // ComponentDidMount

	useEffect(() => {
		if (map != null) {
			validatePins(props.pins);
			createMarkers(map, props.pins, markers, markerClusterer, props.onPinClick);
			fitMarkersOnMap(map, markers);
			setMaximumZoom(map);
			//			setDefaultCenter(map, markers);
		}
		log.debug("After effect %o", map);
	}, [props.pins]); // ComponentDidUpdate - [props.pins]

	return <div id={props.name} />
}

function validatePins(pins) {
	for (let i = 0; pins != null && i < pins.length; i++)
		if (pins[i].lng > 0)
			pins[i].lng *= -1;
}

function loadMap(id, setMap, pins, onPinClick, setMarkerClusterer, markers) {
	let element = document.getElementById(id);
	if (element == null)
		throw new Error("Cannot find map element with id " + id);
	let map = new window.google.maps.Map(element, {
		disableDefaultUI: false,
		center: new window.google.maps.LatLng(39.8283, -98.5795),
		zoom: 5
	});
	log.debug("loadMap for id %s Element %o Map %o", id, element, map);
	let markerClusterer = new MarkerClusterer(map, [], {
		styles: [
			{
				url: getSVG("map-pin-cluster"),
				height: 48,
				width: 48
			}
		],
		maxZoom: 12,
		gridSize: 20
	});
	setMap(map);
	setMarkerClusterer(markerClusterer);
	validatePins(pins);
	createMarkers(map, pins, markers, markerClusterer, onPinClick);
	fitMarkersOnMap(map, markers);
	setMaximumZoom(map);
}

function createMarkers(map, pins, markers, markerClusterer, onPinClick) {
	clearMarkers(markers, markerClusterer);
	let clusters = [];
	while (pins != null && pins.length !== 0) {
		let pin = pins.splice(0, 1)[0];
		let duplicateLocations = findDuplicateLocation(pins, pin);
		if (duplicateLocations.length > 0)
			createMultiLocationPin(map, markers, duplicateLocations, onPinClick);
		else
			createSingleLocationPin(map, markers, clusters, pin, onPinClick);
	}
	markerClusterer.addMarkers(clusters);
}

function clearMarkers(markers, markerClusterer) {
	for (let i = markers.length - 1; i >= 0; i--) {
		markers[i].setMap(null);
		markers.splice(i, 1);
	}
	markerClusterer.clearMarkers();
}

function findDuplicateLocation(pins, pin) {
	let duplicatePins = [];
	if (pin.clusterable !== false) {
		for (let i = pins.length - 1; i >= 0; i--) {
			if (pins[i].lat === pin.lat)
				duplicatePins.push(pins.splice(i, 1)[0]);
		}
	}
	if (duplicatePins.length > 0)
		duplicatePins.push(pin);
	duplicatePins = duplicatePins.reverse();
	return duplicatePins;
}

function createMultiLocationPin(map, markers, duplicateLocations, onPinClick) {
	let count = duplicateLocations.length.toString();
	let multiMarkers = [];
	for (let i = 0; i < duplicateLocations.length; i++) {
		let pin = duplicateLocations[i];
		let markerImg = {
			url: getSVG("map-pin-white-dot-large"),
			scaledSize: new window.google.maps.Size(24, 40),
			labelOrigin: new window.google.maps.Point(11, 12)
		}
		let position = {
			lat: pin.lat,
			lng: pin.lng
		}
		let marker = new window.google.maps.Marker({
			icon: markerImg,
			label: {
				text: count,
				color: '#137CDD',
				fontSize: '13px',
				fontWeight: 'bold',
			},
			position: position,
			pin: pin,
			id: pin.pinId
		});
		multiMarkers.push(marker);
		marker.setMap(map);
		markers.push(marker);
	}
	setupMultiTooltip(map, duplicateLocations, multiMarkers);
	addMultiMarkerListeners(multiMarkers, onPinClick);
}

function createSingleLocationPin(map, markers, clusters, pin, onPinClick) {
	log.debug("Create pin %o", pin);
	let markerImg = { url: getSVG("map-pin-white-dot-small") };
	let label = setupCaptionedPin(pin, markerImg);
	if (pin.image)
		markerImg.url = pin.image;
	let marker = createMarker(markerImg, pin, pin.pinId, label);
	addPinClickListener(marker, onPinClick);
	setupTooltip(map, pin, marker);
	markers.push(marker);
	if (pin.clusterable === false)
		marker.setMap(map);
	else
		clusters.push(marker);
}

function setupCaptionedPin(pin, markerImg) {
	if (pin.caption) {
		let fontColor = '#137CDD';
		if (pin.captionColor)
			fontColor = pin.captionColor;
		markerImg.url = getSVG("map-pin-outlined");
		return { text: pin.caption, color: fontColor, fontWeight: '500' };
	}
	return null;
}

function setupTooltip(map, pin, marker) {
	if (pin.tooltip) {
		let infoWindow = new window.google.maps.InfoWindow({
			content: '<div>' + pin.tooltip + '</div>' // we'll want the ability for the caller to pass more complicated html 
		});
		marker.addListener('mouseover', function () {
			infoWindow.open(map, marker);
		});
		marker.addListener('mouseout', function () {
			infoWindow.close();
		});
	}
}

function setupMultiTooltip(map, pins, markers) {
	let content = "<div><div>" + pins.length + " markers at this exact location.</div>";
	for (let i = 0; i < 20 && i < pins.length; i++) {
		const pin = pins[i];
		if (pin.tooltip)
			content += "<div>" + pin.tooltip + "</div>";
	}
	const infoWindow = new window.google.maps.InfoWindow({
		content: content
	});

	for (let i = 0; i < 20 && i < markers.length; i++) {
		const marker = markers[i];
		marker.addListener('mouseover', function () {
			infoWindow.open(map, marker);
		});
		marker.addListener('mouseout', function () {
			infoWindow.close();
		});
	}
}


function createMarker(markerImg, pin, pinId, label) {
	let position = {
		lat: pin.lat,
		lng: pin.lng
	}
	let marker = new window.google.maps.Marker({
		icon: markerImg,
		position: position,
		id: pinId,
		label: label,
		pin: pin
	});
	return marker;
}

function addPinClickListener(marker, onPinClick) {
	if (onPinClick)
		marker.addListener('click', () => { onPinClick([marker.pin]) });
}

function addMultiMarkerListeners(markers, onPinClick) {
	if (onPinClick) {
		let pins = [];
		for (let i = 0; i < markers.length; i++) {
			pins.push(markers[i].pin);
			markers[i].addListener('click', () => { onPinClick(pins) });
		}
	}
}

function fitMarkersOnMap(map, markers) {
	if (markers.length !== 0) {
		let bounds = new window.google.maps.LatLngBounds();
		for (var i = 0; i < markers.length; i++) {
			bounds.extend(markers[i].getPosition());
		}
		map.fitBounds(bounds);
	}
}

function setMaximumZoom(map) {
	if (map.zoom > 10)
		map.setZoom(10);
}