import { makeStyles } from "@material-ui/core/styles";
import { successColors } from "core/config/theme";

export const useStyles = makeStyles((theme) => ({
  form: {
    width: 320
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(4),
    "& .MuiFormControl-root:not(:first-of-type)": {
      marginLeft: theme.spacing(4)
    }
  },
  divider: {
    marginBottom: theme.spacing(4)
  },
  success: {
    "& .MuiInputBase-input.Mui-disabled": {
      backgroundColor: successColors.light
    },
    "& .MuiIconButton-root.Mui-disabled": {
      color: successColors.dark
    },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: successColors.light
    }
  }
}));
