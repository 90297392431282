import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(2)
  },
  inline: {
    marginBottom: 0,
    borderBottom: `1px solid ${theme.palette.common.mist}`
  }
}));
