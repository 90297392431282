export const BASE_API_URL = calculateBaseUrl();

export const PATHS = {
	CALLIN: "/callin",
	CARRIER_PORTAL: "/carrier-portal",
	ERROR: "/error",
	LOCATIONS_SKELETON: "/main/locations/auto",
	LOGIN: "/login",
	OFFER: "/offer",
	PLAYGROUND: "/playground/:component",
	RESET_PASSWORD: "/reset",
	ROOT: "/",
	SETUP_ACCOUNT: "/setup",
	TRACK: "/track"
};

export const API_PATHS = {
	ACCEPT_OFFER: "carrier/offer/accept",
	BRANDING_LOGO: "branding/logo",
	BRANDING: "branding/all",
	CARRIER_AVAILABLE_ORDERS: "model/br/carrier_available_orders",
	CARRIER_CALLIN: "carrier/callin",
	CARRIER_CALLIN_HISTORY: "carrier/callin/history",
	CARRIER_CALLIN_NEXT_SCHEDULED_DATE: "carrier/callin/nextSched",
	CARRIER_DELIVERED_ORDERS: "model/br/carrier_delivered_orders",
	CARRIER_PAID_ORDERS: "model/br/carrier_paid_orders",
	CARRIER_UNPAID_ORDERS: "model/br/carrier_unpaid_orders",
	CARRIER_DISPATCH: "carrier/dispatch",
	CARRIER_DISPATCH_INFO: "carrier/dispatch/formdata",
	IMAGE_UPLOAD: "image/upload/:userType",
	IMAGES: "image/:userType/all",
	CARRIER_IMAGES: "image/carrier/all",
	CARRIER_OFFER_HISTORY_ORDERS: "carrier/offer/history",
	COMPANY_META_DATA: "ui/display/company",
	COUNTER_OFFER: "carrier/offer/counter",
	DECLINE_OFFER: "carrier/offer/decline",
	FACTORING_COMPANY_PAYMENT_DETAILS: "factoring/payment/details",
	FACTORING_COMPANY_PAYMENTS_DUE_ORDERS: "model/factoring/payments_due",
	FACTORING_COMPANY_PAYMENTS_DUE_ORDERS_COMMENTS: "model/factoring/payments_due_comments",
	FACTORING_COMPANY_PAYMENTS_RECEIVED_ORDERS: "model/factoring/payments_received",
	FACTORING_COMPANY_PAYMENTS_RECEIVED_ORDERS_COMMENTS: "model/factoring/payments_received_comments",
	IMAGE_DOWNLOAD: "image/download",
	LEGAL_TERMS: "getLegalTerms",
	ORDER_TERMS_GENERATE: "generateOrderConfirmation",
	ORDER_TERMS_RETRIEVE: "getOrderConfirmation",
	QUERY_LOCATION: "queryLocation",
	SETTLEMENT_HISTORY: "getSettlementHistory",
	SETTLEMENT_SUMMARY: "generateSettlementSummary",
	USER_ADMIN_EMAIL: "getAdminUserEmail",
	USER_CONTROLS: "getUserControls",
	USER_LEGACY_BASE_URL: "getLegacyBaseUrl",
	USER_LICENSES: "getUserLicenses",
	USER_PERMISSIONS: "getUserInternetModulePermissions"
};

// These paths to root are temporary until we figure out stuff in WebLoginPresentation
export const DEFAULT_USER_TYPE_PATH = {
	CUSTOMER: PATHS.ROOT,
	CARRIER: PATHS.ROOT,
	LOCATION: PATHS.ROOT,
	FACTORING_COMPANY: PATHS.ROOT,
	DRIVER: PATHS.ROOT
};

function calculateBaseUrl() {
	let result = window.location.origin;
	if (result.indexOf("localhost") >= 0) result = "http://localhost:8080/";
	else result += "/service-launcher/";
	return result;
}
