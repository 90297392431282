import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";
import { Call as MuiCallIcon } from "@material-ui/icons";

export const CallIcon = memo(function CallIcon(props) {
  return (
    <SvgIcon {...props}>
      <MuiCallIcon />
    </SvgIcon>
  );
});
