import React from "react";
import { Panel, TextBox, Table, Column, Label, Button, ButtonActions, showProgressSnackbar, useData } from "lib/components";
import { getCompanySettings } from "lib/util";
import { DownloadSettlementSummaryButton } from "./DownloadSettlementSummaryButton";

export default function ParamSettlementHistory() {
	const startDateDefault = "t-30";
	return (
		<Panel name="top" modelName="drs/settlement" fillHeight>
			<Panel variant="form">
				<TextBox caption="Order" fieldName="{tableName}.order_id" noRowBreak />
				<TextBox caption="Delivery start date" fieldName="delivery_date" default={startDateDefault} searchType="start" displayType="date" noRowBreak />
				<TextBox caption="Delivery end date" fieldName="delivery_date" searchType="end" displayType="date" noRowBreak/>
				<TextBox caption="Check start date" fieldName="pay_date" searchType="start" displayType="date" noRowBreak />
				<TextBox caption="Check end date" fieldName="pay_date" searchType="end" displayType="date" noRowBreak />
				<TextBox caption="Check number" fieldName="{tableName}.check_number" noRowBreak/>
				<TextBox caption="Carrier pro number" fieldName="carrier_pro_nbr" visible={getCompanySettings().isLTL} noRowBreak/>
				<Button action={ButtonActions.SEARCH} variant="contained" marginTop={24} width={108} color="primary"/>
			</Panel>
			<Table emptyCaption="No matching settlements found." 
				unsearchedCaption="Use the search above to find settlements." 
				exportName="Settlement History" alternateRowColor fillRow fillHeight>
				<Column caption="Order" fieldName="order_id" minWidth={120}/>
				<Column caption="Origin / pickup date"  sortFieldName="ship_date" sortDataType="datetime" minWidth={200}>
					<Label fieldName="origin_city" displayLabel="Origin city" color="primary" fontBold noRowBreak />
					<Label fieldName="origin_state" displayLabel="Origin state" color="primary" fontBold />
					<Label fieldName="ship_date" displayLabel="Pickup date" />
				</Column>
				<Column caption="Destination / delivery date" sortFieldName="delivery_date" sortDataType="datetime" minWidth={200}>
					<Label fieldName="destination_city" displayLabel="Destination city" color="primary" fontBold noRowBreak />
					<Label fieldName="destination_state" displayLabel="Destination state" color="primary" fontBold />
					<Label fieldName="delivery_date" displayLabel="Delivery date" />
				</Column>
				<Column caption="Load pay" fieldName="total_pay" align="right" minWidth={100}/>
				<Column caption="Other pay" fieldName="other_pay"  align="right" minWidth={100}/>
				<Column caption="Advances / deductions" fieldName="deductions"  align="right" minWidth={100}/>
				<Column caption="Net pay" fieldName="net_pay"  align="right" minWidth={100}/>
				<Column caption="Invoice number" fieldName="invoice"  nullDisplayValue="N/A" minWidth={100}/>
				<Column caption="Check number / date" sortFieldName="check_date" minWidth={100}>
					<Label fieldName="check_number" displayLabel="Check number" color="primary" fontBold marginBottom={0} nullDisplayValue="N/A"/>
					<DownloadSettlementSummaryButton />
				</Column> 
				<Column caption="Carrier pro number" fieldName="carrier_pro_nbr" nullDisplayValue="N/A" visible={getCompanySettings().isLTL} minWidth={120}/> 
			</Table>
		</Panel>
	);
}