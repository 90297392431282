import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    visible: {
      visibility: "visible"
    },
    hidden: {
      visibility: "hidden"
    },
    logo: {
      width: 500,
      height: 50,
      objectFit: "contain",
      objectPosition: "left"
    },
    name: {
      color: theme.branding.colors.headerText
    }
  };
});
